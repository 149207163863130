import * as _ from "lodash";
import { Box } from "@mui/material";
import { NJButton } from "@engie-group/fluid-design-system-react";
import { PrimaryButton } from "../../../../../../common/components/CustomButton";
import FullScreenModal from "../../../../../../common/components/FullScreenModal";
import SimpleEditableTable from "../../../../../../common/components/SimpleEditableTable";
import { EditHeadersType } from "../../../../../../common/components/SimpleEditableTable/SimpleEditableTable";
import { FieldTypes } from "../../../../../../common/components/SimpleEditableTable/SimpleEditableTableCell";
import { Spacer } from "../../../../../../common/components/Spacer";
import { useSimpleEditTable } from "../../../../../../common/hooks/useSimpleEditTable";
import { noop } from "../../../../../../common/utils/operations";
import {
	AssetType,
	ServicePointType,
	TechnologyType,
} from "../../../../../../requests_cm/gecoSitesService/types";
import { GenericReferential } from "../../../../../../requests_cm/gecoReferentialService/types";
import { useMemo, useState } from "react";
import { getListAssetValidationSchema } from "../AddAssetsForm.schema";
import InfoBlock from "../../../../../../common/components/InfoBlock/InfoBlock";
import TechnologyIcon from "../../../../../../common/components/TechnologyIcon";
import Country from "../../../../../../common/components/Country";
import { style } from "./style";
import CopyNumberModal from "./CopyNumberModal";
import { ErrorType } from "../../../../../../common/utils/formatApiErrorMessage";

export interface FormikAsset extends Omit<AssetType, "machine"> {
	machine: number;
}

export interface AssetTableProps {
	assets: FormikAsset[];
	machines: GenericReferential[] | undefined;
	servicePoints: ServicePointType[];
	initialEmptyAssetNumber?: number; // number of row to initialize the table with
	technology: TechnologyType | undefined;
	onSubmit: (data: { items: AssetType[] }) => void;
	site: { name: string; country: string };
	isOpen: boolean;
	onClose: () => void;
	errorUpdateSite?: ErrorType;
}

export const AssetTable = ({
	assets = [],
	isOpen = true,
	initialEmptyAssetNumber,
	technology,
	machines = [],
	servicePoints = [],
	site,
	onSubmit,
	onClose = noop,
	errorUpdateSite,
}: AssetTableProps) => {
	const windHeaders: EditHeadersType[] = [
		{
			label: "Asset Name",
			accessor: "name",
			type: FieldTypes.text,
		},
		{
			label: "Asset Key",
			accessor: "key",
			type: FieldTypes.text,
		},
		{
			label: "Manufacturer Serial Number",
			accessor: "manufacturer_serial_number",
			type: FieldTypes.text,
		},
		{
			label: "Machine Name",
			accessor: "machine",
			items: machines.map(({ id, name }) => {
				return {
					key: id,
					value: id,
					label: name,
				};
			}),
			type: FieldTypes.select,
		},
		{
			label: "Service Point",
			accessor: "service_point",
			items: servicePoints.map(({ id, name }) => {
				return {
					key: id,
					value: id,
					label: name,
				};
			}),
			type: FieldTypes.select,
		},
		{
			label: "Install Capacity",
			accessor: "installed_capacity",
			type: FieldTypes.number,
		},
		{
			label: "Latitude",
			accessor: "latitude",
			type: FieldTypes.number,
		},
		{
			label: "Longitude",
			accessor: "longitude",
			type: FieldTypes.number,
		},
		{
			label: "Hub Height",
			accessor: "hub_height",
			type: FieldTypes.number,
		},
		{
			label: "Rotor Diameter",
			accessor: "rotor_diameter",
			type: FieldTypes.number,
		},
		{
			label: "Subsidy",
			accessor: "subsidy",
			type: FieldTypes.number,
		},
		{
			label: "SR Code",
			accessor: "sr_code",
			type: FieldTypes.text,
		},
		{
			label: "TR Code",
			accessor: "tr_code",
			type: FieldTypes.text,
		},
		{
			label: "TR Master No",
			accessor: "tr_mastr_no",
			type: FieldTypes.text,
		},
		{
			label: "Start Date",
			accessor: "start_date",
			type: FieldTypes.date,
		},
		{
			label: "End Date (excluded)",
			accessor: "end_date",
			type: FieldTypes.date,
		},
		{
			label: "Commissioning Date",
			accessor: "commissioning_date",
			type: FieldTypes.date,
		},
	];

	const solarHeaders: EditHeadersType[] = [
		{
			label: "Asset Name",
			accessor: "name",
			type: FieldTypes.text,
		},
		{
			label: "Asset Key",
			accessor: "key",
			type: FieldTypes.text,
		},
		{
			label: "Manufacturer Serial Number",
			accessor: "manufacturer_serial_number",
			type: FieldTypes.text,
		},
		{
			label: "Machine Name",
			accessor: "machine",
			items: machines.map(({ id, name }) => {
				return {
					key: id,
					value: id,
					label: name,
				};
			}),
			type: FieldTypes.select,
		},
		{
			label: "Service Point",
			accessor: "service_point",
			items: servicePoints.map(({ id, name }) => {
				return {
					key: id,
					value: id,
					label: name,
				};
			}),
			type: FieldTypes.select,
		},
		{
			label: "Install Capacity",
			accessor: "installed_capacity",
			type: FieldTypes.number,
		},
		{
			label: "Latitude",
			accessor: "latitude",
			type: FieldTypes.number,
		},
		{
			label: "Longitude",
			accessor: "longitude",
			type: FieldTypes.number,
		},
		{
			label: "Azimuth",
			accessor: "azimuth",
			type: FieldTypes.number,
		},
		{
			label: "Tilt Angle",
			accessor: "tilt_angle",
			type: FieldTypes.number,
		},
		{
			label: "Subsidy",
			accessor: "subsidy",
			type: FieldTypes.number,
		},
		{
			label: "SR Code",
			accessor: "sr_code",
			type: FieldTypes.text,
		},
		{
			label: "TR Code",
			accessor: "tr_code",
			type: FieldTypes.text,
		},
		{
			label: "TR Master No",
			accessor: "tr_mastr_no",
			type: FieldTypes.text,
		},
		{
			label: "Start Date",
			accessor: "start_date",
			type: FieldTypes.date,
		},
		{
			label: "End Date (excluded)",
			accessor: "end_date",
			type: FieldTypes.date,
		},
		{
			label: "Commissioning Date",
			accessor: "commissioning_date",
			type: FieldTypes.date,
		},
	];

	const headers: EditHeadersType[] = useMemo(() => {
		if (technology === TechnologyType.Solar) {
			return solarHeaders;
		}
		return windHeaders;
	}, [technology, windHeaders, solarHeaders]);

	const {
		addRow,
		deleteRows,
		clearColumn,
		fillInColumn,
		handleRowClick,
		submitForm,
		duplicate,
		formikRef,
		selectedRows,
	} = useSimpleEditTable(headers);

	const [nbrOfDuplicates, setNbrDuplicates] = useState(0);

	const initialAssets = useMemo(() => {
		if (!assets.length && initialEmptyAssetNumber) {
			const emptyAsset = {};
			const items = [];
			for (const header of headers) {
				_.set(emptyAsset, header.accessor, undefined);
			}
			for (let i = 0; i < initialEmptyAssetNumber; i++) {
				items.push(emptyAsset);
			}
			return items;
		}
		return assets;
	}, [assets, initialEmptyAssetNumber]);

	return (
		<>
			<FullScreenModal
				title="Multi add Asset"
				open={isOpen}
				onClose={onClose}
				actions={
					<>
						<PrimaryButton
							onClick={onClose}
							text="Cancel"
							type="button"
							color="secondary"
						/>
						<PrimaryButton
							onClick={submitForm}
							text="Confirm"
							type="button"
							color="primary"
						/>
					</>
				}
			>
				<Box>
					<InfoBlock
						head={[{ value: site?.name }]}
						info={[
							{
								label: "Techno",
								value: technology,
								icon: (
									<TechnologyIcon
										technology={
											technology as TechnologyType
										}
									/>
								),
							},
							{
								label: "Country",
								value: <Country country={site?.country} />,
							},
						]}
						withBackground
						fullWidth
					/>
					<Spacer gap={24} />
					<Box sx={{ display: "flex", gap: 4 }}>
						<NJButton
							icon="add"
							label="Add Row"
							emphasis="subtle"
							onClick={addRow}
							isDisabled={false}
						/>
						<NJButton
							icon="add_circle_outline"
							label="Delete Row"
							emphasis="subtle"
							onClick={deleteRows}
							isDisabled={selectedRows.length === 0}
						/>
						<NJButton
							icon="control_point_duplicate"
							label="Duplicate"
							emphasis="subtle"
							onClick={() => setNbrDuplicates(1)}
							isDisabled={selectedRows.length !== 1}
						/>
					</Box>
					<Spacer gap={24} />
					<Box sx={style.table}>
						<SimpleEditableTable
							validationSchema={getListAssetValidationSchema(
								technology as TechnologyType
							)}
							onSubmit={onSubmit}
							clearColumn={clearColumn}
							fillInColumn={fillInColumn}
							headers={headers}
							handleRowClick={handleRowClick}
							selectedRows={selectedRows}
							items={initialAssets || []}
							formikRef={formikRef}
							selectableRows
							errorUpdateSite={errorUpdateSite}
						/>
					</Box>
				</Box>
			</FullScreenModal>
			<CopyNumberModal
				numberOfAssets={nbrOfDuplicates}
				setAssetsNumber={setNbrDuplicates}
				open={nbrOfDuplicates > 0}
				onClose={() => {
					setNbrDuplicates(0);
				}}
				onConfirm={() => {
					duplicate(
						nbrOfDuplicates,
						selectedRows[0] as number,
						"name"
					);
					setNbrDuplicates(0);
				}}
			/>
		</>
	);
};
