import { RootState } from "../../core/rootReducers";

export function selectErrorModalState(state: RootState) {
	return state.errorModal;
}

export function selectErrorModalError(state: RootState) {
	return selectErrorModalState(state).error;
}

export function selectErrorModalLoading(state: RootState) {
	return selectErrorModalState(state).submit.loading;
}

export function selectErrorModalSuccess(state: RootState) {
	return selectErrorModalState(state).submit.success;
}
