import * as React from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import * as _ from "lodash";
import { CustomButtonProps, PrimaryButton } from "./CustomButton";

export interface ButtonDropdownProps
	extends React.PropsWithChildren<{}>,
		CustomButtonProps {
	items: React.ReactNode[];
}

export default function ButtonDropdown(props: ButtonDropdownProps) {
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);
	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	return (
		<>
			<PrimaryButton
				data-testid="test-dropdown-primary-button"
				id="button-dropdown"
				aria-controls={open ? "basic-menu" : undefined}
				aria-haspopup="true"
				aria-expanded={open ? "true" : undefined}
				onClick={handleClick}
				{..._.omit(props, "items")}
			>
				{props.children}
			</PrimaryButton>
			<Menu
				id="basic-menu"
				anchorEl={anchorEl}
				open={open}
				onClose={handleClose}
				MenuListProps={{
					"aria-labelledby": "button-dropdown",
				}}
			>
				{props.items.map((child, index) => (
					<MenuItem key={index} onClick={handleClose}>
						{child}
					</MenuItem>
				))}
			</Menu>
		</>
	);
}
