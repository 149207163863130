import Box from "@mui/material/Box";
import { ContractPeriodDealsResponseType } from "../../../../requests_cm/gecoContractsService/types";
import { DEAL_STATUS, DEAL_SYNC } from "./DealTab.const";
import { style } from "./DealTab.style";
import InfoIcon from "@mui/icons-material/Info";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { useState } from "react";
import { PrimaryButton } from "../../../../common/components/CustomButton";
import SimpleTable from "../../../../common/components/SimpleTable/SimpleTable";
import CopyToClipboardButton from "../../../../common/components/CopyToClipboardButton";
import Modal from "../../../../common/components/Modal";
import { formatStringDate } from "../../../../common/utils/dateUtils";

const DealTabStatusButton = ({
	deal,
}: {
	deal: ContractPeriodDealsResponseType;
}) => {
	const [isDialogOpen, setIsDialogOpen] = useState(false);

	const status =
		deal.sync_status === DEAL_SYNC.SYNCED &&
		deal.bcv_sync_status === DEAL_SYNC.SYNCED
			? DEAL_STATUS.ACTIVE
			: DEAL_STATUS.ERROR;

	const statusStyle =
		status === DEAL_STATUS.ACTIVE
			? style.active
			: { ...style.active, ...style.error };

	const icon =
		status === DEAL_STATUS.ACTIVE ? (
			<CheckIcon sx={{ ...style.icon, ...style.activeIcon }} />
		) : (
			<CloseIcon sx={{ ...style.icon, ...style.errorIcon }} />
		);

	const handleOpenDialog = () => setIsDialogOpen(true);
	const handleCloseDialog = () => setIsDialogOpen(false);

	const headers = [
		{
			label: "Last Sync Time (CET)",
			accessor: "last_sync_time",
		},
		{
			label: "BCV Sync Status",
			accessor: "bcv_sync_status",
		},
		{
			label: "BCV Sync Error",
			accessor: "bcv_sync_error",
		},
		{
			label: "OT Sync",
			accessor: "ot_sync",
		},
		{
			label: "OT Sync Error",
			accessor: "ot_sync_error",
		},
	];

	const items = [
		{
			last_sync_time: (
				<Box sx={{ whiteSpace: "nowrap" }}>
					{formatStringDate(deal.last_sync_time, "yyyy-MM-dd HH:mm")}
				</Box>
			),
			bcv_sync_status: deal.bcv_sync_status,
			bcv_sync_error: (
				<CopyToClipboardButton
					label={deal.bcv_sync_error}
					text={deal.bcv_sync_error}
				/>
			),
			ot_sync: deal.sync_status,
			ot_sync_error: (
				<CopyToClipboardButton
					label={deal.sync_error}
					text={deal.sync_error}
				/>
			),
		},
	];

	return (
		<>
			<Box role="button" sx={statusStyle} onClick={handleOpenDialog}>
				{icon}
				{status}
				<InfoIcon sx={style.icon} />
			</Box>
			<Modal
				title={
					<>
						<InfoIcon sx={style.statusDialogTitleIcon} />
						Deal status details
					</>
				}
				onClose={handleCloseDialog}
				open={isDialogOpen}
				actions={
					<PrimaryButton
						onClick={handleCloseDialog}
						text="Close"
						type="button"
						color="primary"
					/>
				}
				maxWidth="xl"
			>
				<SimpleTable headers={headers} items={items} />
			</Modal>
		</>
	);
};

export default DealTabStatusButton;
