import { enqueueSnackbar } from "notistack";
import { Box, CircularProgress } from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import AlertDialogModal from "../../common/components/AlertDialogModal";
import AlertDialogWithTextArea from "../../common/components/AlertDialogWithTextArea";
import { PrimaryButton } from "../../common/components/CustomButton";
import ErrorMessageModal from "../../common/components/ErrorMessageDialog";
import { FluidButton } from "../../common/components/FluidButton";
import { If } from "../../common/components/If";
import { OverlayLoader } from "../../common/components/OverlayLoader";
import PageContent from "../../common/components/PageContent";
import PageTitle from "../../common/components/PageTitle/PageTitle";
import { useNavigate, useParams } from "react-router-dom";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import {
	ChangeRequestStatus,
	ChangeRequestResourceType,
} from "../../requests_cm/gepoChangeRequestService/types";
import { ContractValidation } from "./ValidationKind/ContractValidation";
import InfoBlock from "../../common/components/InfoBlock/InfoBlock";
import { formatDateDDMMYYYY } from "../../common/utils/dateUtils";
import { StatusIcon } from "../change_requests_page/StatusIcon";
import { PATH } from "../../router-path";
import {
	useAskCorrectionsOnChangeRequestMutation,
	useLazyGetSingleChangeRequestQuery,
	useRejectChangeRequestMutation,
	useValidateChangeRequestMutation,
} from "../../requests_cm/gepoChangeRequestService/service";

const style = {
	buttonGroup: {
		display: "flex",
		gap: "8px",
	},
	infoBlock: {
		padding: "32px 40px 32px 40px",
	},
	progressWrapper: {
		display: "flex",
		justifyContent: "center",
	},
};

export interface RefMethod {
	onConfirmUpdate: () => void;
}

export const ChangeRequestValidation = () => {
	const validationKindRef = useRef<RefMethod>(null);

	const [isConfirmModalOpen, setConfirmModalOpen] = useState(false);
	const [isRejectModalOpen, setRejectModalOpen] = useState(false);
	const [isAskCorrectionsModalOpen, setAskCorrectionsModalOpen] =
		useState(false);

	const { changeRequestId } = useParams();
	const navigate = useNavigate();

	const [
		getSingleChangeRequest,
		{
			isLoading: isGetChangeRequestLoading,
			error: getChangeRequestError,
			data: changeRequest,
		},
	] = useLazyGetSingleChangeRequestQuery();

	const [
		transitionChangeRequestStatusToValid,
		{
			error: validTranstionError,
			isSuccess: isValidTransitionSuccess,
			isLoading: isValidTranstionLoading,
		},
	] = useValidateChangeRequestMutation();

	const [
		transitionChangeRequestStatusToRejected,
		{
			error: rejectTranstionError,
			isSuccess: isRejectTransitionSuccess,
			isLoading: isRejectTranstionLoading,
		},
	] = useRejectChangeRequestMutation();

	const [
		transitionChangeRequestStatusToAskCorrections,
		{
			error: askCorrectionsTranstionError,
			isSuccess: isAskCorrectionsTransitionSuccess,
			isLoading: isAskCorrectionsTranstionLoading,
		},
	] = useAskCorrectionsOnChangeRequestMutation();

	const goBackToChangeRequestList = () => navigate(PATH.CHANGE_REQUESTS);

	const onApproveSuccess = useCallback(() => {
		transitionChangeRequestStatusToValid({ id: Number(changeRequestId) });
	}, [changeRequestId]);

	const onReject = useCallback(
		(message: string) => {
			transitionChangeRequestStatusToRejected({
				id: Number(changeRequestId),
				message,
			});
		},
		[changeRequestId]
	);

	const onAskCorrections = useCallback(
		(message: string) => {
			transitionChangeRequestStatusToAskCorrections({
				id: Number(changeRequestId),
				message,
			});
		},
		[changeRequestId]
	);

	const onApprove = useCallback(() => {
		if (validationKindRef?.current) {
			//@ts-ignore
			validationKindRef?.current?.onConfirmUpdate();
		}
	}, [validationKindRef?.current]);

	const isPageLoading = useMemo(() => {
		return (
			isGetChangeRequestLoading ||
			isValidTranstionLoading ||
			isRejectTranstionLoading ||
			isAskCorrectionsTranstionLoading
		);
	}, [
		isGetChangeRequestLoading,
		isValidTranstionLoading,
		isRejectTranstionLoading,
		isAskCorrectionsTranstionLoading,
	]);

	useEffect(() => {
		if (changeRequestId) {
			getSingleChangeRequest({ id: Number(changeRequestId) });
		}
	}, [changeRequestId]);

	useEffect(() => {
		if (
			isValidTransitionSuccess ||
			isRejectTransitionSuccess ||
			isAskCorrectionsTransitionSuccess
		) {
			enqueueSnackbar(`Request change has been updated`, {
				variant: "success",
				autoHideDuration: 3000,
			});

			goBackToChangeRequestList();
		}
	}, [
		isValidTransitionSuccess,
		isRejectTransitionSuccess,
		isAskCorrectionsTransitionSuccess,
	]);

	return (
		<>
			<PageTitle
				fontWeight={"bold"}
				label="Change Request Validation"
				leftSide={
					<PrimaryButton
						text="Back"
						type="button"
						color="info"
						sx={{
							width: 106,
							color: "#171D21",
						}}
						onClick={goBackToChangeRequestList}
					>
						<ChevronLeftIcon />
					</PrimaryButton>
				}
				hideRightSide={false}
				rightSide={
					<Box sx={style.buttonGroup}>
						<FluidButton
							onClick={() => setAskCorrectionsModalOpen(true)}
							label="Request corrections"
							type="button"
							variant="secondary"
						></FluidButton>
						<FluidButton
							onClick={() => setRejectModalOpen(true)}
							label="Reject changes"
							type="button"
							variant="destructive"
						></FluidButton>
						<FluidButton
							onClick={() => setConfirmModalOpen(true)}
							label="Validate changes"
							type="button"
							variant="primary"
						></FluidButton>
					</Box>
				}
			/>

			<If condition={!isPageLoading}>
				<Box sx={style.infoBlock}>
					<InfoBlock
						withBackground
						fullWidth
						head={[
							{
								label: changeRequest?.resource_type,
								value: changeRequest?.name,
							},
						]}
						info={[
							{
								label: "Requester",
								value: (
									<p style={{ textTransform: "capitalize" }}>
										{changeRequest?.requester?.first_name}{" "}
										{changeRequest?.requester?.last_name}
									</p>
								),
							},
							{
								label: "Validator",
								value: (
									<p style={{ textTransform: "capitalize" }}>
										{changeRequest?.validator?.first_name}{" "}
										{changeRequest?.validator?.last_name}
									</p>
								),
							},
							{
								label: "Request date",
								value: formatDateDDMMYYYY(
									changeRequest?.created_at
								),
							},
							{
								label: "Status",
								value: (
									<StatusIcon
										status={
											changeRequest?.status ||
											ChangeRequestStatus.PENDING
										}
									/>
								),
							},
						]}
					/>
				</Box>

				<If
					condition={[
						ChangeRequestResourceType.CONTRACT,
						ChangeRequestResourceType.CONTRACT_PERIOD,
					].includes(
						changeRequest?.resource_type as ChangeRequestResourceType
					)}
				>
					<PageContent>
						<ContractValidation
							//@ts-ignore
							ref={validationKindRef}
							contractId={changeRequest?.resource_id}
							jsonDiff={changeRequest?.diff_json || []}
							onConfirmSuccess={onApproveSuccess}
						/>
					</PageContent>
				</If>

				<If condition={isPageLoading}>
					<OverlayLoader />
				</If>
				<ErrorMessageModal
					title={"Something went wrong"}
					content={
						getChangeRequestError ||
						validTranstionError ||
						rejectTranstionError ||
						askCorrectionsTranstionError
					}
				/>
				<AlertDialogModal
					title={"Are you sure you want to validate the changes?"}
					isOpen={isConfirmModalOpen}
					handleApprove={onApprove}
					handleClose={() => setConfirmModalOpen(false)}
				/>
				<AlertDialogWithTextArea
					title={
						"Please provide the reason for rejecting the changes."
					}
					textBoxTitle="Reason for rejection"
					isTextRequired
					isOpen={isRejectModalOpen}
					handleClose={() => setRejectModalOpen(false)}
					handleApprove={(message) => onReject(message)}
				/>

				<AlertDialogWithTextArea
					title={
						"Please provide the corrections you want to see on the request."
					}
					textBoxTitle="Corrections"
					isTextRequired
					isOpen={isAskCorrectionsModalOpen}
					handleClose={() => setAskCorrectionsModalOpen(false)}
					handleApprove={(message) => onAskCorrections(message)}
				/>
			</If>
			<If condition={isPageLoading}>
				<Box sx={style.progressWrapper}>
					<CircularProgress data-testid="loader" />
				</Box>
			</If>
		</>
	);
};
