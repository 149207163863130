import {
	AssetType,
	GetOneSiteResponseType,
	ServicePointType,
	TechnologyType,
} from "../../../../requests_cm/gecoSitesService/types";
import SimpleTable, {
	HeadersType,
} from "../../../../common/components/SimpleTable/SimpleTable";
import {
	isAfterOrEqual,
	isBeforeOrEqual,
} from "../../../../common/utils/dateUtils";
import { useCallback, useEffect, useMemo, useState } from "react";
import _ from "lodash";
import { FluidButton } from "../../../../common/components/FluidButton";
import AssetsTabFilters from "./AssetsTabFilters";
import { FieldValue } from "../../../../common/components/FiltersDrawer";
import { Spacer } from "../../../../common/components/Spacer";
import NoDataMessage from "../../../../common/components/NoDataMessage";
import { Box, IconButton } from "@mui/material";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import AddAssetsModal from "./components/AddAssetsModal";
import { useUpdateOneSiteMutation } from "../../../../requests_cm/gecoSitesService/service";
import { useRtkQueryDynamicEndpoint } from "../../../../common/hooks/useRtkQueryDynamicEndpoint";
import { FormikValues } from "formik";
import { useGetMachinesQuery } from "../../../../requests_cm/gecoReferentialService/service";
import { ErrorType } from "../../../../common/utils/formatApiErrorMessage";
import { AssetTable } from "./components/AssetTable";
import { If } from "../../../../common/components/If";
import EditAssetModal from "./components/EditAssetModal";

const AssetsTab = ({ site }: { site: GetOneSiteResponseType }) => {
	const { data: machines, isLoading: isMachinesLoading } =
		useRtkQueryDynamicEndpoint(useGetMachinesQuery)({});

	const [updateOneSiteBase, { error: errorUpdateSite, isSuccess, reset }] =
		useUpdateOneSiteMutation();
	const updateOneSite = useRtkQueryDynamicEndpoint(updateOneSiteBase);

	const [isMultipleAssetEditOpen, setIsMultipleAssetEditOpen] =
		useState(false);
	const [isModalAddAssetsOpen, setIsModalAddAssetsOpen] = useState(false);
	const [editingAssetId, setEditingAssetId] = useState<number | null>(null);
	const [filterOpen, setFilterOpen] = useState(false);
	const [filters, setFilters] = useState<Record<string, FieldValue>>({});

	const initialAssets = useMemo(() => {
		return _.flatMap(site.service_points, (servicePoint) =>
			servicePoint.assets.map((asset) => ({
				id: asset.key,
				servicePoint: servicePoint.name,
				...asset,
			}))
		);
	}, [site]);

	const initialEditAssets = _.flatMap(site.service_points, (servicePoint) =>
		servicePoint.assets.map((asset) => ({
			...asset,
			service_point: servicePoint.id,
			machine: asset.machine.id,
		}))
	);

	const [assets, setAssets] =
		// @ts-ignore
		useState<(AssetType & { servicePoint: string })[]>(initialAssets);

	const createSiteAssets = useCallback(
		(formAsset: FormikValues[]) => {
			const newSite = _.cloneDeep(site);
			const cloneAsset = _.cloneDeep(formAsset);
			for (const asset of cloneAsset) {
				const previousAssetDefinition = initialEditAssets.find(
					(searchAsset) => searchAsset.id === asset.id
				);
				const servicePoint = newSite.service_points.find(
					(sp) => sp.id === asset.service_point
				);
				const previousServicePoint = newSite.service_points.find(
					(sp) => sp.id === previousAssetDefinition?.service_point
				);

				if (previousServicePoint) {
					_.remove(
						previousServicePoint.assets,
						(spAsset) => spAsset.id === asset.id
					);
				}

				asset.machine = (machines || []).find(
					(machine) => machine.id === asset.machine
				);
				delete asset.service_point;

				servicePoint?.assets.push(asset as AssetType);
			}
			updateOneSite(newSite);
		},
		[site, machines]
	);

	const updateSiteAssets = useCallback(
		(formAsset: FormikValues[]) => {
			const cloneAsset = _.cloneDeep(formAsset);
			const newSite = {
				...site,
				service_points: site.service_points.map((sp) => ({
					...sp,
					assets: [],
				})),
			};
			for (const asset of cloneAsset) {
				const servicePoint = newSite.service_points.find(
					(sp) => sp.id === asset.service_point
				);
				asset.machine = (machines || []).find(
					(machine) => machine.id === asset.machine
				);
				delete asset.service_point;
				// @ts-ignore
				servicePoint?.assets.push(asset as AssetType);
			}
			updateOneSite(newSite);
		},
		[site, machines]
	);

	const updateOneAsset = (assetValues: FormikValues) => {
		setEditingAssetId(null);

		assetValues.machine = (machines || []).find(
			(machine) => String(machine.id) === String(assetValues.machine)
		);

		const newSite = _.cloneDeep(site);

		const servicePoint = newSite.service_points.find(
			(sp: ServicePointType) => sp.id === assetValues.service_point
		);

		const oldServicePoint = newSite.service_points.find(
			(sp: ServicePointType) =>
				sp.assets.some((a: AssetType) => a.id === assetValues.id)
		);

		delete assetValues.service_point;

		if (oldServicePoint?.id) {
			oldServicePoint.assets = oldServicePoint.assets.filter(
				(a: AssetType) => a.id !== assetValues.id
			);
		}

		servicePoint?.assets.push(assetValues as AssetType);

		updateOneSite(newSite);
	};

	const headers: HeadersType<ServicePointType>[] = [
		{
			accessor: "servicePoint",
			label: "Service Point",
			colHeader: true,
		},
		{ accessor: "name", label: "Asset Name" },
		{ accessor: "machine.name", label: "Machine Name" },
		{ accessor: "installed_capacity", label: "Installed Capacity" },
		{ accessor: "latitude", label: "Latitude" },
		{ accessor: "longitude", label: "Longitude" },
		...(site.technology === TechnologyType.Solar
			? [
					{ accessor: "azimuth", label: "Azimuth" },
					{ accessor: "tilt_angle", label: "Tilt Angle" },
			  ]
			: [
					{ accessor: "hub_height", label: "Hub Height" },
					{ accessor: "rotor_diameter", label: "Rotor Diameter" },
			  ]),

		{ accessor: "manufacturer_serial_number", label: "Serial Number" },
		{
			accessor: "commissioning_date",
			label: "Commissioning Date",
		},
		...(site.country === "DE"
			? [
					{ accessor: "key", label: "Asset Key" },
					{ accessor: "sr_code", label: "Sr Code" },
					{ accessor: "tr_code", label: "Tr Code" },
					{ accessor: "tr_mastr_no", label: "Tr Master No" },
			  ]
			: []),
		{ accessor: "actions", label: "Actions" },
	];

	useEffect(() => {
		// @ts-ignore
		setAssets(() => {
			let filteredAssets = initialAssets;

			if ((filters.servicepoints__method__in as string[])?.length) {
				filteredAssets = filteredAssets.filter((asset) =>
					(filters.servicepoints__method__in as string[]).includes(
						asset.servicePoint
					)
				);
			}
			if (filters.start_date__ge) {
				filteredAssets = filteredAssets.filter(
					(asset) =>
						!!asset.start_date &&
						isAfterOrEqual(
							new Date(asset.start_date),
							new Date(filters.start_date__gte as string)
						)
				);
			}
			if (filters.end_date__le) {
				filteredAssets = filteredAssets.filter(
					(asset) =>
						!!asset.end_date &&
						isBeforeOrEqual(
							new Date(asset.end_date),
							new Date(filters.start_date__gte as string)
						)
				);
			}

			return filteredAssets.map((asset) => ({
				...asset,
				actions: (
					<Box sx={{ display: "flex", justifyContent: "center" }}>
						<IconButton
							onClick={() => setEditingAssetId(Number(asset.id))}
							size="small"
							sx={{ margin: "-10px 0" }}
						>
							<ModeEditIcon />
						</IconButton>
					</Box>
				),
			}));
		});
	}, [filters, site]);

	useEffect(() => {
		if (isSuccess) {
			setIsModalAddAssetsOpen(false);
			setIsMultipleAssetEditOpen(false);
		}
	}, [isSuccess, setIsModalAddAssetsOpen, setIsMultipleAssetEditOpen]);

	useEffect(() => {
		// @ts-ignore
		if (errorUpdateSite?.data?.length > 0) {
			// @ts-ignore
			const firstError = errorUpdateSite.data[0];
			const elementId = `${firstError.loc[3]}-${firstError.loc[4]}`;
			const element = document.getElementById(elementId);

			element?.scrollIntoView({ behavior: "smooth" });
		}
	}, [errorUpdateSite]);

	return (
		<>
			{!site.service_points.length && <NoDataMessage />}
			{!!site.service_points.length && (
				<>
					<Box
						sx={{
							display: "flex",
							justifyContent: "space-between",
							paddingRight: "24px",
						}}
					>
						<Box sx={{ display: "flex", gap: "16px" }}>
							<FluidButton
								label="Add asset(s)"
								onClick={() => setIsModalAddAssetsOpen(true)}
								icon="add_circle_outline"
							/>
							<FluidButton
								label="Edit asset(s)"
								onClick={() => setIsMultipleAssetEditOpen(true)}
								icon="edit"
								variant="secondary"
							/>
						</Box>
						<FluidButton
							label={"Filters"}
							icon="filter_list"
							onClick={() => setFilterOpen(true)}
							variant="secondary"
						/>
					</Box>
					<Spacer gap={16} />
					<SimpleTable headers={headers} items={assets} />
					{machines && !isMachinesLoading && (
						<>
							<AddAssetsModal
								open={isModalAddAssetsOpen}
								onClose={() => setIsModalAddAssetsOpen(false)}
								site={{
									name: site.name,
									country: site.country,
									technology: site.technology,
								}}
								servicePoints={site.service_points}
								updateSiteAssets={createSiteAssets}
								machines={machines || []}
							/>
							{editingAssetId && (
								<EditAssetModal
									open={true}
									onClose={() => setEditingAssetId(null)}
									site={{
										name: site.name,
										country: site.country,
										technology: site.technology,
									}}
									servicePoints={site.service_points}
									updateAsset={updateOneAsset}
									machines={machines}
									assetId={editingAssetId}
								/>
							)}
						</>
					)}

					<AssetsTabFilters
						servicePoints={site.service_points.map(
							(servicePoint) => servicePoint.name
						)}
						defaultFilters={filters}
						isOpen={filterOpen}
						onApplyFilters={(newFilters) => {
							setFilterOpen(false);
							setFilters(newFilters);
						}}
						onClose={() => setFilterOpen(false)}
					/>
				</>
			)}
			<If condition={isMultipleAssetEditOpen}>
				<AssetTable
					onSubmit={(data: { items: AssetType[] }) => {
						reset();
						updateSiteAssets(data.items);
					}}
					technology={site.technology as TechnologyType}
					assets={initialEditAssets}
					isOpen={isMultipleAssetEditOpen}
					onClose={() => {
						reset();
						setIsMultipleAssetEditOpen(false);
					}}
					site={site}
					machines={machines}
					servicePoints={site.service_points}
					errorUpdateSite={errorUpdateSite as ErrorType}
				/>
			</If>
		</>
	);
};

export default AssetsTab;
