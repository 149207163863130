import { useSearchParams, useNavigate, useParams } from "react-router-dom";
import _ from "lodash";
import { Box, Button, Tooltip } from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import EditIcon from "@mui/icons-material/Edit";
import TrackChangesIcon from "@mui/icons-material/TrackChanges";
import PageTitle from "../../common/components/PageTitle/PageTitle";
import PageContent from "../../common/components/PageContent";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Spacer } from "../../common/components/Spacer";
import InfoBlock from "../../common/components/InfoBlock/InfoBlock";
import { PrimaryButton } from "../../common/components/CustomButton";
import {
	useLazyGetOneContractQuery,
	useUpdateContractStatusMutation,
	useUpdateOneContractDryRunMutation,
	useUpdateOneContractMutation,
} from "../../requests_cm/gecoContractsService/service";
import SimpleTable, {
	HeadersType,
} from "../../common/components/SimpleTable/SimpleTable";
import {
	ContractDetailType,
	ContractPeriodType,
	ContractStatus,
	TradeType,
} from "../../requests_cm/gecoContractsService/types";
import YesNoTag from "../../common/components/YesNoTag";
import { ErrorText } from "../../common/components/ErrorText";
import {
	ErrorType,
	formatApiErrorMessage,
} from "../../common/utils/formatApiErrorMessage";
import { useRtkQueryDynamicEndpoint } from "../../common/hooks/useRtkQueryDynamicEndpoint";
import { If } from "../../common/components/If";
import { getPathWithParameters, PATH, setFilters } from "../../router-path";
import { ContactModal } from "./ContactModal";
import { CounterPartModal } from "./CounterPartModal";
import { MakeClickable } from "../../common/components/MakeClickable";
import { FluidLink } from "../../common/components/FluidLink";
import { useIsUserWithinGroups } from "../../common/hooks/useIsUserWithinGroups";
import { useChangeRequest } from "../../requests_cm/gepoChangeRequestService/useChangeRequest";
import { Groups } from "../authentication/authentication.constant";
import {
	CounterpartyFormik,
	counterpartyFormikToContractDetailMapper,
} from "./formik/counterpartyFormik";
import { enqueueSnackbar } from "notistack";
import { ChangeRequestAlert } from "../../common/components/ChangeRequestAlert";
import { contractDetailChangeToChangeRequestMapper } from "../../requests_cm/gepoChangeRequestService/utils";
import { ChangeRequestResourceType } from "../../requests_cm/gepoChangeRequestService/types";
import ContractSplitModal from "./forms/ContractSplit/ContractSplitModal";
import { FluidButton } from "../../common/components/FluidButton";
import ErrorMessageModal from "../../common/components/ErrorMessageDialog";

const ContractPeriodList = () => {
	const navigate = useNavigate();
	const { contractId } = useParams();
	const [searchParams] = useSearchParams();

	const { isUserAuthorized } = useIsUserWithinGroups();

	const changeRequestId = searchParams.get("changeRequestId");

	const [isContractSplitModalOpen, setContractSplitModalOpen] =
		useState(false);
	const [isContactModalOpen, setContactModalOpen] = useState(false);
	const [selectedContract, setContractSelected] =
		useState<ContractDetailType | null>(null);

	const isUserTrader = useMemo(() => {
		return isUserAuthorized([Groups.trader]);
	}, [isUserAuthorized]);

	const [
		getContractBase,
		{
			data: originalContract,

			isLoading: isGetContractLoading,
			error,
		},
	] = useLazyGetOneContractQuery();

	const {
		isLoading: isChangeRequestLoading,
		upsertChangeRequest,
		ressouceWithChanges: contractData,
		changeRequest,
		errorMessage: changeRequestErrorMessage,
	} = useChangeRequest(
		changeRequestId ? Number(changeRequestId) : undefined,
		originalContract
	);
	const [updateContractBase, { isLoading: isUpdateContractLoading }] =
		useUpdateOneContractMutation();
	const [dryRunContractBase, { isLoading: isDryRunLoading }] =
		useUpdateOneContractDryRunMutation();

	const [errorMessage, setErrorMessage] = useState<
		string | string[] | undefined
	>();

	const [
		updateContractStatusbase,
		{
			isLoading: isUpdateContractStatusLoading,
			error: updateContractStatusError,
			reset,
		},
	] = useUpdateContractStatusMutation();

	const updateContract = useRtkQueryDynamicEndpoint(updateContractBase);
	const dryRunContract = useRtkQueryDynamicEndpoint(dryRunContractBase);
	const getContract = useRtkQueryDynamicEndpoint(getContractBase);
	const updateContractStatus = useRtkQueryDynamicEndpoint(
		updateContractStatusbase
	);

	const validateContract = useCallback(() => {
		if (contractData?.id) {
			updateContractStatus({
				contract_id: contractData.id,
				status: ContractStatus.VALID,
			});
		}
	}, [contractData?.id, updateContractStatus]);

	const naviateOnThisContractChangeRequest = useCallback(() => {
		navigate(
			PATH.CHANGE_REQUESTS,
			setFilters({
				resource_type__in: [ChangeRequestResourceType.CONTRACT],
				resource_id__in: [String(contractId)],
			})
		);
	}, [navigate, contractId]);

	const onCreateChangeRequest = async (
		currentContract: ContractDetailType,
		updatedContract: ContractDetailType
	) => {
		try {
			const dryRunResponse = await dryRunContract(updatedContract);
			//@ts-ignore
			if (dryRunResponse?.error) {
				setErrorMessage(
					//@ts-ignore
					dryRunResponse?.error
				);
				return;
			}
			const changeRequestUpdate = await upsertChangeRequest(
				contractDetailChangeToChangeRequestMapper(
					currentContract,
					updatedContract
				)
			);
			if (changeRequestUpdate?.errors) {
				setErrorMessage(changeRequestUpdate?.errors);
				return;
			}
			enqueueSnackbar(
				`Request change on contract ${selectedContract?.id} has been sent`,
				{
					variant: "success",
					autoHideDuration: 3000,
				}
			);
			naviateOnThisContractChangeRequest();
		} catch (rejectedValueOrSerializedError) {
			const errors = formatApiErrorMessage(
				rejectedValueOrSerializedError as ErrorType
			);
			setErrorMessage(errors);
		}
	};

	const callUpdateContract = async (updatedContract: ContractDetailType) => {
		try {
			await updateContract(updatedContract);
			enqueueSnackbar(
				`contract ${selectedContract?.id} updated successfully`,
				{
					variant: "success",
					autoHideDuration: 3000,
				}
			);
		} catch (rejectedValueOrSerializedError) {
			const errors = formatApiErrorMessage(
				rejectedValueOrSerializedError as ErrorType
			);
			setErrorMessage(errors);
		}
	};

	const handleUpdateContract = useCallback(
		(formikData: CounterpartyFormik) => {
			const updatedContract = counterpartyFormikToContractDetailMapper(
				formikData,
				originalContract as ContractDetailType
			);

			if (isUserTrader) {
				callUpdateContract(updatedContract);
			} else {
				onCreateChangeRequest(
					originalContract as ContractDetailType,
					updatedContract
				);
			}
		},
		[updateContract, onCreateChangeRequest, originalContract, isUserTrader]
	);

	useEffect(() => {
		const effectFunction = async () => {
			if (contractId) {
				const response = await getContract({
					contract_id: Number(contractId),
					skip_validation: false,
				});
				if (response?.isError) {
					getContract({
						contract_id: Number(contractId),
						skip_validation: true,
					});
				}
			}
		};
		effectFunction();
	}, [contractId]);

	useEffect(() => {
		if (changeRequest && contractData) {
			setContractSelected(contractData as ContractDetailType);
		}
	}, [changeRequest, contractData]);

	const handleRowClick = (contractPeriodId: string | number) => {
		navigate(
			getPathWithParameters(PATH.CONTRACTPERIOD, {
				contractId,
				contractPeriodId,
			})
		);
	};

	const headers: HeadersType<ContractPeriodType>[] = [
		{ label: "Status", accessor: "status" },
		{ label: "ID", accessor: "id" },
		{
			label: "Contract Period Name",
			accessor: "name",
			getAnchorTag: (item: ContractPeriodType) =>
				getPathWithParameters(PATH.CONTRACTPERIOD, {
					contractId,
					contractPeriodId: item.id,
				}),
		},
		{
			label: "Trade Date",
			accessor: "trade_date",
		},
		{
			label: "Start Date",
			accessor: "start_date",
		},
		{
			label: "End Date",
			accessor: "end_date",
		},
		{ label: "# Service point", accessor: "nb_service_point" },
		{ label: "# Site", accessor: "np_site" },
		{ label: "P50", accessor: "p50" },

		{
			label: "Processing Type",
			accessor: "processing_type",
		},
		{ label: "Deal Type", accessor: "deal_type.name" },
		{
			label: "Alpha",
			accessor: "ppa_physical.settlements[0].index.alpha.commodity",
			accessorOverride: (cp) =>
				`${
					cp.trade_type === TradeType.PPA_PHYSICAL
						? "ppa_physical"
						: "ppa_base_physical"
				}.settlements[0].index.alpha.commodity`,
		},
		{
			label: "Beta",
			accessor: "ppa_physical.settlements[0].index.beta.commodity",
			accessorOverride: (cp) =>
				`${
					cp.trade_type === TradeType.PPA_PHYSICAL
						? "ppa_physical"
						: "ppa_base_physical"
				}.settlements[0].index.beta.commodity`,
		},
		{
			label: "Fixed Price",
			accessor: "ppa_base_physical.settlements[0].price",
			accessorOverride: (cp) =>
				`${
					cp.trade_type === TradeType.PPA_PHYSICAL
						? "ppa_physical"
						: "ppa_base_physical"
				}.settlements[0].price.commodity`,
		},
		{
			label: "Test Phase",
			accessor: "test_phase",
		},
		{
			label: "Negative Condition",
			accessor: "negative_price",
		},
		{
			label: "Clickability",
			accessor: "clickability",
		},
		{
			label: "GOO Price",
			accessor: "goo_price",
		},
	];
	const uniqueSortedItems = (items: { id: number; name: string }[]) => {
		const uniqueItems = _.uniqBy(items, "name");
		return _.sortBy(uniqueItems, "name");
	};

	const contractPeriods = useMemo(() => {
		return contractData?.contract_periods.map((cp) => {
			const uniqueSortedServicePoints = uniqueSortedItems(
				cp.servicepoints
			);
			const uniqueSortedSites = uniqueSortedItems(cp.sites);
			return {
				...cp,
				clickability: <YesNoTag yes={!!cp.clickability} />,
				negative_price: <YesNoTag yes={!!cp.negative_price} />,
				nb_service_point: !!uniqueSortedServicePoints.length && (
					<Tooltip
						title={uniqueSortedServicePoints.map((sp) => (
							<p key={sp.name}>{sp.name}</p>
						))}
					>
						<Button sx={{ minWidth: 0 }}>
							{uniqueSortedServicePoints.length}
						</Button>
					</Tooltip>
				),
				np_site: !!uniqueSortedSites.length && (
					<Tooltip
						title={uniqueSortedSites.map((site) => (
							<p key={site.name}>{site.name}</p>
						))}
					>
						<Button
							sx={{ minWidth: 0 }}
							onClick={() =>
								navigate(
									PATH.SITES,
									setFilters({
										id__in: uniqueSortedSites.map(
											(site) => site.id
										),
									})
								)
							}
						>
							{uniqueSortedSites.length}
						</Button>
					</Tooltip>
				),
				ppa_physical: cp?.ppa_physical,
			};
		});
	}, [originalContract]);

	return (
		<>
			<PageTitle
				fontWeight={"bold"}
				label="Contract Period List"
				leftSide={
					<PrimaryButton
						text="Back"
						type="button"
						color="info"
						sx={{
							width: 106,
							color: "#171D21",
						}}
						onClick={() => navigate(PATH.CONTRACTS)}
					>
						<ChevronLeftIcon />
					</PrimaryButton>
				}
				rightSide={
					<Box
						sx={{
							display: "flex",
							marginLeft: "auto",
							gap: "16px",
						}}
					>
						<If
							condition={
								contractData?.status !== ContractStatus.VALID
							}
						>
							<PrimaryButton
								text="Validate Contract"
								type="button"
								color="primary"
								onClick={validateContract}
								loader={isUpdateContractStatusLoading}
							></PrimaryButton>
						</If>
						<PrimaryButton
							text="Modification follow-up"
							type="button"
							color="secondary"
							onClick={naviateOnThisContractChangeRequest}
						>
							<TrackChangesIcon />
						</PrimaryButton>
						<If condition={isUserTrader}>
							<FluidButton
								label="Split contract"
								onClick={() => setContractSplitModalOpen(true)}
								variant="secondary"
								icon="call_split"
							/>
						</If>
					</Box>
				}
			/>

			<PageContent>
				<>
					<Spacer gap={16} />
					{!isGetContractLoading &&
						!isChangeRequestLoading &&
						error && (
							<Box>
								<Box sx={{ mb: 1 }}>
									Error Retrieving Contract
								</Box>
								<Box>
									We are sorry, but we encountered an issue
									while trying to retrieve the contract
									information.
								</Box>
								<Box>
									Please try again later. If the problem
									persists, contact support for assistance.
								</Box>
								<ErrorText>{errorMessage}</ErrorText>
							</Box>
						)}
					{contractData &&
						contractPeriods &&
						!isGetContractLoading &&
						!isChangeRequestLoading && (
							<>
								<InfoBlock
									withBackground
									info={[
										{
											label: "Contract Type",
											value: contractData?.agreement_type,
										},
										{
											label: "UID",
											value: contractData?.contract_uid,
										},
										{
											label: "Contract Status",
											value: contractData?.status,
										},
										{
											label: "Signature Date",
											value: contractData?.trade_date,
										},
										{
											label: "Start Date",
											value: contractData?.start_date,
										},
										{
											label: "End Date",
											value: contractData?.end_date,
										},
										{
											label: "Techno Type",
											value: contractData?.techno_type,
										},
										{
											label: "Counterpart",
											value:
												contractData?.mirror_book
													?.name ??
												contractData?.party?.mnemonic,
										},
										{
											label: "Way",
											value: contractData?.way,
										},
										{
											label: "Country",
											value: contractData?.country,
										},
										{
											label: "Book",
											value: contractData?.trading_book
												?.name,
										},
										{
											label: "Account",
											value: contractData?.party?.account
												?.name,
										},
										{
											label: "Contacts",
											value: (
												<MakeClickable
													onClick={() =>
														setContactModalOpen(
															true
														)
													}
												>
													<FluidLink href={"#"}>{`${
														(
															contractData?.contacts ||
															[]
														)?.length
													} contacts`}</FluidLink>
												</MakeClickable>
											),
										},
										{
											icon: (
												<Box
													sx={{
														margin: "auto",
													}}
												>
													<MakeClickable
														onClick={() =>
															setContractSelected(
																contractData
															)
														}
													>
														<EditIcon color="primary" />
													</MakeClickable>
												</Box>
											),
										},
									]}
								/>
								<Spacer gap={24} />

								<If condition={changeRequest}>
									<ChangeRequestAlert
										changeRequest={changeRequest}
									/>
									<Spacer gap={24} />
								</If>

								<SimpleTable
									headers={headers}
									items={contractPeriods}
									handleRowClick={handleRowClick}
								/>
							</>
						)}
				</>
			</PageContent>
			<ContactModal
				contacts={contractData?.contacts || []}
				isOpen={isContactModalOpen}
				onClose={() => setContactModalOpen(false)}
			/>
			<CounterPartModal
				selectedContract={selectedContract}
				changeRequestId={
					changeRequestId ? Number(changeRequestId) : undefined
				}
				handleCLose={() => setContractSelected(null)}
				errorMessage={errorMessage || changeRequestErrorMessage}
				isLoading={
					isChangeRequestLoading ||
					isUpdateContractLoading ||
					isDryRunLoading
				}
				isChangeRequest={!isUserTrader}
				onSubmit={handleUpdateContract}
			/>
			{contractData &&
				(contractData?.contract_periods?.length ?? 0) > 0 &&
				isContractSplitModalOpen && (
					<ContractSplitModal
						contract={contractData}
						isOpen={isContractSplitModalOpen}
						onClose={() => setContractSplitModalOpen(false)}
					/>
				)}
			<ErrorMessageModal
				title={"Failed to update Contract"}
				content={formatApiErrorMessage(
					updateContractStatusError as ErrorType
				)}
				actionAfterClose={reset}
			/>
		</>
	);
};

export default ContractPeriodList;
