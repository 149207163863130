import { CompactTender, Tender } from "../tender.module";
import { Order } from "../tender.constant";
import { User } from "../../authentication/auth.model";
import { isUserOriginatorAssignedToTender } from "../../../common/authorization/tender.authorization";
import { TenderStatus } from "../../pricing_list/pricingListSlice";

function stringToColor(string: string) {
	let hash = 0;
	let i;

	for (i = 0; i < string.length; i += 1) {
		hash = string.charCodeAt(i) + ((hash << 5) - hash);
	}

	let color = "#";

	for (i = 0; i < 3; i += 1) {
		const value = (hash >> (i * 8)) & 0xff;
		color += `00${value.toString(16)}`.slice(-2);
	}

	return color;
}

export function stringForAvatar(name: string) {
	return {
		sx: {
			bgcolor: stringToColor(name),
			width: "32px",
			height: "32px",
			fontSize: "16px",
		},
		children: `${name.split(" ")[0][0]}${
			name.split(" ")[1][0]
		}`.toUpperCase(),
	};
}

export function mapTenderToWorkflowView(tender: Tender): CompactTender {
	return {
		id: tender.id,
		name: tender.name,
		creation_date: tender.creation_date,
		country_iso2_code: tender.country_iso2_code,
		site_count: tender.site_count,
		direction: tender.direction,
		due_date: tender.due_date,
		trader: tender.trader,
		originator: tender.originator,
		actions_blotter: tender.actions_blotter,
	};
}

export enum OrderColumn {
	dueDate = "due_date",
	createdAt = "created_at",
	latestStatusUpade = "latest_status_update",
}

export interface WorkflowViewColumnOrder {
	statuses: TenderStatus[];
	orderColumn: OrderColumn;
	order: Order;
}

export const workflowViewDefaultOrders: WorkflowViewColumnOrder[] = [
	{
		statuses: [TenderStatus.NEW],
		orderColumn: OrderColumn.createdAt,
		order: Order.desc,
	},
	{
		statuses: [TenderStatus.TODO],
		orderColumn: OrderColumn.dueDate,
		order: Order.asc,
	},
	{
		statuses: Object.values(TenderStatus).filter(
			(status) => ![TenderStatus.NEW, TenderStatus.TODO].includes(status)
		),
		orderColumn: OrderColumn.latestStatusUpade,
		order: Order.desc,
	},
];

export const isDefaultOrder = (
	status: TenderStatus,
	order?: Order,
	orderColumn?: OrderColumn
) => {
	if (!order && !orderColumn) return true;
	return workflowViewDefaultOrders.some(
		(defaultOrder) =>
			defaultOrder.statuses.includes(status) &&
			defaultOrder.order === order &&
			defaultOrder.orderColumn === orderColumn
	);
};

export const getColumnOrderText = (
	order: Order,
	orderColumn: OrderColumn,
	status: TenderStatus
) => {
	const defaultText = isDefaultOrder(status, order, orderColumn)
		? "(Default) "
		: "";
	const directionText =
		order === Order.asc ? "closest first" : "furthest first";
	let columnText = "";
	switch (orderColumn) {
		case OrderColumn.createdAt:
			columnText = "Creation date";
			break;
		case OrderColumn.dueDate:
			columnText = "Deadline date";
			break;
		case OrderColumn.latestStatusUpade:
			columnText = "Status update date";
			break;
	}
	return defaultText + columnText + ", " + directionText;
};
export const invalidTransitions = (
	tender: Tender,
	originator: User | undefined
): any => {
	if (isUserOriginatorAssignedToTender(tender, originator)) {
		return {
			[TenderStatus.NEW]: [],
			[TenderStatus.TODO]: [],
			[TenderStatus.BOOKED]: [],
			[TenderStatus.CLOSED]: [],
			[TenderStatus.LOST]: [],
			[TenderStatus.ONGOING]: [],
			[TenderStatus.PRICING_DONE]: [],
			[TenderStatus.TO_BE_CHECKED]: [],
			[TenderStatus.UNDER_OFFER]: [],
			[TenderStatus.WON]: [],
		};
	}
	return {
		[TenderStatus.NEW]: [...Object.values(TenderStatus)],
		[TenderStatus.TODO]: [TenderStatus.NEW],
		[TenderStatus.BOOKED]: [TenderStatus.NEW],
		[TenderStatus.CLOSED]: [TenderStatus.NEW],
		[TenderStatus.LOST]: [TenderStatus.NEW],
		[TenderStatus.ONGOING]: [TenderStatus.NEW],
		[TenderStatus.PRICING_DONE]: [TenderStatus.NEW],
		[TenderStatus.TO_BE_CHECKED]: [TenderStatus.NEW],
		[TenderStatus.UNDER_OFFER]: [TenderStatus.NEW],
		[TenderStatus.WON]: [TenderStatus.NEW],
	};
};
