import { useCallback, useMemo, useState } from "react";
import { cloneDeep } from "lodash";
import { Box, Drawer } from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";

import CircularProgress from "@mui/material/CircularProgress";
import PageTitle from "../../common/components/PageTitle/PageTitle";
import PageContent from "../../common/components/PageContent";
import SimpleTable, {
	HeadersType,
} from "../../common/components/SimpleTable/SimpleTable";
import {
	useCancelChangeRequestMutation,
	useGetChangeRequestsQuery,
} from "../../requests_cm/gepoChangeRequestService/service";
import { noop } from "../../common/utils/operations";
import { StatusIcon } from "./StatusIcon";
import { FluidButton } from "../../common/components/FluidButton";
import { PrimaryButton } from "../../common/components/CustomButton";
import { useLocation, useNavigate } from "react-router-dom";
import { ChangeRequestBaseCard } from "./ChangeRequestDetailCards/ChangeRequestBaseCard";
import {
	ChangeRequestStatus,
	ChangeRequestType,
} from "../../requests_cm/gepoChangeRequestService/types";
import { formatDateDDMMYYYY } from "../../common/utils/dateUtils";
import { ActionConfirmationModal } from "../../common/components/ActionConfirmationModal";
import { useIsUserWithinGroups } from "../../common/hooks/useIsUserWithinGroups";
import { Groups } from "../authentication/authentication.constant";
import {
	getPathWithParameters,
	getPathWithSearchParams,
	PATH,
} from "../../router-path";
import { PageInfoType } from "../../requests_cm/gecoTypes";
import ChangeRequestFilters from "./ChangeRequestFilters";
import ActiveFilters from "../../common/components/ActiveFilters";
import { transformFilters } from "../../common/components/ActiveFilters/ActiveFilters.utils";
import { Spacer } from "../../common/components/Spacer";

const style = {
	progressWrapper: {
		display: "flex",
		justifyContent: "center",
	},
	searchWrapper: { display: "flex", alignItems: "center" },
};

const ChangeRequestPage = () => {
	const navigate = useNavigate();
	const location = useLocation();

	let { filters: initialFilters } = location.state || {};

	const [isFilterOpen, setFilterOpen] = useState(false);

	const [pageInfo, setPageInfo] = useState<PageInfoType>({
		page: 1,
		search: "",
		filters: initialFilters || {},
	});

	const [selectedChangeRequest, setChangeRequest] = useState<
		ChangeRequestType | undefined
	>();
	const [selectedRequestToCancel, setSelectedRequestToCancel] = useState<
		ChangeRequestType | undefined
	>();

	const mapFilterFieldToOdata = (
		filters: Record<string, string[]>
	): { value: string[]; field_path: string }[] => {
		const odataList = [];
		for (const key of Object.keys(filters)) {
			const filterValue = filters[key];
			const fieldPath = key.split("__").at(-2) as string;
			odataList.push({ value: filterValue, field_path: fieldPath });
		}
		return odataList;
	};

	const {
		isLoading,
		data: changeRequestsResult,
		isFetching,
	} = useGetChangeRequestsQuery({
		...pageInfo,
		filters: mapFilterFieldToOdata(pageInfo?.filters || {}),
	});
	const [cancelRequest, { isLoading: isCancelLoading }] =
		useCancelChangeRequestMutation();

	const { isUserAuthorized } = useIsUserWithinGroups();

	const navigateToChangeRequestReview = useCallback(
		(changeRequestId: number) => {
			const path = getPathWithParameters(
				PATH.CHANGE_REQUESTS_VALIDATION,
				{
					changeRequestId,
				}
			);
			navigate(path);
		},
		[]
	);

	const isUserTrader = useMemo(() => {
		return isUserAuthorized([Groups.trader]);
	}, [isUserAuthorized]);

	const onEditChangeRequest = (editableChangeRequest: ChangeRequestType) => {
		const editPageUri = getPathWithSearchParams(
			editableChangeRequest.resource_edit_path,
			{ changeRequestId: editableChangeRequest.id }
		);
		navigate(editPageUri);
	};

	const activeFilters = useMemo(() => {
		return pageInfo?.filters ? transformFilters(pageInfo?.filters) : {};
	}, [pageInfo]);

	const isLastPage = useMemo(() => {
		return !changeRequestsResult?.has_next_page;
	}, [changeRequestsResult]);

	const items = (changeRequestsResult?.data || []).map((changeRequest) => {
		return {
			...changeRequest,
			status: (
				<StatusIcon
					status={changeRequest?.status}
					validatorMessage={changeRequest?.validator_message}
				/>
			),
			requester: (
				<p style={{ textTransform: "capitalize" }}>
					{changeRequest?.requester?.first_name}{" "}
					{changeRequest?.requester?.last_name}
				</p>
			),
			validator: (
				<p style={{ textTransform: "capitalize" }}>
					{changeRequest?.validator?.first_name}{" "}
					{changeRequest?.validator?.last_name}
				</p>
			),
			action: isUserTrader ? (
				changeRequest?.status === ChangeRequestStatus.PENDING ? (
					<FluidButton
						variant="secondary"
						onClick={() =>
							navigateToChangeRequestReview(changeRequest?.id)
						}
						label={"Review request"}
					/>
				) : (
					"N/A"
				)
			) : (
				<FluidButton
					variant="secondary"
					onClick={() => setChangeRequest(changeRequest)}
					label={"View details"}
				/>
			),
			created_at: <p>{formatDateDDMMYYYY(changeRequest?.created_at)}</p>,
		};
	});

	const removeSingleActiveFilter = useCallback(
		({
			filter_key,
			operation,
		}: {
			filter_key: string;
			operation: string;
		}) => {
			const pageInfoCopy = cloneDeep<PageInfoType>(pageInfo);
			if (pageInfoCopy?.filters) {
				delete pageInfoCopy.filters[`${filter_key}__${operation}`];
				pageInfoCopy.page = 1;
				setPageInfo(pageInfoCopy);
			}
		},
		[pageInfo]
	);

	const removeAllFilters = useCallback(() => {
		if (pageInfo?.filters) {
			const pageInfoCopy = cloneDeep<PageInfoType>(pageInfo);
			pageInfoCopy.filters = {};
			pageInfoCopy.page = 1;
			setPageInfo(pageInfoCopy);
		}
	}, [pageInfo]);

	const headers: HeadersType<ChangeRequestType>[] = [
		{ label: "Request Type", accessor: "resource_type" },
		{ label: "Contract name", accessor: "name" },
		{ label: "Requester", accessor: "requester" },
		{ label: "Validator", accessor: "validator" },
		{ label: "Request date", accessor: "created_at" },
		{ label: "Status", accessor: "status" },
		{ label: "Actions", accessor: "action" },
	].map((header) => ({
		...header,
	})) as HeadersType<ChangeRequestType>[];

	return (
		<>
			<PageTitle
				fontWeight={"bold"}
				label="Change Requests"
				leftSide={
					<PrimaryButton
						text="Back"
						type="button"
						color="info"
						sx={{
							width: 106,
							color: "#171D21",
							marginRight: "16px",
						}}
						onClick={() => navigate(PATH.CONTRACTS)}
					>
						<ChevronLeftIcon />
					</PrimaryButton>
				}
			/>

			{isLoading && (
				<Box sx={style.progressWrapper}>
					<CircularProgress data-testid="loader" />
				</Box>
			)}
			{!isLoading && !isCancelLoading && items && (
				<PageContent>
					<Box sx={style.searchWrapper}>
						<FluidButton
							label={"Filters"}
							icon="filter_list"
							onClick={() => setFilterOpen(true)}
						/>
					</Box>
					<Spacer gap={32} />
					<ActiveFilters
						filters={activeFilters}
						removeFilter={removeSingleActiveFilter}
						resetFilters={removeAllFilters}
					/>
					<Spacer gap={32} />

					<SimpleTable
						handleRowClick={noop}
						headers={headers}
						infiniteScroll
						isFetching={isFetching}
						isLastPage={isLastPage}
						items={items}
						pageInfo={pageInfo}
						setPageInfo={setPageInfo}
					/>
				</PageContent>
			)}
			<Drawer
				PaperProps={{
					sx: { width: "800px" },
				}}
				anchor={"right"}
				open={!!selectedChangeRequest}
				onClose={() => setChangeRequest(undefined)}
			>
				<ChangeRequestBaseCard
					onEdit={() =>
						onEditChangeRequest(
							selectedChangeRequest as ChangeRequestType
						)
					}
					onCancel={() =>
						setSelectedRequestToCancel(selectedChangeRequest)
					}
					changeRequest={selectedChangeRequest}
					onClose={() => setChangeRequest(undefined)}
				/>
			</Drawer>
			<ActionConfirmationModal
				isOpen={!!selectedRequestToCancel}
				title="Close request confirmation"
				text="Are you sure you want to close this request?"
				onClose={() => setSelectedRequestToCancel(undefined)}
				onConfirm={() => {
					cancelRequest({
						id: selectedRequestToCancel?.id as number,
					});
					setSelectedRequestToCancel(undefined);
					setChangeRequest(undefined);
				}}
			/>
			<ChangeRequestFilters
				isOpen={isFilterOpen}
				defaultFilters={pageInfo?.filters}
				onClose={() => setFilterOpen(false)}
				onApplyFilters={(filters) => {
					setFilterOpen(false);
					setPageInfo({ page: 1, search: "", filters });
				}}
			/>
		</>
	);
};

export default ChangeRequestPage;
