import _ from "lodash";
import TrackChangesIcon from "@mui/icons-material/TrackChanges";
import PageTitle from "../../common/components/PageTitle/PageTitle";
import PageContent from "../../common/components/PageContent";
import {
	useLazyGetOneContractQuery,
	useUpdateOneContractDryRunMutation,
	useUpdateOneContractMutation,
} from "../../requests_cm/gecoContractsService/service";
import { enqueueSnackbar } from "notistack";
import ContractPeriodTabs from "./ContractPeriodTabs";
import { BreadCrumbs } from "../../common/components/BreadCrumbs";
import { Spacer } from "../../common/components/Spacer";
import InfoBlock from "../../common/components/InfoBlock/InfoBlock";
import { PrimaryButton } from "../../common/components/CustomButton";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useCallback, useEffect, useMemo, useState } from "react";
import { If } from "../../common/components/If";
import { OverlayLoader } from "../../common/components/OverlayLoader";
import ErrorMessageModal from "../../common/components/ErrorMessageDialog";
import {
	ContractDetailType,
	ContractPeriodType,
	TradeType,
} from "../../requests_cm/gecoContractsService/types";
import { ContractPeriodSelect } from "./ContractPeriodSelect";
import { useLazyGetNegativePriceIndexesQuery } from "../../requests_cm/gecoReferentialService/service";
import {
	ErrorType,
	formatApiErrorMessage,
} from "../../common/utils/formatApiErrorMessage";
import { useRtkQueryDynamicEndpoint } from "../../common/hooks/useRtkQueryDynamicEndpoint";
import { getPathWithParameters, PATH, setFilters } from "../../router-path";
import BookingLogs from "./BookingLogs";
import ConfirmNavigate from "../../common/components/ConfirmNavigate/ConfirmNavigate";
import { useChangeRequest } from "../../requests_cm/gepoChangeRequestService/useChangeRequest";
import { Groups } from "../authentication/authentication.constant";
import { useIsUserWithinGroups } from "../../common/hooks/useIsUserWithinGroups";
import { contractPeriodChangeToChangeRequestMapper } from "../../requests_cm/gepoChangeRequestService/utils";
import { ChangeRequestResourceType } from "../../requests_cm/gepoChangeRequestService/types";
import { Box } from "@mui/material";

const ContractPeriod = () => {
	const navigate = useNavigate();
	const { contractId, contractPeriodId } = useParams();
	const { isUserAuthorized } = useIsUserWithinGroups();
	const [searchParams] = useSearchParams();
	const [errorMessage, setErrorMessage] = useState<
		string | string[] | undefined
	>();

	const changeRequestId = searchParams.get("changeRequestId");

	const [
		getContractBase,
		{
			data: originalContract,
			isLoading: isFetchingContract,
			error: isGetContractError,
		},
	] = useLazyGetOneContractQuery();
	const [
		getIndexesBase,
		{ data: negativePriceIndexes, isLoading: isFetchingIndex },
	] = useLazyGetNegativePriceIndexesQuery();
	const [
		updateContractBase,
		{ isLoading: isUpdateLoading, error: contractUpdateError, reset },
	] = useUpdateOneContractMutation();

	const [dryRunContractBase] = useUpdateOneContractDryRunMutation();

	const updateContract = useRtkQueryDynamicEndpoint(updateContractBase);
	const getIndexes = useRtkQueryDynamicEndpoint(getIndexesBase);
	const getContract = useRtkQueryDynamicEndpoint(getContractBase);
	const dryRunContract = useRtkQueryDynamicEndpoint(dryRunContractBase);

	const isUserTrader = useMemo(() => {
		return isUserAuthorized([Groups.geco_trader]);
	}, [isUserAuthorized]);

	const {
		isLoading: isChangeRequestLoading,
		upsertChangeRequest,
		ressouceWithChanges: contractData,
		errorMessage: changeRequestErrorMessage,
	} = useChangeRequest(
		changeRequestId ? Number(changeRequestId) : undefined,
		originalContract
	);

	const [updatedContract, setUpdatedContract] = useState<
		ContractDetailType | undefined
	>();

	const isLoading = useMemo(() => {
		return (
			isChangeRequestLoading ||
			isUpdateLoading ||
			isFetchingContract ||
			isFetchingIndex
		);
	}, [
		isChangeRequestLoading,
		isUpdateLoading,
		isFetchingContract,
		isFetchingIndex,
	]);

	const naviateOnThisContractPeriodChangeRequest = useCallback(() => {
		navigate(
			PATH.CHANGE_REQUESTS,
			setFilters({
				resource_type__in: [ChangeRequestResourceType.CONTRACT_PERIOD],
				resource_id__in: [String(contractId)],
			})
		);
	}, [navigate, contractId]);

	const onCreateChangeRequest = async (
		currentContract: ContractDetailType,
		newVersionContract: ContractDetailType
	) => {
		try {
			const dryRunResponse = await dryRunContract(newVersionContract);
			//@ts-ignore
			if (dryRunResponse?.error) {
				setErrorMessage(
					//@ts-ignore
					dryRunResponse?.error
				);
				return;
			}
			const changeRequestUpdate = await upsertChangeRequest(
				contractPeriodChangeToChangeRequestMapper(
					currentContract,
					newVersionContract,
					Number(contractPeriodId)
				)
			);
			if (changeRequestUpdate?.errors) {
				setErrorMessage(changeRequestUpdate?.errors);
				return;
			}
			enqueueSnackbar(
				`Request change on contract ${originalContract?.id} has been sent`,
				{
					variant: "success",
					autoHideDuration: 3000,
				}
			);
			naviateOnThisContractPeriodChangeRequest();
		} catch (rejectedValueOrSerializedError) {
			const errors = formatApiErrorMessage(
				rejectedValueOrSerializedError as ErrorType
			);
			setErrorMessage(errors);
		}
	};

	const handleUpdateContract = useCallback(
		(newVersionContract: ContractDetailType | undefined) => {
			if (!newVersionContract) {
				return;
			}

			if (isUserTrader) {
				updateContract(newVersionContract);
			} else {
				onCreateChangeRequest(
					originalContract as ContractDetailType,
					newVersionContract
				);
			}
		},
		[updateContract, onCreateChangeRequest, originalContract, isUserTrader]
	);

	useEffect(() => {
		if (contractId) {
			getContract({
				contract_id: Number(contractId),
				skip_validation: false,
			});
		}
	}, [contractId]);

	useEffect(() => {
		if (contractId && isGetContractError) {
			getContract({
				contract_id: Number(contractId),
				skip_validation: true,
			});
		}
	}, [contractId, isGetContractError]);

	useEffect(() => {
		if (contractData) {
			setUpdatedContract(contractData);
			getIndexes({ country_code: contractData?.country });
		}
	}, [contractData]);

	const contractContainsChanges = useMemo(() => {
		return !_.isEqual(updatedContract, contractData);
	}, [updatedContract, contractData]);

	const contractPeriod: ContractPeriodType | undefined = useMemo(() => {
		return updatedContract?.contract_periods.filter(
			(cp: ContractPeriodType) => cp.id === Number(contractPeriodId)
		)[0];
	}, [updatedContract, contractPeriodId]);

	const breadCrumbsLabel = useMemo(() => {
		if (contractPeriod && contractData) {
			const contractPath = getPathWithParameters(PATH.CONTRACT_DETAIL, {
				contractId: contractData.id,
			});
			const contractPeriodPath = getPathWithParameters(
				PATH.CONTRACTPERIOD,
				{
					contractId: contractData.id,
					contractPeriodId: contractPeriod.id,
				}
			);
			return [
				{ path: contractPath, name: contractData.name },
				{ path: contractPeriodPath, name: contractPeriod.name },
			];
		}
		return [];
	}, [contractPeriod, contractData]);

	const navigateToOtherContractPeriod = (cpId: string) => {
		navigate(
			getPathWithParameters(PATH.CONTRACTPERIOD, {
				contractId,
				contractPeriodId: cpId,
			})
		);
	};

	return (
		<>
			<PageTitle
				fontWeight={"bold"}
				label="Contract period"
				leftSide={
					<ConfirmNavigate
						navigate={() =>
							navigate(
								getPathWithParameters(PATH.CONTRACT_DETAIL, {
									contractId,
								})
							)
						}
						condition={contractContainsChanges}
						saveChanges={() =>
							handleUpdateContract(updatedContract)
						}
					/>
				}
				rightSide={
					<Box sx={{ display: "flex", gap: "8px" }}>
						<PrimaryButton
							text="Modification follow-up"
							type="button"
							color="secondary"
							onClick={naviateOnThisContractPeriodChangeRequest}
						>
							<TrackChangesIcon />
						</PrimaryButton>
						<If condition={contractContainsChanges}>
							<PrimaryButton
								onClick={() =>
									handleUpdateContract(updatedContract)
								}
								text={
									isUserTrader
										? "Update changes"
										: "Request Changes"
								}
								type="button"
								color="primary"
							></PrimaryButton>
						</If>
					</Box>
				}
			/>
			<PageContent>
				<BreadCrumbs crumbsLabels={breadCrumbsLabel} />
				<Spacer gap={24} />
				<If condition={!isLoading}>
					{!!contractPeriod && contractData && (
						<>
							<BookingLogs contractPeriodId={contractPeriod.id} />
							<InfoBlock
								withBackground
								info={[
									{
										label: "Contract Type",
										value: contractData?.agreement_type,
									},
									{
										label: "Start Date",
										value: contractPeriod?.start_date,
									},
									{
										label: "End Date",
										value: contractPeriod?.end_date,
									},
									{
										label: "Signature Date",
										value: contractData?.trade_date,
									},
									{
										label: "Techno Type",
										value: contractData?.techno_type,
									},
									{
										label: "Uid",
										value: contractData?.contract_uid,
									},
									{
										label: "Counterpart",
										value:
											contractData?.mirror_book?.name ??
											contractData?.party?.mnemonic,
									},
									{ label: "Way", value: contractData?.way },
									{
										label: "Country",
										value: contractData?.country,
									},
									{
										label: "Book",
										value: contractData?.trading_book?.name,
									},
								]}
							/>

							<Spacer gap={32} />
							<InfoBlock
								withBackground
								info={[
									{
										label: "Base Config",
										value:
											contractPeriod?.trade_type ===
											TradeType.PPA_BASE_PHYSICAL
												? "Yes"
												: "No",
									},
									{
										label: "Goo Included",
										value: contractPeriod?.goo_price
											? "Yes"
											: "No",
									},
								]}
							>
								<ContractPeriodSelect
									availableContractPeriods={
										contractData?.contract_periods || []
									}
									currentContractPeriodId={contractPeriodId}
									onChange={navigateToOtherContractPeriod}
									showConfirmation={contractContainsChanges}
								/>
							</InfoBlock>
							<Spacer gap={24} />
							{!!updatedContract && !!contractPeriod && (
								<ContractPeriodTabs
									onSaveDraftContract={setUpdatedContract}
									contract={updatedContract}
									contractPeriod={contractPeriod}
									negativePriceIndexes={negativePriceIndexes}
								/>
							)}
						</>
					)}
				</If>
			</PageContent>
			<If condition={isLoading}>
				<OverlayLoader />
			</If>
			<ErrorMessageModal
				title={"Failed to update Contract"}
				content={formatApiErrorMessage(
					(contractUpdateError as ErrorType) ||
						changeRequestErrorMessage ||
						errorMessage
				)}
				actionAfterClose={reset}
			/>
		</>
	);
};
export default ContractPeriod;
