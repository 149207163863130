import { NJInlineMessage } from "@engie-group/fluid-design-system-react";
import React from "react";

export interface FluidMessageBoxProps extends React.PropsWithChildren<{}> {
	variant: "success" | "error" | "warning";
	title: string;
	message?: string;
}

const FluidMessageBox = ({
	variant,
	title,
	message,
	children,
}: FluidMessageBoxProps) => {
	return (
		<NJInlineMessage variant={variant}>
			<span>{title}</span>
			<div>{message || children}</div>
		</NJInlineMessage>
	);
};

export default FluidMessageBox;
