import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { If } from "../../../common/components/If";
import { PrimaryButton } from "../../../common/components/CustomButton";
import ButtonDropdown from "../../../common/components/ButtonDropdown";
import { getPathWithParameters, PATH } from "../../../router-path";
import { Link } from "react-router-dom";
import _ from "lodash";
import { PricingStepperType } from "../../pricing_request/hooks/usePricingStepper";

interface AfterCreationType {
	title: string;
	content?: string;
	isOpen: boolean;
	createdTenderId: number;
	handleClose: () => void;
	transactionType: string;
}
export default function AfterCreationModal(props: AfterCreationType) {
	const {
		isOpen,
		content,
		title,
		handleClose,
		createdTenderId,
		transactionType,
	} = props;
	const backPage = `/tenders/${createdTenderId}/my-pricings`;
	let dropDownItems = [
		{
			key: "power",
			type: PricingStepperType.POWER_AND_GOO,
			name: "Power",
		},
		{
			key: "GOO-Only",
			type: PricingStepperType.GOO_ONLY,
			name: "GOO Only",
		},
		{
			key: "financial",
			type: PricingStepperType.FINANCIAL,
			name: "Financial",
		},
	].filter((item) => {
		if (transactionType == "Financial") return item.key !== "power";
		if (transactionType == "Physical") return item.key !== "financial";
	});
	return (
		<div data-testid="after-creation-modal">
			<Dialog onClose={handleClose} open={isOpen}>
				<DialogTitle>{title}</DialogTitle>
				<If condition={content}>
					<DialogContent>
						<DialogContentText>{content}</DialogContentText>
					</DialogContent>
				</If>
				<DialogActions>
					<PrimaryButton
						onClick={handleClose}
						text="Go to blotter"
						type="button"
						color="primary"
					></PrimaryButton>
					<ButtonDropdown
						text="Create pricings"
						type="submit"
						color="primary"
						role="link"
						sx={{ marginLeft: "15px" }}
						items={dropDownItems.map((item) => {
							return (
								<Link
									data-testid={"test-link-" + item.key}
									key={item.key}
									to={`${getPathWithParameters(
										PATH.PRICING_REQUEST,
										{
											tenderId: createdTenderId,
											type: _.kebabCase(item.type),
										}
									)}?backPage=${backPage}`}
								>
									{item.name}
								</Link>
							);
						})}
					></ButtonDropdown>
				</DialogActions>
			</Dialog>
		</div>
	);
}
