import { createSlice, type PayloadAction } from "@reduxjs/toolkit";
import { ContractType } from "../../requests_cm/gecoContractsService/types";
import { FiltersWithLabelsType } from "../../common/components/ActiveFilters/ActiveFilters";

const initialState: {
	currentPage: number | null;
	isLastPage: boolean | null;
	contracts: ContractType[];
	filters: { [key: string]: any };
	filtersWithLabels: FiltersWithLabelsType;
	search: string;
} = {
	currentPage: null,
	isLastPage: null,
	contracts: [],
	filters: {},
	filtersWithLabels: {},
	search: "",
};

export const contractsPageSlice = createSlice({
	name: "contractsPage",
	initialState,
	reducers: {
		updateFilters: (
			state,
			action: PayloadAction<{ [key: string]: any }>
		) => {
			state.filters = action.payload.filters;
			state.filtersWithLabels = action.payload.filtersWithLabels;
		},
		updateSearch: (state, action: PayloadAction<string>) => {
			state.search = action.payload;
		},
	},
});

export const { updateFilters, updateSearch } = contractsPageSlice.actions;

export default contractsPageSlice.reducer;
