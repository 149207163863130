import { useMemo } from "react";
import { Tooltip } from "@mui/material";
import { NJTag } from "@engie-group/fluid-design-system-react";
import { ChangeRequestStatus } from "../../requests_cm/gepoChangeRequestService/types";

export interface StatusIconProps {
	status: ChangeRequestStatus;
	validatorMessage?: string;
}

export interface StatusField {
	title: string;
	variant: string;
	icon: string;
}

const StatusToLabelMap: Record<ChangeRequestStatus, StatusField> = {
	[ChangeRequestStatus.PENDING]: {
		title: "Pending",
		variant: "blue",
		icon: "incomplete_circle",
	},
	[ChangeRequestStatus.REJECTED]: {
		title: "Refused",
		variant: "red",
		icon: "close",
	},
	[ChangeRequestStatus.VALID]: {
		title: "Valid",
		variant: "green",
		icon: "check_circle",
	},
	[ChangeRequestStatus.CORRECTION_NEEDED]: {
		title: "Correction needed",
		variant: "orange",
		icon: "edit",
	},
	[ChangeRequestStatus.CLOSED]: {
		title: "Closed",
		variant: "grey",
		icon: "block_flipped",
	},
};

export const StatusIcon = ({ status, validatorMessage }: StatusIconProps) => {
	const statusFields: StatusField = useMemo(() => {
		if (Object.keys(StatusToLabelMap).includes(status)) {
			return StatusToLabelMap[status];
		}
		return {
			title: "",
			variant: "",
			icon: "",
		};
	}, [status]);

	return (
		<Tooltip title={validatorMessage}>
			<NJTag
				size="lg"
				label={statusFields.title}
				variant={statusFields.variant as any}
				iconName={statusFields.icon}
			/>
		</Tooltip>
	);
};
