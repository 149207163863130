import { useGetSiteCurtailmentObjectsQuery } from "../../../../requests_cm/gecoSitesService/service";
import { useRtkQueryDynamicEndpoint } from "../../../../common/hooks/useRtkQueryDynamicEndpoint";
import CurtailmentObjects from "../../../../common/components/CurtailmentObjects";
import { Box, CircularProgress } from "@mui/material";
import NoDataMessage from "../../../../common/components/NoDataMessage";
import { CurtailmentKindFilter } from "../../../../common/components/CurtailmentObjects/CurtailmentKindFilter";
import { useCurtailementObjectsFilters } from "../../../../common/hooks/useCurtailementObjectsFilters";
import { If } from "../../../../common/components/If";
import { FluidButton } from "../../../../common/components/FluidButton";
import { useState } from "react";
import { BuildCurtailmentObjectsModalContainer } from "./CurtailmentObjectGenerateModals/BuildCurtailmentObjectsModalContainer";

const CurtailmentObjectsTab = ({ siteId }: { siteId: number }) => {
	const { data, isLoading } = useRtkQueryDynamicEndpoint(
		useGetSiteCurtailmentObjectsQuery
	)({ siteId });

	const {
		curtailementObjectsGroupsBySites,
		filterByKinds,
		updateFilterByKinds,
	} = useCurtailementObjectsFilters(data);

	const [isBuildCurtailmentOpen, setOpenBuildCurtailment] = useState(false);

	return (
		<>
			{isLoading && <CircularProgress />}
			{!isLoading && (
				<>
					<Box
						sx={{
							display: "flex",
							justifyContent: "space-between",
							paddingRight: "24px",
							marginBottom: "32px",
						}}
					>
						<Box sx={{ display: "flex", gap: "16px" }}>
							<FluidButton
								label="Build Curtailment objects"
								onClick={() => setOpenBuildCurtailment(true)}
								icon="add_circle_outline"
							/>
						</Box>
					</Box>
					{!data?.length && <NoDataMessage />}
					{!!data?.length && (
						<>
							<CurtailmentKindFilter
								curtailementKinds={filterByKinds}
								onChange={updateFilterByKinds}
							/>
							<If
								condition={
									curtailementObjectsGroupsBySites.length > 0
								}
							>
								<CurtailmentObjects
									curtailmentObjectGroup={
										curtailementObjectsGroupsBySites[0]
									}
								/>
							</If>
						</>
					)}
				</>
			)}
			<BuildCurtailmentObjectsModalContainer
				siteId={siteId}
				isOpen={isBuildCurtailmentOpen}
				onClose={() => setOpenBuildCurtailment(false)}
			/>
		</>
	);
};

export default CurtailmentObjectsTab;
