import { sortedUniq, findIndex } from "lodash";
import { useEffect, useMemo, useState } from "react";
import { HorizonChangeType } from "../../requests_cm/gecoContractsService/types";

export interface UseHorizonChange {
	entities: string[];
	entitiesMap: Record<string, HorizonChangeType[]>;
	updatedHorizonChanges: HorizonChangeType[];
	updateSingleEntity: (
		updatedEntity: Partial<HorizonChangeType>,
		entityId: number
	) => void;
}

export const useHorizonChanges = (
	horizonChanges: HorizonChangeType[]
): UseHorizonChange => {
	const [cachedHorizonChanged, setCachedHorizonChanged] = useState<
		HorizonChangeType[]
	>([]);

	useEffect(() => {
		if (horizonChanges && horizonChanges.length) {
			setCachedHorizonChanged(horizonChanges);
		}
	}, [horizonChanges]);

	const entities = useMemo(
		() =>
			sortedUniq(
				cachedHorizonChanged.map(
					({ target_entity_name }: HorizonChangeType) =>
						target_entity_name
				)
			),
		[cachedHorizonChanged]
	);
	const entitiesMap = useMemo(() => {
		const mapping: Record<string, HorizonChangeType[]> = {};
		for (const entity of entities) {
			mapping[entity] = cachedHorizonChanged.filter(
				({ target_entity_name }: HorizonChangeType) =>
					target_entity_name === entity
			);
		}
		return mapping;
	}, [cachedHorizonChanged, entities]);

	const updateEntity = (
		updatedEntity: Partial<HorizonChangeType>,
		entityId: number
	) => {
		const newHorizon = [...cachedHorizonChanged];
		const elementIndex = findIndex(
			newHorizon,
			({ target_id }: HorizonChangeType) => target_id === entityId
		);
		newHorizon[elementIndex] = {
			...newHorizon[elementIndex],
			...updatedEntity,
		};
		setCachedHorizonChanged(newHorizon);
	};

	const updatedHorizonChanges = useMemo(() => {
		// filter out horizonchange not selected, and make date exluced if changes for end date
		return cachedHorizonChanged
			.filter(
				(horizonChange: HorizonChangeType) => !horizonChange.isDiscarded
			)
			.map((horizonChange: HorizonChangeType) => ({
				...horizonChange,
				old_value: horizonChange.old_value,
				new_value: horizonChange.new_value,
			}));
	}, [cachedHorizonChanged]);

	return {
		entities,
		entitiesMap,
		updatedHorizonChanges,
		updateSingleEntity: updateEntity,
	};
};
