import { useNavigate } from "react-router-dom";
import { FluidLink } from "../FluidLink";

export interface BreadCrumbsLinkProps extends React.PropsWithChildren<{}> {
	active?: boolean;
	path: string;
}

export const BreadCrumbsLink = (props: BreadCrumbsLinkProps) => {
	const navigate = useNavigate();
	return (
		<FluidLink
			variant={props?.active ? "contextual" : "grayed"}
			href={props.path}
			onClick={(e) => {
				e.preventDefault();
				navigate(props.path);
				return false;
			}}
		>
			{props.children}
		</FluidLink>
	);
};
