import { Box } from "@mui/material";
import ServicePointCard from "./ServicePointCard";
import { useCallback, useEffect, useMemo, useState } from "react";
import {
	useLazyGetContractPeriodServicePointsQuery,
	useUnlinkContractPeriodServicePointsMutation,
	useUpsertContractPeriodServicePointsMutation,
} from "../../../../requests_cm/gecoContractsService/service";
import ServicePointCreateModal from "./ServicePointCreateModal/ServicePointCreateModal";
import { useLazyGetServicePointsQuery } from "../../../../requests_cm/gecoReferentialService/service";
import {
	CreateServicePointSubmitValues,
	mapFormikToSubmitValue,
} from "../../formik/servicepointsTabFormik";
import {
	ContractPeriodServicePoint,
	ContractPeriodType,
} from "../../../../requests_cm/gecoContractsService/types";
import { useRtkQueryDynamicEndpoint } from "../../../../common/hooks/useRtkQueryDynamicEndpoint";
import { NJButton } from "@engie-group/fluid-design-system-react";
import { Spacer } from "../../../../common/components/Spacer";
import ErrorMessageModal from "../../../../common/components/ErrorMessageDialog";
import {
	ErrorType,
	formatApiErrorMessage,
} from "../../../../common/utils/formatApiErrorMessage";
import { useIsUserWithinGroups } from "../../../../common/hooks/useIsUserWithinGroups";
import { Groups } from "../../../authentication/authentication.constant";
import NoDataMessage from "../../../../common/components/NoDataMessage";

export interface ServicePointsTabProps {
	contractPeriod: ContractPeriodType | undefined;
}

const ServicePointsTab = ({ contractPeriod }: ServicePointsTabProps) => {
	const { isUserAuthorized } = useIsUserWithinGroups();

	const [isCreateServicePointOpen, setIsCreateServicePointOpen] =
		useState(false);

	const [getContractPeriodServicePointsBase, { data }] =
		useLazyGetContractPeriodServicePointsQuery();

	const [getServicePointsBase, { data: servicePointData }] =
		useLazyGetServicePointsQuery();
	const [unlinkContractPeriodServicePointsBase] =
		useUnlinkContractPeriodServicePointsMutation();
	const [
		upsertContractPeriodServicePointsBase,
		{ error: errorContractPeriodServicePoints },
	] = useUpsertContractPeriodServicePointsMutation();

	const unlinkContractPeriodServicePoints = useRtkQueryDynamicEndpoint(
		unlinkContractPeriodServicePointsBase
	);
	const upsertContractPeriodServicePoints = useRtkQueryDynamicEndpoint(
		upsertContractPeriodServicePointsBase
	);
	const getServicePoints = useRtkQueryDynamicEndpoint(getServicePointsBase);
	const getContractPeriodServicePoints = useRtkQueryDynamicEndpoint(
		getContractPeriodServicePointsBase
	);

	const isUserAuthorizedBool = isUserAuthorized([
		Groups.geco_admin,
		Groups.geco_trader,
	]);

	const contractPeriodServicePoints = useMemo(() => {
		return data?.result ?? [];
	}, [data]);
	const servicePoints = useMemo(() => {
		return servicePointData?.result ?? [];
	}, [servicePointData]);

	useEffect(() => {
		if (!!contractPeriod?.id) {
			getContractPeriodServicePoints({
				contractPeriod_id: contractPeriod?.id as unknown as string,
			});
		}
	}, [contractPeriod]);

	const handleUnlinkContractPeriodServicePoints = (id: number) => {
		unlinkContractPeriodServicePoints({
			contractPeriod_id: String(contractPeriod?.id),
			servicepoint_id: String(id),
		});
	};

	const handleCreateNewContractPeriodServicePoint = useCallback(
		(newServicePoint: CreateServicePointSubmitValues) => {
			const {
				servicepoint_id: servicePointId,
				...newContractPeriodServicePoint
			} = newServicePoint;
			upsertContractPeriodServicePoints({
				contractPeriod_id: contractPeriod?.id as unknown as string,
				servicepoint_id: servicePointId as string,
				contractperiod_servicepoints: [
					newContractPeriodServicePoint as ContractPeriodServicePoint,
				],
			});
		},
		[contractPeriod?.id]
	);

	const handleSaveChanges = (
		servicePointId: number,
		servicePointContractPeriods: ContractPeriodServicePoint[]
	) => {
		upsertContractPeriodServicePoints({
			contractPeriod_id: contractPeriod?.id as unknown as string,
			servicepoint_id: String(servicePointId),
			contractperiod_servicepoints: servicePointContractPeriods.map(
				(servicePointContractPeriod) => ({
					...servicePointContractPeriod,
					volume_share: servicePointContractPeriod.volume_share / 100,
				})
			),
		});
	};

	const errorMessage = useMemo(() => {
		return errorContractPeriodServicePoints
			? formatApiErrorMessage(
					errorContractPeriodServicePoints as ErrorType
			  )
			: null;
	}, [errorContractPeriodServicePoints]);
	return (
		<>
			<ErrorMessageModal
				key="errorModal"
				title="Update Error: Data Update Unsuccessful"
				content={errorMessage}
			/>
			<Box sx={{ mt: 3 }}>
				<NJButton
					icon="add_circle_outline"
					label="Add Service Point"
					emphasis="subtle"
					onClick={() => setIsCreateServicePointOpen(true)}
					isDisabled={!isUserAuthorizedBool}
				/>
				{data && !contractPeriodServicePoints.length && (
					<NoDataMessage />
				)}
				{!!contractPeriodServicePoints.length && (
					<>
						<Spacer gap={24} />
						<Box
							sx={{
								display: "flex",
								gap: 3,
								flexWrap: "wrap",
							}}
						>
							{contractPeriod?.id &&
								contractPeriodServicePoints.map(
									(servicePoint) => (
										<ServicePointCard
											handleUnlinkContractPeriodServicePoints={
												handleUnlinkContractPeriodServicePoints
											}
											key={servicePoint.id}
											servicePoint={servicePoint}
											handleSaveChanges={
												handleSaveChanges
											}
										/>
									)
								)}
						</Box>
					</>
				)}
			</Box>
			<ServicePointCreateModal
				isOpen={isCreateServicePointOpen}
				errorMessage={errorContractPeriodServicePoints}
				servicePoints={servicePoints}
				contractPeriod={contractPeriod}
				handleClose={() => setIsCreateServicePointOpen(false)}
				onCreateContractPeriodServicePoint={(formikValues) => {
					handleCreateNewContractPeriodServicePoint(
						mapFormikToSubmitValue(formikValues)
					);
					setIsCreateServicePointOpen(false);
				}}
				onSearchServicePoints={(name) =>
					getServicePoints({ search__name: name })
				}
			/>
			<Spacer gap={24} />
		</>
	);
};

export default ServicePointsTab;
