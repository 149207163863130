import {
	Dialog,
	DialogTitle,
	DialogContent,
	IconButton,
	Box,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import CloseIcon from "@mui/icons-material/Close";

import CounterPartyForm from "./forms/CounterPartyForm";

import { ErrorText } from "../../common/components/ErrorText";
import { If } from "../../common/components/If";
import { CounterpartyFormik } from "./formik/counterpartyFormik";
import { ContractDetailType } from "../../requests_cm/gecoContractsService/types";

export interface CounterPartModalProps {
	selectedContract: ContractDetailType | null;
	changeRequestId?: number;
	isLoading: boolean;
	isChangeRequest: boolean;
	errorMessage?: string | string[];
	handleCLose: () => void;
	onSubmit: (data: CounterpartyFormik) => void;
}

export const CounterPartModal = ({
	selectedContract,
	isLoading,
	errorMessage,
	isChangeRequest,
	handleCLose,
	onSubmit,
}: CounterPartModalProps) => {
	if (!selectedContract) return null;
	return (
		<Dialog fullWidth onClose={handleCLose} open={!!selectedContract}>
			<DialogTitle
				sx={{
					display: "flex",
					justifyContent: "space-between",
					alignItems: "center",
				}}
			>
				<Box>
					<EditIcon color="primary" sx={{ mr: 1 }} />
					Edit Contract
				</Box>
				<IconButton aria-label="close" onClick={handleCLose}>
					<CloseIcon />
				</IconButton>
			</DialogTitle>
			<DialogContent
				sx={{
					overflow: "unset",
				}}
			>
				<If condition={errorMessage}>
					<Box>
						<Box sx={{ mb: 1 }}>Error Updating the Contract</Box>
						<Box>The updated contrat is not valid.</Box>
						<ErrorText>{errorMessage}</ErrorText>
					</Box>
				</If>
				<CounterPartyForm
					isLoading={isLoading}
					onCancelForm={handleCLose}
					submitButtonLabel={
						isChangeRequest ? "Request changes" : "Update changes"
					}
					contract={selectedContract}
					onSubmit={onSubmit}
				/>
			</DialogContent>
		</Dialog>
	);
};
