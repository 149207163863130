export function camelToSnake(str: string) {
	return str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);
}

export function convertKeysToSnakeCase<T>(obj: any): T {
	const newObject = {};
	for (const key of Object.keys(obj)) {
		//@ts-ignore
		newObject[camelToSnake(key)] = obj[key];
	}
	return newObject as T;
}
