import { createSlice } from "@reduxjs/toolkit";
import { Groups } from "../authentication/authentication.constant";

export interface PersistedFilters {
	tenders_query: string;
	tenders_filters: string;
	tenders_filters_count: Number;
	tenders_filter_template: string;
	tenders_workflow_view_query: string;
	tenders_workflow_view_filters: string;
	tenders_workflow_view_filters_count: number;
	tenders_workflow_view_filters_template: string;
	pricings_query: string;
	pricings_filters: string;
	pricings_filters_count: Number;
	pricings_filter_template: string;
}

export const filterTemplates = [
	{
		key: "CUSTOM",
		value: "CUSTOM",
		label: "Custom filters",
		filter: "",
		roles: [Groups.originator, Groups.trader],
	},
	{
		key: "ORIGINATOR_ACTIVE_TENDERS",
		value: "ORIGINATOR_ACTIVE_TENDERS",
		label: "Active tenders (originator)",
		filter: `
		{
			"tenders_status_not_in":[
				{ "id": "CLOSED", "name": "CLOSED" },
				{ "id": "BOOKED", "name": "BOOKED" },
				{ "id": "LOST", "name": "LOST" }
			],
			"originator":["USER()"], "validity_date": ["NOW()!1W", "NOW()|1M"]}`,
		roles: [Groups.originator],
	},
	{
		key: "TRADER_ACTIVE_TENDERS",
		value: "TRADER_ACTIVE_TENDERS",
		label: "Active tenders (trader)",
		filter: `
		{
			"tenders_status_not_in":[
				{ "id": "CANCELLED", "name": "CANCELLED" },
				{ "id": "BOOKED", "name": "BOOKED" },
				{ "id": "LOST", "name": "LOST" }
			],
			"trader":["USER()"], "validity_date": ["NOW()", "NOW()|1Y"]}`,
		roles: [Groups.originator],
	},
	{
		key: "ORIGINATOR_MYTENDERS",
		value: "ORIGINATOR_MYTENDERS",
		label: "My tenders (originator)",
		filter: `
		{
			"tenders_status":[
				{ "id":"NEW", "name":"NEW" },
				{ "id":"TODO", "name":"TODO" },
				{ "id": "TO_BE_CHECKED", "name": "TO_BE_CHECKED" },
				{ "id": "ONGOING", "name": "ONGOING" },
				{ "id": "PRICING_DONE", "name": "PRICING DONE" },
				{ "id": "UNDER_OFFER", "name": "UNDER OFFER" },
				{ "id": "LOST", "name": "LOST" },
				{ "id": "UNDER_BOOKING", "name": "UNDER BOOKING" },
				{ "id": "WON", "name": "WON" }
			],
			"originator":["USER()"], "validity_date": ["NOW()!1W", "NOW()|1M"]}`,
		roles: [Groups.originator],
	},
	{
		key: "TRADER_MYTENDERS",
		value: "TRADER_MYTENDERS",
		label: "My tenders (trader)",
		filter: `
		{
			"tenders_status":[
				{ "id":"NEW", "name":"NEW" },
				{ "id":"TODO", "name":"TODO" },
				{ "id": "TO_BE_CHECKED", "name": "TO_BE_CHECKED" },
				{ "id": "ONGOING", "name": "ONGOING" },
				{ "id": "PRICING_DONE", "name": "PRICING DONE" },
				{ "id": "UNDER_OFFER", "name": "UNDER OFFER" },
				{ "id": "LOST", "name": "LOST" },
				{ "id": "UNDER_BOOKING", "name": "UNDER BOOKING" },
				{ "id": "WON", "name": "WON" }
			],
			"trader":["USER()"], "validity_date": ["NOW()", "NOW()|1Y"]}`,
		roles: [Groups.trader],
	},
];

export enum FilterType {
	alphanumeric = "alphanumeric",
	numeric = "numeric",
	date = "date",
	list = "list",
	list_not_in = "list_not_in",
}

export interface PickListItem {
	id: string;
	name: string;
}

export interface PickLists {
	id: string;
	items: PickListItem[];
}

export interface Filter {
	field_key: string;
	display_name: string;
	field_path: string;
	http_source?: string;
	pickList?: PickListItem[];
	filter_type: FilterType;
}

interface FiltersState {
	opened: boolean;
	page: string;
	errors?: string;
	loading: boolean;
	pricings_odataQuery?: string;
	tenders_odataQuery?: string;
	lists: PickLists[];
	pricingLinqPredicates: any[];
	tenderLinqPredicates: any[];
	tenderWorkflowViewLinqPredicates: any[];
	persistedFilters?: PersistedFilters;
}

const initialState: FiltersState = {
	opened: false,
	page: "",
	loading: false,
	lists: [],
	pricingLinqPredicates: [],
	tenderLinqPredicates: [],
	tenderWorkflowViewLinqPredicates: [],
	persistedFilters: {
		tenders_query: "",
		tenders_filters: "",
		tenders_filter_template: "",
		tenders_filters_count: 0,
		tenders_workflow_view_query: "",
		tenders_workflow_view_filters: "",
		tenders_workflow_view_filters_template: "",
		tenders_workflow_view_filters_count: 0,
		pricings_query: "",
		pricings_filters: "",
		pricings_filter_template: "",
		pricings_filters_count: 0,
	},
};

export const filtersSlice = createSlice({
	name: "Filters",
	initialState,
	reducers: {
		resetPickList: (state, { payload }) => {
			const listIndex = state.lists.findIndex(
				(l: any) => l.id === payload.id
			);
			if (listIndex >= 0) {
				state.lists.splice(listIndex, 1);
			}
		},
		setLinqPredicates: (state, { payload }) => {
			if (payload.page === "Tenders") {
				state.tenderLinqPredicates = payload.predicates;
			}
			if (payload.page === "Tenders Workflow view") {
				state.tenderWorkflowViewLinqPredicates = payload.predicates;
			}
			if (payload.page === "Pricings") {
				state.pricingLinqPredicates = payload.predicates;
			}
		},
		setPickLists: (state, { payload }) => {
			state.lists = payload.lists;
		},
		setPersistedFilters: (state, { payload }) => {
			state.persistedFilters = payload;
		},
		setFiltersDrawerOpened(state, { payload }) {
			state.opened = payload.opened;
		},
	},
});

export const {
	setFiltersDrawerOpened,
	setPickLists,
	setLinqPredicates,
	resetPickList,
	setPersistedFilters,
} = filtersSlice.actions;

export default filtersSlice.reducer;
