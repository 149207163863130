import { Box, Button, Popover, Typography } from "@mui/material";
import React from "react";

export interface ClusterBreakdownProps {
	breakdown: Record<string, string>;
}

const styles = {
	box: {
		display: "flex",
		flexDirection: "column",
		gap: "8px",
		padding: "8px",
		maxHeight: "200px",
		overflowY: "auto",
	},
	singleBox: {
		display: "inline-flex",
		flexDirection: "row",
		gap: "16px",
		justifyContent: "flex-start",
	},
};

export function ClusterBreakdown(props: ClusterBreakdownProps) {
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);
	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};
	return (
		<>
			<Button onClick={handleClick}>Many</Button>
			<Popover
				anchorEl={anchorEl}
				open={open}
				onClose={handleClose}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "left",
				}}
			>
				<Box sx={styles.box}>
					{Object.keys(props.breakdown).map((key) => (
						<Box key={key} sx={styles.singleBox}>
							<Typography>{key}:</Typography>
							<Typography>
								<b>{props.breakdown[key] || "Cluster"}</b>
							</Typography>
						</Box>
					))}
				</Box>
			</Popover>
		</>
	);
}
