import { ServicePointType } from "../../../../../requests_cm/gecoSitesService/types";
import Modal from "../../../../../common/components/Modal";
import EditIcon from "@mui/icons-material/Edit";
import { primaryColor } from "../../../../../core/theme";
import { Box, FormControlLabel, IconButton, Switch } from "@mui/material";
import InfoBlock from "../../../../../common/components/InfoBlock/InfoBlock";
import { Formik, getIn } from "formik";
import { FluidTextField } from "../../../../../common/components/FluidTextField";
import FluidDatePicker from "../../../../../common/components/FluidDatePicker";
import FluidSelect from "../../../../../common/components/FluidSelect";
import { FluidButton } from "../../../../../common/components/FluidButton";
import React, { useEffect, useRef, useState } from "react";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { validationSchema } from "./EditServicePointModal.validation";
import { style } from "./EditServicePointModal.style";
import { initialValues, onSubmitForm } from "./EditServicePointModal.utils";

const EditServicePointModal = ({
	open = false,
	servicePoint,
	onClose,
	onSubmit,
	siteName,
	servicePointTypes,
	servicePointCodeTypes,
	tsos,
	dsos,
	eicBalancingGroups,
}: {
	open?: boolean;
	servicePoint: ServicePointType;
	onClose: () => void;
	onSubmit: (values: any) => void;
	siteName: string;
	servicePointTypes: { value: string; label: string }[];
	servicePointCodeTypes: { value: string; label: string }[];
	tsos: { value: string; label: string }[];
	dsos: { value: string; label: string }[];
	eicBalancingGroups: { value: string; label: string }[];
}) => {
	const [code2list, setCode2List] = useState<string[]>(
		servicePoint.code_2 ?? []
	);
	const [editingCode2Index, setEditingCode2Index] = useState<number | null>(
		null
	);

	const divRefs = useRef<(HTMLDivElement | null)[]>([]);

	const handleEditCode2 = (index: number) => {
		setEditingCode2Index(index);
	};

	const handleChangeCode2 = (value: string) => {
		console.log({ value });
	};

	useEffect(() => {
		if (editingCode2Index !== null && divRefs.current[editingCode2Index]) {
			divRefs.current[editingCode2Index]?.querySelector("input")?.focus();
		}
	}, [editingCode2Index]);

	const handleRemoveCode2 = (codeToRemove: string) => {
		setCode2List((currentCode2list) =>
			currentCode2list.filter((code) => code !== codeToRemove)
		);
	};

	const handleBlur = () => {
		setEditingCode2Index(null);
	};

	return (
		<Modal
			open={open}
			onClose={onClose}
			title={
				<Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
					<EditIcon sx={{ color: primaryColor }} /> Edit{" "}
					{servicePoint.name}
				</Box>
			}
			sx={{ width: "auto", maxWidth: "100%" }}
		>
			<InfoBlock
				info={[
					{
						label: "Site attached",
						value: siteName,
					},
				]}
				withBackground
				fullWidth
			/>
			<Formik
				initialValues={initialValues(servicePoint)}
				validationSchema={validationSchema}
				onSubmit={(values) => {
					onSubmitForm(
						values,
						servicePointTypes,
						servicePointCodeTypes,
						tsos,
						dsos,
						eicBalancingGroups,
						onSubmit,
						code2list
					);
					onClose();
				}}
			>
				{({
					errors,
					handleChange,
					setFieldValue,
					submitForm,
					values,
				}) => (
					<Box>
						<Box
							sx={{
								display: "flex",
								flexDirection: "column",
								gap: 1,
								margin: "16px 0",
							}}
						>
							<Box sx={{ display: "flex", gap: 2 }}>
								<FluidDatePicker
									onChange={handleChange}
									name="start_date"
									label="Start Date"
									value={values.start_date}
								/>
								<FluidDatePicker
									onChange={handleChange}
									name="end_date"
									label="End Date"
									value={values.end_date}
								/>
							</Box>
							<FluidTextField
								onChange={handleChange}
								value={values.name}
								name="name"
								label="Name"
								errorMessage={getIn(errors, "name")}
								isRequired
							/>
							<FluidSelect
								items={[
									{ value: "", label: "" },
									...servicePointTypes,
								]}
								label="Service Point Type"
								isLabelStatic
								name="type"
								onChange={(value) => {
									setFieldValue("type", value);
								}}
								value={String(values.type)}
								errorMessage={getIn(errors, "type")}
								isRequired
								sx={style.select}
							/>
							<Box sx={style.container}>
								<Box sx={style.container.col1}>
									<FluidSelect
										items={[
											{ value: "", label: "" },
											...servicePointCodeTypes,
										]}
										label="Service Point Code Type 1"
										isLabelStatic
										name="code_type"
										onChange={(value) => {
											setFieldValue("code_type", value);
										}}
										value={String(values.code_type)}
										errorMessage={getIn(
											errors,
											"code_type"
										)}
										sx={style.select}
										isRequired
									/>
									The name of the type of the code contained
									in code (ex: malo code for germany)
								</Box>
								<Box sx={style.container.col2}>
									<FluidTextField
										onChange={handleChange}
										value={values.code}
										name="code"
										label="Code 1"
									/>
									If there is a code needed for the service
									point, use this field (ex: malo code for
									germany)
								</Box>
								<Box sx={style.container.col3}></Box>
							</Box>
							<Box sx={style.container}>
								<Box sx={style.container.col1}>
									<FluidSelect
										items={[
											{ value: "", label: "" },
											...servicePointCodeTypes,
										]}
										label="Service Point Code Type 2"
										isLabelStatic
										name="code_type_2"
										onChange={(value) => {
											setFieldValue("code_type_2", value);
										}}
										value={String(values.code_type_2)}
										sx={style.select}
									/>
									The name of the type of the code contained
									in code (ex: malo code for germany)
								</Box>
								<Box sx={style.container.col2}>
									<Box sx={{ display: "flex", gap: 2 }}>
										<Box>
											<FluidTextField
												onChange={handleChange}
												value={values.code_2}
												name="code_2"
												label="Code 2"
											/>
											If there is a code needed for the
											service point, use this field (ex:
											malo code for germany)
										</Box>
									</Box>

									<Box>
										{code2list.map((code2, index) => (
											<Box key={code2} sx={style.code2}>
												<Box sx={style.code2.item}>
													<div
														ref={(el) =>
															(divRefs.current[
																index
															] = el)
														}
													>
														<FluidTextField
															value={code2}
															label=""
															name={code2}
															onChange={
																handleChangeCode2
															}
															isDisabled={
																editingCode2Index !==
																index
															}
															onBlur={handleBlur}
														/>
													</div>
													<IconButton
														type="button"
														onClick={() =>
															handleRemoveCode2(
																code2
															)
														}
													>
														<RemoveCircleOutlineIcon />
													</IconButton>
													<IconButton
														type="button"
														onClick={() =>
															handleEditCode2(
																index
															)
														}
													>
														<EditIcon />
													</IconButton>
												</Box>
											</Box>
										))}
									</Box>
								</Box>
								<Box sx={style.container.col3}>
									<FluidButton
										icon="add_circle_outline"
										label="Add Code 2"
										type="button"
										onClick={() => {
											if (values.code_2) {
												setCode2List(
													(currentCode2list) => [
														...currentCode2list,
														values.code_2,
													]
												);
												setFieldValue("code_2", "");
											}
										}}
										variant="inverse"
									/>
								</Box>
							</Box>
							<FormControlLabel
								control={
									<Switch
										checked={values.localconso}
										onChange={(e) => {
											setFieldValue(
												"localconso",
												(e.target as HTMLInputElement)
													.checked
											);
										}}
									/>
								}
								label="Local conso"
							/>
							<FluidSelect
								items={[{ value: "", label: "" }, ...tsos]}
								label="Tso"
								isLabelStatic
								name="tso"
								onChange={(value) => {
									setFieldValue("tso", value);
								}}
								value={String(values.tso)}
								isRequired
								errorMessage={getIn(errors, "tso")}
								sx={style.select}
							/>
							<FluidSelect
								items={[{ value: "", label: "" }, ...dsos]}
								label="Dso"
								isLabelStatic
								name="dso"
								onChange={(value) => {
									setFieldValue("dso", value);
								}}
								value={String(values.dso)}
								isRequired
								errorMessage={getIn(errors, "dso")}
								sx={style.select}
							/>
							<FluidSelect
								items={[
									{ value: "", label: "" },
									...eicBalancingGroups,
								]}
								label="Eic Balancing Group"
								isLabelStatic
								name="eic_balancing_group"
								onChange={(value) => {
									setFieldValue("eic_balancing_group", value);
								}}
								value={String(values.eic_balancing_group)}
								sx={style.select}
							/>
							<FluidTextField
								onChange={handleChange}
								value={values.marketing_type}
								name="marketing_type"
								label="Marketing Type"
							/>
						</Box>
						<Box
							sx={{
								display: "flex",
								justifyContent: "flex-end",
								gap: 2,
							}}
						>
							<FluidButton
								type="button"
								label="Cancel"
								onClick={onClose}
								variant="secondary"
							/>
							<FluidButton
								type="button"
								label="Save"
								onClick={submitForm}
							/>
						</Box>
					</Box>
				)}
			</Formik>
		</Modal>
	);
};

export default EditServicePointModal;
