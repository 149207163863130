import {
	Avatar,
	Box,
	CircularProgress,
	Dialog,
	DialogContent,
	DialogTitle,
	FormControl,
	FormHelperText,
	IconButton,
	Link,
	SxProps,
	Theme,
} from "@mui/material";
import { PrimaryButton } from "../../../common/components/CustomButton";
import { memo, useCallback, useEffect, useState } from "react";
import EventIcon from "@mui/icons-material/Event";
import MessageOutlinedIcon from "@mui/icons-material/MessageOutlined";
import CloseIcon from "@mui/icons-material/Close";
import {
	backgroundBrandSecondary,
	backgroundNeutralSecondary,
	primaryColor,
	textNeutralTertiary,
	white,
} from "../../../core/theme";
import StyledTextarea from "../../../common/components/StyledTextarea";
import { useFormik } from "formik";
import * as yup from "yup";
import { useAppDispatch } from "../../../common/hooks/default";
import {
	createNewComment,
	deleteComment,
	getCommentsByTender,
} from "../tender.thunk";
import { useSelector } from "react-redux";
import { selectLoader, selectTenderComments } from "../tender.selector";
import { If } from "../../../common/components/If";
import { CommentType, TenderComments as TenderComment } from "../tender.module";
import { formatStringDate } from "../../../common/utils/dateUtils";
import { stringForAvatar } from "../utils/workflowView.util";
import { initTenderComments } from "../tenderSlice";
import DeleteIcon from "@mui/icons-material/Delete";
import { ActionConfirmationModal } from "../../../common/components/ActionConfirmationModal";
import { selectAuthenticatedUser } from "../../authentication/auth.selector";
import canUserDeleteComment from "../utils/activityModal.util";

const style: { [key: string]: SxProps<Theme> } = {
	dialog: {
		minWidth: "40%",
	},
	dialogTitle: {
		display: "flex",
		alignItems: "center",
		justifyContent: "space-between",
		"& .message-icon": {
			color: primaryColor,
			marginRight: "12px",
		},
	},
	tenderName: {
		backgroundColor: backgroundBrandSecondary,
		padding: "16px",
		color: textNeutralTertiary,
		marginBottom: "12px",
		"& span": {
			color: "#000",
			fontWeight: "700",
		},
	},
	systemComment: {
		display: "flex",
		color: textNeutralTertiary,
		"& span": {
			color: "#000",
			fontWeight: "700",
		},
		"& svg": {
			color: "#000",
			marginRight: "12px",
		},
		marginBottom: "16px",
	},
	manualComment: {
		display: "flex",
		flexDirection: "column",
		"& div:first-of-type": {
			display: "flex",
		},
		"& .comment-reated-at": {
			color: textNeutralTertiary,
		},
		"& .creator-name": {
			fontWeight: "700",
			color: "#000",
		},
		marginBottom: "16px",
	},
	createdAt: {
		fontSize: "12px",
	},
	avatar: {
		backgroundColor: primaryColor,
		color: white,
		height: "28px",
		width: "28px",
		fontSize: "12px",
		marginRight: "12px",
	},
	commentContent: {
		backgroundColor: backgroundNeutralSecondary,
		padding: "8px",
		marginTop: "8px",
		display: "flex",
		justifyContent: "space-between",
		alignItems: "center",
	},
	commentform: {
		display: "flex",
		justifyContent: "space-between",
		"& textarea": {
			maxWidth: "75%",
		},
	},
};

export interface TenderActivityModalProps {
	onClose: () => void;
	isOpen: boolean;
	tenderId?: number;
	tenderName?: string;
}

export interface SystemCommentProps {
	tenderName: string;
	comment: TenderComment;
}

function TenderActivityModal(props: TenderActivityModalProps) {
	const { onClose, isOpen, tenderName, tenderId } = props;
	const dispatch = useAppDispatch();

	const [commentIdToDelete, setCommentIdToDelete] = useState<number | null>(
		null
	);
	const currentUser = useSelector(selectAuthenticatedUser);
	let validationSchema = yup.object({
		comment: yup.string().required("Comment is a required field"),
	});

	let tenderComments = useSelector(selectTenderComments);
	let loader = useSelector(selectLoader("tenderComments"));
	const [page, setPage] = useState(1);

	let formik = useFormik({
		initialValues: {
			comment: "",
		},
		validationSchema: validationSchema,
		enableReinitialize: true,
		onSubmit: (values: { comment: string }, { resetForm }) => {
			if (tenderId) {
				dispatch(createNewComment(tenderId, values.comment));
				resetForm();
			}
		},
	});

	useEffect(() => {
		if (tenderId) {
			dispatch(getCommentsByTender(tenderId, page, 30));
		}
	}, [tenderId, page]);

	const closeModal = () => {
		setPage(1);
		dispatch(initTenderComments());
		onClose();
	};

	const openDeleteCommentConfirmation = (commentId: number) => {
		setCommentIdToDelete(commentId);
	};
	const deleteCommentConfirmed = useCallback(() => {
		if (commentIdToDelete && tenderId)
			dispatch(deleteComment(commentIdToDelete, tenderId));
	}, [commentIdToDelete, tenderId]);

	const deleteCommentUnconfirmed = () => {
		setCommentIdToDelete(null);
	};

	return (
		<>
			<Dialog
				PaperProps={{ sx: style.dialog }}
				maxWidth="md"
				onClose={closeModal}
				open={isOpen}
			>
				<DialogTitle sx={style.dialogTitle}>
					<Box alignItems={"center"} display={"flex"}>
						<MessageOutlinedIcon className="message-icon" />
						Activity
					</Box>
					<IconButton
						onClick={() => {
							closeModal();
						}}
					>
						<CloseIcon />
					</IconButton>
				</DialogTitle>
				<DialogContent>
					<Box sx={style.tenderName}>
						Tender: <Box component={"span"}>{tenderName}</Box>
					</Box>

					{tenderComments?.data.map((comment: TenderComment) => (
						<>
							<If condition={comment.type === CommentType.SYSTEM}>
								<Box sx={style.systemComment}>
									<EventIcon />
									<Box>
										<Box component={"span"}>
											{comment.creator.first_name}&nbsp;
											{comment.creator.last_name}&nbsp;
										</Box>{" "}
										{comment.comment}
										<Box sx={style.createdAt}>
											{formatStringDate(
												comment.created_at,
												"dd/MM/yyyy, HH:mm"
											)}
										</Box>
									</Box>
								</Box>
							</If>

							<If condition={comment.type === CommentType.USER}>
								<Box sx={style.manualComment}>
									<Box>
										<Avatar
											{...stringForAvatar(
												`${comment.creator?.first_name} ${comment.creator?.last_name}`
											)}
											sx={style.avatar}
										/>
										<Box className="comment-reated-at">
											<Box
												component={"span"}
												className="creator-name"
											>
												{comment.creator.first_name}
												&nbsp;
												{comment.creator.last_name}
											</Box>
											&nbsp; added a comment
											<Box sx={style.createdAt}>
												{formatStringDate(
													comment.created_at,
													"dd/MM/yyyy, HH:mm",
													"CET"
												)}
											</Box>
										</Box>
									</Box>
									<Box sx={style.commentContent}>
										<Box component={"span"}>
											{comment.comment}
										</Box>
										{canUserDeleteComment(
											comment.creator,
											currentUser
										) && (
											<IconButton
												disabled={loader}
												onClick={() => {
													openDeleteCommentConfirmation(
														comment.id
													);
												}}
											>
												<DeleteIcon />
											</IconButton>
										)}
									</Box>
								</Box>
							</If>
						</>
					))}
					{loader && (
						<Box display={"flex"} justifyContent={"center"}>
							<CircularProgress
								sx={{ margin: "40px" }}
								data-testid="loader"
							/>
						</Box>
					)}
					{!loader && !tenderComments?.data.length && (
						<Box
							display={"flex"}
							margin={"20px"}
							justifyContent={"center"}
							color={textNeutralTertiary}
						>
							No Comments found
						</Box>
					)}
					{!loader && tenderComments?.has_more && (
						<Box
							display={"flex"}
							margin={"12px"}
							justifyContent={"center"}
							color={textNeutralTertiary}
						>
							<Link
								sx={{ cursor: "pointer" }}
								onClick={() => {
									setPage((prevPage) => prevPage + 1);
								}}
							>
								Load more
							</Link>
						</Box>
					)}

					<form
						key={`form-action-pricing-modal`}
						onSubmit={formik.handleSubmit}
					>
						<Box sx={style.commentform}>
							<FormControl
								error={!!formik.errors?.comment}
								sx={{ width: "100%" }}
								variant="standard"
							>
								<StyledTextarea
									onChange={formik.handleChange}
									value={formik.values?.comment}
									name="comment"
									placeholder="Tap you comment here"
									minRows={3}
									sx={{ width: "100%" }}
									disabled={!!loader}
								/>
								{!!formik.errors?.comment && (
									<FormHelperText id="error-text">
										{formik.errors?.comment || ""}
									</FormHelperText>
								)}
							</FormControl>
							<PrimaryButton
								disabled={!!loader}
								sx={{ minWidth: "100px" }}
								text="send"
								type="submit"
								color="primary"
							></PrimaryButton>
						</Box>
					</form>
				</DialogContent>
			</Dialog>
			<ActionConfirmationModal
				title="Confirmation"
				text="Do you want to delete this comment ?"
				isOpen={!!commentIdToDelete}
				onConfirm={deleteCommentConfirmed}
				onClose={deleteCommentUnconfirmed}
			/>
		</>
	);
}

export default memo(TenderActivityModal);
