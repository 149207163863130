import { ToggleButton, ToggleButtonGroup } from "@mui/material";

interface CustomToggleButtonGroupProps {
	groupValue: string;
	onValueChange: (newValue: any) => void;
	groupLabel: string;
	buttonValues: Array<string>;
	displayValues: Array<any>;
	sx?: any;
}

export function CustomToggleButtonGroup(props: CustomToggleButtonGroupProps) {
	const {
		groupValue,
		onValueChange,
		groupLabel,
		buttonValues,
		displayValues,
	} = props;
	const sx = props.sx ?? {
		height: "25px",
		paddingRight: "30px",
		marginTop: "7px",
	};
	return (
		<ToggleButtonGroup
			data-testid={"test-toggle-button-group-" + groupLabel}
			sx={sx}
			value={groupValue}
			exclusive
			onChange={(__, choice) => {
				onValueChange(choice);
			}}
			aria-label={groupLabel}
		>
			{buttonValues.map((buttonValue, index) => (
				<ToggleButton
					data-testid={"test-toggle-button-" + buttonValue}
					key={buttonValue}
					value={buttonValue}
					aria-label={buttonValue}
					sx={{
						borderRadius: 0,
						textTransform: "none",
					}}
				>
					{displayValues[index]}
				</ToggleButton>
			))}
		</ToggleButtonGroup>
	);
}
