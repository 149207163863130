import { useMemo, useState } from "react";
import isEqual from "lodash.isequal";
import { Formik, getIn } from "formik";
import { Box, Button, IconButton, Typography } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import en from "date-fns/locale/en-GB";
import { LocalizationProvider } from "@mui/x-date-pickers";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";

import {
	ContractPeriodServicePoint,
	ServicePointType,
} from "../../../../../requests_cm/gecoContractsService/types";
import FluidDatePicker from "../../../../../common/components/FluidDatePicker";
import AlertDialogModal from "../../../../../common/components/AlertDialogModal";
import { contractPeriodServicePointValidator } from "./ServicePointCardValidators";
import { FluidNumber } from "../../../../../common/components/FluidNumberField";
import { useIsUserWithinGroups } from "../../../../../common/hooks/useIsUserWithinGroups";
import { Groups } from "../../../../authentication/authentication.constant";
import { ActionConfirmationModal } from "../../../../../common/components/ActionConfirmationModal";

import { style } from "./ServicePointCard.style";

const ServicePointCard = ({
	servicePoint,
	handleUnlinkContractPeriodServicePoints,
	handleSaveChanges,
}: {
	servicePoint: ServicePointType;
	handleUnlinkContractPeriodServicePoints: (id: number) => void;
	handleSaveChanges: (
		id: number,
		servicePointContractPeriods: ContractPeriodServicePoint[]
	) => void;
}) => {
	const { isUserAuthorized } = useIsUserWithinGroups();

	const initialServicePointContractPeriods =
		servicePoint.contractperiod_servicepoints.map((obj) => ({
			...obj,
			volume_share: Number(obj.volume_share) * 10 * 10,
		}));

	const [servicePointContractPeriods, setServicePointContractPeriods] =
		useState(initialServicePointContractPeriods);
	const [isOpenConfirmationModal, setIsOpenConfirmationModal] =
		useState(false);
	const [servicePointIndexToDelete, setServicePointIndexToDelete] = useState<
		null | number
	>(null);

	const isUserAuthorizedBool = isUserAuthorized([
		Groups.geco_admin,
		Groups.geco_trader,
	]);

	const formDataTouched = useMemo(() => {
		return !isEqual(
			initialServicePointContractPeriods,
			servicePointContractPeriods
		);
	}, [initialServicePointContractPeriods, servicePointContractPeriods]);

	const handleAddContractPeriod = () => {
		setServicePointContractPeriods((current) => [
			...current,
			{
				end_date: "",
				start_date: "",
				volume_share: 0,
			},
		]);
	};

	return (
		<>
			<AlertDialogModal
				handleApprove={() =>
					handleUnlinkContractPeriodServicePoints(servicePoint.id)
				}
				handleClose={() => setIsOpenConfirmationModal(false)}
				isOpen={isOpenConfirmationModal}
				title="Are you sure you want to unlink this service point from the contract period?"
			/>
			<Box sx={style.card}>
				<Box sx={style.header}>
					<Typography variant="h3" sx={style.header.title}>
						{servicePoint?.name}
					</Typography>
					{isUserAuthorizedBool && (
						<Box sx={style.header.icons}>
							<IconButton
								onClick={() => setIsOpenConfirmationModal(true)}
							>
								<RemoveCircleOutlineIcon />
							</IconButton>
						</Box>
					)}
				</Box>
				<Box sx={style.infoList}>
					<Box>
						<Typography>Site</Typography>
						<Typography sx={style.infoText}>
							{servicePoint?.site_name}
						</Typography>
					</Box>
					<Box>
						<Typography>Capacity (kW)</Typography>
						<Typography sx={style.infoText}>
							{servicePoint?.installed_capacity}
						</Typography>
					</Box>
					<Box>
						<Typography>Type</Typography>
						<Typography sx={style.infoText}>
							{servicePoint?.servicepoint_type?.name}
						</Typography>
					</Box>
					<Box>
						<Typography>Code</Typography>
						<Typography sx={style.infoText}>
							{servicePoint?.code}
						</Typography>
					</Box>
					<Box>
						<Typography>TSO</Typography>
						<Typography sx={style.infoText}>
							{servicePoint?.tso?.name}
						</Typography>
					</Box>
				</Box>
				<Box>
					<Typography>DSO</Typography>
					<Typography sx={style.infoText}>
						{servicePoint?.dso?.name}
					</Typography>
				</Box>
				<Box sx={style.contractPeriodServicePointList}>
					<Box sx={style.contractPeriodServicePoint}>
						{servicePointContractPeriods?.map(
							(contractPeriod, index) => (
								<Formik
									key={index}
									initialValues={{
										startDate: contractPeriod.start_date,
										endDate: contractPeriod.end_date,
										volumeShare: String(
											contractPeriod.volume_share
										),
									}}
									validationSchema={
										contractPeriodServicePointValidator
									}
									onSubmit={(values) => {
										if (isUserAuthorizedBool) {
											setServicePointContractPeriods(
												(current) => {
													const newState = [
														...current,
													];

													newState[index] = {
														id: contractPeriod.id,
														start_date:
															values.startDate!,
														end_date:
															values.endDate!,
														volume_share: Number(
															values.volumeShare
														),
													};

													return newState;
												}
											);
										}
									}}
								>
									{({
										handleChange,
										handleSubmit,
										values,
										submitForm,
										errors,
									}) => (
										<form onSubmit={handleSubmit}>
											<LocalizationProvider
												dateAdapter={AdapterDateFns}
												adapterLocale={en}
											>
												<Box sx={style.formWrapper}>
													<FluidDatePicker
														label="Start Date"
														name="startDate"
														onChange={(e) => {
															handleChange(e);
															submitForm();
														}}
														value={values.startDate}
														errorMessage={
															errors.startDate
														}
														isReadOnly={
															!isUserAuthorizedBool
														}
													/>
													<FluidDatePicker
														label="End Date (excluded)"
														name="endDate"
														onChange={(e) => {
															handleChange(e);
															submitForm();
														}}
														value={values.endDate}
														errorMessage={
															errors.endDate
														}
														isReadOnly={
															!isUserAuthorizedBool
														}
													/>
													<FluidNumber
														onChange={(e) => {
															handleChange(e);
															submitForm();
														}}
														errorMessage={getIn(
															errors,
															"volumeShare"
														)}
														name="volumeShare"
														title={
															"Volume Share in %"
														}
														value={Number(
															values.volumeShare
														)}
														sx={{
															width: "120px",
														}}
														isReadOnly={
															!isUserAuthorizedBool
														}
													/>
													<Box
														sx={style.deleteWrapper}
													>
														<Button
															onClick={() =>
																setServicePointIndexToDelete(
																	index
																)
															}
															sx={
																style.deleteButton
															}
															disabled={
																!isUserAuthorizedBool
															}
														>
															<DeleteForeverIcon />{" "}
															Delete
														</Button>
													</Box>
												</Box>
											</LocalizationProvider>
										</form>
									)}
								</Formik>
							)
						)}
					</Box>
					<Button
						onClick={handleAddContractPeriod}
						startIcon={<AddCircleOutlineIcon />}
						sx={style.addButton}
						disabled={!isUserAuthorizedBool}
					>
						Add a Contract Period Service Point
					</Button>
					{formDataTouched && (
						<Button
							onClick={() =>
								handleSaveChanges(
									servicePoint.id,
									servicePointContractPeriods
								)
							}
							sx={style.saveButton}
							disabled={!isUserAuthorizedBool}
						>
							Save changes
						</Button>
					)}
				</Box>
			</Box>
			{servicePointIndexToDelete !== null && (
				<ActionConfirmationModal
					isOpen
					title="Delete Service Point"
					text="Remove the service point from the contract period?"
					onClose={() => setServicePointIndexToDelete(null)}
					onConfirm={() => {
						setServicePointContractPeriods((current) => {
							current.splice(servicePointIndexToDelete, 1);
							return [...current];
						});
					}}
				/>
			)}
		</>
	);
};

export default ServicePointCard;
