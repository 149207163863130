import {
	SearchPartiesResultType,
	TprPartyDetailType,
	TprPartyListType,
} from "./types";
import contractManagementGepoApi from "../contractManagementGepoApi";

const sortParties = (parties: TprPartyListType[]) => {
	return parties.sort((a, b) => a.displayName.localeCompare(b.displayName));
};

const service = contractManagementGepoApi.injectEndpoints({
	endpoints: (builder) => ({
		searchParties: builder.query<
			SearchPartiesResultType,
			{ search: string }
		>({
			query: ({ search }) => {
				return {
					url: "external-services/parties",
					method: "POST",
					data: { search },
				};
			},
			transformResponse: (response: SearchPartiesResultType) => {
				const uniqueParties = Array.from(
					new Map(
						response.partyResults.map((party) => [
							party.displayName,
							party,
						])
					).values()
				);

				return {
					...response,
					partyResults: sortParties(uniqueParties),
				};
			},
		}),
		getPartyDetail: builder.query<TprPartyDetailType, { id: string }>({
			query: ({ id }) => {
				return {
					url: `external-services/parties_detail/${id}`,
					method: "POST",
				};
			},
		}),
	}),
});

export const { useLazyGetPartyDetailQuery, useLazySearchPartiesQuery } =
	service;

export default service;
