import { Box } from "@mui/material";
import { style } from "./RedispatchConstraint.style";
import { InfoBlockItem } from "../../../../../common/components/InfoBlockItem";
import { PrimaryTitle } from "../../../../../common/components/PrimaryTitle";
import Group from "../../../../../common/components/Group";
import { If } from "../../../../../common/components/If";
import { RedispatchConstraint } from "../../../../../requests_cm/gecoSitesService/types";
import { FluidButton } from "../../../../../common/components/FluidButton";

type HighlightedFields = {
	[K in keyof RedispatchConstraint]: boolean | undefined;
};

export interface RedispatchConstraintCardProps {
	active?: boolean;
	onEdit: () => void;
	currentRedispatchConstraint?: boolean;
	highlightedFields: Record<keyof HighlightedFields, boolean>;
	redispatchConstraint: RedispatchConstraint;
}

export const RedispatchConstraintCard = ({
	active,
	onEdit,
	currentRedispatchConstraint = false,
	highlightedFields,
	redispatchConstraint,
}: RedispatchConstraintCardProps) => {
	return (
		<Box sx={{ ...style.container, background: active ? "#FFFFFF" : "" }}>
			<Box
				sx={{
					display: "flex",
					alignItems: "center",
					gap: "16px",
				}}
			>
				<PrimaryTitle underline title={redispatchConstraint?.name} />
				{currentRedispatchConstraint && (
					<FluidButton
						icon="edit"
						label="Edit"
						onClick={onEdit}
						variant="inverse"
					/>
				)}
			</Box>
			<Group>
				<InfoBlockItem
					fallback="2000-01-01"
					label="Start Date"
					value={redispatchConstraint?.start_date}
				/>
				<InfoBlockItem
					fallback="2100-01-01"
					label="End Date"
					value={redispatchConstraint?.end_date}
				/>
			</Group>
			<If condition={redispatchConstraint?.technical_resource_operator}>
				<InfoBlockItem
					fallback="Not provided"
					label="BTR"
					value={redispatchConstraint?.technical_resource_operator}
					active={highlightedFields?.technical_resource_operator}
				/>
			</If>
			<If condition={redispatchConstraint?.eiv}>
				<InfoBlockItem
					fallback="Not provided"
					label="EIV"
					value={redispatchConstraint?.eiv}
					active={highlightedFields?.eiv}
				/>
			</If>
			<If condition={redispatchConstraint?.supplier}>
				<InfoBlockItem
					fallback="Not provided"
					label="Supplier"
					value={redispatchConstraint?.supplier}
					active={highlightedFields?.supplier}
				/>
			</If>
			<If condition={redispatchConstraint?.tr_settlement_model}>
				<InfoBlockItem
					fallback="Not provided"
					label="Settlement model"
					value={redispatchConstraint?.tr_settlement_model}
					active={highlightedFields?.tr_settlement_model}
				/>
			</If>
			<If condition={redispatchConstraint?.accounting_model}>
				<InfoBlockItem
					fallback="Not provided"
					label="Account Model"
					value={redispatchConstraint?.accounting_model}
					active={highlightedFields?.accounting_model}
				/>
			</If>
			<If condition={redispatchConstraint?.redispatch_mode}>
				<InfoBlockItem
					fallback="Not provided"
					label="Redispatch Mode"
					value={redispatchConstraint?.redispatch_mode}
					active={highlightedFields?.redispatch_mode}
				/>
			</If>
		</Box>
	);
};
