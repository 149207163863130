import { ChangeEvent, CSSProperties, DragEvent } from "react";
import { Box, Checkbox, FormControlLabel, FormGroup } from "@mui/material";
import {
	ContractPeriodServicePointType,
	ContractSplitValuesType,
} from "../../../../requests_cm/gecoContractsService/types";
import { ServicePointShareType } from "./ContractSplit.types";
import { FluidNumber } from "../../../../common/components/FluidNumberField";
import { FormikHelpers } from "formik";

interface ServicePointListProps {
	dataTestid?: string;
	hasVolumeShare?: boolean;
	index: number;
	isDraggingOver: boolean;
	onCheckboxChange: (servicePointId: number) => void;
	onDragLeave: () => void;
	onDragOver: (e: DragEvent<Element>) => void;
	onDragStart: (
		e: DragEvent<Element>,
		servicePoint: ContractPeriodServicePointType
	) => void;
	onDrop: (e: DragEvent<Element>) => void;
	onSelectAll: (event: ChangeEvent<HTMLInputElement>) => void;
	selectedIds: number[];
	servicePoints: ContractPeriodServicePointType[] | ServicePointShareType[];
	setFieldValue: FormikHelpers<ContractSplitValuesType>["setFieldValue"];
	title: string;
}

const ServicePointList = ({
	dataTestid,
	hasVolumeShare = false,
	index,
	isDraggingOver,
	onCheckboxChange,
	onDragLeave,
	onDragOver,
	onDragStart,
	onDrop,
	onSelectAll,
	selectedIds,
	servicePoints,
	setFieldValue,
	title,
}: ServicePointListProps) => {
	const handleValueShareChange = (
		e: ChangeEvent<HTMLInputElement>,
		servicePointId: number
	) => {
		const updatedServicePoints = servicePoints.map((sp) =>
			sp.id === servicePointId
				? { ...sp, volume_share: parseFloat(e.target.value) }
				: sp
		);

		setFieldValue(`${index}.servicepoints`, updatedServicePoints);
	};

	const servicePointStyle: (isHeader?: boolean) => CSSProperties = (
		isHeader = false
	) => ({
		display: "flex",
		alignItems: "center",
		justifyContent: "space-between",
		backgroundColor: isHeader ? "#EBEEF1" : undefined,
		border: isHeader ? "1px solid #CCD4DA" : undefined,
		padding: "0 8px",
		height: "48px",
	});

	return (
		<Box
			data-testid={dataTestid}
			data-is-dragging-over={isDraggingOver}
			sx={{ flexGrow: 1 }}
		>
			{title} ({servicePoints.length})
			<Box
				data-testid={`${dataTestid}-list`}
				sx={{
					backgroundColor: isDraggingOver ? "action.hover" : "white",
					p: 2,
					borderRadius: 1,
				}}
				onDragOver={onDragOver}
				onDragLeave={onDragLeave}
				onDrop={onDrop}
			>
				<FormGroup>
					<Box style={servicePointStyle(true)}>
						<FormControlLabel
							control={
								<Checkbox
									data-testid="select-all"
									checked={
										selectedIds.length ===
											servicePoints.length &&
										servicePoints.length > 0
									}
									indeterminate={
										selectedIds.length > 0 &&
										selectedIds.length <
											servicePoints.length
									}
									onChange={onSelectAll}
								/>
							}
							label="Service point"
						/>
						<Box
							sx={{
								width: "100px",
								fontSize: "16px",
								fontWeight: "bold",
							}}
						>
							% of part
						</Box>
					</Box>
					{servicePoints.map((servicePoint) => (
						<Box key={servicePoint.id} style={servicePointStyle()}>
							<FormControlLabel
								key={servicePoint.id}
								control={
									<Checkbox
										checked={selectedIds.includes(
											servicePoint.id
										)}
										onChange={() =>
											onCheckboxChange(servicePoint.id)
										}
									/>
								}
								label={servicePoint.name}
								draggable
								onDragStart={(e) =>
									onDragStart(e, servicePoint)
								}
								sx={{ cursor: "move", flexGrow: 1 }}
							/>
							{hasVolumeShare && (
								<Box
									sx={{
										display: "block",
										overflow: "hidden",
										padding: "1px",
									}}
								>
									<FluidNumber
										onChange={(e) =>
											handleValueShareChange(
												e,
												servicePoint.id
											)
										}
										name="volumeShare"
										title=""
										value={
											(
												servicePoint as ServicePointShareType
											).volume_share
										}
										sx={{
											marginTop: "-28px",
											width: "100px",
										}}
									/>
								</Box>
							)}
						</Box>
					))}
				</FormGroup>
			</Box>
		</Box>
	);
};

export default ServicePointList;
