import { Operations } from "../FiltersDrawer";

export interface FiltersByKeys {
	[key: string]: { operation: Operations; value: any; label: string };
}

export const transformFilters = (filters: { [key: string]: any }) => {
	const filtersByKeys: FiltersByKeys = {};

	Object.keys(filters).forEach((filterKey) => {
		const parts = filterKey.split("__");
		const operation = parts.pop()! as Operations;
		const filterkeyValue = parts.join("__");
		filtersByKeys[filterkeyValue] = {
			operation,
			value: filters[filterKey],
			label: filterkeyValue,
		};
	});
	return filtersByKeys;
};
