import SimpleTable from "../../../../common/components/SimpleTable";
import { HeadersType } from "../../../../common/components/SimpleTable/SimpleTable";
import NoDataMessage from "../../../../common/components/NoDataMessage";
import { ContractType } from "../../../../requests_cm/gecoContractsService/types";
import { PATH, getPathWithParameters } from "../../../../router-path";
import _ from "lodash";

const ContractsTab = ({
	contracts,
	isLoading = false,
}: {
	contracts: ContractType[];
	isLoading?: boolean;
}) => {
	const headers: HeadersType<ContractType>[] = [
		{ label: "Status", accessor: "status" },
		{ label: "ID", accessor: "id" },
		{
			label: "Contract Name",
			accessor: "name",
			getAnchorTag: (item: ContractType) =>
				getPathWithParameters(PATH.CONTRACT_DETAIL, {
					contractId: item.id,
				}),
		},
		{ label: "Uid", accessor: "uid" },
		{ label: "CP", accessor: "nb_contractperiods" },
		{ label: "Country", accessor: "country" },
		{ label: "Book (prefix)", accessor: "book" },
		{ label: "Contract Type", accessor: "contract_type" },
		{
			label: "Start Date",
			accessor: "start_date",
		},
		{
			label: "End Date",
			accessor: "end_date",
		},
		{ label: "Time Zone", accessor: "timezone" },
		{
			label: "Signature Date",
			accessor: "signature_date",
		},
		{ label: "Agreement Type", accessor: "agreement_type" },
		{ label: "Payment Market Places", accessor: "payment_market_places" },
		{ label: "Language", accessor: "language" },
	];

	const sortedContracts = _.orderBy(contracts, [
		"agreement_type",
		"start_date",
		"name",
		"uid",
	]);

	return (
		<>
			{!contracts.length && !isLoading && <NoDataMessage />}
			{!!contracts.length && (
				<SimpleTable
					isLoading={isLoading}
					headers={headers}
					items={sortedContracts}
				/>
			)}
		</>
	);
};

export default ContractsTab;
