import { useSelector } from "react-redux";
import CustomSelect from "../../../common/components/CustomSelect";
import { selectPricingTemplateState } from "../PricingRequestStepper.selector";
import { SelectChangeEvent } from "@mui/material";
import { PricingTemplate } from "../PricingRequestStepper.slice";

interface PricingTemplateInputProps {
	value: string;
	setFieldValue: (fieldName: string, value: any) => void;
}

function PricingTemplateInput(props: PricingTemplateInputProps) {
	const { value, setFieldValue } = props;
	const pricingTemplates = useSelector(selectPricingTemplateState);
	function handleApplyingPricingTemplate(
		event: SelectChangeEvent<any>
	): void {
		const fieldsShouldBeEmpty = [
			"startDate",
			"pricingDate",
			"gooClickDate",
			"endDate",
			"template",
			"gooStartDate",
			"gooEndDate",
			"clickLimitDate",
		];
		const selectedTemplate = event.target.value as string;
		const pricingTpl: any = JSON.parse(selectedTemplate);

		Object.entries(pricingTpl).forEach((element) => {
			const [fieldTitle, fieldValue] = element;
			if (!fieldsShouldBeEmpty.includes(fieldTitle)) {
				setFieldValue(fieldTitle, fieldValue);
			}
		});
		setFieldValue("template", event.target.value);
	}

	return (
		<CustomSelect
			data-testid="pricing_template"
			name="template"
			label="Template"
			value={value}
			onChange={handleApplyingPricingTemplate}
			sx={{
				width: "500px",
				background: "#fff",
				borderRadius: "0px",
			}}
			items={pricingTemplates?.map((tpl: PricingTemplate) => {
				return {
					key: tpl.title,
					value: JSON.stringify(tpl.params),
					label: tpl.title,
				};
			})}
		></CustomSelect>
	);
}

export default PricingTemplateInput;
