import { useState } from "react";
import Tabs, { SingleTab } from "../../../common/components/Tabs";
import { If } from "../../../common/components/If";
import ServicePointsTab from "./ServicePointsTab";
import { GetOneSiteResponseType } from "../../../requests_cm/gecoSitesService/types";
import { Spacer } from "../../../common/components/Spacer";
import { ControlObjectsTab } from "./ControlObjectsTab/ControlObjectsTab";
import { RedispatchConstraintTab } from "./RedispatchConstraint/RedispatchConstraintTab";
import AssetsTab from "./AssetsTab";
import CurtailmentObjectsTab from "./CurtailmentObjectsTab";
import { ContractsTabContainer } from "./ContractsTab";

export enum TabID {
	SERVICE_POINT = "SERVICE_POINT",
	ASSET = "ASSET",
	CONTROL_OBJECT = "CONTROL_OBJECT",
	REDISPATCH_CONSTRAINT = "REDISPATCH_CONSTRAINT",
	CURTAILMENT_OBJECT = "CURTAILMENT_OBJECT",
	CONTRACT = "CONTRACT",
}

const SiteDetailTabs = ({ site }: { site: GetOneSiteResponseType }) => {
	const [activeTab, setActiveTab] = useState<TabID>(TabID.SERVICE_POINT);

	return (
		<>
			<Tabs
				activeTab={activeTab}
				label={""}
				onClickTabItem={(tab: TabID) => {
					setActiveTab(tab);
				}}
			>
				<SingleTab
					label="Service point"
					id={TabID.SERVICE_POINT}
					isActive={activeTab === TabID.SERVICE_POINT}
				/>

				<SingleTab
					label="Contracts"
					id={TabID.CONTRACT}
					isActive={activeTab === TabID.CONTRACT}
				/>

				<SingleTab
					label="Asset"
					id={TabID.ASSET}
					isActive={activeTab === TabID.ASSET}
				/>
				<SingleTab
					label="Redispatch Constraint"
					id={TabID.REDISPATCH_CONSTRAINT}
					isDisabled={site?.country != "DE"}
					isActive={activeTab === TabID.REDISPATCH_CONSTRAINT}
				/>
				<SingleTab
					label="Control Object"
					id={TabID.CONTROL_OBJECT}
					isActive={activeTab === TabID.CONTROL_OBJECT}
				/>
				<SingleTab
					label="Curtailment Object"
					id={TabID.CURTAILMENT_OBJECT}
					isActive={activeTab === TabID.CURTAILMENT_OBJECT}
				/>
			</Tabs>
			<Spacer gap={24} />
			<If condition={activeTab === TabID.SERVICE_POINT}>
				<ServicePointsTab
					servicePoints={site.service_points}
					site={site}
				/>
			</If>

			<If condition={activeTab === TabID.CONTRACT}>
				<ContractsTabContainer site={site} />
			</If>
			<If condition={activeTab === TabID.ASSET}>
				<AssetsTab site={site} />
			</If>
			<If condition={activeTab === TabID.CONTROL_OBJECT}>
				<ControlObjectsTab siteId={site.id} />
			</If>
			<If condition={activeTab === TabID.REDISPATCH_CONSTRAINT}>
				<RedispatchConstraintTab siteId={site.id} />
			</If>
			<If condition={activeTab === TabID.CURTAILMENT_OBJECT}>
				<CurtailmentObjectsTab siteId={site.id} />
			</If>
		</>
	);
};

export default SiteDetailTabs;
