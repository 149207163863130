import { Box, CircularProgress } from "@mui/material";
import { ControlObjectCard } from "./ControlObjectCard";
import { style } from "./ControlObjectsTab.style";
import { useEffect, useMemo, useState } from "react";
import {
	useAddControlObjectMutation,
	useLazyGetSiteControlObjectsQuery,
	useUpdateControlObjectMutation,
} from "../../../../requests_cm/gecoSitesService/service";
import { useRtkQueryDynamicEndpoint } from "../../../../common/hooks/useRtkQueryDynamicEndpoint";
import { If } from "../../../../common/components/If";
import ErrorMessageModal from "../../../../common/components/ErrorMessageDialog";
import {
	ErrorType,
	formatApiErrorMessage,
} from "../../../../common/utils/formatApiErrorMessage";
import { useSelector } from "react-redux";
import { selectFeatureFlags } from "../../../parameters/ParametersPage.selector";
import { FeatureName } from "../../../../common/hooks/useFeatureFlags";
import NoDataMessage from "../../../../common/components/NoDataMessage";
import { FluidButton } from "../../../../common/components/FluidButton";
import EditControlObjectModal from "./EditControlObjectModal";
import { ControlObject } from "../../../../requests_cm/gecoSitesService/types";

export interface ControlObjectsTabProps {
	siteId: number;
}

export const ControlObjectsTab = ({ siteId }: ControlObjectsTabProps) => {
	// TODO: waiting for the fix on geco side to provide host for file path
	const features = useSelector(selectFeatureFlags);
	const gecoEndpoint = useMemo(
		() => features[FeatureName.geco_endpoint],
		[features]
	) as unknown as string;

	const [updateControlObjectBase] = useUpdateControlObjectMutation();
	const updateControlObject = useRtkQueryDynamicEndpoint(
		updateControlObjectBase
	);

	const [addControlObjectBase] = useAddControlObjectMutation();
	const addControlObject = useRtkQueryDynamicEndpoint(addControlObjectBase);

	const [controlObjectToEdit, setControlObjectToEdit] = useState<
		ControlObject | undefined
	>();

	const [
		getSiteControlObjectBase,
		{ isLoading, error, data: controlObjects },
	] = useLazyGetSiteControlObjectsQuery();
	const getSiteControlObject = useRtkQueryDynamicEndpoint(
		getSiteControlObjectBase
	);

	const [isModalAddControlObjectOpen, setisModalAddControlObjectOpen] =
		useState(false);

	useEffect(() => {
		if (siteId) {
			getSiteControlObject({ siteId });
		}
	}, []);

	const handleUpdateControlObject = (values: any) => {
		if (values.id !== null) {
			updateControlObject({ siteId, controlObject: values });
		} else {
			addControlObject({ siteId, controlObject: values });
		}
	};

	return (
		<>
			<If condition={isLoading}>
				<Box sx={style.progressWrapper}>
					<CircularProgress data-testid="loader" />
				</Box>
			</If>
			<If condition={!isLoading}>
				<Box sx={style.container}>
					<Box sx={{ marginBottom: 2 }}>
						<FluidButton
							label="Add Control Object"
							onClick={() => setisModalAddControlObjectOpen(true)}
							icon="add_circle_outline"
						/>
					</Box>
					{!controlObjects?.length && <NoDataMessage />}
					{!!controlObjects?.length &&
						(controlObjects || []).map((controlObject, index) => (
							<ControlObjectCard
								key={index}
								active={index === 0}
								controlObject={controlObject}
								gecoEndpoint={gecoEndpoint}
								onEdit={() => {
									setControlObjectToEdit(controlObject);
									setisModalAddControlObjectOpen(true);
								}}
							/>
						))}
					<EditControlObjectModal
						open={isModalAddControlObjectOpen}
						onClose={() => setisModalAddControlObjectOpen(false)}
						controlObject={controlObjectToEdit}
						onSubmit={(values) => handleUpdateControlObject(values)}
					/>
				</Box>
			</If>
			<ErrorMessageModal
				title={`Cannot retrieve control objects on site ${siteId}`}
				content={formatApiErrorMessage(error as ErrorType)}
			/>
		</>
	);
};
