import { Box } from "@mui/material";
import { ContractPeriodCurtailmentObjectsAudit } from "../../../../../requests_cm/gecoContractsService/types";
import Slider from "../../../../../common/components/Slider";
import CurtailmentDataList from "../CurtailmentDataList";
import { style } from "../CurtailmentObject.style";

interface CurtailmentObjectHistorySliderProps {
	data: ContractPeriodCurtailmentObjectsAudit[];
	width: string;
}

const CurtailmentObjectHistorySlider = ({
	data,
	width,
}: CurtailmentObjectHistorySliderProps) => {
	return (
		<Slider sx={{ width }} innerSx={{ gap: "16px" }}>
			{data?.map((curtailmentObject, index) => {
				const nextCurtailmentObject = data[index + 1];

				const list = [
					{
						value: (
							<Box sx={style.cartridge}>
								{curtailmentObject.curtailment_object_subtype}
							</Box>
						),
					},
					{
						label: "Start Date",
						value: curtailmentObject.start_date,
					},
					{
						label: "End Date",
						value: curtailmentObject.end_date,
					},
					{
						label: "Pricing Method",
						value: curtailmentObject.pricing_method,
						changed:
							nextCurtailmentObject &&
							nextCurtailmentObject.pricing_method !==
								curtailmentObject.pricing_method,
					},
					{
						label: "Commodity Index",
						value: curtailmentObject.commodity_index,
						changed:
							nextCurtailmentObject &&
							nextCurtailmentObject.commodity_index !==
								curtailmentObject.commodity_index,
					},
					{
						label: "Compensation Method",
						value: curtailmentObject.compensation_method,
						changed:
							nextCurtailmentObject &&
							nextCurtailmentObject.compensation_method !==
								curtailmentObject.compensation_method,
					},
					{
						label: "BCV Config",
						value: curtailmentObject.bcv_config,
						changed:
							nextCurtailmentObject &&
							nextCurtailmentObject.bcv_config !==
								curtailmentObject.bcv_config,
					},
					{
						label: "Compensation Deal Type",
						value: curtailmentObject.compensation_deal_type,
						changed:
							nextCurtailmentObject &&
							nextCurtailmentObject.compensation_deal_type !==
								curtailmentObject.compensation_deal_type,
					},
					{
						label: "Is Compensable",
						value: curtailmentObject.is_compensable ? "Yes" : "No",
						changed:
							nextCurtailmentObject &&
							nextCurtailmentObject.is_compensable !==
								curtailmentObject.is_compensable,
					},
					{
						label: "Counterpart",
						value: curtailmentObject.counterpart_kind,
						changed:
							nextCurtailmentObject &&
							nextCurtailmentObject.counterpart_kind !==
								curtailmentObject.counterpart_kind,
					},
				];

				return (
					<Box
						key={curtailmentObject.id}
						sx={{ border: "1px solid #EBEEF1", padding: "16px" }}
					>
						<CurtailmentDataList
							wrapperSx={{
								borderLeft: "1px solid #9EADB8",
								padding: "0 16px 16px 16px",
								display: "flex",
								flexDirection: "column",
								gap: "8px",
							}}
							list={list}
						/>
					</Box>
				);
			})}
		</Slider>
	);
};

export default CurtailmentObjectHistorySlider;
