import { Formik } from "formik";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { PrimaryButton } from "../../../../../common/components/CustomButton";
import { noop } from "../../../../../common/utils/operations";
import { ContractDetailType } from "../../../../../requests_cm/gecoContractsService/types";
import { Box } from "@mui/material";
import { style } from "./ClickabilityModal.style";
import { FluidButton } from "../../../../../common/components/FluidButton";
import {
	ClickabilityFormik,
	clickabilityFormikToContractMapper,
	clickabilityTabFormikInitialValueMapper,
} from "../../../formik/clickabilityTabFormik";
import { clickabilityValidator } from "../../../formik/clickabilityTabValidators";
import { ClickabilityFields } from "./ClickabilityFields";

interface ClickabilityModalProps {
	contract: ContractDetailType | undefined;
	contractPeriodId: number;
	isOpen: boolean;
	handleClose: () => void;
	onSaveDraftContract: (contractDetail: ContractDetailType) => void;
}

function ClickabilityModal(props: ClickabilityModalProps) {
	const {
		isOpen,
		contract,
		contractPeriodId,
		handleClose,
		onSaveDraftContract,
	} = props;
	return (
		<Dialog maxWidth="lg" onClose={handleClose} open={isOpen}>
			<DialogTitle>{"Edit Clickability"}</DialogTitle>
			<Formik
				validationSchema={clickabilityValidator}
				enableReinitialize
				initialValues={clickabilityTabFormikInitialValueMapper(
					contract,
					contractPeriodId
				)}
				onSubmit={(formData: ClickabilityFormik) => {
					onSaveDraftContract(
						clickabilityFormikToContractMapper(
							formData,
							contract as ContractDetailType,
							contractPeriodId
						)
					);
					handleClose();
				}}
			>
				{({
					handleChange,
					handleSubmit,
					setFieldValue,
					values,
					errors,
				}) => (
					<form onSubmit={handleSubmit}>
						<DialogContent>
							<Box sx={style.container}>
								<ClickabilityFields
									errors={errors}
									values={values}
									setFieldValue={setFieldValue}
									handleChange={handleChange}
								/>
							</Box>
						</DialogContent>
						<DialogActions>
							<PrimaryButton
								onClick={handleClose}
								text="Cancel"
								type="button"
								color="secondary"
							></PrimaryButton>
							<FluidButton
								type="submit"
								icon={"done"}
								label={"Valid"}
								onClick={noop}
							/>
						</DialogActions>
					</form>
				)}
			</Formik>
		</Dialog>
	);
}

export default ClickabilityModal;
