import SimpleTable from "../../../../common/components/SimpleTable";
import { HeadersType } from "../../../../common/components/SimpleTable/SimpleTable";
import {
	GetOneSiteResponseType,
	ServicePointType,
} from "../../../../requests_cm/gecoSitesService/types";
import NoDataMessage from "../../../../common/components/NoDataMessage";
import React, { useState } from "react";
import { IconButton } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { primaryColor } from "../../../../core/theme";
import EditServicePointModal from "./components/EditServicePointModal";
import { useRtkQueryDynamicEndpoint } from "../../../../common/hooks/useRtkQueryDynamicEndpoint";
import {
	useGetDsosQuery,
	useGetEicBalancingGroupsQuery,
	useGetServicePointCodeTypesQuery,
	useGetServicePointTypesQuery,
	useGetTsosQuery,
} from "../../../../requests_cm/gecoReferentialService/service";
import { useUpdateOneSiteMutation } from "../../../../requests_cm/gecoSitesService/service";

const ServicePointsTab = ({
	servicePoints,
	site,
}: {
	servicePoints: ServicePointType[];
	site: GetOneSiteResponseType;
}) => {
	const { data: servicePointTypes } = useRtkQueryDynamicEndpoint(
		useGetServicePointTypesQuery
	)({});
	const { data: servicePointCodeTypes } = useRtkQueryDynamicEndpoint(
		useGetServicePointCodeTypesQuery
	)({});
	const { data: dataTsos } = useRtkQueryDynamicEndpoint(useGetTsosQuery)({});
	const { data: dataDsos } = useRtkQueryDynamicEndpoint(useGetDsosQuery)({});
	const { data: dataEicBalancingGroups } = useRtkQueryDynamicEndpoint(
		useGetEicBalancingGroupsQuery
	)({});
	const [updateOneSiteBase] = useUpdateOneSiteMutation();

	const updateOneSite = useRtkQueryDynamicEndpoint(updateOneSiteBase);

	const [servicePointToEdit, setServicePointToEdit] =
		useState<ServicePointType | null>(null);

	const handleUpdateSite = (values: any) => {
		const updatedServicePoints = site.service_points.map((sp) =>
			sp.id === servicePointToEdit?.id ? { ...sp, ...values } : sp
		);

		updateOneSite({ ...site, service_points: updatedServicePoints });
	};

	const headers: HeadersType<ServicePointType>[] = [
		{ accessor: "id", label: "Id" },
		{ accessor: "name", label: "Name" },
		{ accessor: "installed_capacity", label: "Installed Capacity" },
		{ accessor: "type.name", label: "Service Point type" },
		{ accessor: "dso.name", label: "DSO" },
		{ accessor: "tso.name", label: "TSO" },
		{ accessor: "code", label: "Code" },
		{ accessor: "localconso", label: "Local Conso" },
		{ accessor: "eic_balancing_group.name", label: "EIC Balancing group" },
		{ accessor: "marketing_type", label: "Marketing type" },
		{ accessor: "actions", label: "Actions" },
	];

	const items = servicePoints.map((servicePoint) => ({
		...servicePoint,
		localconso: servicePoint.localconso ? "Yes" : "No",
		actions: (
			<IconButton onClick={() => setServicePointToEdit(servicePoint)}>
				<EditIcon sx={{ color: primaryColor }} />
			</IconButton>
		),
	}));

	return (
		<>
			{!servicePoints.length && <NoDataMessage />}
			{!!servicePoints.length &&
				!!servicePointTypes &&
				!!servicePointCodeTypes &&
				!!dataTsos &&
				!!dataDsos &&
				!!dataEicBalancingGroups && (
					<>
						<SimpleTable headers={headers} items={items} />
						{servicePointToEdit !== null && (
							<EditServicePointModal
								open={servicePointToEdit !== null}
								servicePoint={servicePointToEdit}
								onClose={() => setServicePointToEdit(null)}
								onSubmit={handleUpdateSite}
								siteName={site.name}
								servicePointTypes={servicePointTypes.map(
									(type) => ({
										value: String(type.id),
										label: type.name,
									})
								)}
								servicePointCodeTypes={servicePointCodeTypes.map(
									(type) => ({
										value: String(type.id),
										label: type.name,
									})
								)}
								tsos={dataTsos.map((tso) => ({
									value: String(tso.id),
									label: tso.name,
								}))}
								dsos={dataDsos.map((dso) => ({
									value: String(dso.id),
									label: dso.name,
								}))}
								eicBalancingGroups={dataEicBalancingGroups.map(
									(eicBalancingGroup) => ({
										value: String(eicBalancingGroup.id),
										label: eicBalancingGroup.name,
									})
								)}
							/>
						)}
					</>
				)}
		</>
	);
};

export default ServicePointsTab;
