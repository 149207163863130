import * as yup from "yup";

export const validationSchema = (numberOfParts: number) =>
	yup
		.array()
		.of(
			yup.object().shape({
				party: yup
					.object()
					.nullable()
					.required("Counterparty is required"),
				servicepoints: yup.array().of(
					yup.object().shape({
						volume_share: yup
							.number()
							.required("Volume share is required")
							.typeError("Volume share must be a number")
							.moreThan(0, "Volume share must be greater than 0")
							.max(
								100,
								"Volume share must be less than or equal to 100"
							),
					})
				),
			})
		)
		.length(numberOfParts)
		.test("total-volume-share", function (counterparties) {
			if (!counterparties) return true;

			const totalVolumeShare = counterparties.reduce(
				(total, counterparty) => {
					if (!counterparty?.servicepoints) return total;
					return (
						total +
						counterparty.servicepoints.reduce(
							(sum, sp) => sum + (Number(sp?.volume_share) || 0),
							0
						)
					);
				},
				0
			);

			if (totalVolumeShare < 0 || totalVolumeShare > 100) {
				return new yup.ValidationError(
					counterparties.map((_, index) =>
						this.createError({
							path: `${index}.servicepoints`,
							message: `Total volume share (${totalVolumeShare}%) must be between 0 and 100%`,
						})
					)
				);
			}

			return true;
		});
