import { ClickabilityTabFiltersType } from "./ClickabilityTab.types";
import FiltersDrawer, {
	FiltersField,
	Operations,
	retrieveOperationFromFieldName,
	retrieveValueFromFieldName,
} from "../../../../common/components/FiltersDrawer";
import FluidSelectMultiple from "../../../../common/components/FluidSelectMulti";
import { noop } from "../../../../common/utils/operations";
import { useMemo } from "react";
import FluidDatePicker from "../../../../common/components/FluidDatePicker";

const ClickabilityTabFilters = ({
	clickTypes,
	isOpen,
	defaultFilters = {},
	onClose,
	onApplyFilters,
}: ClickabilityTabFiltersType) => {
	const clickTypeOptions = useMemo(() => {
		return (clickTypes || [])?.map((clickType) => ({
			label: clickType.name,
			value: clickType.id,
		}));
	}, [clickTypes]);

	return (
		<FiltersDrawer
			title={"Contract Filters"}
			isOpen={isOpen}
			onClose={onClose}
			onApplyFilters={onApplyFilters}
			defaultFilters={defaultFilters}
		>
			<FiltersField
				name={"click_period_id"}
				label="Period"
				defaultOperation={
					retrieveOperationFromFieldName(
						"click_period_id",
						defaultFilters
					) || Operations.IN
				}
				defaultFieldValue={
					retrieveValueFromFieldName(
						"click_period_id",
						defaultFilters
					) || []
				}
				allowedOperations={[Operations.IN, Operations.NOT_IN]}
				selector={(data: string) => data}
				Input={
					<FluidSelectMultiple
						isLabelStatic
						items={clickTypeOptions}
						label={""}
						name={""}
						// @ts-ignore
						onChange={noop}
						value={[]}
					/>
				}
			/>
			<FiltersField
				name={"click_start"}
				label="Click start date"
				defaultOperation={
					retrieveOperationFromFieldName(
						"click_start",
						defaultFilters
					) || Operations.GE
				}
				defaultFieldValue={
					retrieveValueFromFieldName("click_start", defaultFilters) ||
					""
				}
				allowedOperations={[Operations.LE, Operations.GE]}
				Input={
					<FluidDatePicker
						onChange={noop}
						name={""}
						label={""}
						value={undefined}
					/>
				}
			/>
		</FiltersDrawer>
	);
};

export default ClickabilityTabFilters;
