import { style } from "../../../features/contract_period/ContractPeriodTabs/CurtailmentObjectTab/CurtailmentObject.style";
import { max, min } from "date-fns";
import _ from "lodash";
import { Box, IconButton } from "@mui/material";
import Tag from "../Tag/Tag";
import { Spacer } from "../Spacer";
import { formatDate } from "../../utils/dateUtils";
import CurtailmentDataList from "../../../features/contract_period/ContractPeriodTabs/CurtailmentObjectTab/CurtailmentDataList";
import CurtailmentObjectDetailsModal from "../../../features/contract_period/ContractPeriodTabs/CurtailmentObjectTab/CurtailmentObjectDetailsModal";
import CurtailmentObjectAuditModal from "../../../features/contract_period/ContractPeriodTabs/CurtailmentObjectTab/CurtailmentObjectAuditModal";
import { ReactComponent as DetailsIcon } from "../../assets/icons/details.svg";
import { ReactComponent as HistoryIcon } from "../../assets/icons/history.svg";
// import { ReactComponent as EditIcon } from "../../assets/icons/edit.svg";
import { useState } from "react";
import { CurtailmentObjectGroupType } from "../../../features/contract_period/ContractPeriodTabs/CurtailmentObjectTab/CurtailmentObject.types";
import { CurtailmentObjectSubtypeType } from "../../../requests_cm/gecoContractsService/types";

const CurtailmentObjects = ({
	curtailmentObjectGroup,
}: {
	curtailmentObjectGroup: CurtailmentObjectGroupType | undefined;
}) => {
	const [openDetailModal, setOpenDetailModal] = useState<string | null>();
	const [openAuditModal, setOpenAuditModal] = useState<string | null>();

	return (
		<Box sx={style.collapseWrapper}>
			{curtailmentObjectGroup?.curtailmentKinds.map((curtailmentKind) => {
				const curtailmentObjects = curtailmentObjectGroup.data.filter(
					(curtailmentObject) =>
						curtailmentObject.curtailment_kind === curtailmentKind
				);

				const startDate = min(
					_.chain(curtailmentObjects)
						.map("start_date")
						.filter((date) => !!date && !isNaN(Date.parse(date)))
						.map((date) => new Date(date))
						.value()
				);

				const endDate = max(
					_.chain(curtailmentObjects)
						.map("end_date")
						.filter((date) => !!date && !isNaN(Date.parse(date)))
						.map((date) => new Date(date))
						.value()
				);

				return (
					<Box
						key={curtailmentKind}
						sx={style.curtailmentKindWrapper}
					>
						<Tag label={curtailmentKind} />
						<Spacer gap={8} />
						<Box sx={style.dataTitle}>Horizon</Box>
						{formatDate(startDate, "yyyy/MM/dd")} -{" "}
						<b>{formatDate(endDate, "yyyy/MM/dd")}</b>
						<Spacer gap={8} />
						<Box sx={style.curtailmentObjectList}>
							{curtailmentObjects.map((curtailmentObject) => (
								<CurtailmentDataList
									key={curtailmentObject.id}
									wrapperSx={style.curtailmentObject}
									list={[
										{
											value: (
												<Box
													sx={{
														...style.cartridge,
														...(curtailmentObject.curtailment_object_subtype ===
														CurtailmentObjectSubtypeType.GRID
															? style.brandCartridge
															: {}),
													}}
												>
													{
														curtailmentObject.curtailment_object_subtype
													}
												</Box>
											),
										},
										{
											label: "Compensation Method",
											value: curtailmentObject.compensation_method ?? (
												<br />
											),
										},
										{
											label: "Pricing Method",
											value: curtailmentObject.pricing_method ?? (
												<br />
											),
										},
										{
											label: "Curtailment Price",
											value:
												curtailmentObject.curtailment_price >=
												0 ? (
													`${curtailmentObject.curtailment_price}c€ / kWh`
												) : (
													<br />
												),
										},
										{
											label: "Invoice Price",
											value:
												curtailmentObject.invoice_price >=
												0 ? (
													`${curtailmentObject.invoice_price}c€ / kWh`
												) : (
													<br />
												),
										},
										{
											label: "Index",
											value: curtailmentObject.commodity_index ?? (
												<br />
											),
										},
										{
											label: "Virtual Portfolio",
											value: curtailmentObject.virtual_portfolio ?? (
												<br />
											),
										},
									]}
								/>
							))}
						</Box>
						<Box sx={style.iconsWrapper}>
							<Box sx={style.icon}>
								<IconButton
									onClick={() =>
										setOpenDetailModal(curtailmentKind)
									}
								>
									<DetailsIcon />
								</IconButton>
							</Box>
							<Box sx={style.icon}>
								<IconButton
									onClick={() =>
										setOpenAuditModal(curtailmentKind)
									}
								>
									<HistoryIcon />
								</IconButton>
							</Box>
							{/* <Box sx={style.icon}>
									<IconButton>
										<EditIcon />
									</IconButton>
								</Box> */}
						</Box>
						<CurtailmentObjectDetailsModal
							curtailmentKind={curtailmentKind}
							curtailmentObjectGroup={curtailmentObjectGroup}
							curtailmentObjects={curtailmentObjects}
							setOpenModal={setOpenDetailModal}
							setOpenAuditModal={setOpenAuditModal}
							openModal={openDetailModal === curtailmentKind}
						/>
						<CurtailmentObjectAuditModal
							curtailmentObjectGroup={curtailmentObjectGroup}
							curtailmentObjects={curtailmentObjects}
							setOpenModal={setOpenAuditModal}
							openModal={openAuditModal === curtailmentKind}
						/>
					</Box>
				);
			})}
		</Box>
	);
};

export default CurtailmentObjects;
