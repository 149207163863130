import { Box, DialogActions, Typography } from "@mui/material";
import {
	borderNeutralSecondary,
	textNeutralSecondary,
	white,
} from "../../../../../core/theme";
import { Toggle } from "../../../../../common/components/Toggle";
import { noop } from "../../../../../common/utils/operations";
import { NegativePriceInputs } from "./NegativePriceInputs";
import { NegativePriceIndex } from "../../../../../requests_cm/gecoReferentialService/types";
import { Formik } from "formik";
import {
	ContractPeriodType,
	NegativePrice,
} from "../../../../../requests_cm/gecoContractsService/types";
import { If } from "../../../../../common/components/If";
import { PrimaryButton } from "../../../../../common/components/CustomButton";
import { FluidButton } from "../../../../../common/components/FluidButton";
import { isNull } from "../../../../../common/utils/isFalsy";
import { useCallback, useEffect, useState } from "react";
import AlertDialogModal from "../../../../../common/components/AlertDialogModal";

const style = {
	container: {
		width: "600px",
		padding: "16px",
		display: "flex",
		flexDirection: "column",
		border: `1px solid ${borderNeutralSecondary}`,
		gap: "16px",
		background: white,
		toogleBox: {
			width: "100%",
			display: "flex",
			justifyContent: "space-between",
			title: {
				color: textNeutralSecondary,
				fontSize: "16px",
				fontWeight: "600",
			},
		},
		inputBox: {
			display: "flex",
			justifyContent: "space-between",
		},
	},
};

export interface NegativeSettlementBoxProps {
	hasNegativePrice: boolean;
	contractPeriod: ContractPeriodType | undefined;
	negativePriceIndexes: NegativePriceIndex[];
	onSubmit: (formikData: NegativePrice) => void;
	onRemoveNegativeCondition: () => void;
	toggleNegativePrice: (isToggle: boolean) => void;
}

export const NegativeSettlementBox = ({
	hasNegativePrice,
	negativePriceIndexes,
	contractPeriod,
	toggleNegativePrice,
	onSubmit,
	onRemoveNegativeCondition,
}: NegativeSettlementBoxProps) => {
	const [isConfirmationModalOpen, setConfirmationModalOpen] = useState(false);

	const onToggleNegativePrice = useCallback(
		(isToggled: boolean) => {
			if (!isToggled && contractPeriod?.negative_price?.type) {
				// request confirmation when contract period has a negative price
				setConfirmationModalOpen(true);
			} else {
				toggleNegativePrice(isToggled);
			}
		},
		[toggleNegativePrice, contractPeriod]
	);

	const confirmRemoveNegativePriceCondition = useCallback(() => {
		toggleNegativePrice(false);
		setConfirmationModalOpen(false);
		onRemoveNegativeCondition();
	}, [toggleNegativePrice, setConfirmationModalOpen, onSubmit]);

	useEffect(() => {
		if (contractPeriod) {
			// open the negative price if negative price present on contract period
			toggleNegativePrice(!!contractPeriod?.negative_price?.type);
		}
	}, [contractPeriod]);

	return (
		<>
			<Box sx={style.container}>
				<Formik
					validateOnChange={false}
					validateOnBlur={false}
					enableReinitialize
					initialValues={{
						index: contractPeriod?.negative_price?.index,
						type: contractPeriod?.negative_price?.type,
						value: contractPeriod?.negative_price?.value || 0,
					}}
					onSubmit={onSubmit}
				>
					{({
						values,
						handleChange,
						resetForm,
						handleSubmit,
						dirty,
					}) => (
						<form onSubmit={handleSubmit}>
							<Box sx={style.container.toogleBox}>
								<Typography
									style={style.container.toogleBox.title}
								>
									Negative Price
								</Typography>
								<Box
									onClick={() =>
										onToggleNegativePrice(!hasNegativePrice)
									}
								>
									<Toggle
										onChange={noop}
										isTrue={hasNegativePrice}
										name={""}
										label={"Enable Negative Price"}
										description=""
									/>
								</Box>
							</Box>
							<If condition={hasNegativePrice}>
								<Box sx={style.container.inputBox}>
									<NegativePriceInputs
										onChange={handleChange}
										negativePriceValue={values.value}
										negativePriceType={values.type}
										currentNegativePriceIndex={values.index}
										negativePriceIndexes={
											negativePriceIndexes || []
										}
									/>
								</Box>
							</If>
							<DialogActions>
								<If condition={dirty}>
									<PrimaryButton
										onClick={() => resetForm()}
										text="Reset"
										type="button"
										color="secondary"
									/>
									<FluidButton
										type="submit"
										label={"Save"}
										isDisabled={
											!values.index ||
											!values.type ||
											isNull(values.value)
										}
										onClick={noop}
									/>
								</If>
							</DialogActions>
						</form>
					)}
				</Formik>
			</Box>
			<AlertDialogModal
				title={"Confirm Negative Price Condition Removal"}
				content="By approving you will remove all negative conditions on all settlement objects as well."
				isOpen={isConfirmationModalOpen}
				handleClose={() => setConfirmationModalOpen(false)}
				handleApprove={confirmRemoveNegativePriceCondition}
			/>
		</>
	);
};
