import { combineReducers } from "@reduxjs/toolkit";
import tenderReducer from "../features/tender_page/tenderSlice";
import tenderFormReducer from "../features/tender_form/tenderForm.slice";
import siteReducer from "../features/sites/siteSlice";
import authReducer from "../features/authentication/authSlice";
import notificationsReducer from "../features/notifications/notificationsSlice";
import filtersReducer from "../features/filters/filtersSlice";
import pricingRequestReducer from "../features/pricing_request/PricingRequestStepper.slice";
import pricingListReducer from "../features/pricing_list/pricingListSlice";
import parametersReducer from "../features/parameters/ParametersPage.slice";
import pricingTradersReducer from "../features/pricing_traders/PricingTradersPage.slice";
import geodeApi from "../features/reporting_page/geodeApi";
import metadataReducer from "../features/metadata/metadata.slice";
import groupingReducer from "../features/grouping/grouping.slice";
import contractsPageReducer from "../features/contracts_page/ContractsPageSlice";
import contractManagementGecoApi from "../requests_cm/contractManagementGecoApi";
import contractManagementGepoApi from "../requests_cm/contractManagementGepoApi";
import errorModalReducer from "../features/error_modal/ErrorModal.slice";

const rootReducers = combineReducers({
	tenderPage: tenderReducer,
	tenderForm: tenderFormReducer,
	sites: siteReducer,
	authentication: authReducer,
	notifications: notificationsReducer,
	pricingRequestForm: pricingRequestReducer,
	pricingList: pricingListReducer,
	parameters: parametersReducer,
	pricingTraders: pricingTradersReducer,
	filters: filtersReducer,
	metadata: metadataReducer,
	grouping: groupingReducer,
	contractsPage: contractsPageReducer,
	errorModal: errorModalReducer,
	[geodeApi.reducerPath]: geodeApi.reducer,
	[contractManagementGecoApi.reducerPath]: contractManagementGecoApi.reducer,
	[contractManagementGepoApi.reducerPath]: contractManagementGepoApi.reducer,
});

export type RootState = ReturnType<typeof rootReducers>;

export default rootReducers;
