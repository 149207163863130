import { NJFormItem } from "@engie-group/fluid-design-system-react";
import { Box } from "@mui/material";
import FluidSelect from "../../../../../common/components/FluidSelect";
import { NegativePriceType } from "../../../../../requests_cm/gecoContractsService/types";
import { getSyntheticEvent } from "../../../../../common/utils/getSyntheticEvent";
import { useMemo } from "react";
import { NegativePriceIndex } from "../../../../../requests_cm/gecoReferentialService/types";

const style = {
	container: {
		width: 162,
	},
};

export interface NegativePriceInputsProps {
	onChange: (event: any) => void;
	currentNegativePriceIndex?: number;
	negativePriceIndexes: NegativePriceIndex[];
	negativePriceValue?: number;
	negativePriceType?: NegativePriceType;
	isReadOnly?: boolean;
}

export const NegativePriceInputs = (props: NegativePriceInputsProps) => {
	const availableOptions = useMemo(() => {
		return (props.negativePriceIndexes || []).map(
			(index: NegativePriceIndex) => ({
				value: `${index.orchestrade_id}`,
				label: index.name,
			})
		);
	}, [props.negativePriceIndexes]);

	return (
		<>
			<Box sx={style.container}>
				<FluidSelect
					isLabelStatic
					items={availableOptions}
					label={"Negative Price Index"}
					name={"index"}
					// @ts-ignore does not provides an HTMLElement as it should but the value itself
					onChange={(index: number) =>
						props.onChange(getSyntheticEvent("index", index))
					}
					value={`${props.currentNegativePriceIndex}`}
					isReadOnly={props.isReadOnly}
				/>
			</Box>
			<Box sx={style.container}>
				<FluidSelect
					isReadOnly={props.isReadOnly}
					isLabelStatic
					items={[
						{
							label: NegativePriceType.PRICE,
							value: NegativePriceType.PRICE,
						},
						{
							label: NegativePriceType.CENTS,
							value: NegativePriceType.CENTS,
						},
					]}
					label={"Negative Price Type"}
					name={"type"}
					// @ts-ignore does not provides an HTMLElement as it should but the value itself
					onChange={(type: NegativePriceType) =>
						props.onChange(getSyntheticEvent("type", type))
					}
					value={props.negativePriceType}
				/>
			</Box>
			<Box sx={style.container}>
				<NJFormItem
					id="value"
					label="Negative Price Value"
					labelKind="static"
					name="value"
					type="number"
					// @ts-ignore Typing issue coming from package, expecting string...
					value={props.negativePriceValue}
					onChange={props.onChange}
					title="Negative Price Value"
					wrap="soft"
					readOnly={props.isReadOnly}
				/>
			</Box>
		</>
	);
};
