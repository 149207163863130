import service, {
	sitesSortableColumns,
	useGetSitesQuery,
} from "../../requests_cm/gecoSitesService/service";
import { useCallback, useEffect, useMemo, useState } from "react";
import PageTitle from "../../common/components/PageTitle/PageTitle";
import { Box } from "@mui/material";
import { style } from "../contracts_page/ContractsPage.style";
import CircularProgress from "@mui/material/CircularProgress";
import PageContent from "../../common/components/PageContent";
import SimpleTable, {
	HeadersType,
} from "../../common/components/SimpleTable/SimpleTable";
import { useRtkQueryDynamicEndpoint } from "../../common/hooks/useRtkQueryDynamicEndpoint";
import { SearchField } from "../../common/components/SearchField";
import { Spacer } from "../../common/components/Spacer";
import SiteListPageFilters from "./SiteListPageFilters";
import { FluidButton } from "../../common/components/FluidButton";
import {
	useGetCountriesQuery,
	useGetTsosQuery,
} from "../../requests_cm/gecoReferentialService/service";
import { FieldValue } from "../../common/components/FiltersDrawer";
import { isLastPage } from "../../requests_cm/utils/infiniteScroll.utils";
import { getPathWithParameters, PATH } from "../../router-path";
import { PageInfoType } from "../../requests_cm/gecoTypes";
import { ReactComponent as EditIcon } from "../../common/assets/icons/edit.svg";
import { EditSiteModal } from "./EditSiteModal";
import { If } from "../../common/components/If";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { useLocation } from "react-router-dom";
import { SiteType } from "../../requests_cm/gecoSitesService/types";

const SiteListPage = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const location = useLocation();
	let { filters } = location.state || {};

	const [isFiltersOpen, setFiltersOpen] = useState(false);
	const [editModalSiteId, setEditModalSiteId] = useState<
		undefined | number
	>();
	const [isSearchLoading, setSearchLoading] = useState(false);
	const [pageInfo, setPageInfo] = useState<PageInfoType>({
		page: 1,
		filters,
	});

	const { data: dataCountries, isLoading: isLoadingCountries } =
		useRtkQueryDynamicEndpoint(useGetCountriesQuery)({});
	const { data: dataTsos, isLoading: isLoadingTsos } =
		useRtkQueryDynamicEndpoint(useGetTsosQuery)({});

	const {
		data: dataSites,
		isLoading: isLoadingSites,
		isFetching: isFetchingSites,
		refetch: refetchSites,
	} = useRtkQueryDynamicEndpoint(useGetSitesQuery)({
		...pageInfo,
	});

	const onOpenSiteEditModal = useCallback(
		(siteId: number) => {
			setEditModalSiteId(siteId);
		},
		[setEditModalSiteId]
	);

	useEffect(() => {
		refetchSites();
	}, [pageInfo, refetchSites]);

	useEffect(() => {
		if (!isLoadingSites && !isFetchingSites) {
			setSearchLoading(false);
		}
	}, [isLoadingSites, isFetchingSites]);

	const headers: HeadersType<SiteType>[] = [
		{ label: "ID", accessor: "id" },
		{
			label: "Name",
			accessor: "name",
			getAnchorTag: (item: SiteType) =>
				getPathWithParameters(PATH.SITE_DETAIL, {
					siteId: item.id,
				}),
		},
		{ label: "TPR ID", accessor: "tpr_system_id" },
		{ label: "Installed Capacity", accessor: "installed_capacity" },
		{ label: "Max grid injection", accessor: "max_grid_injection" },
		{ label: "Country", accessor: "country" },
		{ label: "TSO", accessor: "tso" },
		{ label: "Latitude", accessor: "latitude" },
		{ label: "Longitude", accessor: "longitude" },
		{ label: "#Service Point", accessor: "nb_servicepoints" },
		{ label: "Asset", accessor: "nb_assets" },
		{ label: "", accessor: "edit_component" },
	].map((header) => ({
		...header,
		sortable: sitesSortableColumns.includes(header.accessor),
	})) as HeadersType<SiteType>[];

	const sites: SiteType[] = useMemo(() => {
		return dataSites?.result.map((site) => ({
			...site,
			edit_component: (
				<Box
					role="button"
					onClick={() => onOpenSiteEditModal(site.id)}
					sx={style.editContainer}
				>
					<EditIcon />
				</Box>
			),
		})) as SiteType[];
	}, [dataSites]);

	const handleSearch = (searchValue: string) => {
		setSearchLoading(true);
		setPageInfo((current) => ({
			...current,
			page: 1,
			search: searchValue,
			filters: {},
		}));
	};

	const handleApplyFilters = (newFilters: Record<string, FieldValue>) => {
		setFiltersOpen(false);
		setSearchLoading(true);
		setPageInfo((current) => ({
			...current,
			page: 1,
			search: "",
			filters: newFilters,
		}));
		refetchSites();
	};

	const onEditSuccessCallback = useCallback(() => {
		// when editing a site, we need to refresh the page list (due to edit in a list, we have pagination issues)
		dispatch(service.util.resetApiState());
		navigate(
			getPathWithParameters(PATH.SITE_DETAIL, {
				siteId: editModalSiteId,
			})
		);
	}, [editModalSiteId]);

	const isPageLoading = isLoadingCountries || isLoadingTsos || isLoadingSites;

	return (
		<>
			<PageTitle fontWeight={"bold"} label="Site list" />
			{isPageLoading && (
				<Box sx={style.progressWrapper}>
					<CircularProgress data-testid="loader" />
				</Box>
			)}
			{!isPageLoading && dataSites && (
				<PageContent>
					<Box sx={{ display: "flex", alignItems: "center" }}>
						<SearchField onSearch={handleSearch} />
						<FluidButton
							label={"Filters"}
							icon="filter_list"
							onClick={() => setFiltersOpen(true)}
						/>
					</Box>
					<Spacer gap={32} />
					<SimpleTable
						headers={headers}
						infiniteScroll
						isFetching={isFetchingSites && !isSearchLoading}
						isLastPage={isLastPage(dataSites)}
						isLoading={isLoadingSites || isSearchLoading}
						items={isSearchLoading ? [] : sites}
						pageInfo={pageInfo}
						setPageInfo={setPageInfo}
					/>
					<SiteListPageFilters
						countries={dataCountries || []}
						tsos={dataTsos || []}
						defaultFilters={pageInfo?.filters || {}}
						isOpen={isFiltersOpen}
						onApplyFilters={(newFilters) => {
							handleApplyFilters(newFilters);
						}}
						onClose={() => setFiltersOpen(false)}
					/>
				</PageContent>
			)}
			<If condition={!!editModalSiteId}>
				<EditSiteModal
					handleSucessSubmit={onEditSuccessCallback}
					handleClose={() => setEditModalSiteId(undefined)}
					siteId={editModalSiteId}
				/>
			</If>
		</>
	);
};

export default SiteListPage;
