import BookingLogs from "./BookingLogs";
import { useLazyGetBookingLogsQuery } from "../../../requests_cm/gecoContractsService/service";
import { useRtkQueryDynamicEndpoint } from "../../../common/hooks/useRtkQueryDynamicEndpoint";
import { useEffect, useState } from "react";
import { addDays, addHours, addMinutes, getTime } from "date-fns";
import {
	BookingLogItemType,
	BookingLogsListType,
} from "../../../requests_cm/gecoContractsService/types";
import { Box, CircularProgress, Typography } from "@mui/material";
import { style } from "./BookingLogs.style";
import FluidSelect from "../../../common/components/FluidSelect";

const BookingLogsContainer = ({
	contractPeriodId,
}: {
	contractPeriodId: number;
}) => {
	const initDate = getTime(new Date());
	const [logsState, setLogsState] = useState<
		BookingLogsListType | undefined
	>();

	const [logsFromSelect, setLogsFromSelect] = useState(
		String(getTime(addMinutes(initDate, -30)))
	);

	const [logsFromQueryParam, setLogsFromQueryParam] =
		useState(logsFromSelect);

	const [getBookingLogsBase, { data: logsData, isLoading, isFetching }] =
		useLazyGetBookingLogsQuery();
	const getBookingLogs = useRtkQueryDynamicEndpoint(getBookingLogsBase);

	const filterDuplicateLogs = (logs: BookingLogsListType) => {
		return logs.reduce((acc, curr) => {
			if (!acc.some((item: BookingLogItemType) => item.id === curr.id)) {
				acc.push(curr);
			}

			return acc;
		}, [] as BookingLogsListType);
	};

	const selectOptions = [
		{
			value: String(getTime(addMinutes(initDate, -30))),
			label: "30 minutes",
		},
		{
			value: String(getTime(addHours(initDate, -4))),
			label: "4 hours",
		},
		{
			value: String(getTime(addHours(initDate, -24))),
			label: "24 hours",
		},
		{
			value: String(getTime(addDays(initDate, -7))),
			label: "7 days",
		},
		{
			value: String(getTime(addDays(initDate, -30))),
			label: "30 days",
		},
	];

	useEffect(() => {
		getBookingLogs({
			contract_period_id: contractPeriodId,
			logs_from: logsFromQueryParam,
		});
		const interval = setInterval(() => {
			getBookingLogs({
				contract_period_id: contractPeriodId,
				logs_from: logsFromQueryParam,
			});
		}, 5000);

		return () => clearInterval(interval);
	}, [logsFromQueryParam]);

	useEffect(() => {
		setLogsState((currentLogs) =>
			filterDuplicateLogs([...(logsData ?? []), ...(currentLogs ?? [])])
		);

		if (logsData?.[0]?.timestamp) {
			setLogsFromQueryParam(logsData[0].timestamp);
		}
	}, [logsData]);

	useEffect(() => {
		setLogsState([]);
		setLogsFromQueryParam(logsFromSelect);
	}, [logsFromSelect]);

	return (
		<Box sx={style.content}>
			<Typography variant="h3">Orchestrade Booking logs</Typography>
			<FluidSelect
				items={selectOptions}
				label="Logs for the past"
				isLabelStatic
				name="logs"
				onChange={(val) => {
					setLogsFromSelect(String(val));
				}}
				value={logsFromSelect}
			/>
			{isLoading && (
				<Box sx={style.progress}>
					<CircularProgress />
				</Box>
			)}
			{!isLoading && (
				<BookingLogs logs={logsState} isFetching={isFetching} />
			)}
		</Box>
	);
};

export default BookingLogsContainer;
