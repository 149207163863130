import { useCallback, useMemo, useState } from "react";
import FluidSelect from "../../../common/components/FluidSelect";
import { ContractPeriodType } from "../../../requests_cm/gecoContractsService/types";
import AlertDialogModal from "../../../common/components/AlertDialogModal";

export interface ContractPeriodSelectProps {
	availableContractPeriods: ContractPeriodType[];
	currentContractPeriodId: string | undefined;
	onChange: (selectedContractPeriodId: string) => void;
	showConfirmation?: boolean;
}

export const ContractPeriodSelect = ({
	availableContractPeriods,
	currentContractPeriodId,
	onChange,
	showConfirmation,
}: ContractPeriodSelectProps) => {
	const [isConfirmationModalOpen, setConfirmationModalOpen] = useState(false);
	const [
		selectedContractPeriodConfirmation,
		setContractPeriodIdForConfirmation,
	] = useState<string | undefined>();
	const availableOptions = useMemo(() => {
		return availableContractPeriods.map(
			(contractPeriod: ContractPeriodType) => ({
				value: `${contractPeriod.id}`,
				label: contractPeriod.name,
			})
		);
	}, [availableContractPeriods]);

	const onChangeContractPeriod = useCallback(
		(contractPeriodId: string) => {
			if (showConfirmation) {
				setContractPeriodIdForConfirmation(contractPeriodId);
				setConfirmationModalOpen(true);
			} else {
				setConfirmationModalOpen(false);
				setContractPeriodIdForConfirmation(undefined);
				onChange(contractPeriodId);
			}
		},
		[showConfirmation]
	);

	const onDialogCallCancel = () => {
		setConfirmationModalOpen(false);
		setContractPeriodIdForConfirmation(undefined);
	};

	const onDialogCallApprove = () => {
		onChange(selectedContractPeriodConfirmation as string);
		setContractPeriodIdForConfirmation(undefined);
		setConfirmationModalOpen(false);
	};

	return (
		<>
			<FluidSelect
				isLabelStatic
				items={availableOptions}
				label={"Contract Period"}
				name={"current_contract_period"}
				// @ts-ignore currently select fluid only returns the value and not all the target input
				onChange={onChangeContractPeriod}
				value={currentContractPeriodId}
			/>
			<AlertDialogModal
				title={"Are you sure you want to continue?"}
				content="You have un-submitted data pending, by approving you will lose your changes."
				isOpen={isConfirmationModalOpen}
				handleClose={onDialogCallCancel}
				handleApprove={onDialogCallApprove}
			/>
		</>
	);
};
