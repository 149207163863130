import { configureStore } from "@reduxjs/toolkit";
import rootReducers from "./core/rootReducers";
import rootThunks from "./core/rootThunks";
import logger from "redux-logger";
import geodeApi from "./features/reporting_page/geodeApi";
import contractManagementGecoApi from "./requests_cm/contractManagementGecoApi";
import contractManagementGepoApi from "./requests_cm/contractManagementGepoApi";

const useReduxlogger = process.env.REACT_APP_USE_REDUX_LOGGER;

const middlewareList = [
	geodeApi.middleware,
	contractManagementGecoApi.middleware,
	contractManagementGepoApi.middleware,
];

if (useReduxlogger && JSON.parse(useReduxlogger)) {
	middlewareList.push(logger);
}

export function setupStore() {
	return configureStore({
		reducer: rootReducers,
		middleware: (getDefaultMiddleware) =>
			getDefaultMiddleware({
				serializableCheck: false,
				thunk: {
					extraArgument: rootThunks,
				},
			}).concat(...middlewareList),
	});
}

// Infer the `RootState` and `AppDispatch` types from the store itself
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore["dispatch"];
export const store = setupStore();
