import { Typography } from "@mui/material";
import {
	ChangeRequestStatus,
	ChangeRequestType,
} from "../../../requests_cm/gepoChangeRequestService/types";
import FluidMessageBox from "../FluidMessageBox/FluidMessageBox";
import { If } from "../If";

export const style = {
	alertMessage: {
		fontWeight: 600,
		fontSize: "16px",
	},
};

export interface ChangeRequestBaseCardProps {
	changeRequest: ChangeRequestType | undefined;
}

export const ChangeRequestAlert = ({
	changeRequest,
}: ChangeRequestBaseCardProps) => {
	return (
		<>
			<If
				condition={
					changeRequest?.status === ChangeRequestStatus.REJECTED
				}
			>
				<FluidMessageBox
					variant={"error"}
					title={
						"Modification(s) are not validated for the following reason:"
					}
				>
					<Typography sx={style.alertMessage}>
						{changeRequest?.validator_message}
					</Typography>
				</FluidMessageBox>
			</If>

			<If
				condition={
					changeRequest?.status ===
					ChangeRequestStatus.CORRECTION_NEEDED
				}
			>
				<FluidMessageBox
					variant={"warning"}
					title={
						"Some modifications in your request require corrections:"
					}
				>
					<Typography sx={style.alertMessage}>
						{changeRequest?.validator_message}
					</Typography>
				</FluidMessageBox>
			</If>
		</>
	);
};
