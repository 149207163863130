export interface PaginationType {
	total_elements: number;
	page: number;
	pages: number;
	per_page: number;
}

export enum OrderEnum {
	ASC = "ASC",
	DESC = "DESC",
}

export interface PageInfoType {
	page: number;
	pages?: number;
	filters?: Record<string, any>;
	search?: string;
	sortOn?: string;
	order?: OrderEnum;
}

export interface DynamicEndpointQuery {
	endpoint?: string;
}
