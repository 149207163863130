export enum PATH {
	ROOT = "/",
	LOGIN = "/login/callback",
	LANDING = "/landing",
	REPORTS = "/reports",
	DATA_QUALITY_REPORTS = "/data-quality-reports",
	CONTRACT_QUALITY_REPORT = "/contract-quality-report",
	SITE_POSITION_REPORT = "/site-position-report",
	TENDERS = "/tenders",
	NEW_TENDER = "/tenders/new",
	EDIT_TENDER = "/tenders/:tenderId",
	CLONE_TENDER = "/tenders/:tenderId/clone",
	PRICING_REQUEST = "/tenders/:tenderId/new-pricing/:type",
	TENDER_PRICING_RUN = "/tenders/:tenderId/my-pricings",
	MAXIMIZED_TENDER_PRICING_RUN = "/tenders/:tenderId/my-pricings/maximized",
	PARAMETERS = "/parameters",
	PRICING_TRADERS = "/pricing-traders",
	CONTRACTS = "/contracts",
	CONTRACT_DETAIL = "/contracts/:contractId",
	VALIDATE_CONTRACTPERIOD = "/validateContractPeriod",
	CONTRACTPERIOD = "/contracts/:contractId/:contractPeriodId",
	CONTRACT_VALIDATION = "/contract_validation/:contractId",
	SITES = "/site_list",
	SITE_DETAIL = "/site_list/:siteId",
	CONTRACT_COUNTER_VALIDATION = "/contract-counter-validation/:contractId",
	CHANGE_REQUESTS = "/change-requests",
	CHANGE_REQUESTS_VALIDATION = "/change-requests-validation/:changeRequestId",
}

export const getPathWithParameters = (
	path: PATH,
	params: Record<string, string | number | undefined>
): PATH => {
	for (const key in params) {
		const placeholder = key.startsWith(":") ? key.substring(1) : key;
		const regex = new RegExp(":" + placeholder, "g");
		// @ts-ignore
		path = path.replace(regex, params[key]);
	}
	return path;
};

export const getPathWithSearchParams = (
	path: PATH,
	params: Record<string, string | number | boolean>
): string => {
	const searchParams = new URLSearchParams();
	for (const key of Object.keys(params)) {
		searchParams.set(key, String(params[key]));
	}

	return `${path}?${searchParams.toString()}`;
};

export const setFilters = (
	filters: Record<string, string | number | (string | number)[] | undefined>
) => {
	return {
		state: {
			filters,
		},
	};
};
