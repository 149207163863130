import { useEffect, useMemo } from "react";
import { Box } from "@mui/material";

import {
	ContractParty,
	ErrorType,
} from "../../../requests_cm/gecoContractsService/types";
import { formatApiErrorMessage } from "../../../common/utils/formatApiErrorMessage";
import CustomAutocomplete from "../../../common/components/CustomAutocomplete";

import {
	TprPartyDetailType,
	TprPartyListType,
} from "../../../requests_cm/gepoTprProxyService/types";

export interface CounterpartyProps {
	detailParty: TprPartyDetailType | undefined;
	errorMessage: ErrorType | string;
	getPartyDetail: ({ id }: { id: string }) => void;
	isLoading: boolean;
	label?: string;
	name?: string;
	parties: TprPartyListType[];
	party: ContractParty | null | undefined;
	searchParties: ({ search }: { search: string }) => void;
	setFieldValue: (field: string, value: any) => void;
}

const Counterparty = ({
	detailParty,
	errorMessage,
	getPartyDetail,
	isLoading,
	label = "Counter Party",
	name = "party",
	parties,
	party,
	searchParties,
	setFieldValue,
}: CounterpartyProps) => {
	const partiesResult = useMemo(() => {
		return (parties || []).map((oneParty: TprPartyListType) => ({
			...oneParty,
			id: oneParty.partyId,
		}));
	}, [parties]);

	useEffect(() => {
		if (detailParty) {
			setFieldValue("party", detailParty);
		}
	}, [detailParty]);

	const error = useMemo(() => {
		if (typeof errorMessage === "string") {
			return errorMessage;
		}

		if (typeof errorMessage === "object" && "data" in errorMessage) {
			return formatApiErrorMessage(errorMessage);
		}

		return undefined;
	}, [errorMessage]);

	return (
		<Box sx={{ marginBottom: "10px" }}>
			<CustomAutocomplete
				fetchData={(search: string) => searchParties({ search })}
				data={partiesResult}
				// @ts-ignore
				setFieldValue={(_fieldName: string, value: TprPartyListType) =>
					getPartyDetail({ id: value.id! })
				}
				label={label}
				name={name}
				loading={isLoading}
				required={false}
				value={party}
				errorMessage={error as string}
			/>
		</Box>
	);
};

export default Counterparty;
