import _ from "lodash";
import {
	ContractDetailType,
	ContractPeriodSettlementCategories,
	ContractPeriodType,
	NegativePrice,
	SettlementType,
} from "../../../requests_cm/gecoContractsService/types";
import {
	getContractPeriodFromId,
	getContractPeriodIndexFromId,
	getSettlementIndexFromId,
} from "./common";

export interface FormikNpqrs {
	n: number | undefined;
	p: number | undefined;
	q: number | undefined;
	r: number | undefined;
	s: number | undefined;
}

export const npqrsToFormik = (npqrs: number[] | undefined): FormikNpqrs => {
	let n = undefined;
	let p = undefined;
	let q = undefined;
	let r = undefined;
	let s = undefined;
	if (npqrs) {
		n = npqrs[0];
		p = npqrs[1];
		q = npqrs[2];
		r = npqrs[3];
		s = npqrs[4];
	}
	return { n, p, q, r, s };
};

export const settlementFormikToContractMapper = (
	settlementFormik: SettlementType,
	initialContract: ContractDetailType,
	contractPeriodId: number,
	settlementCategory: ContractPeriodSettlementCategories
): ContractDetailType => {
	const updatedContract = _.cloneDeep(initialContract) as ContractDetailType;
	const contractPeriodIndex = getContractPeriodIndexFromId(
		updatedContract.contract_periods,
		contractPeriodId
	);
	const contractPeriod = getContractPeriodFromId(
		updatedContract.contract_periods,
		contractPeriodId
	) as ContractPeriodType;

	const settlements = contractPeriod[settlementCategory].settlements;
	const settlementIndex = getSettlementIndexFromId(
		settlements,
		settlementFormik.id
	);
	updatedContract.contract_periods[contractPeriodIndex][
		settlementCategory
	].settlements[settlementIndex] = settlementFormik;

	return updatedContract;
};

export const negativePriceConditionFormikToContractMapper = (
	negativePriceFormik: NegativePrice,
	initialContract: ContractDetailType,
	contractPeriodId: number
): ContractDetailType => {
	const updatedContract = _.cloneDeep(initialContract) as ContractDetailType;
	const contractPeriodIndex = getContractPeriodIndexFromId(
		updatedContract.contract_periods,
		contractPeriodId
	);
	const contractPeriod = getContractPeriodFromId(
		updatedContract.contract_periods,
		contractPeriodId
	) as ContractPeriodType;

	contractPeriod.negative_price = negativePriceFormik;

	updatedContract.contract_periods[contractPeriodIndex] = contractPeriod;

	return updatedContract;
};

export const clearNegativePriceCondition = (
	initialContract: ContractDetailType,
	contractPeriodId: number,
	settlementCategory: ContractPeriodSettlementCategories
): ContractDetailType => {
	const updatedContract = _.cloneDeep(initialContract) as ContractDetailType;
	const contractPeriodIndex = getContractPeriodIndexFromId(
		updatedContract.contract_periods,
		contractPeriodId
	);
	const contractPeriod = getContractPeriodFromId(
		updatedContract.contract_periods,
		contractPeriodId
	) as ContractPeriodType;

	delete contractPeriod.negative_price;

	const settlements = contractPeriod[settlementCategory].settlements;
	const updatedSettlements = (settlements || []).map(
		(singleSettlement: SettlementType) => ({
			...singleSettlement,
			condition_neg_price: false,
		})
	);

	updatedContract.contract_periods[contractPeriodIndex][
		settlementCategory
	].settlements = updatedSettlements;

	updatedContract.contract_periods[contractPeriodIndex] = contractPeriod;

	return updatedContract;
};
