import Tabs, { SingleTab } from "../../../common/components/Tabs";
import { DefaultTabContent } from "../../../common/components/DefaultTabContent";
import GeneralTab from "./GeneralTab";
import {
	ContractDetailType,
	ContractPeriodType,
} from "../../../requests_cm/gecoContractsService/types";
import { NegativePriceIndex } from "../../../requests_cm/gecoReferentialService/types";
import { SettlementTab } from "./SettlementTab";
import ClickabilityTab from "./ClickabilityTab";
import ServicePointsTab from "./ServicePointsTab";
import DealsTab from "./DealsTab";
import CurtailmentObjectTab from "./CurtailmentObjectTab";
import { isFalsy } from "../../../common/utils/isFalsy";

export enum TabID {
	GENERAL = "GENERAL",
	CLICKABILITY = "CLICKABILITY",
	SETTLEMENT_OBJECT = "SETTLEMENT_OBJECT",
	SERVICE_POINT = "SERVICE_POINT",
	CURTAILMENT_OBJECT = "CURTAILMENT_OBJECT",
	DEAL = "DEAL",
	FIELD_DATA = "FIELD_DATA",
}

export interface ContractPeriodTabsProps {
	contract: ContractDetailType;
	contractPeriod: ContractPeriodType;
	onSaveDraftContract: (data: ContractDetailType) => void;
	negativePriceIndexes: NegativePriceIndex[] | undefined;
}

const ContractPeriodTabs = ({
	contractPeriod,
	onSaveDraftContract,
	contract,
	negativePriceIndexes,
}: ContractPeriodTabsProps) => {
	return (
		<Tabs activeTab={TabID.GENERAL} label={""}>
			<SingleTab label="General" id={TabID.GENERAL}>
				<DefaultTabContent>
					<GeneralTab
						onSaveDraftContract={onSaveDraftContract}
						contract={contract}
						contractPeriod={contractPeriod}
					/>
				</DefaultTabContent>
			</SingleTab>
			<SingleTab
				label="Clickability"
				id={TabID.CLICKABILITY}
				isDisabled={isFalsy(contractPeriod?.clickability)}
			>
				<ClickabilityTab
					contractPeriodId={contractPeriod?.id}
					onSaveDraftContract={onSaveDraftContract}
					contract={contract}
				/>
			</SingleTab>
			<SingleTab label="Settlement Object" id={TabID.SETTLEMENT_OBJECT}>
				<SettlementTab
					contract={contract}
					onSaveDraftContract={onSaveDraftContract}
					contractPeriodId={contractPeriod?.id}
					negativePriceIndexes={negativePriceIndexes}
				/>
			</SingleTab>
			<SingleTab label="Service Point" id={TabID.SERVICE_POINT}>
				<ServicePointsTab contractPeriod={contractPeriod} />
			</SingleTab>
			<SingleTab label="Curtailment Object" id={TabID.CURTAILMENT_OBJECT}>
				<CurtailmentObjectTab contractPeriodId={contractPeriod.id} />
			</SingleTab>
			<SingleTab label="Deals" id={TabID.DEAL}>
				<DealsTab contractPeriodId={contractPeriod?.id} />
			</SingleTab>
		</Tabs>
	);
};

export default ContractPeriodTabs;
