import { Box } from "@mui/material";
import FluidDatePicker from "../../../../common/components/FluidDatePicker";
import { PrimaryButton } from "../../../../common/components/CustomButton";

const style = {
	container: {
		display: "flex",
		flexDirection: "row",
		gap: "24px",
		alignItems: "flex-end",
	},
};

export interface DatePickersProps {
	onChange: (event: any) => void;
	onOpenChangeHorizon: () => void;
	tradeDate: string | undefined;
	startDate: string | undefined;
	endDate: string | undefined;
	isDisabled?: boolean;
}

export const DatePickers = ({
	onChange,
	onOpenChangeHorizon,
	tradeDate,
	startDate,
	endDate,
	isDisabled = false,
}: DatePickersProps) => {
	return (
		<Box sx={style.container}>
			<FluidDatePicker
				onChange={onChange}
				name={"trade_date"}
				label={"Trade Date"}
				value={tradeDate}
			/>

			<FluidDatePicker
				isReadOnly
				onChange={onChange}
				name={"start_date"}
				label={"Start Date"}
				value={startDate}
			/>

			<FluidDatePicker
				isReadOnly
				onChange={onChange}
				name={"end_date"}
				label={"End Date"}
				value={endDate}
			/>
			<PrimaryButton
				onClick={onOpenChangeHorizon}
				text="Change Horizon"
				type="button"
				color="primary"
				isDisabled={isDisabled}
			></PrimaryButton>
		</Box>
	);
};
