import { Formik } from "formik";
import Counterparty from "./CounterPartyFields";
import CheckIcon from "@mui/icons-material/Check";
import {
	ContractDetailType,
	ErrorType,
} from "../../../requests_cm/gecoContractsService/types";
import {
	CounterpartyFormik,
	counterpartyTabFormikInitialValueMapper,
} from "../formik/counterpartyFormik";

import { Box, DialogActions } from "@mui/material";
import { useRtkQueryDynamicEndpoint } from "../../../common/hooks/useRtkQueryDynamicEndpoint";
import { useGetBooksQuery } from "../../../requests_cm/gecoReferentialService/service";
import { useCallback, useMemo } from "react";
import { NJRadioGroup, NJRadio } from "@engie-group/fluid-design-system-react";
import CustomSelect from "../../../common/components/CustomSelect";
import { PrimaryButton } from "../../../common/components/CustomButton";
import {
	useLazyGetPartyDetailQuery,
	useLazySearchPartiesQuery,
} from "../../../requests_cm/gepoTprProxyService/service";

export interface CounterpartyFormProps {
	contract: ContractDetailType | undefined;
	onSubmit: (data: CounterpartyFormik) => void;
	submitButtonLabel: string;
	onCancelForm: () => void;
	isLoading: boolean;
}

const CounterpartyForm = ({
	contract,
	onSubmit,
	submitButtonLabel,
	onCancelForm,
	isLoading,
}: CounterpartyFormProps) => {
	const [
		getPartyDetail,
		{
			data: detailParty,
			isLoading: isDetailLoading,
			error: detailPartyError,
		},
	] = useLazyGetPartyDetailQuery();
	const [searchParties, { data: parties, isLoading: isSearchLoading }] =
		useLazySearchPartiesQuery();

	const { data: books } = useRtkQueryDynamicEndpoint(useGetBooksQuery)({});

	const booksOptions = useMemo(() => {
		const options: {
			label: string;
			value: string | undefined;
			key: string | undefined;
		}[] = [{ label: "No Mirror Book", value: undefined, key: undefined }];
		for (const book of books || []) {
			options.push({
				label: book.name,
				value: String(book.id),
				key: String(book.id),
			});
		}

		return options;
	}, [books]);

	const onSubmitMiddelware = useCallback(
		(formikData: CounterpartyFormik): void => {
			if (formikData?.mirror_book) {
				formikData.mirror_book = (books || [])?.find(
					(book) =>
						Number(book.id) === Number(formikData?.mirror_book)
				);
			}

			if (formikData?.trading_book) {
				formikData.trading_book = (books || [])?.find(
					(book) =>
						Number(book.id) === Number(formikData?.trading_book)
				);
			}

			return onSubmit(formikData);
		},
		[onSubmit, books]
	);

	return (
		<Formik
			validateOnChange={false}
			validateOnBlur={false}
			enableReinitialize
			initialValues={counterpartyTabFormikInitialValueMapper(contract)}
			onSubmit={onSubmitMiddelware}
		>
			{({
				handleChange,
				setFieldValue,
				handleSubmit,
				values,
				errors,
				dirty,
			}) => {
				return (
					<form onSubmit={handleSubmit} id="counterPartyForm">
						<Box
							sx={{
								display: "flex",
								flexDirection: "column",
								gap: "24px",
							}}
						>
							<Counterparty
								party={values.party}
								parties={parties?.partyResults || []}
								detailParty={detailParty}
								errorMessage={detailPartyError as ErrorType}
								isLoading={isDetailLoading || isSearchLoading}
								setFieldValue={setFieldValue}
								searchParties={searchParties}
								getPartyDetail={getPartyDetail}
								label="Counterpart"
							/>
							<CustomSelect
								value={values.mirror_book as string}
								name={"mirror_book"}
								label={"Mirror Book"}
								onChange={handleChange}
								//@ts-ignore
								items={booksOptions}
								error={!!errors.mirror_book}
								errorText={errors.mirror_book}
							/>

							<CustomSelect
								value={values.trading_book as string}
								name={"trading_book"}
								label={"Trading Book"}
								onChange={handleChange}
								//@ts-ignore
								items={booksOptions}
								error={!!errors.trading_book}
								errorText={errors.trading_book}
							/>

							<NJRadioGroup
								checkedId={values.way}
								legend="Contract Way"
								onChange={(way) => {
									setFieldValue("way", way);
								}}
								orientation="row"
							>
								<NJRadio
									id={"Buy"}
									label={"Buy"}
									name="way"
									value={"Buy"}
								/>
								<NJRadio
									id={"Sell"}
									label={"Sell"}
									name="way"
									value={"Sell"}
								/>
							</NJRadioGroup>
						</Box>
						<DialogActions>
							<PrimaryButton
								onClick={onCancelForm}
								text="Cancel"
								type="button"
								color="secondary"
							/>
							<PrimaryButton
								isDisabled={!dirty}
								text={submitButtonLabel}
								type="submit"
								loader={isLoading}
							>
								<CheckIcon />
							</PrimaryButton>
						</DialogActions>
					</form>
				);
			}}
		</Formik>
	);
};

export default CounterpartyForm;
