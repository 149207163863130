import { PaginationType } from "../gecoTypes";
import {
	Contact,
	CurtailmentObjectSubtypeType,
} from "../gecoContractsService/types";

export enum SettlementModelValues {
	"PAUSCHAL" = "PAUSCHAL",
	"SPITZ" = "SPITZ",
	"SPITZ_LIGHT" = "SPITZ_LIGHT",
}

export enum AccountingModelValues {
	"PLANWERTMODELL" = "PLANWERTMODELL",
	"PROGNOSEMODELL" = "PROGNOSEMODELL",
}

export enum DuldungsfallStatusValues {
	"AUFFORDERUNGSFALL" = "AUFFORDERUNGSFALL",
	"DULDUNGSFALL" = "DULDUNGSFALL",
}

export interface SiteType {
	country: string;
	id: number;
	installed_capacity: number;
	latitude: number;
	longitude: number;
	max_grid_injection: number;
	name: string;
	nb_assets: number;
	nb_servicepoints: number;
	tpr_system_id: string;
	tso: string;
}

export interface GetSitesResponseType {
	pagination: PaginationType;
	result: SiteType[];
}

export interface AssetType {
	id?: number;
	commissioning_date?: string;
	hub_height?: number;
	installed_capacity: number;
	key?: string;
	latitude: number;
	longitude: number;
	machine: {
		id: number;
		brand: string;
		model: string;
		name: string;
		type: string;
		power_curve?: {};
	};
	name: string;
	azimuth?: number;
	tilt_angle?: number;
	rotor_diameter?: number;
	start_date?: string;
	end_date?: string;
	manufacturer_serial_number: string;
	subsidy: string;
	sr_code: string;
	tr_code: string;
	tr_mastr_no: string;
}

export type ServicePointType = {
	assets: AssetType[];
	code: string;
	code_2: string[];
	code_type: {
		id: number;
		name: "string";
	};
	code_type_2: {
		id: number;
		name: "string";
	};
	contractperiods: {
		contract: {
			id: number;
			name: string;
		};
		contractperiod_id: number;
		end_date: string;
		id: number;
		name: string;
		start_date: string;
		volume_share: number;
	}[];
	end_date: string;
	id: number;
	installed_capacity: number;
	name: string;
	reference: {};
	start_date: string;
	dso: { name: string; id: number };
	tso: { name: string; id: number };
	type: { name: string; id: number };
	eic_balancing_group: {
		name: string;
		id: number;
	};
	marketing_type: "string";
	localconso: boolean;
};

export enum TechnologyType {
	Solar = "Solar",
	WindOnshore = "Wind Onshore",
	WindOffshore = "Wind Offshore",
}

export interface GetOneSiteResponseType {
	contacts: Contact[];
	country: string;
	id: number;
	installed_capacity: number;
	latitude: number;
	longitude: number;
	max_grid_injection: number;
	name: string;
	service_points: ServicePointType[];
	technology: TechnologyType;
	tpr_system_id: string;
	tso: string;
	is_self_consumer?: boolean;
	self_consumption_ratio?: number;
	self_sufficiency_ratio?: number;
}

export interface GetOneSiteParamsType {
	siteId: number;
}

export interface ControlObject {
	software_provider: string;
	end_date: string;
	files_attached: { name: string; file: string }[];
	id: number;
	minimum_duration: string;
	minimum_rest_time: string;
	minimum_value: number;
	name: string;
	night_end: string;
	night_set_point: number;
	night_start: string;
	ramp_down: number;
	ramp_up: number;
	start_date: string;
	test: boolean;
}

export interface RedispatchConstraint {
	accounting_model: "PLANWERTMODELL" | "PROGNOSMODELL";
	eiv: string;
	end_date: string;
	id: number;
	name: string;
	redispatch_mode: "AUFFORDERUNGSFALL" | "DULDUNGSFALL";
	start_date: string;
	supplier: string;
	technical_resource_operator: string;
	tr_settlement_model: "PAUSCHAL" | "SPITZ" | "SPITZ_LIGHT";
}
export interface CurtailmentObjectType {
	bcv_config: string;
	commodity_index: string;
	commodity_index_id: number;
	compensation_deal_type: string;
	compensation_method: string;
	counterpart_kind: string;
	curtailment_kind: string;
	curtailment_object_subtype: CurtailmentObjectSubtypeType;
	curtailment_price: number;
	end_date: string;
	id: number;
	invoice_price: number;
	is_compensable: boolean;
	name: string;
	pricing_method: string;
	site: string;
	start_date: string;
	tso_or_dso_counterpart: string;
	virtual_portfolio: string;
}

export interface NewRedispatchConstraintParamsType {
	site_id: number;
	data: RedispatchConstraint;
}

export interface BuildCurtailementParamsTypeType {
	siteId: number;
	startDate?: string;
	endDate?: string;
}

export interface BuildCurtailmentObjectsResponseType {
	errors: string[];
	success: { name: string; start_date: string; end_date: string }[];
}
export interface EditRedispatchConstraintParamsType {
	site_id: number;
	constraint_id: number;
	data: RedispatchConstraint;
}
