import { RootState } from "../../core/rootReducers";

const selectFiltersState = (state: RootState) => state.filters;

export function selectIsFiltersDrawerOpened(state: RootState) {
	const filtersState = selectFiltersState(state);
	return filtersState.opened;
}

export function selectCurrentPageDrawerOpened(state: RootState) {
	const filtersState = selectFiltersState(state);
	return filtersState.page;
}

export function selectTenderFilters(state: RootState) {
	return state.metadata.tendersMetadata?.filters?.fields || [];
}

export function selectTenderWorkflowViewFilters(state: RootState) {
	return state.metadata.tendersWorkflowViewMetadata?.filters?.fields || [];
}

export function selectPricingFilters(state: RootState) {
	return state.metadata.pricingsMetadata?.filters?.fields || [];
}

export function selectFiltersErrors(state: RootState) {
	const filtersState = selectFiltersState(state);
	return filtersState.errors;
}

export function selectFiltersLoading(state: RootState) {
	const filtersState = selectFiltersState(state);
	return filtersState.loading;
}

export function selectTendersOdataQuery(state: RootState) {
	const filtersState = selectFiltersState(state);
	return filtersState.persistedFilters?.tenders_query;
}

export function selectTendersWorkflowViewOdataQuery(state: RootState) {
	const filtersState = selectFiltersState(state);
	return filtersState.persistedFilters?.tenders_workflow_view_query;
}

export function selectPricingsOdataQuery(state: RootState) {
	const filtersState = selectFiltersState(state);
	return filtersState.persistedFilters?.pricings_query;
}

export function selectLinqPredicates(state: RootState) {
	const filtersState = selectFiltersState(state);
	return {
		pricings: filtersState.pricingLinqPredicates,
		tenders: filtersState.tenderLinqPredicates,
	};
}

export function selectFiltersLists(state: RootState) {
	const filtersState = selectFiltersState(state);
	return {
		lists: filtersState.lists,
	};
}

export function selectPersistedFilters(state: RootState) {
	const filtersState = selectFiltersState(state);
	return {
		filters: filtersState.persistedFilters,
	};
}
