import {
	RenewexSubmission,
	UploadedFile,
} from "../../common/files/files.module";
import {
	Action,
	Pricing,
	TenderStatus,
} from "../pricing_list/pricingListSlice";
import { Site, Portfolio } from "../sites/sites.module";
import { Order } from "./tender.constant";
import { OrderColumn } from "./utils/workflowView.util";

export interface MasterSiteTimeserie {
	ts_type: "master_site";
	id: number;
	timeserie_name: string;
	cleaned: boolean;
	master_site: Site;
}

export interface SiteTimeserie {
	ts_type: "site";
	id: number;
	timeserie_name: string;
	cleaned: boolean;
	site: Site;
}

export interface RawTimeserie {
	ts_type: "raw";
	id?: number;
	timeserie_name: string;
	cleaned: boolean;
}

export type TenderTimeserie =
	| MasterSiteTimeserie
	| SiteTimeserie
	| RawTimeserie;

export function isMasterSiteTimeserie(
	it: TenderTimeserie
): it is MasterSiteTimeserie {
	return it.ts_type === "master_site";
}

export function isSiteTimeserie(it: TenderTimeserie): it is SiteTimeserie {
	return it.ts_type === "site";
}

export function isRawTimeserie(it: TenderTimeserie): it is RawTimeserie {
	return it.ts_type === "raw";
}

export type GepoUser = {
	first_name: string;
	last_name: string;
	gaia_id: string;
};

export type CompactTender = Partial<Tender>;
export interface Tender {
	id: number;
	name: string;
	creation_date: string;
	originator?: GepoUser;
	trader?: GepoUser;
	status: TenderStatus;
	capacity: string;
	p50: string;
	proxy: string;
	validity_date: string;
	feedbacks: string;
	country_iso2_code: string;
	account: {
		id: number;
		name: string;
		external_id: string;
	};
	installed_capacity_mw: number;
	countries: string[];
	site_count: number;
	count_attached_sites: number;
	technologies: string;
	due_date: string;
	earliest_pricing_expiration_date: string;
	direction: string;
	transaction_type: string;
	sites?: Site[];
	portfolios?: Portfolio[];
	historical_data: UploadedFile[];
	historical_data_count?: number;
	latest_status_update?: string;
	updated_at: string;
	created_at?: string;
	pricings?: Pricing[];
	actions: Action[];
	actions_blotter: Action[];
	isNewUpdate?: boolean;
	archived?: boolean;
	latest_renewex_submission?: RenewexSubmission;
	timeseries?: object;
	ts_list?: TenderTimeserie[];
	realized_data_will_be_added?: boolean;
	sites_info: { site_name: string; asset_id: string }[];
	cor_opportunity_id?: string;
	cor_sync_datetime?: string;
	pricing_stage?: string;
	graph_status: "NEW" | "PENDING" | "READY";
	graph_file_id?: number;
	graph_error?: string;
	has_negative_price_clause: boolean;
	is_balancing_responsible_party: boolean;
}
export interface TSData {
	sheetName?: string;
	assetId: string;
	fileId: number;
	id: string;
	productionCol: number;
	indexDate: number;
	unit: string;
	indexTime?: number;
	offset: number;
	timezone: string;
}

export type ColumnType = {
	status: TenderStatus;
	count: number;
	tenders: CompactTender[];
	order?: Order;
	orderColumn?: OrderColumn;
};
export type ColumnMap = { [status: string]: ColumnType };

export type Outcome =
	| {
			type: "column-reorder";
			columnId: string;
			startIndex: number;
			finishIndex: number;
	  }
	| {
			type: "card-reorder";
			columnId: string;
			startIndex: number;
			finishIndex: number;
	  }
	| {
			type: "card-move";
			finishColumnId: string;
			itemIndexInStartColumn: number;
			itemIndexInFinishColumn: number;
	  };

export type Trigger = "pointer" | "keyboard";

export type Operation = {
	trigger: Trigger;
	outcome: Outcome;
};

export type BoardState = {
	columnMap: ColumnMap;
	orderedColumnIds: string[];
	lastOperation: Operation | null;
};

export const enum CommentType {
	SYSTEM = "SYSTEM",
	USER = "USER",
}

export interface TenderComments {
	id: number;
	tender_id: number;
	file_id: number;
	file: string;
	creator_id: number;
	creator: GepoUser;
	type: CommentType;
	comment: string;
	created_at: string;
}
