import { createApi } from "@reduxjs/toolkit/dist/query/react";
import customBaseQuery from "../common/utils/customBaseQuery";

const contractManagementGecoApi = createApi({
	reducerPath: "contractManagementGecoApi",
	baseQuery: customBaseQuery({
		baseURL: process.env.REACT_APP_GECO_API_PATH!,
	}),
	tagTypes: [
		"Clicks",
		"Contract",
		"ContractAttachements",
		"ContractPeriodBookingLogs",
		"ControlObjects",
		"Deals",
		"HorizonChange",
		"RedispatchConstraints",
		"ServicePoints",
		"Sites",
	],
	endpoints: () => ({}),
});

export default contractManagementGecoApi;
