import { FluidNumber } from "../../../../common/components/FluidNumberField";
import ContractSplitCounterparty from "./ContractSplitCounterparty";
import ContractSplitConfirmationModal from "./ContractSplitConfirmationModal";
import ErrorMessageModal from "../../../../common/components/ErrorMessageDialog";
import { formatApiErrorMessage } from "../../../../common/utils/formatApiErrorMessage";
import {
	ContractDetailType,
	ContractSplitValuesType,
	ErrorType,
} from "../../../../requests_cm/gecoContractsService/types";
import { useSplitContractMutation } from "../../../../requests_cm/gecoContractsService/service";
import { useRtkQueryDynamicEndpoint } from "../../../../common/hooks/useRtkQueryDynamicEndpoint";
import { useEffect, useMemo, useState } from "react";
import { Formik, FormikProps } from "formik";
import { enqueueSnackbar } from "notistack";
import { map } from "lodash";
import { Typography } from "@mui/material";
import { validationSchema } from "./validationSchema";

const titleStyle = {
	fontWeight: "bold",
	fontSize: "18px",
	marginTop: "40px",
	marginBottom: "24px",
};

const DefaultNumberOfParts = 2;

interface ContractSplitFormProps {
	contract: ContractDetailType;
	formikRef: React.RefObject<FormikProps<ContractSplitValuesType>>;
	onClose: () => void;
}

const ContractSplitForm = ({
	contract,
	formikRef,
	onClose,
}: ContractSplitFormProps) => {
	const [isConfirmationModalOpen, setIsConfirmationModalOpen] =
		useState(false);
	const [validatedValues, setValidatedValues] =
		useState<ContractSplitValuesType | null>(null);

	const [numberOfParts, setNumberOfParts] =
		useState<number>(DefaultNumberOfParts);

	const [splitContractBase, { error: splitContractError, isSuccess, reset }] =
		useSplitContractMutation();
	const splitContract = useRtkQueryDynamicEndpoint(splitContractBase);

	useEffect(() => {
		if (isSuccess) {
			enqueueSnackbar("Contract split has been successfully updated", {
				variant: "success",
				autoHideDuration: 10000,
			});

			reset();
			onClose();
		}
	}, [isSuccess, reset]);

	const initialValues: ContractSplitValuesType = useMemo(() => {
		return Array.from({ length: numberOfParts }, () => ({
			party: null,
			servicepoints: [],
		}));
	}, [numberOfParts]);

	useEffect(() => {
		if (formikRef.current) {
			const currentValues = formikRef.current.values;
			const newValues = Array.from(
				{ length: numberOfParts },
				(_, index) => {
					return (
						currentValues[index] || {
							party: null,
							servicepoints: [],
						}
					);
				}
			);

			formikRef.current.resetForm({
				values: newValues,
			});
		}
	}, [numberOfParts]);

	const handleSubmit = async (values: ContractSplitValuesType) => {
		setIsConfirmationModalOpen(true);
		setValidatedValues(values);
	};

	const handleConfirmation = () => {
		if (validatedValues) {
			const updatedValues = map(validatedValues, (value) => {
				const updatedServicePoints = map(value.servicepoints, (sp) => ({
					...sp,
					volume_share: sp.volume_share / 100,
				}));

				return {
					...value,
					servicepoints: updatedServicePoints,
				};
			});

			splitContract({
				contract_id: contract.id,
				data: updatedValues,
			});
			setIsConfirmationModalOpen(false);
		}
	};

	return (
		<>
			<Typography sx={titleStyle}>
				1. Determine the number of parts
			</Typography>
			<FluidNumber
				onChange={(e) => {
					const newValue = Number.isNaN(e.target.value)
						? DefaultNumberOfParts
						: Math.max(
								parseInt(e.target.value, 10),
								DefaultNumberOfParts
						  );

					setNumberOfParts(newValue);
				}}
				value={Number(numberOfParts)}
				name="numberOfParts"
				title="Number of parts *"
			/>
			<Typography sx={titleStyle}>
				2. Assign counterparties and service points to each part
			</Typography>
			<Formik
				innerRef={formikRef}
				initialValues={initialValues}
				validationSchema={validationSchema(numberOfParts)}
				onSubmit={handleSubmit}
			>
				{({ errors, touched, values }) => {
					return Array.from({ length: values.length }).map(
						(_, index) => {
							return (
								<ContractSplitCounterparty
									key={index}
									index={index}
									servicePoints={
										contract.contract_periods[0]
											.servicepoints
									}
									formikPath={`${index}`}
									errors={errors}
									touched={touched}
								/>
							);
						}
					);
				}}
			</Formik>
			<ContractSplitConfirmationModal
				handleConfirmation={handleConfirmation}
				isConfirmationModalOpen={isConfirmationModalOpen}
				setIsConfirmationModalOpen={setIsConfirmationModalOpen}
			/>
			<ErrorMessageModal
				title={"Failed to split Contract"}
				content={formatApiErrorMessage(splitContractError as ErrorType)}
				actionAfterClose={reset}
			/>
		</>
	);
};

export default ContractSplitForm;
