import { User } from "../../authentication/auth.model";
import { GepoUser } from "../tender.module";

function canUserDeleteComment(
	commentCreator: GepoUser,
	currentUser: User | undefined
): boolean {
	return !!currentUser?.preferred_username
		?.toUpperCase()
		?.includes(commentCreator.gaia_id?.toUpperCase());
}

export default canUserDeleteComment;
