import Modal from "../../../../common/components/Modal";
import { FluidButton } from "../../../../common/components/FluidButton";
import ControlPointDuplicateIcon from "@mui/icons-material/ControlPointDuplicate";
import { colorPaletteBlue600 } from "../../../../core/theme";
import InfoBlock from "../../../../common/components/InfoBlock/InfoBlock";
import { useRef } from "react";
import { Box } from "@mui/material";
import { FormikProps } from "formik";
import {
	ContractDetailType,
	ContractSplitValuesType,
} from "../../../../requests_cm/gecoContractsService/types";
import ContractSplitForm from "./ContractSplitForm";

interface ContractSplitModalProps {
	isOpen?: boolean;
	onClose: () => void;
	contract: ContractDetailType;
}

const ContractSplitModal = ({
	isOpen = false,
	onClose,
	contract,
}: ContractSplitModalProps) => {
	const formikRef = useRef<FormikProps<ContractSplitValuesType>>(null);

	return (
		<>
			<Modal
				maxWidth={"xl"}
				onClose={onClose}
				open={isOpen}
				title={
					<Box
						sx={{
							display: "flex",
							alignItems: "center",
							gap: "16px",
						}}
					>
						<ControlPointDuplicateIcon
							style={{ color: colorPaletteBlue600 }}
						/>
						Split a Contract
					</Box>
				}
				actions={
					<>
						<FluidButton
							onClick={onClose}
							label="Cancel"
							variant="secondary"
						/>
						<FluidButton
							onClick={() => formikRef.current?.submitForm()}
							icon="call_split"
							label="Split"
						/>
					</>
				}
			>
				<InfoBlock
					withBackground
					info={[
						{
							label: "Contract Type",
							value: contract?.agreement_type,
						},
						{
							label: "Signature Date",
							value: contract?.trade_date,
						},
						{
							label: "Start Date",
							value: contract?.start_date,
						},
						{
							label: "End Date",
							value: contract?.end_date,
						},
						{
							label: "Techno",
							value: contract?.techno,
						},
						{
							label: "Techno Type",
							value: contract?.techno_type,
						},
						{
							label: "Uid",
							value: contract?.contract_uid,
						},
						{
							label: "Counterpart",
							value:
								contract?.mirror_book?.name ??
								contract?.party?.mnemonic,
						},
						{ label: "Way", value: contract?.way },
						{
							label: "Country",
							value: contract?.country,
						},
						{
							label: "Book",
							value: contract?.trading_book?.name,
						},
					]}
				/>
				<ContractSplitForm
					contract={contract}
					onClose={onClose}
					formikRef={formikRef}
				/>
			</Modal>
		</>
	);
};

export default ContractSplitModal;
