import FiltersDrawer, {
	FieldValue,
	FiltersField,
	Operations,
	retrieveValueFromFieldName,
} from "../../common/components/FiltersDrawer";
import { FluidNumber } from "../../common/components/FluidNumberField";
import FluidSelectMultiple from "../../common/components/FluidSelectMulti";
import { noop } from "../../common/utils/operations";
import {
	ChangeRequestStatus,
	ChangeRequestResourceType,
} from "../../requests_cm/gepoChangeRequestService/types";

const ChangeRequestFilters = ({
	isOpen,
	onClose,
	defaultFilters = {},
	onApplyFilters,
}: {
	isOpen: boolean;
	onClose: () => void;
	onApplyFilters: (data: Record<string, FieldValue>) => void;
	defaultFilters?: Record<string, FieldValue>;
}) => {
	return (
		<FiltersDrawer
			title={"Change Request Filters"}
			isOpen={isOpen}
			onClose={onClose}
			onApplyFilters={onApplyFilters}
			defaultFilters={defaultFilters}
		>
			<FiltersField
				name={"status"}
				label="Status"
				defaultOperation={Operations.IN}
				defaultFieldValue={
					retrieveValueFromFieldName("status", defaultFilters) || []
				}
				allowedOperations={[Operations.IN]}
				selector={(data: string) => data}
				Input={
					<FluidSelectMultiple
						isLabelStatic
						items={[
							{
								value: ChangeRequestStatus.PENDING,
								label: "Pending",
							},
							{
								value: ChangeRequestStatus.REJECTED,
								label: "Refused",
							},
							{
								value: ChangeRequestStatus.CORRECTION_NEEDED,
								label: "Correction needed",
							},
							{
								value: ChangeRequestStatus.VALID,
								label: "Valid",
							},
							{
								value: ChangeRequestStatus.CLOSED,
								label: "Closed",
							},
						]}
						label={"status"}
						name={"Status"}
						onChange={noop}
						value={[]}
					/>
				}
			/>
			<FiltersField
				name={"resource_type"}
				label="Resource type"
				defaultOperation={Operations.IN}
				defaultFieldValue={
					retrieveValueFromFieldName(
						"resource_type",
						defaultFilters
					) || []
				}
				allowedOperations={[Operations.IN]}
				selector={(data: string) => data}
				Input={
					<FluidSelectMultiple
						isLabelStatic
						items={[
							{
								value: ChangeRequestResourceType.CONTRACT,
								label: "Contract",
							},
							{
								value: ChangeRequestResourceType.CONTRACT_PERIOD,
								label: "Contract period",
							},
							{
								value: ChangeRequestResourceType.HORIZON_CHANGE,
								label: "Horizon change",
							},
						]}
						label={"resource_type"}
						name={"Resource type"}
						onChange={noop}
						value={[]}
					/>
				}
			/>
			<FiltersField
				name={"resource_id"}
				label="Resource id"
				defaultOperation={Operations.IN}
				defaultFieldValue={
					retrieveValueFromFieldName("resource_id", defaultFilters) ||
					""
				}
				allowedOperations={[Operations.IN]}
				Input={
					<FluidNumber
						onChange={noop}
						name={""}
						title={""}
						value={undefined}
					/>
				}
			/>
		</FiltersDrawer>
	);
};

export default ChangeRequestFilters;
