import {
	ChangeRequestType,
	CreateChangeRequestType,
	ReadChangeRequestType,
	UpdateChangeRequestType,
} from "./types";
import contractManagementGepoApi from "../contractManagementGepoApi";
import { fieldIn, and } from "../../common/utils/odata";
import { gepoMerge, serializeQueryArgs } from "../utils/infiniteScroll.utils";
import { PageInfo } from "../gepoTypes";
import { isFalsy } from "../../common/utils/isFalsy";

const service = contractManagementGepoApi.injectEndpoints({
	endpoints: (builder) => ({
		getChangeRequests: builder.query<ReadChangeRequestType, PageInfo>({
			query: ({ filters = [], per_page = 20, page = 1 }) => {
				const validFilters = [];
				let url = `/change-requests?page=${page}&per_page=${per_page}`;
				for (const filterDetail of filters.filter(
					(entryFilter) => !isFalsy(entryFilter.value)
				)) {
					validFilters.push(
						fieldIn(filterDetail.field_path, filterDetail.value)
					);
				}
				if (validFilters.length) {
					url = `${url}&filters="${and(validFilters)}"`;
				}
				return {
					url,
					method: "GET",
				};
			},
			serializeQueryArgs: serializeQueryArgs,
			merge: gepoMerge,
			providesTags: ["ChangeRequest"],
		}),
		createChangeRequest: builder.mutation<void, CreateChangeRequestType>({
			query: (changeRequest) => {
				return {
					url: `/change-requests`,
					method: "POST",
					data: changeRequest,
				};
			},
			invalidatesTags: ["ChangeRequest"],
		}),
		getSingleChangeRequest: builder.query<
			ChangeRequestType,
			{ id: number }
		>({
			query: ({ id }) => {
				return {
					url: `/change-requests/${id}`,
					method: "GET",
				};
			},
		}),
		updateSingleChangeRequest: builder.mutation<
			void,
			UpdateChangeRequestType
		>({
			query: (updatedChangeRequest) => {
				return {
					url: `/change-requests/${updatedChangeRequest.id}`,
					method: "PUT",
					data: updatedChangeRequest,
				};
			},
			invalidatesTags: ["ChangeRequest"],
		}),
		cancelChangeRequest: builder.mutation<void, { id: number }>({
			query: ({ id }) => {
				return {
					url: `/change-requests/${id}/cancel`,
					method: "PATCH",
				};
			},
			invalidatesTags: ["ChangeRequest"],
		}),
		validateChangeRequest: builder.mutation<void, { id: number }>({
			query: ({ id }) => {
				return {
					url: `/change-requests/${id}/validate`,
					method: "PATCH",
				};
			},
			invalidatesTags: ["ChangeRequest"],
		}),

		rejectChangeRequest: builder.mutation<
			void,
			{ id: number; message: string }
		>({
			query: ({ id, message }) => {
				return {
					url: `/change-requests/${id}/reject`,
					method: "PATCH",
					data: { message },
				};
			},
			invalidatesTags: ["ChangeRequest"],
		}),

		askCorrectionsOnChangeRequest: builder.mutation<
			void,
			{ id: number; message: string }
		>({
			query: ({ id, message }) => {
				return {
					url: `/change-requests/${id}/ask-corrections`,
					method: "PATCH",
					data: { message },
				};
			},
			invalidatesTags: ["ChangeRequest"],
		}),
	}),
});

export const {
	useCreateChangeRequestMutation,
	useGetChangeRequestsQuery,
	useLazyGetChangeRequestsQuery,
	useCancelChangeRequestMutation,
	useLazyGetSingleChangeRequestQuery,
	useUpdateSingleChangeRequestMutation,
	useValidateChangeRequestMutation,
	useRejectChangeRequestMutation,
	useAskCorrectionsOnChangeRequestMutation,
} = service;

export default service;
