import { useCallback, useEffect, useMemo } from "react";
import {
	useCreateChangeRequestMutation,
	useLazyGetSingleChangeRequestQuery,
	useUpdateSingleChangeRequestMutation,
} from "./service";
import { ChangeRequestType, CreateChangeRequestType } from "./types";
import { updateOldValueWithDiffs } from "./utils";
import {
	ErrorType,
	formatApiErrorMessage,
} from "../../common/utils/formatApiErrorMessage";

export interface UseChangeRequest<T extends Record<string, any>> {
	ressouceWithChanges?: T;
	changeRequest?: ChangeRequestType;
	isLoading: boolean;
	errorMessage?: string | string[];
	upsertChangeRequest: (
		changeRequest: CreateChangeRequestType
	) => Promise<any>;
}

export function useChangeRequest<T extends Record<string, any>>(
	changeRequestId: number | undefined,
	orignalRessource: T | undefined
): UseChangeRequest<T> {
	const [
		createChangeRequest,
		{
			isLoading: isCreateChangeRequestLoading,
			error: createChangeRequestError,
		},
	] = useCreateChangeRequestMutation();
	const [
		updateChangeRequest,
		{
			isLoading: isUpdateChangeRequestLoading,
			error: updateChangeRequestError,
		},
	] = useUpdateSingleChangeRequestMutation();
	const [
		getSingleChangeRequest,
		{
			isLoading: isGetChangeRequestLoading,
			error: getChangeRequestError,
			data: changeRequest,
		},
	] = useLazyGetSingleChangeRequestQuery();

	const upsertChangeRequest = useCallback(
		(changreRequestToUpsert: CreateChangeRequestType) => {
			if (changeRequestId) {
				return updateChangeRequest({
					...changreRequestToUpsert,
					id: changeRequestId,
				});
			} else {
				return createChangeRequest(changreRequestToUpsert);
			}
		},
		[changeRequestId, createChangeRequest, updateChangeRequest]
	);

	useEffect(() => {
		if (changeRequestId) {
			getSingleChangeRequest({ id: Number(changeRequestId) });
		}
	}, [changeRequestId]);

	const ressouceWithChanges: T | undefined = useMemo(() => {
		if (orignalRessource && changeRequest) {
			const result = updateOldValueWithDiffs<T>(
				orignalRessource,
				changeRequest.diff_json
			);
			return result;
		}
		return orignalRessource;
	}, [orignalRessource, changeRequest]);

	const error = useMemo(
		() =>
			createChangeRequestError ||
			updateChangeRequestError ||
			getChangeRequestError,
		[
			createChangeRequestError,
			updateChangeRequestError,
			getChangeRequestError,
		]
	);

	const errorMessage = useMemo(() => {
		if (error) {
			return formatApiErrorMessage(error as ErrorType);
		}
	}, [error]);

	return {
		upsertChangeRequest,
		isLoading:
			isCreateChangeRequestLoading ||
			isUpdateChangeRequestLoading ||
			isGetChangeRequestLoading,
		ressouceWithChanges,
		changeRequest,
		errorMessage,
	};
}
