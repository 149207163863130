import { PaginationType } from "../gecoTypes";

type QueryArgsType = { [key: string]: any };

interface ResultType {
	id: number;
}

interface CacheType<T extends ResultType> {
	pagination: PaginationType;
	result: T[];
}

interface GepoCacheType<T extends ResultType> {
	has_next_page: boolean;
	page: number;
	page_size: number;
	data: T[];
}

export const isLastPage = <T extends ResultType>(list: CacheType<T>) =>
	list.pagination.pages === 0 ||
	list.pagination.page === list.pagination.pages;

/**
 * Serializes query arguments by removing keys not relevant for caching.
 *
 * @param queryArgs - The original query arguments.
 * @returns The serialized query arguments with specific keys removed.
 */
export const serializeQueryArgs = ({
	queryArgs,
}: {
	queryArgs: QueryArgsType;
}) => {
	const newQueryArgs = {
		...queryArgs,
	} as any;
	delete newQueryArgs.endpoint;
	delete newQueryArgs.page;

	return newQueryArgs;
};

/**
 * Merges new items into the current cache, ensuring no duplicates based on item IDs.
 *
 * @template T - The type of the result items, extending ResultType.
 * @param currentCache - The current cache containing pagination and result items.
 * @param newItems - The new items to be merged into the current cache.
 * @returns The updated cache with merged items, ensuring unique IDs.
 */
export const merge = <T extends ResultType>(
	currentCache: CacheType<T>,
	newItems: CacheType<T>
): CacheType<T> => {
	const { pagination } = newItems;

	const existingIds = new Set<number>(
		currentCache.result.map((item) => item.id)
	);

	const mergedResults = [...currentCache.result];

	newItems.result.forEach((item) => {
		if (!existingIds.has(item.id)) {
			existingIds.add(item.id);
			mergedResults.push(item);
		}
	});

	return {
		pagination,
		result: mergedResults,
	};
};

export const gepoMerge = <T extends ResultType>(
	currentCache: GepoCacheType<T>,
	newItems: GepoCacheType<T>
): GepoCacheType<T> => {
	const mapper: any = {};
	const currentValues = [...currentCache.data];
	const newValues = newItems.data;
	const diffValues = [];

	let data;

	if (newItems.page == 1) {
		data = newItems.data;
	} else {
		for (const oldItem of currentValues) {
			mapper[oldItem.id] = oldItem;
		}

		for (const newValue of newValues) {
			if (mapper[newValue.id]) {
				mapper[newValue.id] = newValue;
			} else {
				diffValues.push(newValue);
			}
		}

		const updatedCurrentValues = currentValues.map(
			(entry) => mapper[entry.id]
		);
		data = [...updatedCurrentValues, ...diffValues];
	}

	return {
		...newItems,
		data,
	};
};
