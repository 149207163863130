import { Box } from "@mui/material";
import { FluidButton } from "../../../../common/components/FluidButton";
import Modal from "../../../../common/components/Modal";
import InfoIcon from "@mui/icons-material/Info";

interface ContractSplitConfirmationModalProps {
	handleConfirmation: () => void;
	isConfirmationModalOpen: boolean;
	setIsConfirmationModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const ContractSplitConfirmationModal = ({
	handleConfirmation,
	isConfirmationModalOpen,
	setIsConfirmationModalOpen,
}: ContractSplitConfirmationModalProps) => {
	return (
		<Modal
			open={isConfirmationModalOpen}
			onClose={() => setIsConfirmationModalOpen(false)}
			title={
				<Box
					sx={{
						display: "flex",
						alignItems: "center",
						gap: "8px",
					}}
				>
					<InfoIcon sx={{ color: "#007ACD" }} />
					Confirm the split
				</Box>
			}
			actions={
				<>
					<FluidButton
						onClick={() => setIsConfirmationModalOpen(false)}
						label="Cancel"
						variant="secondary"
					/>
					<FluidButton onClick={handleConfirmation} label="Confirm" />
				</>
			}
		>
			Are you sure you want to split this contract with the above changes?
			<br />
			Please note that this action is not reversible and will impact the
			contract.
		</Modal>
	);
};

export default ContractSplitConfirmationModal;
