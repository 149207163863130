import { Box, DialogActions } from "@mui/material";
import Tabs, { SingleTab } from "../../../common/components/Tabs";
import { useState } from "react";
import { camelToSpaceCase } from "../../../common/utils/camelToSpaceCase";
import { Spacer } from "../../../common/components/Spacer";
import { HorizonUnitHOC } from "./HorizonUnit";
import { HorizonChangeType } from "../../../requests_cm/gecoContractsService/types";
import { PrimaryButton } from "../../../common/components/CustomButton";

export interface HorizonMonitoringProps {
	entities: string[];
	entitiesMap: Record<string, HorizonChangeType[]>;
	updateSingleEntity: (
		updatedEntity: Partial<HorizonChangeType>,
		entityId: number
	) => void;
	onConfirm: () => void;
	onGoBack: () => void;
}

const HorizonMonitoringStep = ({
	entities,
	entitiesMap,
	updateSingleEntity,
	onGoBack,
	onConfirm,
}: HorizonMonitoringProps) => {
	const CardWidth = 380;
	const WrapGap = 24;
	const WrapWidth = CardWidth * 2 + WrapGap;
	const style = {
		container: {
			width: "100%",
			display: "flex",
			flexDirection: "column",
			alignItems: "Center",
			justifyContent: "Center",
		},
		pairUnitContainer: {
			display: "flex",
			flexDirection: "row",
			flexWrap: "wrap",
			gap: `${WrapGap}px`,
			width: `${WrapWidth}px`,
		},
		actionContainer: {
			width: "100%",
		},
	};
	const [currentTab, setCurrentTab] = useState(entities[0]);
	return (
		<>
			<Box sx={style.container}>
				<Tabs
					activeTab={currentTab}
					label={""}
					onClickTabItem={(tab: string) => {
						setCurrentTab(tab);
					}}
				>
					{entities.map((tab) => (
						<SingleTab
							key={tab}
							label={camelToSpaceCase(tab)}
							id={tab}
							isActive={tab === currentTab}
						/>
					))}
				</Tabs>
				<Spacer gap={24} />
				<Box
					sx={{
						...style.pairUnitContainer,
						justifyContent:
							entitiesMap[currentTab]?.length === 1
								? "center"
								: "flex-start",
					}}
				>
					{entitiesMap[currentTab]?.map(
						(horizonChange: HorizonChangeType) => (
							<HorizonUnitHOC
								key={horizonChange.target_id}
								name={horizonChange.target_name}
								onUpdate={(
									partialHorizonChange: Partial<HorizonChangeType>
								) =>
									updateSingleEntity(
										partialHorizonChange,
										horizonChange.target_id
									)
								}
								initialValues={horizonChange}
							/>
						)
					)}
				</Box>
			</Box>
			<Box sx={style.actionContainer}>
				<DialogActions>
					<PrimaryButton
						onClick={onGoBack}
						text={"Back"}
						type="button"
						color="secondary"
					></PrimaryButton>
					<PrimaryButton
						text={"Confirm Changes"}
						onClick={onConfirm}
						type="button"
						color="primary"
					></PrimaryButton>
				</DialogActions>
			</Box>
		</>
	);
};

export default HorizonMonitoringStep;
