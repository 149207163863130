import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { DragEvent, useState, useMemo } from "react";
import { FormikErrors, FormikTouched, useFormikContext } from "formik";
import { Box } from "@mui/material";
import { FluidButton } from "../../../../common/components/FluidButton";
import Counterparty from "../CounterPartyFields";
import ServicePointList from "./ServicePointList";
import { ErrorText } from "../../../../common/components/ErrorText";
import { get } from "lodash";
import {
	useLazyGetPartyDetailQuery,
	useLazySearchPartiesQuery,
} from "../../../../requests_cm/gepoTprProxyService/service";
import {
	ContractPeriodServicePointType,
	ErrorType,
	ContractSplitValuesType,
} from "../../../../requests_cm/gecoContractsService/types";
import { useRtkQueryDynamicEndpoint } from "../../../../common/hooks/useRtkQueryDynamicEndpoint";

enum TargetListNamesEnum {
	available = "available",
	allocated = "allocated",
}

type DraggingOverType = TargetListNamesEnum | null;

interface ContractSplitCounterpartyProps {
	index: number;
	servicePoints: ContractPeriodServicePointType[];
	formikPath: string;
	errors: FormikErrors<ContractSplitValuesType>;
	touched: FormikTouched<ContractSplitValuesType>;
}

enum CounterpartyColor {
	default = "#334551",
	filled = "#007ACD",
	error = "#DB3131",
}

const ContractSplitCounterparty = ({
	index,
	servicePoints,
	formikPath,
	errors,
	touched,
}: ContractSplitCounterpartyProps) => {
	const [
		getPartyDetailBase,
		{
			data: detailParty,
			isLoading: isDetailLoading,
			error: detailPartyError,
		},
	] = useLazyGetPartyDetailQuery();
	const getPartyDetail = useRtkQueryDynamicEndpoint(getPartyDetailBase);
	const [searchPartiesBase, { data: parties, isLoading: isSearchLoading }] =
		useLazySearchPartiesQuery();
	const searchParties = useRtkQueryDynamicEndpoint(searchPartiesBase);
	const { setFieldValue, getFieldProps } =
		useFormikContext<ContractSplitValuesType>();
	const currentValues =
		getFieldProps<ContractSplitValuesType[number]>(formikPath).value;

	const [isCollapsed, setIsCollapsed] = useState(index !== 0);
	const [isDraggingOver, setIsDraggingOver] =
		useState<DraggingOverType>(null);
	const [selectedAvailable, setSelectedAvailable] = useState<number[]>([]);
	const [selectedAllocated, setSelectedAllocated] = useState<number[]>([]);

	const availableServicePoints = useMemo(() => {
		const allocatedIds = currentValues.servicepoints.map((sp) => sp.id);
		return servicePoints.filter((sp) => !allocatedIds.includes(sp.id));
	}, [servicePoints, currentValues.servicepoints]);

	const isRequired = index === 0;
	const statusColor: CounterpartyColor = CounterpartyColor.default;

	const handleCollapse = () => {
		setIsCollapsed((currentIsCollapsed) => !currentIsCollapsed);
	};

	const handleAvailableCheckboxChange = (servicePointId: number) => {
		setSelectedAvailable((prev) =>
			prev.includes(servicePointId)
				? prev.filter((id) => id !== servicePointId)
				: [...prev, servicePointId]
		);
	};

	const handleAllocatedCheckboxChange = (servicePointId: number) => {
		setSelectedAllocated((prev) =>
			prev.includes(servicePointId)
				? prev.filter((id) => id !== servicePointId)
				: [...prev, servicePointId]
		);
	};

	const handleAddSelected = () => {
		const pointsToMove = availableServicePoints.filter((sp) =>
			selectedAvailable.includes(sp.id)
		);

		setFieldValue(`${formikPath}.servicepoints`, [
			...currentValues.servicepoints,
			...pointsToMove,
		]);

		setSelectedAvailable([]);
	};

	const handleRemoveSelected = () => {
		setFieldValue(
			`${formikPath}.servicepoints`,
			currentValues.servicepoints.filter(
				(sp) => !selectedAllocated.includes(sp.id)
			)
		);
		setSelectedAllocated([]);
	};

	const handleSelectAllAvailable = (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		if (event.target.checked) {
			setSelectedAvailable(availableServicePoints.map((sp) => sp.id));
		} else {
			setSelectedAvailable([]);
		}
	};

	const handleSelectAllAllocated = (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		if (event.target.checked) {
			setSelectedAllocated(
				currentValues.servicepoints.map((sp) => sp.id)
			);
		} else {
			setSelectedAllocated([]);
		}
	};

	const handleDragStart = (
		e: DragEvent<Element>,
		servicePoint: ContractPeriodServicePointType
	) => {
		e.dataTransfer.setData("servicePointId", servicePoint.id.toString());
	};

	const handleDragOver = (
		e: DragEvent<Element>,
		listType: TargetListNamesEnum
	) => {
		e.preventDefault();
		setIsDraggingOver(listType);
	};

	const handleDragLeave = () => {
		setIsDraggingOver(null);
	};

	const handleDrop = (
		e: DragEvent<Element>,
		targetList: TargetListNamesEnum
	) => {
		e.preventDefault();
		setIsDraggingOver(null);
		const servicePointId = parseInt(
			e.dataTransfer.getData("servicePointId")
		);

		if (targetList === TargetListNamesEnum.allocated) {
			const pointToMove = availableServicePoints.find(
				(sp) => sp.id === servicePointId
			);
			setFieldValue(`${formikPath}.servicepoints`, [
				...currentValues.servicepoints,
				{ ...pointToMove, volume_share: 0 },
			]);
		} else {
			setFieldValue(
				`${formikPath}.servicepoints`,
				currentValues.servicepoints.filter(
					(sp) => sp.id !== servicePointId
				)
			);
		}
	};

	const displayServicePointsErrorMessage = () => {
		let errorMessage = "";
		const servicepointsError = get(errors[index], "servicepoints");

		if (typeof servicepointsError === "string") {
			errorMessage = servicepointsError;
		}
		if (Array.isArray(servicepointsError)) {
			const firstError = (
				servicepointsError as { volume_share: string }[]
			).find((error) => error.volume_share.trim() !== "");

			if (firstError) {
				errorMessage = firstError.volume_share;
			}
		}

		const isTouched = get(touched[index], "servicepoints");

		return errorMessage && isTouched ? (
			<ErrorText>{errorMessage}</ErrorText>
		) : null;
	};

	const titleColorKey = (): keyof typeof CounterpartyColor => {
		if (
			errors[index] &&
			touched[index] &&
			Object.values(errors[index] ?? {}).filter((error) => error).length
		) {
			return "error";
		}
		if (currentValues.party && currentValues.servicepoints.length) {
			return "filled";
		}

		return "default";
	};

	const titleColorKeyValue = titleColorKey();
	const titleColor = CounterpartyColor[titleColorKeyValue];

	return (
		<Box>
			<Box sx={{ display: "flex", alignItems: "center", gap: "16px" }}>
				<Box
					role="button"
					data-testid="counterparty-title"
					onClick={handleCollapse}
					sx={{ display: "flex", alignItems: "center", gap: "8px" }}
				>
					<Box sx={{ height: "24px" }}>
						{isCollapsed ? (
							<ExpandMoreIcon data-testid="expand-more-icon" />
						) : (
							<ExpandLessIcon data-testid="expand-less-icon" />
						)}
					</Box>
					<Box
						sx={{ fontWeight: 700, color: titleColor }}
						data-testid={`counterparty-title-${titleColorKeyValue}`}
					>
						Counterparty {index + 1} {isRequired && "*"}
					</Box>
				</Box>
				<Box
					sx={{
						height: "1px",
						backgroundColor: statusColor,
						flexGrow: 1,
					}}
				/>
			</Box>
			<Box
				sx={{
					display: "grid",
					transition: "grid-template-rows 300ms ease-out",
					gridTemplateRows: isCollapsed ? "0fr" : "1fr",
				}}
			>
				<Box sx={{ overflow: "hidden" }}>
					<Box
						sx={{
							padding: "25px 0 0.5rem 0",
							display: "flex",
							flexDirection: "column",
							gap: "0.5rem",
						}}
					>
						<Box sx={{ width: "526px" }}>
							<Counterparty
								party={currentValues.party}
								parties={parties?.partyResults || []}
								detailParty={detailParty}
								errorMessage={
									(detailPartyError as ErrorType) ??
									(get(touched[index], "party") &&
										get(errors[index], "party"))
								}
								isLoading={isSearchLoading || isDetailLoading}
								setFieldValue={(_, value) =>
									setFieldValue(`${formikPath}.party`, value)
								}
								searchParties={searchParties}
								getPartyDetail={getPartyDetail}
								label="New Counterpart Name"
							/>
						</Box>
						<Box
							sx={{
								backgroundColor: "#F6F8F9",
								paddingBottom: "24px",
							}}
						>
							<Box
								style={{
									display: "flex",
									gap: "32px",
									padding: "24px",
									paddingBottom: "0",
								}}
							>
								<Box sx={{ width: "50%" }}>
									<ServicePointList
										title="Available service point(s)"
										dataTestid="available-service-points"
										servicePoints={availableServicePoints}
										selectedIds={selectedAvailable}
										onCheckboxChange={
											handleAvailableCheckboxChange
										}
										onSelectAll={handleSelectAllAvailable}
										onDragOver={(e) =>
											handleDragOver(
												e,
												TargetListNamesEnum.available
											)
										}
										onDragLeave={handleDragLeave}
										onDrop={(e) =>
											handleDrop(
												e,
												TargetListNamesEnum.available
											)
										}
										isDraggingOver={
											isDraggingOver ===
											TargetListNamesEnum.available
										}
										onDragStart={handleDragStart}
										setFieldValue={setFieldValue}
										index={index}
									/>
								</Box>
								<Box
									sx={{
										display: "flex",
										flexDirection: "column",
										gap: "32px",
									}}
								>
									<FluidButton
										icon="arrow_forward_ios"
										label="Add selected"
										onClick={handleAddSelected}
										variant="secondary"
									/>
									<FluidButton
										icon="arrow_back_ios"
										label="Remove selected"
										onClick={handleRemoveSelected}
										variant="secondary"
									/>
								</Box>
								<Box sx={{ width: "50%" }}>
									<ServicePointList
										title="Allocated service point(s)"
										dataTestid="allocated-service-points"
										servicePoints={
											currentValues.servicepoints
										}
										selectedIds={selectedAllocated}
										onCheckboxChange={
											handleAllocatedCheckboxChange
										}
										onSelectAll={handleSelectAllAllocated}
										onDragOver={(e) =>
											handleDragOver(
												e,
												TargetListNamesEnum.allocated
											)
										}
										onDragLeave={handleDragLeave}
										onDrop={(e) =>
											handleDrop(
												e,
												TargetListNamesEnum.allocated
											)
										}
										isDraggingOver={
											isDraggingOver ===
											TargetListNamesEnum.allocated
										}
										onDragStart={handleDragStart}
										setFieldValue={setFieldValue}
										hasVolumeShare
										index={index}
									/>
								</Box>
							</Box>
							<Box
								style={{
									textAlign: "right",
									paddingRight: "24px",
								}}
							>
								{displayServicePointsErrorMessage()}
							</Box>
						</Box>
					</Box>
				</Box>
			</Box>
		</Box>
	);
};

export default ContractSplitCounterparty;
