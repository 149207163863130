import { apiGet, apiPost, apiPut } from "../../common/utils/request.utils";
import { AppDispatch } from "../../store";
import { TenderCreation, SiteInCreation, Timeserie } from "./tenderForm.module";
import {
	getTenderSuccess,
	getAccountsSuccess,
	getAccountsError,
	getTenderError,
	createTenderError,
	createSiteError,
	createSiteSuccess,
	updateSiteImport,
	setNewPorfolio,
	removePortfolio,
	changePortfolioName,
	addNewSite,
	addListNewSites,
	removeSite,
	removeMultipleSites,
	setTenderLoading,
	createTenderWarning,
	siteKnownStatusAreLoading,
	siteKnownStatusError,
	siteKnownStatusSuccess,
	getTimeseriesSuccess,
	getTimeseriesError,
	updateTimeseriesLoader,
	createPricingByTsError,
	updateSiteLocal,
	setGraphLoader,
	updateTenderData,
	updateLoader,
} from "./tenderForm.slice";
import { enqueueSnackbar } from "notistack";
import { getTenders } from "../tender_page/tender.thunk";
import { mapTenderDataToReducerTender } from "./components/tenderForm.mappers.retrieve";
import { Site } from "../sites/sites.module";
import { TSInUse, TSToClean } from "./components/timeserie_tab/TimeseriesTable";

export function getTender(id: number, allPortfolios: boolean = false): any {
	return function (dispatch: AppDispatch) {
		dispatch(setTenderLoading());
		const tenderUrl =
			`tenders/${id}` + (allPortfolios ? "?portfolio=All" : "");
		return apiGet(tenderUrl).then(
			(response) => {
				return dispatch(
					getTenderSuccess({
						tender: mapTenderDataToReducerTender(response.data),
					})
				);
			},
			(error) => {
				return dispatch(getTenderError(error.message));
			}
		);
	};
}

export function createTender(tender: TenderCreation): any {
	return function (dispatch: AppDispatch) {
		dispatch(updateLoader({ name: "tender", value: true }));

		return apiPost("tenders", tender).then(
			(t) => {
				dispatch(getTenders(1));
				enqueueSnackbar(
					`Tender ${t?.data?.name} was successfully created`,
					{ variant: "success", autoHideDuration: 3000 }
				);
				dispatch(updateLoader({ name: "tender", value: false }));

				return t.data;
			},
			(error) => {
				dispatch(updateLoader({ name: "tender", value: false }));

				enqueueSnackbar(error.message, {
					variant: "error",
					autoHideDuration: 3000,
				});
				return dispatch(createTenderError(error.message));
			}
		);
	};
}

export function getAccounts(search: string = ""): any {
	return function (dispatch: AppDispatch) {
		return apiPost(`external-services/accounts`, { search: search }).then(
			(accounts) => {
				return dispatch(
					getAccountsSuccess(accounts.data?.accountResults)
				);
			},
			(error) => {
				return dispatch(getAccountsError(error.message));
			}
		);
	};
}
export function updateTender(
	id: number,
	tender: TenderCreation,
	bypassWarning: boolean
): any {
	return async function (dispatch: AppDispatch) {
		dispatch(updateLoader({ name: "tender", value: true }));
		try {
			const url = bypassWarning
				? `tenders/${id}?bypassWarning=true`
				: `tenders/${id}`;
			const tenderData = await apiPut(url, tender);
			dispatch(getTenderSuccess({ tender: tenderData.data }));
			enqueueSnackbar(
				`Tender ${tenderData?.data?.name} was successfully update`,
				{ variant: "success", autoHideDuration: 3000 }
			);
			dispatch(updateLoader({ name: "tender", value: false }));

			return true;
		} catch (error: any) {
			dispatch(updateLoader({ name: "tender", value: false }));

			if (error?.response?.data?.is_warning) {
				dispatch(createTenderWarning(error?.response?.data?.message));
			} else {
				enqueueSnackbar(error.message, {
					variant: "error",
					autoHideDuration: 3000,
				});
				dispatch(createTenderError(error.message));
			}
			return false;
		}
	};
}

export function createSite(site: SiteInCreation): any {
	return function (dispatch: AppDispatch) {
		return apiPost("sites", site).then(
			(siteData) => {
				if (!siteData.data.is_warning) {
					enqueueSnackbar(
						`Site ${siteData?.data?.name} was successfully created`,
						{ variant: "success", autoHideDuration: 3000 }
					);
				}
				dispatch(
					createSiteSuccess({
						isWarning: siteData.data.is_warning || false,
						duplicates: siteData.data.duplicates || [],
						reasons: siteData.data.reasons || [],
						newSite: siteData.data.duplicates
							? undefined
							: siteData.data,
					})
				);
			},
			(error) => {
				enqueueSnackbar(error.message, {
					variant: "error",
					autoHideDuration: 3000,
				});
				return dispatch(createSiteError(error.message));
			}
		);
	};
}

export function importSite(fileId: number, ignoreDuplicates: boolean = false) {
	return async function (dispatch: AppDispatch) {
		await apiPost("sites/trigger-import", {
			file_id: fileId,
			ignore_duplicates: ignoreDuplicates,
		});
		const fileResponse = await apiGet("files/" + fileId);
		dispatch(updateSiteImport(fileResponse.data));
	};
}

export function refreshSiteImport(fileId: number) {
	return async function (dispatch: AppDispatch) {
		const fileResponse = await apiGet("files/" + fileId);
		dispatch(updateSiteImport(fileResponse.data));
	};
}

export function addNewSiteToTender(site: Site) {
	return (dispatch: AppDispatch) => {
		dispatch(addNewSite(site));
	};
}

export function getNewSiteKnownStatus(sites: Site[]) {
	return async (dispatch: AppDispatch) => {
		const assetIds = sites
			.map((site) => site.asset_id)
			.filter((assetId) => !!assetId) as string[];
		dispatch(siteKnownStatusAreLoading({ assetIds }));
		try {
			const response = await apiGet(
				"sites/known_status/" + assetIds.join(",")
			);
			const { data } = response;
			dispatch(siteKnownStatusSuccess({ status: data }));
		} catch (err: any) {
			dispatch(
				siteKnownStatusError({
					assetIds: assetIds,
					error: err?.response?.data || "unknown error",
				})
			);
		}
	};
}

export function addListNewSitesToTender(sites: Site[]) {
	return (dispatch: AppDispatch) => {
		dispatch(addListNewSites(sites));
		const sitesToLookFor = sites.filter((site) => !site.known_status);
		dispatch(getNewSiteKnownStatus(sitesToLookFor));
	};
}

export function deleteSiteOnTender(site: Site) {
	return (dispatch: AppDispatch) => {
		dispatch(removeSite({ site }));
	};
}

export function deleteMultipleSitesOnTender(sites: Site[]) {
	return (dispatch: AppDispatch) => {
		dispatch(removeMultipleSites({ sites }));
	};
}

export function createNewPorfolio(name: string): any {
	return (dispatch: AppDispatch) => {
		const randomNumber = Math.floor(Math.random() * 1e5);
		const data = {
			id: randomNumber,
			name: name,
			isNew: true,
			children: [],
			sites: [],
		};
		dispatch(setNewPorfolio(data));
	};
}

export function deletePortfolio(portfolioId: number): any {
	return (dispatch: AppDispatch) => {
		dispatch(removePortfolio({ portfolioId }));
	};
}

export function editPortfolioName(portfolioId: number, name: string): any {
	return (dispatch: AppDispatch) => {
		dispatch(changePortfolioName({ portfolioId, name }));
	};
}

export function refreshSiteCluster(siteId: number) {
	return async (dispatch: AppDispatch) => {
		const response = await apiPost(`sites/${siteId}/refresh`, {});
		dispatch(updateSiteLocal(response.data));
	};
}

export function getTimeseriesByTender(params: { tenderId: number }): any {
	return async function (dispatch: AppDispatch) {
		dispatch(updateTimeseriesLoader(true));
		try {
			let response = await apiGet(
				"/timeseries?tender_id=" + params.tenderId
			);
			return dispatch(getTimeseriesSuccess(response.data));
		} catch (error: any) {
			if (!error?.response?.data?.errors) {
				dispatch(updateTimeseriesLoader(false));
				return ["unknown error"];
			}
			return dispatch(getTimeseriesError(error.message));
		}
	};
}

export function triggerTimeserieCleaning(
	listTsToClean: TSToClean[],
	tenderId: number
) {
	return async function (dispatch: AppDispatch) {
		dispatch(updateTimeseriesLoader(true));
		const body = {
			tsToClean: listTsToClean.map((element) => ({
				timeserie_id: element.timeserieId,
				site_id: element.siteId,
				is_new: element.isNew,
			})),
		};
		await apiPost("/timeseries/trigger_cleaning", body);
		await dispatch(getTimeseriesByTender({ tenderId }));
	};
}

export function createTimeserie(
	siteId: number,
	timeserieTag: string,
	tenderId: number
) {
	return async function (dispatch: AppDispatch) {
		const body = {
			tourbillon_tag: timeserieTag,
			site_id: siteId,
		};
		await apiPost("/timeseries", body);
		await dispatch(getTimeseriesByTender({ tenderId }));
	};
}

export function createPricingsByTs(
	body: {
		tender_id?: number;
		ts_list: TSInUse[];
		portfolios: Record<number, Record<number, Timeserie>>;
	},
	tenderId?: number
): any {
	return async function (dispatch: AppDispatch) {
		dispatch(updateTimeseriesLoader(true));
		try {
			await apiPost("/pricing/ts", body);

			enqueueSnackbar(`A request has been sent to create pricings.`, {
				variant: "success",
				autoHideDuration: 3000,
			});
			dispatch(createPricingByTsError(undefined));
			return dispatch(updateTimeseriesLoader(false));
		} catch (error: any) {
			if (error?.response?.data?.message) {
				return dispatch(
					createPricingByTsError(error?.response?.data?.message)
				);
			}
			return dispatch(
				createPricingByTsError("Error creating new pricing")
			);
		} finally {
			if (tenderId) {
				dispatch(getTimeseriesByTender({ tenderId }));
			}
		}
	};
}

export function requestGeneratingGraph(body: {
	tender_id?: number;
	ts_list: TSInUse[];
	action?: string;
}): any {
	return async function (dispatch: AppDispatch) {
		dispatch(setGraphLoader({ name: "generateGraph", value: true }));
		try {
			let response = await apiPost("/timeseries/graph", body);
			return dispatch(
				updateTenderData({
					graph_status: response.data.graph_status,
					graph_file_id: response.data.graph_file_id,
					graph_error: response.data.graph_error,
				})
			);
		} catch (error: any) {
			return dispatch(getTenderError(error.message));
		} finally {
			dispatch(setGraphLoader({ name: "generateGraph", value: false }));
		}
	};
}

export function downloadGraph(fileId?: number): any {
	return async function (dispatch: AppDispatch) {
		dispatch(setGraphLoader({ name: "downloadGraph", value: true }));

		try {
			let response = await apiGet("/files/download/" + fileId);
			if (response?.data?.url) return window.open(response.data.url);
		} catch (error: any) {
			return dispatch(getTenderError(error.message));
		} finally {
			dispatch(setGraphLoader({ name: "downloadGraph", value: false }));
		}
	};
}

export async function downloadFile(fileId?: number): Promise<any> {
	let response = await apiGet("/files/download/" + fileId);
	if (response?.data?.url) return window.open(response.data.url);
}

export default {
	createSite,
};
