import { ReactCountryFlag } from "react-country-flag";
import GenericTable from "../../../../common/components/GenericTable";
import { ColModel } from "../../../../common/components/GenericTableRow";
import PricingStatusCell from "../../../tender_page/components/PricingStatusCell";
import CommentIcon from "@mui/icons-material/Comment";
import { primaryColor } from "../../../../core/theme";
import { Tender } from "../../../tender_page/tender.module";
import { IconButton, Typography } from "@mui/material";
import { updateTenders } from "../../../tender_page/tender.thunk";
import { If } from "../../../../common/components/If";
import { useSelector } from "react-redux";
import {
	selectAuthenticatedUser,
	selectUserGroups,
} from "../../../authentication/auth.selector";
import { useAppDispatch } from "../../../../common/hooks/default";
import { useContext, useMemo } from "react";
import * as _ from "lodash";
import { TenderSectionContext } from "./TenderSection";

interface TenderInformationTableProps {
	model: Tender[];
}

const style: any = {
	pickupMe: {
		opacity: 0,
		"&:hover": {
			opacity: 1,
			border: "1px dashed rgba(0,0,0,0.2)",
			padding: "10px",
			textAlign: "center",
			cursor: "pointer",
		},
	},
};

export default function TenderInformationTable({
	model,
}: TenderInformationTableProps) {
	const dispatch = useAppDispatch();
	const currentUser = useSelector(selectAuthenticatedUser);
	const isUserTrader = useSelector(selectUserGroups)?.isTrader;
	const tenderSectionContext = useContext(TenderSectionContext);

	const rowModel: ColModel<Tender>[] = [
		{
			id: "1",
			header: () => "Status",
			xs: 1,
			accessor: (m: Tender) => (
				<PricingStatusCell
					status={(m.status as any) ?? "UNDER_PRICING"}
				/>
			),
		},
		{
			id: "2",
			header: () => "Name",
			xs: 2.5,
			accessor: (m: Tender) => m.name,
		},
		{
			id: "3",
			header: () => "Account",
			xs: 2.5,
			accessor: (m: Tender) => m.account.name,
		},
		{
			id: "4",
			header: () => "Country",
			xs: 1,
			accessor: (m: Tender) => (
				<>
					<ReactCountryFlag
						countryCode={m.countries.length ? m.countries[0] : ""}
						svg
						title={m.countries.length ? m.countries[0] : ""}
					/>{" "}
					{m.countries.length ? m.countries[0] : ""}
				</>
			),
		},
		{
			id: "5",
			header: () => "Capacity",
			xs: 1,
			accessor: (m: Tender) => m.installed_capacity_mw,
		},
		{
			id: "8",
			header: () => "Sites",
			xs: 0.5,
			accessor: (m: Tender) =>
				`${m.count_attached_sites}/${m.site_count}`,
		},
		{
			id: "9",
			header: () => "Techno",
			xs: 1.2,
			accessor: (m: Tender) =>
				m?.technologies?.length > 1 ? "Mixed" : m?.technologies,
		},
		{
			id: "11",
			header: () => "Direction",
			xs: 0.7,
			accessor: (m: Tender) => m.direction,
		},
		{
			id: "12",
			header: () => "Pricing Stage",
			xs: 0.7,
			accessor: (m: Tender) => m.pricing_stage,
		},
		{
			id: "13",
			header: () => "Originator",
			xs: 1.3,
			accessor: (m: Tender) => (
				<If condition={m?.originator}>
					<Typography variant="subtitle2" component="p">
						{m?.originator?.first_name} {m?.originator?.last_name}
					</Typography>
				</If>
			),
		},
		{
			id: "14",
			header: () => "Trader",
			xs: 1.3,
			accessor: (m: Tender) => {
				const pickupAction = useMemo(
					() =>
						_.find(
							m.actions_blotter,
							(action) => action.action === "PICKUP"
						),
					[m]
				);
				return (
					<>
						<If condition={m?.trader}>
							<Typography variant="subtitle2" component="p">
								{m?.trader?.first_name} {m?.trader?.last_name}
							</Typography>
						</If>
						<If
							condition={
								!m?.trader && isUserTrader && pickupAction
							}
						>
							<Typography
								variant="subtitle2"
								component="p"
								sx={style.pickupMe}
								onClick={(e) => {
									e.preventDefault();
									e.stopPropagation();
									dispatch(
										updateTenders(
											[m.id],
											pickupAction!,
											currentUser?.name
										)
									);
								}}
							>
								Pickup
							</Typography>
						</If>
					</>
				);
			},
		},
		{
			id: "15",
			header: () => "Deadline",
			xs: 1,
			accessor: (m: Tender) => m.due_date,
		},
		{
			id: "16",
			header: () => "Validity",
			xs: 1,
			accessor: (m: Tender) => m.validity_date,
		},
		{
			id: "17",
			header: () => "Feedbacks",
			xs: 0.5,
			accessor: (m: Tender) => (
				<IconButton
					onClick={() => {
						tenderSectionContext?.setTenderActivityModal({
							tenderId: m.id,
							tenderName: m.name,
						});
					}}
				>
					<CommentIcon sx={{ fontSize: 16, color: primaryColor }} />
				</IconButton>
			),
		},
	];

	return (
		<GenericTable
			virtualScrollable={false}
			itemName="Sites"
			model={model}
			selection={() => {}}
			updatePage={() => {}}
			page={0}
			rowModel={rowModel}
			isSelectable={false}
			loading={false}
		/>
	);
}
