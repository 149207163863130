import { createSlice } from "@reduxjs/toolkit";
import { Tender } from "../tender_page/tender.module";
import {
	DuplicateWarningReason,
	SiteImportFile,
	Timeserie,
	TimeseriesList,
} from "./tenderForm.module";
import { Site, Portfolio, SiteKnownStatus } from "../sites/sites.module";
import * as _ from "lodash";
import { WithPayload } from "../../common/utils/withPayload";
import { ensureArray } from "../../common/utils/ensureArray";

export interface Account {
	accountId: number;
	accountName: string;
}

export interface TenderFormState {
	accounts: Account[];
	loader: {
		accounts: boolean;
		createSite: boolean;
		tender: boolean;
		tsBySite: boolean;
		downloadGraph: boolean;
		generateGraph: boolean;
	};
	errors: {
		accounts?: string;
		tender?: string;
		createSite?: string;
		createTender?: string;
		tsBySite?: string;
		createPricingByTs?: string;
	};
	warning: {
		createTender?: string;
	};
	tender?: Tender;
	tsBySite: {
		sites: TimeseriesList[];
		portfolios: Record<number, Record<number, Timeserie>>;
	};
	siteForm: {
		isWarning: boolean;
		duplicates: Site[];
		reasons: DuplicateWarningReason[];
	};
	siteImport: {
		file?: SiteImportFile;
	};
}

export const initialState: TenderFormState = {
	accounts: [],
	loader: {
		accounts: false,
		createSite: false,
		tender: false,
		tsBySite: false,
		downloadGraph: false,
		generateGraph: false,
	},
	errors: {},
	warning: {},
	siteForm: {
		isWarning: false,
		duplicates: [],
		reasons: [],
	},
	siteImport: {
		file: undefined,
	},
	tender: {
		sites: [],
		portfolios: [],
	} as any,
	tsBySite: {
		sites: [],
		portfolios: {},
	},
};

function deduplicateSites(sites?: Site[]): Site[] {
	// deduplicate by asset_id and prioritize non master sites (ones that were already created before)
	return _.uniqBy(
		_.orderBy(sites || [], "is_master_site"), // is_master_site === false > is_master_site == true
		"asset_id"
	);
}

function portofolioWithoutSite(portfolio: Portfolio, site: Site): Portfolio {
	return {
		...portfolio,
		children: ensureArray(portfolio.children).filter(
			(child) => child.asset_id !== site.asset_id
		),
		sites: ensureArray(portfolio.sites).filter(
			(child) => child.asset_id !== site.asset_id
		),
	};
}

export const tenderFormSlice = createSlice({
	name: "tenderForm",
	initialState,
	reducers: {
		resetTender: (state) => {
			state.tender = { sites: [], portfolios: [] } as any;
			state.errors = initialState.errors;
			state.loader = initialState.loader;
		},
		setTenderLoading: (state) => {
			state.loader.tender = true;
		},
		getTenderSuccess: (
			state,
			action: WithPayload<{ tender: Partial<Tender> }>
		) => {
			const { tender } = action.payload;
			state.tender = tender as Tender;
			state.loader.tender = false;
		},
		updateTenderData: (state, action: WithPayload<Partial<Tender>>) => {
			state.tender = { ...state.tender, ...action.payload } as Tender;
			state.loader.tender = false;
		},
		getTenderError: (state, action) => {
			state.errors.tender = action.payload;
			state.loader.tender = false;
		},
		getAccountsSuccess: (state, action) => {
			state.accounts = action.payload;
			state.loader.accounts = false;
		},
		updateAccountsLoader: (state, action) => {
			state.loader.accounts = action.payload;
		},
		getAccountsError: (state, action) => {
			state.errors.accounts = action.payload;
		},
		createSiteSuccess: (state, action) => {
			state.siteForm = action.payload;
			state.loader.createSite = false;
			if (action.payload.newSite && state.tender?.sites) {
				state.tender.sites = [
					...state.tender?.sites,
					action.payload.newSite,
				];
			}
		},
		resetSite: (state) => {
			state.siteForm = {
				isWarning: false,
				duplicates: [],
				reasons: [],
			};
		},
		createSiteError: (state, action) => {
			state.errors.createSite = action.payload;
			state.loader.createSite = false;
		},
		createTenderError: (state, action) => {
			state.errors.createTender = action.payload;
		},
		createTenderWarning: (state, action) => {
			state.warning.createTender = action.payload;
		},
		updateCreateSiteLoader: (state, action) => {
			state.loader.createSite = action.payload;
		},
		updateSiteImport: (state, action) => {
			state.siteImport.file = action.payload;
		},

		addListNewSites: (state, action: WithPayload<Site[]>) => {
			if (!!state.tender) {
				state.tender.sites?.push(...action.payload);
				state.tender.sites = deduplicateSites(state.tender?.sites);
			}
		},
		addNewSite: (state, action: WithPayload<Site>) => {
			if (!!state.tender) {
				state.tender.sites?.push(action.payload);
				state.tender.sites = deduplicateSites(state.tender?.sites);
			}
		},
		removeSite: (state, action: WithPayload<{ site: Site }>) => {
			if (state?.tender) {
				state.tender.sites = state.tender.sites?.filter(
					(site) => site.asset_id !== action.payload.site.asset_id
				);
				state.tender.sites = deduplicateSites(state.tender?.sites);
				state.tender.portfolios = ensureArray(
					state.tender?.portfolios
				).map((portofolio) =>
					portofolioWithoutSite(portofolio, action.payload.site)
				);
			}
		},
		removeMultipleSites: (
			state,
			action: WithPayload<{ sites: Site[] }>
		) => {
			if (state?.tender) {
				for (const site of action.payload.sites) {
					state.tender.sites = state.tender.sites?.filter(
						(tenderSite) => tenderSite.asset_id !== site.asset_id
					);
					state.tender.sites = deduplicateSites(state.tender?.sites);
					state.tender.portfolios = ensureArray(
						state.tender?.portfolios
					).filter((portofolio) =>
						portofolioWithoutSite(portofolio, site)
					);
				}
			}
		},
		deleteSiteFromPortfolio: (
			state,
			action: WithPayload<{ portfolioId: number; site: Site }>
		) => {
			if (!!state?.tender) {
				state.tender.portfolios = ensureArray(
					state.tender?.portfolios
				).map((portfolio) => {
					if (portfolio.id === action.payload.portfolioId) {
						return portofolioWithoutSite(
							portfolio,
							action.payload.site
						);
					}
					return portfolio;
				});
			}
		},
		setNewPorfolio: (state, action: WithPayload<Portfolio>) => {
			if (!!state?.tender) {
				state.tender.portfolios = [
					action.payload,
					...ensureArray(state.tender.portfolios),
				];
			}
		},
		setPortfoliosSites: (
			state,
			action: WithPayload<{ portfolioId: number; sites: Site[] }>
		) => {
			if (!!state?.tender) {
				state.tender.portfolios = (state?.tender?.portfolios || []).map(
					(portfolio) => {
						if (portfolio.id === action.payload.portfolioId) {
							return {
								...portfolio,
								sites: action.payload.sites,
							};
						}
						return portfolio;
					}
				);
				state.tender.sites?.push(...action.payload.sites);
				state.tender.sites = deduplicateSites(state.tender?.sites);
			}
		},
		removePortfolio: (
			state,
			action: WithPayload<{ portfolioId: number }>
		) => {
			if (!!state?.tender) {
				const portfolioId = action.payload.portfolioId;
				state.tender.portfolios = (
					state.tender?.portfolios ?? []
				).filter((portfolio: Portfolio) => portfolio.id != portfolioId);
			}
		},
		changePortfolioName: (
			state,
			action: WithPayload<{ name: string; portfolioId: number }>
		) => {
			if (!!state?.tender) {
				const portfolioId = action.payload.portfolioId;
				const newName = action.payload.name;
				state.tender.portfolios = (state.tender?.portfolios ?? []).map(
					(portfolio: Portfolio) => {
						if (portfolio.id == portfolioId)
							portfolio.name = newName;
						return portfolio;
					}
				);
			}
		},
		updateSiteLocal: (state, action: WithPayload<Site>) => {
			if (state.tender && state.tender.sites) {
				state.tender.sites.forEach((site, index) => {
					if (
						site.id === action.payload.id &&
						state.tender?.sites !== undefined
					) {
						state.tender.sites[index] = action.payload;
					}
				});
			}
		},
		siteKnownStatusAreLoading: (
			state,
			action: WithPayload<{ assetIds?: string[] }>
		) => {
			if (state.tender && state.tender.sites) {
				state.tender.sites = state.tender.sites.map((site) => {
					if (action.payload.assetIds) {
						if (
							action.payload.assetIds.includes("" + site.asset_id)
						) {
							return {
								...site,
								known_status_loading: true,
								known_status_error: null,
							};
						}
					}
					return site;
				});
			}
		},
		siteKnownStatusSuccess: (
			state,
			action: WithPayload<{ status: Record<string, SiteKnownStatus> }>
		) => {
			if (action.payload.status) {
				if (state.tender && state.tender.sites) {
					state.tender.sites = state.tender.sites.map((site) => {
						if ((site.asset_id || "") in action.payload.status) {
							return {
								...site,
								known_status:
									action.payload.status[site.asset_id || ""],
								known_status_loading: false,
								known_status_error: null,
							};
						}
						return site;
					});
				}
			}
		},
		siteKnownStatusError: (
			state,
			action: WithPayload<{ assetIds?: string[]; error: any }>
		) => {
			if (action.payload.assetIds) {
				if (state.tender && state.tender.sites) {
					state.tender.sites = state.tender.sites.map((site) => {
						if (
							action.payload.assetIds?.includes(
								site.asset_id || ""
							)
						) {
							return {
								...site,
								known_status_loading: false,
								known_status_error: action.payload.error,
							};
						}
						return site;
					});
				}
			}
		},
		initTimeseries: (state) => {
			state.tsBySite = { sites: [], portfolios: {} };
		},
		updateTimeseriesLoader: (state, action: WithPayload<boolean>) => {
			state.loader.tsBySite = action.payload;
		},
		getTimeseriesSuccess: (
			state,
			action: WithPayload<{
				sites: TimeseriesList[];
				portfolios: Record<number, Record<number, Timeserie>>;
			}>
		) => {
			state.loader.tsBySite = false;
			state.tsBySite = action.payload;
		},
		getTimeseriesError: (state, action) => {
			state.loader.tsBySite = false;
			state.errors.tsBySite = action.payload;
		},
		createPricingByTsError: (state, action) => {
			state.loader.tsBySite = false;
			state.errors.createPricingByTs = action.payload;
		},
		setGraphLoader: (
			state,
			action: WithPayload<{
				name: "downloadGraph" | "generateGraph";
				value: boolean;
			}>
		) => {
			state.loader[action.payload.name] = action.payload.value;
		},
		updateLoader: (
			state,
			action: WithPayload<{
				name: "downloadGraph" | "generateGraph" | "tender";
				value: boolean;
			}>
		) => {
			state.loader[action.payload.name] = action.payload.value;
		},
	},
});

export const {
	updateLoader,
	getTimeseriesSuccess,
	getTimeseriesError,
	createPricingByTsError,
	initTimeseries,
	getTenderSuccess,
	updateTenderData,
	getAccountsSuccess,
	getAccountsError,
	updateAccountsLoader,
	resetTender,
	createSiteError,
	getTenderError,
	createTenderError,
	createTenderWarning,
	updateCreateSiteLoader,
	createSiteSuccess,
	resetSite,
	updateSiteImport,
	setNewPorfolio,
	setPortfoliosSites,
	removePortfolio,
	changePortfolioName,
	addNewSite,
	addListNewSites,
	setTenderLoading,
	removeSite,
	removeMultipleSites,
	updateSiteLocal,
	deleteSiteFromPortfolio,
	siteKnownStatusAreLoading,
	siteKnownStatusSuccess,
	siteKnownStatusError,
	updateTimeseriesLoader,
	setGraphLoader,
} = tenderFormSlice.actions;

export default tenderFormSlice.reducer;
