import { Box } from "@mui/material";
import _ from "lodash";
import {
	ContractDetailType,
	ContractPeriodSettlementCategories,
	ContractPeriodType,
	NegativePrice,
	SettlementType,
} from "../../../../requests_cm/gecoContractsService/types";
import { Spacer } from "../../../../common/components/Spacer";
import CircularProgress from "@mui/material/CircularProgress";
import { useGetNegativePriceIndexesQuery } from "../../../../requests_cm/gecoReferentialService/service";
import { useRtkQueryDynamicEndpoint } from "../../../../common/hooks/useRtkQueryDynamicEndpoint";
import NoDataMessage from "../../../../common/components/NoDataMessage";
import { useCallback, useMemo, useState } from "react";
import {
	clearNegativePriceCondition,
	negativePriceConditionFormikToContractMapper,
	settlementFormikToContractMapper,
} from "../../formik/settlementFormikToContractMapper";
import { EditableSettlementCard } from "./EditableSettlementCard";
import { NegativeSettlementBox } from "./NegativeSettlementBox";
import { NegativePriceIndex } from "../../../../requests_cm/gecoReferentialService/types";

export const SettlementTab = ({
	contract,
	contractPeriodId,
	onSaveDraftContract,
	negativePriceIndexes,
}: {
	contract?: ContractDetailType;
	contractPeriodId?: number;
	negativePriceIndexes: NegativePriceIndex[] | undefined;
	onSaveDraftContract: (contract: ContractDetailType) => void;
}) => {
	const [hasNegativePrice, setHasNegativePrice] = useState(false);
	const { data: indexes, isLoading } = useRtkQueryDynamicEndpoint(
		useGetNegativePriceIndexesQuery
	)({
		country_code: contract?.country ?? "",
	});

	const contractPeriod: ContractPeriodType | undefined = useMemo(() => {
		return contract?.contract_periods.filter(
			(cp: ContractPeriodType) => cp.id === Number(contractPeriodId)
		)[0];
	}, [contract]);

	const settlementCategory: ContractPeriodSettlementCategories | undefined =
		useMemo(() => {
			for (const key of Object.keys(ContractPeriodSettlementCategories)) {
				if (_.get(contractPeriod, key)) {
					return key as ContractPeriodSettlementCategories;
				}
			}
		}, [contractPeriod]);

	const settlements: SettlementType[] = useMemo(() => {
		if (contractPeriod && settlementCategory) {
			return contractPeriod[settlementCategory]?.settlements || [];
		}
		return [];
	}, [contractPeriod, settlementCategory]);

	const updateSettlementOnContractPeriod = useCallback(
		(formikSettlement: SettlementType) => {
			onSaveDraftContract(
				settlementFormikToContractMapper(
					formikSettlement,
					contract as ContractDetailType,
					contractPeriodId as number,
					settlementCategory as ContractPeriodSettlementCategories
				)
			);
		},
		[settlementCategory, contractPeriodId, contract]
	);

	const updateNegativePriceOnContractPeriod = useCallback(
		(negativePrice: NegativePrice) => {
			onSaveDraftContract(
				negativePriceConditionFormikToContractMapper(
					negativePrice,
					contract as ContractDetailType,
					contractPeriodId as number
				)
			);
		},
		[contractPeriodId, contract]
	);

	const clearNegativePriceConditionOnContractPeriod = useCallback(() => {
		onSaveDraftContract(
			clearNegativePriceCondition(
				contract as ContractDetailType,
				contractPeriodId as number,
				settlementCategory as ContractPeriodSettlementCategories
			)
		);
	}, [settlementCategory, contractPeriodId, contract]);

	return (
		<>
			{isLoading && <CircularProgress />}
			{!isLoading && (
				<Box sx={{ mt: 3 }}>
					<Spacer gap={24} />
					{!settlements.length && <NoDataMessage />}
					{!!settlements.length && (
						<>
							<NegativeSettlementBox
								hasNegativePrice={hasNegativePrice}
								contractPeriod={contractPeriod}
								negativePriceIndexes={
									negativePriceIndexes || []
								}
								onSubmit={updateNegativePriceOnContractPeriod}
								toggleNegativePrice={(isNegative: boolean) =>
									setHasNegativePrice(isNegative)
								}
								onRemoveNegativeCondition={
									clearNegativePriceConditionOnContractPeriod
								}
							/>
							<Spacer gap={24} />
							<Box
								sx={{
									display: "flex",
									gap: 3,
									flexWrap: "wrap",
								}}
							>
								{(settlements || []).map(
									(settlement, index) => (
										<EditableSettlementCard
											doesSupportNegativeCondition={
												!!contractPeriod?.negative_price
													?.type
											}
											key={index}
											settlement={settlement}
											isLoading={false}
											indexes={indexes || []}
											onSubmit={
												updateSettlementOnContractPeriod
											}
										/>
									)
								)}
							</Box>
						</>
					)}
				</Box>
			)}
		</>
	);
};
