import { NJLink } from "@engie-group/fluid-design-system-react";

export interface FluidLinkProps extends React.PropsWithChildren {
	href: string;
	variant?: "grayed" | "contextual" | "high_contrast" | "inverse" | "default";
	onClick?: (e: any) => void;
}

export const FluidLink = ({
	children,
	href,
	variant,
	onClick,
}: FluidLinkProps) => {
	return (
		<NJLink onClick={onClick} variant={variant} href={href}>
			{children}
		</NJLink>
	);
};
