import { PATH } from "../../router-path";

export enum ChangeRequestStatus {
	PENDING = "PENDING",
	REJECTED = "REJECTED",
	VALID = "VALID",
	CORRECTION_NEEDED = "CORRECTION_NEEDED",
	CLOSED = "CLOSED",
}

export enum ChangeRequestResourceType {
	CONTRACT = "CONTRACT",
	CONTRACT_PERIOD = "CONTRACT_PERIOD",
	HORIZON_CHANGE = "HORIZON_CHANGE",
}

export interface Paticipant {
	gaia_id?: string;
	first_name: string;
	last_name: string;
	email: string;
}

export interface JsonDiff {
	old_value: string | number | boolean | object;
	path: (string | number)[];
	type: "CHANGE" | "REMOVE" | "CREATE";
	value: string | number | boolean | object;
}

export interface ChangeRequestCommon {
	resource_type: ChangeRequestResourceType;
	resource_id: number;
	resource_edit_path: PATH;
	name: string;
	diff_json: JsonDiff[];
}

export interface ChangeRequestType extends ChangeRequestCommon {
	id: number;
	requester: Paticipant;
	validator: Paticipant;
	requester_message?: string;
	validator_message?: string;
	status: ChangeRequestStatus;
	created_at: string;
}

export interface CreateChangeRequestType extends ChangeRequestCommon {
	validator_gaia_id: string;
	requester_message?: string;
}

export interface UpdateChangeRequestType extends ChangeRequestCommon {
	id: number;
}

export interface ReadChangeRequestType {
	data: ChangeRequestType[];
	has_next_page: boolean;
	page: number;
	page_size: number;
}
