import { createSlice } from "@reduxjs/toolkit";
import { ModelMetadata } from "./metadata.module";

export interface PickListItem {
	id: string;
	name: string;
}

export interface PickLists {
	id: string;
	items: PickListItem[];
}

export interface CachedMetadata {
	id: string;
	metadata: ModelMetadata;
}

export interface MetadataState {
	tendersMetadata: ModelMetadata | null;
	tendersWorkflowViewMetadata: ModelMetadata | null;
	pricingsMetadata: ModelMetadata | null;
	cachedMetadatas: CachedMetadata[];
	isLoading: boolean;
	error: any;
	lists: PickLists[];
}

export const initialMetadataState: MetadataState = {
	tendersMetadata: null,
	tendersWorkflowViewMetadata: null,
	pricingsMetadata: null,
	cachedMetadatas: [],
	isLoading: false,
	error: null,
	lists: [],
};

export const metadataSlice = createSlice({
	name: "metadata",
	initialState: initialMetadataState,
	reducers: {
		startLoading: (state) => {
			state.isLoading = true;
			state.error = null;
		},
		success: (state, { payload }) => {
			state.isLoading = false;
			switch (payload.page) {
				case "Tenders":
					state.tendersMetadata = payload.metadata;
					const tenderCachedMetadataIndex =
						state.cachedMetadatas.findIndex(
							(c: CachedMetadata) => c.id === payload.id
						);
					if (tenderCachedMetadataIndex === -1) {
						state.cachedMetadatas.push({
							id: payload.id,
							metadata: payload.metadata,
						});
					} else {
						state.cachedMetadatas[tenderCachedMetadataIndex] = {
							id: payload.id,
							metadata: payload.metadata,
						};
					}
					break;
				case "Tenders Workflow view":
					state.tendersWorkflowViewMetadata = payload.metadata;
					const tenderWorkflowViewCachedMetadataIndex =
						state.cachedMetadatas.findIndex(
							(c: CachedMetadata) => c.id === payload.id
						);
					if (tenderWorkflowViewCachedMetadataIndex === -1) {
						state.cachedMetadatas.push({
							id: payload.id,
							metadata: payload.metadata,
						});
					} else {
						state.cachedMetadatas[
							tenderWorkflowViewCachedMetadataIndex
						] = {
							id: payload.id,
							metadata: payload.metadata,
						};
					}
					break;
				case "Pricings":
					state.pricingsMetadata = payload.metadata;
					const cacheIndex = state.cachedMetadatas.findIndex(
						(c: CachedMetadata) => c.id === payload.id
					);
					if (cacheIndex === -1) {
						state.cachedMetadatas.push({
							id: payload.id,
							metadata: payload.metadata,
						});
					} else {
						state.cachedMetadatas[cacheIndex] = {
							id: payload.id,
							metadata: payload.metadata,
						};
					}
					break;
			}
		},
		failure: (state, { payload }) => {
			state.isLoading = false;
			state.error = payload;
		},
		setPickLists: (state, { payload }) => {
			state.lists = payload.lists;
		},
	},
});

export const { startLoading, success, failure } = metadataSlice.actions;

export default metadataSlice.reducer;
