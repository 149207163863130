import { createApi } from "@reduxjs/toolkit/dist/query/react";
import customBaseQuery from "../common/utils/customBaseQuery";

const contractManagementGepoApi = createApi({
	reducerPath: "contractManagementGepoApi",
	baseQuery: customBaseQuery({
		baseURL: process.env.REACT_APP_API_PATH!,
	}),
	tagTypes: ["ChangeRequest"],
	endpoints: () => ({}),
});

export default contractManagementGepoApi;
