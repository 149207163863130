import { NJRadioGroup, NJRadio } from "@engie-group/fluid-design-system-react";
import { ContractStatus } from "../../../../requests_cm/gecoContractsService/types";

export interface StatusFieldProps {
	onChange: (value: any) => void;
	value: ContractStatus | undefined;
	isDisabled?: boolean;
}

export const StatusField = (props: StatusFieldProps) => {
	return (
		<NJRadioGroup
			checkedId={props.value}
			legend="Indicates the status of the Contract Period"
			onChange={props.onChange}
			orientation="row"
		>
			<NJRadio
				id={ContractStatus.DRAFT}
				label={ContractStatus.DRAFT}
				name="status"
				value={ContractStatus.DRAFT}
				isDisabled={props.isDisabled}
			/>
			<NJRadio
				id={ContractStatus.TO_REVIEW}
				label={ContractStatus.TO_REVIEW}
				name="status"
				value={ContractStatus.TO_REVIEW}
				isDisabled={props.isDisabled}
			/>
			<NJRadio
				id={ContractStatus.VALID}
				label={ContractStatus.VALID}
				name="status"
				value={ContractStatus.VALID}
				isDisabled={props.isDisabled}
			/>
		</NJRadioGroup>
	);
};
