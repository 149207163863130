import React from "react";
import { Timeserie, TimeseriesList } from "../../tenderForm.module";
import { TableRow, TableCell } from "@mui/material";
import { ReactCountryFlag } from "react-country-flag";
import CustomSelect from "../../../../common/components/CustomSelect";

export interface PortfolioTimeserieConfigTableRowProps {
	timeserie: TimeseriesList;
	tsInUses: Record<string, Timeserie>;
	setTsInUses: (value: Record<string, Timeserie>) => void;
}

const style = {
	row: {
		border: "1px solid rgba(0, 0, 0, .1)",
	},
};

export function PortfolioTimeserieConfigTableRow(
	props: PortfolioTimeserieConfigTableRowProps
) {
	const { timeserie, tsInUses, setTsInUses } = props;
	return (
		<TableRow sx={style.row} key={timeserie.site_info.asset_id}>
			<TableCell component={"td"} scope="col">
				{timeserie.site_info.asset_id}
			</TableCell>
			<TableCell component={"td"} scope="col">
				{timeserie.site_info.name}
			</TableCell>
			<TableCell component={"td"} scope="col">
				<ReactCountryFlag
					countryCode={timeserie.site_info.country ?? ""}
					svg
					title={timeserie.site_info.country ?? ""}
				/>
			</TableCell>
			<TableCell component={"td"} scope="col">
				{timeserie.site_info.techno?.name}
			</TableCell>
			<TableCell component={"td"} scope="col">
				{timeserie.site_info.installed_capacity_mw}
			</TableCell>
			<TableCell component={"td"} scope="col">
				<CustomSelect
					name={"timeserie"}
					label={""}
					items={timeserie.ts.map((ts) => ({
						key: ts.id,
						value: ts.name,
					}))}
					value={
						tsInUses?.[timeserie.site_info.id]?.name || "Cluster"
					}
					onChange={(event) => {
						setTsInUses({
							...tsInUses,
							[timeserie.site_info.id]: timeserie.ts.filter(
								(ts) => ts.name === event.target.value
							)?.[0],
						});
					}}
				></CustomSelect>
			</TableCell>
		</TableRow>
	);
}
