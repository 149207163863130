import { Box, IconButton, Typography } from "@mui/material";
import theme, { borderNeutralSecondary } from "../../../core/theme";
import Button from "@mui/material/Button";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import CloseIcon from "@mui/icons-material/Close";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { FluidLink } from "../../../common/components/FluidLink";
import InfoBlock from "../../../common/components/InfoBlock/InfoBlock";
import {
	ChangeRequestStatus,
	ChangeRequestType,
} from "../../../requests_cm/gepoChangeRequestService/types";
import { StatusIcon } from "../StatusIcon";
import SimpleTable from "../../../common/components/SimpleTable";
import { PrimaryButton } from "../../../common/components/CustomButton";
import { formatDateDDMMYYYY } from "../../../common/utils/dateUtils";
import { If } from "../../../common/components/If";
import { ChangeRequestAlert } from "../../../common/components/ChangeRequestAlert";
import { User } from "../../authentication/auth.model";
import { useSelector } from "react-redux";
import { selectAuthenticatedUser } from "../../authentication/auth.selector";
import { useMemo } from "react";

export const style = {
	card: {
		wdith: "558px",
		height: "100%",
	},
	header: {
		padding: "16px 24px 16px 24px",
		borderBottom: `1px solid ${borderNeutralSecondary}`,
		alignItems: "center",
		display: "flex",
		buttonGroup: {
			display: "flex",
			marginLeft: "auto",
			gap: "16px",
		},
		title: {
			fontFamily: theme.typography.fontFamily,
			fontSize: "20px",
			fontWeight: "600",
		},
	},
	body: {
		padding: "24px",
		display: "flex",
		flexDirection: "column",
		gap: "24px",

		title: {
			fontFamily: theme.typography.fontFamily,
			fontSize: "20px",
			fontWeight: "600",
		},
		comment: {
			fontFamily: theme.typography.fontFamily,
			fontSize: "16px",
			fontWeight: "400",
		},
	},
	footer: {
		position: "absolute",
		bottom: 0,
		right: 0,
		left: 0,
		borderTop: `1px solid ${borderNeutralSecondary}`,
		padding: "24px",
		alignItems: "center",
		display: "flex",
		closeButton: {
			marginLeft: "auto",
		},
	},
};

export interface ChangeRequestBaseCardProps {
	changeRequest: ChangeRequestType | undefined;
	onCancel: () => void;
	onClose: () => void;
	onEdit: () => void;
}

export const ChangeRequestBaseCard = ({
	changeRequest,
	onClose,
	onCancel,
	onEdit,
}: ChangeRequestBaseCardProps) => {
	const currentRequester: User | undefined = useSelector(
		selectAuthenticatedUser
	);

	const isRequesterOwner = useMemo(() => {
		if (currentRequester && changeRequest) {
			return !!(
				currentRequester?.preferred_username?.includes(
					changeRequest?.requester?.gaia_id?.toUpperCase() || ""
				) ||
				(currentRequester?.preferred_username || "").toLowerCase() ===
					(changeRequest?.requester?.gaia_id || "").toLowerCase()
			);
		}
	}, [currentRequester, changeRequest]);

	return (
		<Box data-testId="change-request-drawer" sx={style.card}>
			<Box sx={style.header}>
				<Typography sx={style.header.title}>
					Modification Request
				</Typography>
				<Box sx={style.header.buttonGroup}>
					<If
						condition={
							![
								ChangeRequestStatus.VALID,
								ChangeRequestStatus.REJECTED,
							].includes(
								changeRequest?.status as ChangeRequestStatus
							) && isRequesterOwner
						}
					>
						<Button
							onClick={onEdit}
							startIcon={<ModeEditIcon />}
							color="primary"
							variant="outlined"
						>
							Edit request
						</Button>
					</If>
					<If condition={isRequesterOwner}>
						<Button
							onClick={onCancel}
							startIcon={<DeleteOutlineIcon />}
							color="error"
							variant="outlined"
						>
							Close request
						</Button>
					</If>
					<IconButton aria-label="close" onClick={onClose}>
						<CloseIcon />
					</IconButton>
				</Box>
			</Box>
			<Box sx={style.body}>
				<InfoBlock
					withBackground
					fullWidth
					head={[
						{
							label: changeRequest?.resource_type,
							value: changeRequest?.name,
						},
					]}
					info={[
						{
							label: "Requester",
							value: (
								<p style={{ textTransform: "capitalize" }}>
									{changeRequest?.requester?.first_name}{" "}
									{changeRequest?.requester?.last_name}
								</p>
							),
						},
						{
							label: "Validator",
							value: (
								<p style={{ textTransform: "capitalize" }}>
									{changeRequest?.validator?.first_name}{" "}
									{changeRequest?.validator?.last_name}
								</p>
							),
						},
						{
							label: "Request date",
							value: formatDateDDMMYYYY(
								changeRequest?.created_at
							),
						},
						{
							label: "Status",
							value: (
								<StatusIcon
									status={
										changeRequest?.status ||
										ChangeRequestStatus.PENDING
									}
								/>
							),
						},
					]}
				/>

				<ChangeRequestAlert changeRequest={changeRequest} />

				<Typography sx={style.body.title}>
					Requested modifications
				</Typography>
				<SimpleTable
					headers={[
						{ label: "Field name", accessor: "path" },
						{
							label: "Old value",
							accessor: "old_value",
							format: (value: any) =>
								typeof value === "object"
									? JSON.stringify(value)
									: value,
						},
						{
							label: "New value",
							accessor: "value",
							format: (value: any) =>
								typeof value === "object"
									? JSON.stringify(value)
									: value,
						},
					]}
					items={(changeRequest?.diff_json || []).map(
						(contractDiff) => {
							// Stringifying value in case of new values beeing a complete dict or list of dict
							return {
								...contractDiff,
								value: contractDiff.value,
								path: contractDiff.path.join("."),
							};
						}
					)}
				/>

				<Typography sx={style.body.title}>
					Requester comment:
				</Typography>
				<Typography sx={style.body.comment}>
					{changeRequest?.requester_message || "N/A"}
				</Typography>
			</Box>
			<Box sx={style.footer}>
				<FluidLink href={changeRequest?.resource_edit_path || ""}>
					Link to the resource
				</FluidLink>
				<PrimaryButton onClick={onClose} sx={style.footer.closeButton}>
					Close
				</PrimaryButton>
			</Box>
		</Box>
	);
};
