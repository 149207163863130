import AddCircleIcon from "@mui/icons-material/AddCircle";
import { Box, Chip, FormGroup } from "@mui/material";
import { PrimaryButton } from "../../../../common/components/CustomButton";
import { If } from "../../../../common/components/If";
import { Tender } from "../../../tender_page/tender.module";
import { PricingStatus, Pricing, Action } from "../../pricingListSlice";
import useIsAuthorized from "../../../../common/hooks/useIsAuthorized";
import { PricingRunStatusTransitions } from "../../pricingList.constant";
import { isUserAuthorizedToCreatePricings } from "../../../../common/authorization/tender.authorization";
import { TenderActionIcon } from "./TenderActionIcon";
import TuneIcon from "@mui/icons-material/Tune";
import { useAppDispatch } from "../../../../common/hooks/default";
import { Dispatch } from "react";
import {
	setFiltersDrawerOpened,
	setPersistedFilters,
} from "../../../filters/filtersSlice";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { getPathWithParameters, PATH } from "../../../../router-path";
import {
	Column,
	ColumnsSelector,
} from "../../../../common/components/ColumnsSelector/ColumnsSelector";
import { ColumnIds } from "../../hooks/columns/common";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import OpenInFullIcon from "@mui/icons-material/OpenInFull";
import { ShowHideProvisionColumnsButton } from "../../../../common/components/ShowHideProvisionColumnsButton/ShowHideProvisionColumnsButton";
import ButtonDropdown from "../../../../common/components/ButtonDropdown";
import { PricingStepperType } from "../../../pricing_request/hooks/usePricingStepper";
import { useSelector } from "react-redux";
import { selectPersistedFilters } from "../../../filters/filters.selector";
import useFetchFilters from "../../../../common/hooks/useFetchFilters";
import {
	buildPredicates,
	removeEmptyListsAndNullValues,
} from "../../../filters/utils";
import { CANCEL_FILTER } from "../../../filters/filters.constant";
import _ from "lodash";
import { CustomToggleButtonGroup } from "../../../../common/components/ToggleButtonGroup/ToggleButtonGroup";

interface TenderActionsProps {
	activeOnly: "all" | "active";
	setActiveOnly: (newValue: "all" | "active") => void;
	valueOrPercent: string;
	setValueOrPercent: (newValue: "value" | "percent") => void;
	canPrintUnderPricing: boolean;
	tender: Tender | undefined;
	pricings: Pricing[] | null | undefined;
	filterCount: number;
	selectedColumns: Column<ColumnIds>[];
	onColumnToggled: (column: Column<ColumnIds>) => void;
	availableColumns: Column<ColumnIds>[];
	actOnPricings: (action: Action) => void;
	onPricingsDownload: (
		tender_id: number,
		status_in: PricingStatus[],
		format: string,
		id_in?: number[] | null
	) => void;
}

export function TenderActions(props: TenderActionsProps) {
	const {
		activeOnly,
		setActiveOnly,
		valueOrPercent,
		setValueOrPercent,
		canPrintUnderPricing,
		tender,
		pricings,
		filterCount,
		selectedColumns,
		onColumnToggled,
		availableColumns,
		onPricingsDownload,
		actOnPricings,
	} = props;
	const dispatch: Dispatch<any> = useAppDispatch();
	const { data: filtersData } = useFetchFilters("Pricings", tender?.id);
	const location = useLocation();
	const navigate = useNavigate();
	const canCreatePricings = useIsAuthorized<Tender>(
		isUserAuthorizedToCreatePricings,
		tender
	);
	const persistedFilters = useSelector(selectPersistedFilters);
	function showActiveOnly(choice: any) {
		if (choice !== null) {
			setActiveOnly(choice);
			const filters = JSON.parse(
				persistedFilters.filters?.pricings_filters || "{}"
			);
			if (choice === "active") {
				filters.pricings_status_not_in = filters.pricings_status_not_in
					? [...filters.pricings_status_not_in, CANCEL_FILTER]
					: [CANCEL_FILTER];
			} else {
				filters.pricings_status_not_in =
					filters.pricings_status_not_in.filter(
						(e: any) => e.id !== CANCEL_FILTER.id
					);
			}
			const { predicates } = buildPredicates(
				filtersData,
				filters,
				"Pricings"
			);
			const filteredValues = removeEmptyListsAndNullValues(filters);
			const odataString = `"${predicates.join(" and ")}"`;
			const filtersToPersist = JSON.stringify(filteredValues);
			const params = new URLSearchParams();
			params.set("filters", filtersToPersist);
			dispatch(
				setPersistedFilters({
					...persistedFilters.filters,
					pricings_filters: filtersToPersist,
					pricings_filters_count: predicates.length.toString(),
					pricings_query: odataString,
				})
			);
			navigate("?" + params.toString());
		}
	}
	let pageUrl = `${getPathWithParameters(PATH.MAXIMIZED_TENDER_PRICING_RUN, {
		tenderId: tender?.id,
	})}`;

	if (location.pathname.includes("/maximized")) {
		pageUrl = `${getPathWithParameters(PATH.TENDER_PRICING_RUN, {
			tenderId: tender?.id,
		})}`;
	}

	return (
		<Box
			display="flex"
			justifyContent="space-between"
			sx={{ width: "100%", marginTop: "20px" }}
		>
			<Box display="flex" justifyContent="flex-start" alignItems="center">
				<CustomToggleButtonGroup
					groupValue={activeOnly}
					onValueChange={showActiveOnly}
					groupLabel="all-or-active-only"
					buttonValues={["all", "active"]}
					displayValues={["All", "Active only"]}
				/>
				<CustomToggleButtonGroup
					groupValue={valueOrPercent}
					onValueChange={setValueOrPercent}
					groupLabel="value-or-percentage"
					buttonValues={["value", "percent"]}
					displayValues={["Value", "%"]}
				/>
				<FormGroup>
					<PrimaryButton
						sx={{
							width: 140,
							marginRight: "30px",
						}}
						startIcon={<OpenInFullIcon />}
						type="button"
						color="secondary"
						id="column-maximized-button"
						role="link"
						href={pageUrl}
						text="Full screen"
						onClick={(e) => {
							e.preventDefault();
							navigate(pageUrl);
							return false;
						}}
					/>
				</FormGroup>
				<FormGroup>
					<PrimaryButton
						sx={{ marginRight: "30px" }}
						text="Export Pricings"
						type="button"
						color="secondary"
						disabled={pricings?.length === 0}
						onClick={() => {
							onPricingsDownload(
								tender?.id || 0,
								[],
								valueOrPercent
							);
						}}
					></PrimaryButton>
				</FormGroup>
				<If condition={canPrintUnderPricing}>
					<FormGroup>
						<PrimaryButton
							sx={{ marginRight: "30px" }}
							text="Export Under Offer"
							type="button"
							color="secondary"
							onClick={() => {
								onPricingsDownload(
									tender?.id || 0,
									[PricingStatus.UNDER_OFFER],
									valueOrPercent
								);
							}}
						></PrimaryButton>
					</FormGroup>
				</If>
				<FormGroup>
					<PrimaryButton
						text="Filters"
						type="button"
						color="secondary"
						data-testid="open-filters"
						sx={{
							width: 120,
							marginLeft: "10px",
						}}
						onClick={() =>
							dispatch(
								setFiltersDrawerOpened({
									opened: true,
								})
							)
						}
						startIcon={<TuneIcon />}
						endIcon={
							<Chip
								label={
									<div style={{ fontSize: "13px" }}>
										{filterCount}
									</div>
								}
								size="small"
							/>
						}
					/>
				</FormGroup>
				<FormGroup>
					<ColumnsSelector
						availableColumns={availableColumns}
						selectedColumns={selectedColumns}
						onColumnToggled={onColumnToggled}
					/>
				</FormGroup>
				<FormGroup>
					<ShowHideProvisionColumnsButton
						availableColumns={availableColumns}
						selectedColumns={selectedColumns}
						onColumnToggled={onColumnToggled}
					/>
				</FormGroup>
				<FormGroup>
					<PrimaryButton
						text="Pricing bricks description"
						type="button"
						role="link"
						href="https://confluence.tools.digital.engie.com/x/aq1LEw"
						color="secondary"
						sx={{
							marginLeft: "16px",
							width: 230,
						}}
						target="_blank"
					>
						<HelpOutlineOutlinedIcon />
					</PrimaryButton>
				</FormGroup>
				{(tender?.actions || []).map((action, index) => (
					<PrimaryButton
						key={index}
						sx={{ marginLeft: "15px" }}
						text={action.display_name}
						type="submit"
						color="primary"
						onClick={() => {
							actOnPricings(action);
						}}
					>
						<TenderActionIcon
							transition={
								action.action as PricingRunStatusTransitions
							}
						/>
					</PrimaryButton>
				))}
				{canCreatePricings && (
					<ButtonDropdown
						text="Add pricing"
						type="submit"
						color="primary"
						role="link"
						sx={{ marginLeft: "15px" }}
						items={[
							<Link
								key="power"
								to={`${getPathWithParameters(
									PATH.PRICING_REQUEST,
									{
										tenderId: tender?.id,
										type: _.kebabCase(
											PricingStepperType.POWER_AND_GOO
										),
									}
								)}?backPage=${location.pathname}`}
							>
								Power
							</Link>,
							<Link
								key="GOO Only"
								to={`${getPathWithParameters(
									PATH.PRICING_REQUEST,
									{
										tenderId: tender?.id,
										type: _.kebabCase(
											PricingStepperType.GOO_ONLY
										),
									}
								)}?backPage=${location.pathname}`}
							>
								GOO Only
							</Link>,
							<Link
								key="financial"
								to={`${getPathWithParameters(
									PATH.PRICING_REQUEST,
									{
										tenderId: tender?.id,
										type: _.kebabCase(
											PricingStepperType.FINANCIAL
										),
									}
								)}?backPage=${location.pathname}`}
							>
								Financial
							</Link>,
						]}
					>
						<AddCircleIcon sx={{ color: "#fff" }} />
					</ButtonDropdown>
				)}
			</Box>
		</Box>
	);
}
