import { OktaAuth, toRelativeUrl } from "@okta/okta-auth-js";
import { Security } from "@okta/okta-react";
import { useNavigate, useOutlet } from "react-router-dom";
import NotificationDrawer from "../notifications/Notifications";
import SocketProvider from "../../core/socketProvider";
import useMatomo from "../matomo/useMatomo";

const oktaAuth = new OktaAuth({
	issuer: process.env.REACT_APP_ISSUER,
	clientId: process.env.REACT_APP_CLIENT_ID,
	scopes: [
		"openid",
		"email",
		"profile",
		"api.ets.gepo",
		"api.trs.getdeals",
		"api.backtoearth2",
	],
	pkce: true,
	redirectUri: window.location.origin + "/login/callback",
});

export const AuthLayout = () => {
	const outlet = useOutlet();
	const navigate = useNavigate();

	if (process.env.REACT_APP_ENV === "prod") {
		useMatomo(oktaAuth);
	}

	const restoreOriginalUri = async (
		_oktaAuth: OktaAuth,
		originalUri: string
	) => {
		navigate(toRelativeUrl(originalUri || "/", window.location.origin), {
			replace: true,
		});
	};

	return (
		<Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
			<NotificationDrawer />
			<SocketProvider />

			{outlet}
		</Security>
	);
};
