import { NJFormItem } from "@engie-group/fluid-design-system-react";
import { Box, SxProps, Theme, Typography } from "@mui/material";
import { ErrorText } from "../ErrorText";
import { noop } from "../../utils/operations";

const style = {
	container: {
		width: 162,
	},
};

export interface FluidNumberProps {
	onChange: (event: any) => void;
	onBlur?: () => void;
	name: string;
	title: string;
	value: number | undefined;
	description?: string;
	errorMessage?: string;
	sx?: SxProps<Theme>;
	isDisabled?: boolean;
	isReadOnly?: boolean;
}

export const FluidNumber = ({
	name,
	title,
	value,
	description,
	errorMessage = "",
	onChange,
	onBlur = noop,
	sx,
	isDisabled,
	isReadOnly: readOnly = false,
}: FluidNumberProps) => {
	return (
		<Box sx={{ ...style.container, ...sx }}>
			<NJFormItem
				id={name}
				label={title}
				labelKind="static"
				name={name}
				type="number"
				// @ts-ignore Typing issue coming from package, expecting string...
				value={value}
				onBlur={onBlur}
				onChange={onChange}
				title={title}
				wrap="soft"
				isDisabled={isDisabled}
				readOnly={readOnly}
			/>
			<Typography variant="subtitle2">{description}</Typography>
			<ErrorText>{errorMessage}</ErrorText>
		</Box>
	);
};
