import React, { useCallback, useMemo } from "react";
import { Timeserie, TimeseriesList } from "../../tenderForm.module";
import {
	Table,
	TableContainer,
	Paper,
	TableHead,
	TableRow,
	TableCell,
	TableBody,
	Button,
} from "@mui/material";
import { secondaryColor } from "../../../../core/theme";
import { PortfolioTimeserieConfigTableRow } from "./PortfolioTimeserieConfigTableRow";
import { TSInUse } from "./TimeseriesTable";
import { Site } from "../../../sites/sites.module";

interface Portolio {
	name: string;
	id: number;
	sites: Site[];
}

export interface PortfolioTimeserieConfigTableProps {
	portfolio: Portolio;
	timeseries: TimeseriesList[];
	portfolioTsInUse: Record<string, Record<string, Timeserie>>;
	setPortfolioTsInUse: (
		newValue: Record<string, Record<string, Timeserie>>
	) => void;
	sitesTsInUses: Record<string, TSInUse>;
}

const style = {
	title: {
		textAlign: "center",
	},
	tableContainer: {
		backgroundColor: "transparent",
		marginTop: "42px",
	},
	headerCell: {
		backgroundColor: secondaryColor,
		color: "#000",
		fontWeight: 700,
		fontSize: 12,
		paddingLeft: 0,
		"& th": {
			fontWeight: 700,
			padding: "12px 16px",
		},
	},
	table: {
		tableLayout: "fixed",
		minWidth: 650,
		border: "1px solid rgba(0, 0, 0, .1)",
	},
	row: {
		border: "1px solid rgba(0, 0, 0, .1)",
	},
};

export function PortfolioTimeserieConfigTable(
	props: PortfolioTimeserieConfigTableProps
) {
	const currentPortofolioTsInUses = useMemo<any>(
		() => props.portfolioTsInUse[props.portfolio.id] || {},
		[props.portfolioTsInUse, props.portfolio]
	);

	const onReset = useCallback(() => {
		const newValue: any = {};
		props.portfolio.sites.forEach((site) => {
			if (site.asset_id) {
				const tsInUse = props.sitesTsInUses[site.asset_id];
				const availableSites = props.timeseries.filter(
					(timeserie) =>
						timeserie.site_info.asset_id === site.asset_id
				)?.[0];
				if (availableSites) {
					const availableTs = availableSites.ts.filter(
						(ts) => ts.tourbillon_tag === tsInUse.tourbillon_tag
					)?.[0];
					if (availableTs) {
						newValue[site.id] = availableTs;
					}
				}
			}
		});
		const newUse = {
			...props.portfolioTsInUse,
			[props.portfolio.id]: newValue,
		};
		props.setPortfolioTsInUse(newUse);
	}, [
		props.portfolio,
		props.setPortfolioTsInUse,
		props.sitesTsInUses,
		props.portfolioTsInUse,
	]);

	return (
		<TableContainer component={Paper} sx={style.tableContainer}>
			<Table aria-label="simple table" sx={style.table}>
				<TableHead>
					<TableRow sx={style.headerCell}>
						<TableCell
							sx={style.title}
							component="th"
							scope="col"
							colSpan={5}
						>
							Portfolio {props.portfolio.name}
						</TableCell>
						<TableCell sx={style.title} component="th" scope="col">
							<Button onClick={onReset}>Reset</Button>
						</TableCell>
					</TableRow>
					<TableRow sx={style.headerCell}>
						<TableCell component="th" scope="col">
							TPR Id
						</TableCell>
						<TableCell component="th" scope="col">
							Name
						</TableCell>
						<TableCell component="th" scope="col">
							Country
						</TableCell>
						<TableCell component="th" scope="col">
							Technology
						</TableCell>
						<TableCell component="th" scope="col">
							Installed Capacity
						</TableCell>
						<TableCell component="th" scope="col">
							Timeseries
						</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{props.timeseries.map((timeserie) => (
						<PortfolioTimeserieConfigTableRow
							key={timeserie.site_info.id}
							timeserie={timeserie}
							tsInUses={currentPortofolioTsInUses}
							setTsInUses={(value: Record<string, Timeserie>) => {
								props.setPortfolioTsInUse({
									...props.portfolioTsInUse,
									[props.portfolio.id]: value,
								});
							}}
						/>
					))}
				</TableBody>
			</Table>
		</TableContainer>
	);
}
