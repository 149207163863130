import { useState } from "react";
import AddAssetsNumberModal from "./AddAssetsNumberModal";
import AddAssetsFormModal from "./AddAssetsFormModal";
import {
	AssetType,
	ServicePointType,
	TechnologyType,
} from "../../../../../requests_cm/gecoSitesService/types";
import { FormikValues } from "formik";
import { GenericReferential } from "../../../../../requests_cm/gecoReferentialService/types";
import { AssetTable } from "./AssetTable";

const AddAssetsModal = ({
	open,
	onClose,
	site,
	servicePoints,
	updateSiteAssets,
	machines,
}: {
	open: boolean;
	onClose: () => void;
	site: { name: string; country: string; technology: string };
	servicePoints: ServicePointType[];
	updateSiteAssets: (newAssets: [FormikValues]) => void;
	machines: GenericReferential[];
}) => {
	const [numberOfAssets, setNumberOfAssets] = useState(1);
	const [assetsNumberConfirmed, setAssetsNumberConfirmed] = useState(false);

	return (
		<>
			<AddAssetsNumberModal
				numberOfAssets={numberOfAssets}
				setAssetsNumber={setNumberOfAssets}
				open={open && !assetsNumberConfirmed}
				onClose={onClose}
				onConfirm={() => setAssetsNumberConfirmed(true)}
			/>
			<AddAssetsFormModal
				site={site}
				servicePoints={servicePoints}
				open={open && assetsNumberConfirmed && numberOfAssets == 1}
				onClose={() => {
					setAssetsNumberConfirmed(false);
					onClose();
				}}
				upsertSingleAsset={(asset) => updateSiteAssets([asset])}
				machines={machines}
			/>
			<AssetTable
				onSubmit={(data: { items: AssetType[] }) => {
					//@ts-ignore
					updateSiteAssets(data.items);
				}}
				technology={site.technology as TechnologyType}
				assets={[]}
				initialEmptyAssetNumber={numberOfAssets}
				isOpen={open && assetsNumberConfirmed && numberOfAssets > 1}
				onClose={() => {
					setAssetsNumberConfirmed(false);
					onClose();
				}}
				site={site}
				machines={machines}
				servicePoints={servicePoints}
			/>
		</>
	);
};

export default AddAssetsModal;
