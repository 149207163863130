import { Box, TableCell, TableCellProps } from "@mui/material";
import { memo, useEffect, useMemo, useRef, useState } from "react";
import * as _ from "lodash";

const ANIM_DURATION = 200;

export interface FlashUpdateTableCellProps extends TableCellProps {
	field: any;
	isUpdate?: boolean;
	componentType?: "table-cell" | "box";
}

function FlashUpdateTableCellComponent(props: FlashUpdateTableCellProps) {
	const { field, isUpdate, componentType, ...cellProps } = props;
	const ref = useRef<any>(field);
	const rendered = useRef(false);
	const [flash, setFlash] = useState(false);

	const component = componentType ?? "table-cell";

	useEffect(() => {
		if (!isUpdate) {
			setFlash(false);

			return () => {};
		}

		if (!rendered.current || !_.isEqual(field, ref.current)) {
			setFlash(true);
			const timeoutInterval = setTimeout(() => {
				setFlash(false);
			}, ANIM_DURATION);

			return () => {
				clearTimeout(timeoutInterval);
			};
		}

		ref.current = field;
		rendered.current = true;
	}, [field]);

	const sx = useMemo(
		() => ({
			...(cellProps.sx || {}),
			backgroundColor: flash ? "rgba(0, 255, 0, 1)" : "unset",
		}),
		[cellProps, flash]
	);

	return component === "table-cell" ? (
		<TableCell
			{...cellProps}
			sx={sx}
			style={{ willChange: "background-color" }}
		>
			{props.children}
		</TableCell>
	) : (
		<Box {...cellProps} sx={sx} style={{ willChange: "background-color" }}>
			{props.children}
		</Box>
	);
}

export const FlashUpdateTableCell = memo(FlashUpdateTableCellComponent);
