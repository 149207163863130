import { WsEventTypes } from "../../common/constants/wsEvents";
import {
	apiDelete,
	apiGet,
	apiPatch,
	apiPost,
} from "../../common/utils/request.utils";
import { RootState } from "../../core/rootReducers";
import wsPublisher$ from "../../core/wsPublisher";
import { AppDispatch } from "../../store";
import {
	selectPersistedFilters,
	selectTenderFilters,
	selectTenderWorkflowViewFilters,
} from "../filters/filters.selector";
import {
	applyLinqPredicates,
	buildPredicates,
	setSelectedFilterValues,
} from "../filters/utils";
import { TSData, Tender } from "./tender.module";
import {
	getTendersError,
	setTenderLoading,
	getTendersSuccess,
	updateTenderBlotter,
	setTenderPricings,
	setPricingsByTenderIsLoading,
	setTenders,
	setTenderActionLoading,
	setTsImporterLoading,
	tsImporterSuccess,
	tsImporterError,
	tsImporterWarning,
	getTenderWorkflowSuccess,
	setloader,
	setTenderWorkflow,
	setTenderComments,
	TenderWorkflowView,
} from "./tenderSlice";
import { enqueueSnackbar } from "notistack";
import * as liveDataUpdate from "../live_update/LiveUpdate.merge";
import { selectTenders, selectWorkflowList } from "./tender.selector";
import { Action, TenderStatus } from "../pricing_list/pricingListSlice";
import * as _ from "lodash";
import {
	mapTenderToWorkflowView,
	workflowViewDefaultOrders,
} from "./utils/workflowView.util";

export function getTenders(
	page: number,
	search: string = "",
	perPage: number = 20,
	latestUpdatedAtParam: Date | undefined = undefined
): any {
	return function (dispatch: AppDispatch, getState: any) {
		dispatch(setTenderLoading());
		const state = getState();

		const tenderQuery = state.filters.persistedFilters.tenders_query;

		return apiGet(
			`tenders?page=${page}&per_page=${perPage}&search=${search}&filters=${tenderQuery}&sort_by=id&order=desc` +
				(latestUpdatedAtParam
					? `&latest_updated_at=${latestUpdatedAtParam}`
					: "")
		).then(
			(tenders) => {
				return dispatch(
					getTendersSuccess({
						tenders: tenders.data.data,
						latestUpdatedAt:
							tenders.data.max_updated_at_tender_date,
						latestUpdatedAtParam,
						hasNextPage: tenders.data.has_next_page,
					})
				);
			},
			(error) => {
				return dispatch(getTendersError(error.message));
			}
		);
	};
}

export function getTendersForWorkflowView(
	odataQuery: string | undefined = ""
): any {
	return async function (dispatch: AppDispatch) {
		dispatch(setloader({ key: "workflowView", value: true }));
		try {
			const responses = await Promise.all(
				workflowViewDefaultOrders.map((defaultOrder) => {
					const params = new URLSearchParams({
						statuses: defaultOrder.statuses.toString(),
						order: defaultOrder.order,
						order_column: defaultOrder.orderColumn,
					});
					let url = `tenders-workflow-view?${params}`;
					if (odataQuery !== "") {
						url += `&filters=${odataQuery}`;
					}
					return apiGet(url);
				})
			);
			const responseArray = responses.map((re) => re.data.data);
			return dispatch(
				getTenderWorkflowSuccess(
					_.keyBy(responseArray.flat(1) || [], "status")
				)
			);
		} finally {
			dispatch(setloader({ key: "workflowView", value: false }));
		}
	};
}

export function updateTenderStatus(tender_id: number, status: string) {
	return async function () {
		try {
			await apiPatch(`/tenders/${tender_id}/transition/${status}`, {});
		} catch {
			enqueueSnackbar("Error updating tender status", {
				variant: "error",
				autoHideDuration: 3000,
			});
		}
	};
}

export function loadTenderPricings(tenderId: number) {
	return async function (dispatch: AppDispatch) {
		dispatch(setPricingsByTenderIsLoading({ tenderId, isLoading: true }));
		try {
			const response = await apiGet(
				`pricing?tender_id=${tenderId}&page=1&per_page=11`
			);
			dispatch(
				setTenderPricings({ tenderId, pricings: response.data.data })
			);
		} finally {
			dispatch(
				setPricingsByTenderIsLoading({ tenderId, isLoading: false })
			);
		}
	};
}

const actionMessageStarted = (
	tender_ids: number[],
	action: Action,
	user_name: string | undefined
) => {
	return `Starting process ${action.display_name} on ${tender_ids.length} tenders by ${user_name} started`;
};

const actionMessageDone = (action: Action) => {
	return `Process ${action.display_name} succeeded`;
};

export function updateTenders(
	tender_ids: number[],
	action: Action,
	user_name: string | undefined
) {
	enqueueSnackbar(actionMessageStarted(tender_ids, action, user_name), {
		variant: "warning",
		autoHideDuration: 5000,
	});
	return async function (dispatch: AppDispatch) {
		const endpoint = action.endpoint;
		try {
			dispatch(setTenderActionLoading(true));
			const res = await apiPatch(`${endpoint}`, {
				...action,
				tender_ids,
			});
			enqueueSnackbar(actionMessageDone(action), {
				variant: "success",
				autoHideDuration: 5000,
			});
			res?.data?.data?.map((tender: Tender) => {
				wsPublisher$.publish({
					type: WsEventTypes.TENDER_UPDATED,
					data: { ...tender, isNewUpdate: true },
					at: "" + new Date(),
				});
			});
			dispatch(updateTenderBlotter(res.data));
		} finally {
			dispatch(setTenderActionLoading(false));
		}
	};
}

export function mergeIncomingTenders(incomingTenders: Tender[]) {
	return function (dispatch: AppDispatch, getState: () => RootState) {
		const tenders = selectTenders(getState());
		const tendersFiltersData = selectTenderFilters(getState());
		const persistedFilters = selectPersistedFilters(getState());
		const tenderValues = setSelectedFilterValues(
			tendersFiltersData,
			persistedFilters.filters?.tenders_filters ?? ""
		);

		const tenderPredicates = buildPredicates(
			tendersFiltersData,
			tenderValues,
			"Tenders"
		);

		dispatch(
			setTenders(
				liveDataUpdate.mergeIncomingTenders(
					tenders,
					incomingTenders,
					[],
					tenderPredicates.linqPredicates
				)
			)
		);
	};
}

export function mergeIncomingTenderUpdates(incomingTenders: Tender[]) {
	return function (dispatch: AppDispatch, getState: () => RootState) {
		const tenders = selectTenders(getState());
		const tenderIds = new Set(tenders.map((tender) => tender.id));
		const incomingTendersIntersection = incomingTenders.filter((tender) =>
			tenderIds.has(tender.id)
		);
		if (incomingTendersIntersection.length) {
			dispatch(mergeIncomingTenders(incomingTendersIntersection));
		}
	};
}

const mergeWorkflowView = (
	tender: Tender,
	prevColumn: TenderWorkflowView[],
	workflowList: Record<TenderStatus, TenderWorkflowView>
) => {
	// case: the tender updated and the status changed
	if (prevColumn.length > 0 && prevColumn[0].status !== tender.status) {
		let prevTender = _.remove(prevColumn[0].tenders, (t) => {
			return t.id === tender.id;
		});
		if (prevTender.length === 0) return;
		workflowList[prevColumn[0].status] = {
			...prevColumn[0],
			count: prevColumn[0].count - 1,
			tenders: prevColumn[0].tenders,
		};
		prevTender[0] = mapTenderToWorkflowView(tender);

		workflowList[tender.status] = {
			...workflowList[tender.status],
			count: workflowList[tender.status].count + 1,
			tenders: [...workflowList[tender.status].tenders, prevTender[0]],
		};
	}
	// case: the tender was updated but the status did not change
	else if (prevColumn.length > 0 && prevColumn[0].status === tender.status) {
		if (tender.archived) {
			_.remove(prevColumn[0].tenders, (t) => {
				return t.id === tender.id;
			});
			workflowList[tender.status] = {
				...prevColumn[0],
				count: prevColumn[0].count - 1,
			};
		} else {
			let prevTenderIndex = _.findIndex(prevColumn[0].tenders, (t) => {
				return t.id === tender.id;
			});
			if (prevTenderIndex === -1) return;
			prevColumn[0].tenders[prevTenderIndex] =
				mapTenderToWorkflowView(tender);
			workflowList[tender.status] = {
				...workflowList[tender.status],
				tenders: prevColumn[0].tenders,
			};
		}
	}
	// case: new tender was created
	else {
		if (!tender.archived)
			workflowList[tender.status] = {
				...workflowList[tender.status],
				tenders: [
					mapTenderToWorkflowView(tender),
					...workflowList[tender.status].tenders,
				],
				count: workflowList[tender.status].tenders.length + 1,
			};
	}
	return workflowList;
};

export function updateWorkflowView(incomingTenders: Tender[]) {
	return function (dispatch: AppDispatch, getState: () => RootState) {
		let workflowList = _.cloneDeep(selectWorkflowList(getState()));

		const workflowViewFiltersData = selectTenderWorkflowViewFilters(
			getState()
		);
		const persistedFilters = selectPersistedFilters(getState());
		const workflowViewValues = setSelectedFilterValues(
			workflowViewFiltersData,
			persistedFilters.filters?.tenders_workflow_view_filters ?? ""
		);
		const predicates = buildPredicates(
			workflowViewFiltersData,
			workflowViewValues,
			"Tenders Workflow view"
		);

		if (_.some(workflowList, "count")) {
			for (const tender of incomingTenders) {
				let prevColumn = _.cloneDeep(
					_.filter(workflowList, {
						tenders: [{ id: tender.id }],
					})
				);

				const filteredTenders = applyLinqPredicates(
					[tender],
					predicates.linqPredicates
				);
				// tender does not meet filters
				if (filteredTenders.length === 0) {
					// It's in the store remove it
					if (prevColumn.length > 0) {
						_.remove(prevColumn[0].tenders, (t) => {
							return t.id === tender.id;
						});
						workflowList[tender.status] = {
							...prevColumn[0],
							count: prevColumn[0].count - 1,
						};
						dispatch(setTenderWorkflow(workflowList));
						continue;
					} else {
						// Not in the store ? skip  it
						continue;
					}
				}
				const mergedWorkFlowList = mergeWorkflowView(
					tender,
					prevColumn,
					workflowList
				);
				if (mergedWorkFlowList) {
					workflowList = mergedWorkFlowList;
				}
			}
		}
		dispatch(setTenderWorkflow(workflowList));
	};
}

export function tsImporter(values: {
	tsInfo: { [key: string]: TSData };
	ignoreWarning: boolean;
}): any {
	let mappedTsInfo: any = Object.values(values.tsInfo);
	mappedTsInfo = mappedTsInfo.map((element: any) => ({
		asset_id: element.assetId,
		file_id: element.fileId,
		sheet_name: element.sheetName,
		production_col: element.productionCol,
		index_date: element.indexDate,
		index_time: element.indexTime,
		unit: element.unit,
		timezone: element.timezone,
		id: element.id,
		offset: element.offset,
	}));
	mappedTsInfo = _.groupBy(Object.values(mappedTsInfo), "asset_Id");
	return function (dispatch: AppDispatch) {
		dispatch(setTsImporterLoading(true));
		return apiPost("timeseries/ts-importer", {
			ts_list: Object.values(mappedTsInfo),
			ignore_warning: values.ignoreWarning,
		}).then(
			(t) => {
				if (t.data?.warnings?.length > 0) {
					dispatch(tsImporterWarning(t.data?.warnings));
				} else if (t.data?.errors?.length > 0) {
					dispatch(tsImporterError(t.data?.errors));
				} else {
					dispatch(tsImporterSuccess(t.data?.ts_list));
				}
				dispatch(tsImporterError(undefined));
				dispatch(tsImporterWarning(undefined));
				dispatch(setTsImporterLoading(false));
				return;
			},
			(error: any) => {
				dispatch(tsImporterError(error.response.data.errors));
				return;
			}
		);
	};
}

export function getFileSheetNames(file_id: number, rowId: string) {
	return function (dispatch: AppDispatch) {
		return apiGet(`/files/sheet-names/${file_id}`).then(
			(t) => {
				return t.data?.list;
			},
			() => {
				dispatch(
					tsImporterError([
						{ [rowId]: "Unable to load sheet name for this file" },
					])
				);
				return [];
			}
		);
	};
}

export function getCommentsByTender(
	tenderId: number,
	page: number,
	perPage: number = 20,
	init_page: boolean = false
) {
	return async function (dispatch: AppDispatch) {
		dispatch(setloader({ key: "tenderComments", value: true }));
		try {
			const repsonse = await apiGet(
				`/comments/tender/${tenderId}?page=${page}&per_page=${perPage}`
			);

			return dispatch(
				setTenderComments({ ...repsonse.data, init_page: init_page })
			);
		} finally {
			dispatch(setloader({ key: "tenderComments", value: false }));
		}
	};
}

export function createNewComment(tenderId: number, comment: string) {
	return async function (dispatch: AppDispatch) {
		dispatch(setloader({ key: "tenderComments", value: true }));
		try {
			await apiPost(`/comments/tender/${tenderId}`, {
				comment: comment,
			});
			dispatch(getCommentsByTender(tenderId, 1, 20, true));
		} finally {
			dispatch(setloader({ key: "tenderComments", value: false }));
		}
	};
}
export function deleteComment(commentId: number, tenderId: number) {
	return async function (dispatch: AppDispatch) {
		dispatch(setloader({ key: "tenderComments", value: true }));
		try {
			await apiDelete(`/comments/${commentId}`);
			dispatch(getCommentsByTender(tenderId, 1, 20, true));
		} finally {
			dispatch(setloader({ key: "tenderComments", value: false }));
		}
	};
}

export default {
	getTenders,
	tsImporter,
};
