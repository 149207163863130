import _ from "lodash";
import { OtClickPeriods } from "../../requests_cm/gecoReferentialService/types";
// TimePeriodOptions help user select a start and an end date.
// The dates are provided depending on 3 types of selections, Monthly, Quaterly and yearly.
// TimePeriodOptions generates options as follow:
// if Monthly : label Jan26, value: 01-01-2026@01-02-2026
// if Quaterly: Q226, 01-04-2026@01-07-2026
// if Yearly: 2027, 01-01-2027@01-01-2028
// Options can be restraints by max start date and a max end date
// Options can be also restrains that start date cannot before today.

// Restrictions works on all level for example, the min start date is 01/01/2024, the max end is 01/01/2025 and today is the 22/11/2024,
// the Monthly options will only be DEC2024, the quaterly option will be empty (since the next quarter is Q125 but the max date is 01/01/2025) and yearly option will also be empty

export interface Option {
	label: string;
	value: string;
}

export interface PreOption {
	label: string;
	start: string;
	end: string;
}

export type TimePeriodOptions = Record<OtClickPeriods, Option[]>;

const MONTHS = [
	"JAN",
	"FEB",
	"MAR",
	"APR",
	"MAY",
	"JUN",
	"JUL",
	"AUG",
	"SEP",
	"OCT",
	"NOV",
	"DEC",
];
const QUARTERS = ["Q1", "Q2", "Q3", "Q4"];

const retrieveYear = (date: string) => date.split("-")[2];

const getYears = (startYearInclusive: number, endDateInclusive: number) => {
	const years = [];
	for (let i = startYearInclusive; i < endDateInclusive + 1; i += 1) {
		years.push(i);
	}
	return years;
};

const mapYearsToYearsOptions = (yearList: number[]) => {
	const options: PreOption[] = [];
	for (const year of yearList) {
		options.push({
			label: `${year}`,
			start: `01-01-${year}`,
			end: `01-01-${year + 1}`,
		});
	}
	return options;
};

const mapYearsToQuarterOptions = (yearList: number[]): PreOption[] => {
	let options: PreOption[][] = [];
	for (const year of yearList) {
		options.push([
			{
				label: `${QUARTERS[0]}-${year}`,
				start: `01-01-${year}`,
				end: `01-04-${year}`,
			},
			{
				label: `${QUARTERS[1]}-${year}`,
				start: `01-04-${year}`,
				end: `01-07-${year}`,
			},
			{
				label: `${QUARTERS[2]}-${year}`,
				start: `01-07-${year}`,
				end: `01-10-${year}`,
			},
			{
				label: `${QUARTERS[3]}-${year}`,
				start: `01-10-${year}`,
				end: `01-01-${year + 1}`,
			},
		]);
	}
	return _.flatten(options);
};

const mapYearsToMonthsOptions = (yearList: number[]): PreOption[] => {
	let options: PreOption[][] = [];
	for (const year of yearList) {
		options.push([
			{
				label: `${MONTHS[0]}-${year}`,
				start: `01-01-${year}`,
				end: `01-02-${year}`,
			},
			{
				label: `${MONTHS[1]}-${year}`,
				start: `01-02-${year}`,
				end: `01-03-${year}`,
			},
			{
				label: `${MONTHS[2]}-${year}`,
				start: `01-03-${year}`,
				end: `01-04-${year}`,
			},
			{
				label: `${MONTHS[3]}-${year}`,
				start: `01-04-${year}`,
				end: `01-05-${year}`,
			},
			{
				label: `${MONTHS[4]}-${year}`,
				start: `01-05-${year}`,
				end: `01-06-${year}`,
			},
			{
				label: `${MONTHS[5]}-${year}`,
				start: `01-06-${year}`,
				end: `01-07-${year}`,
			},
			{
				label: `${MONTHS[6]}-${year}`,
				start: `01-07-${year}`,
				end: `01-08-${year}`,
			},
			{
				label: `${MONTHS[7]}-${year}`,
				start: `01-08-${year}`,
				end: `01-09-${year}`,
			},
			{
				label: `${MONTHS[8]}-${year}`,
				start: `01-09-${year}`,
				end: `01-10-${year}`,
			},
			{
				label: `${MONTHS[9]}-${year}`,
				start: `01-10-${year}`,
				end: `01-11-${year}`,
			},
			{
				label: `${MONTHS[10]}-${year}`,
				start: `01-11-${year}`,
				end: `01-12-${year}`,
			},
			{
				label: `${MONTHS[11]}-${year}`,
				start: `01-12-${year}`,
				end: `01-01-${year + 1}`,
			},
		]);
	}
	return _.flatten(options);
};

const formatMonthOrDayInString = (value: number): string => {
	return value < 10 ? "0" + value : `${value}`;
};

const getTodayInDayMonthYearFormat = () => {
	const now = Date.now();
	// date now for testing purposes
	const today = new Date(now);
	const yyyy = today.getFullYear();
	let mm = today.getMonth() + 1; // Months start at 0!
	let dd = today.getDate();

	return (
		formatMonthOrDayInString(dd) +
		"-" +
		formatMonthOrDayInString(mm) +
		"-" +
		yyyy
	);
};

export const getDateFromDayMonthYearFormat = (ddMmYyyy: string) => {
	const [day, month, year] = ddMmYyyy.split("-").map(Number);
	return new Date(year, month - 1, day);
};

export const mapYearMonthDayToDayMonthYear = (yyyyMmDd: string) => {
	const [year, month, day] = yyyyMmDd.split("-").map(Number);
	return (
		formatMonthOrDayInString(day) +
		"-" +
		formatMonthOrDayInString(month) +
		"-" +
		year
	);
};

export const MapDayMonthYearToYearMonthDay = (yyyyMmDd: string) => {
	const [day, month, year] = yyyyMmDd.split("-").map(Number);
	return (
		year +
		"-" +
		formatMonthOrDayInString(month) +
		"-" +
		formatMonthOrDayInString(day)
	);
};

const mapPreOptionsToOptions = (preOptions: PreOption[]): Option[] => {
	return preOptions.map((preOption: PreOption) => ({
		label: preOption.label,
		value: `${preOption.start}@${preOption.end}`,
	}));
};

const clampingPreOptions = (
	options: PreOption[],
	startDateClamp: string,
	endDateClamp: string
) => {
	const condition = (option: PreOption) => {
		const isGreaterThanStart =
			getDateFromDayMonthYearFormat(option.start) >=
			getDateFromDayMonthYearFormat(startDateClamp);
		const isLowerThanEnd =
			getDateFromDayMonthYearFormat(option.end) <=
			getDateFromDayMonthYearFormat(endDateClamp);
		return isGreaterThanStart && isLowerThanEnd;
	};
	return _.filter(options, condition);
};

export const timePeriodOptions = (
	minStartDate: string,
	maxEndate: string,
	isCapToToday: boolean = false
): TimePeriodOptions => {
	const today = getTodayInDayMonthYearFormat();

	if (
		isCapToToday &&
		getDateFromDayMonthYearFormat(today) >
			getDateFromDayMonthYearFormat(minStartDate)
	) {
		minStartDate = today;
	}

	const initialYear = retrieveYear(minStartDate);
	const finalYear = retrieveYear(maxEndate);

	const years = getYears(Number(initialYear), Number(finalYear));

	const allYearly = mapYearsToYearsOptions(years);
	const allQuarters = mapYearsToQuarterOptions(years);
	const allMonthly = mapYearsToMonthsOptions(years);

	const clampedYear = clampingPreOptions(allYearly, minStartDate, maxEndate);
	const clampedQuarter = clampingPreOptions(
		allQuarters,
		minStartDate,
		maxEndate
	);
	const clampedMonthly = clampingPreOptions(
		allMonthly,
		minStartDate,
		maxEndate
	);

	return {
		[OtClickPeriods.CalYear]: mapPreOptionsToOptions(clampedYear),
		[OtClickPeriods.Month]: mapPreOptionsToOptions(clampedMonthly),
		[OtClickPeriods.Quarter]: mapPreOptionsToOptions(clampedQuarter),
		[OtClickPeriods.TradePeriod]: [],
	};
};
