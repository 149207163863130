import { ReactComponent as WindOffshoreIcon } from "../assets/icons/windTurbineOffshore.svg";
import { ReactComponent as WindTurbineIcon } from "../assets/icons/windTurbine.svg";
import { ReactComponent as SolarPanelIcon } from "../assets/icons/solarPanel.svg";
import { TechnologyType } from "../../requests_cm/gecoSitesService/types";

export interface TechnologyIconProps {
	technology: TechnologyType;
}

const TechnologyIcon = ({ technology }: TechnologyIconProps) => {
	if (technology === TechnologyType.Solar) {
		return <SolarPanelIcon />;
	}

	if (technology === TechnologyType.WindOffshore) {
		return <WindOffshoreIcon />;
	}

	if (technology === TechnologyType.WindOnshore) {
		return <WindTurbineIcon />;
	}

	return null;
};

export default TechnologyIcon;
