import {
	PersistedFilters,
	setPersistedFilters,
} from "../../features/filters/filtersSlice";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

export const usePersistedFilters = (): PersistedFilters | undefined => {
	const dispatch = useDispatch();
	const [filters, setFilters] = useState<PersistedFilters>();
	useEffect(() => {
		// This hook will be usefull if we decide to use local storage again.
		// we might need to version this information (in case we need to overwrite it following breaking change)
		const values = {
			tenders_query: "",
			tenders_filters: "",
			tenders_filters_count: 0,
			tenders_filter_template: "",
			tenders_workflow_view_query: "",
			tenders_workflow_view_filters: "",
			tenders_workflow_view_filters_count: 0,
			tenders_workflow_view_filters_template: "",
			pricings_query: "",
			pricings_filters: "",
			pricings_filters_count: 0,
			pricings_filter_template: "",
		};

		setFilters(values);
		dispatch(setPersistedFilters(values));
	}, []);

	return filters;
};
