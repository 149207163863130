import { UploadedFile } from "../../common/files/files.module";
import { Portfolio, Site } from "../sites/sites.module";

export interface PortfolioCreation {
	id?: number;
	name: string;
	sites?: SelectedSite[];
}

export enum TsSource {
	REALIZED_ENDPOINT,
	GECO,
	RENEWEX,
	TS_IMPORTER,
	TOURBILLON,
	CLUSTER,
}

export enum TenderGraphStatus {
	NEW,
	PENDING,
	READY,
	ERROR,
}

export interface Timeserie {
	name: string;
	id: number;
	source: "REALIZED_ENDPOINT" | "GECO" | "RENEWEX" | "TS_IMPORTER";
	status: string;
	can_be_cleaned?: boolean;
	has_warnings?: boolean;
	can_cleaning_be_retried?: boolean;
	cleaning_error?: string;
	tourbillon_tag?: string;
	warnings?: { check: boolean; message: string }[];
}

export interface TimeseriesList {
	site_info: Site;
	ts: Timeserie[];
}

interface TenderCommon {
	id?: number;
	account: {
		id: number;
		external_id: string;
		name: string;
	};
	name: string;
	validity_date: string;
	due_date: string;
	direction: string;
	transaction_type: string;
	historicalDataIds: number[];
	realized_data_will_be_added?: boolean;
	pricing_stage: string;
	has_negative_price_clause?: boolean;
	is_balancing_responsible_party?: boolean;
}

export interface TenderCreation extends TenderCommon {
	sites: SelectedSite[];
	portfolios: PortfolioCreation[];
}

export interface PortfolioReception {
	id: number;
	name: string;
	siteIds: number[];
}

export interface TenderReception extends TenderCommon {
	sites: Site[];
	portfolios: Portfolio[];
}

export interface SiteInCreation {
	name: string;
	country: string;
	capacity: number | "";
	latitude: number | "";
	longitude: number | "";
	commercial_operation_date?: string;
	technology: string;
	gps_type: string;
	hub_height?: number | "";
	azimuth?: number | "";
	tilt_angle?: number | "";
	ignore_duplicates?: boolean;
}

export interface DuplicateWarningReason {
	is_same_techno?: boolean;
	name_is_close?: boolean;
	is_geographically_close?: boolean;
	capacity_is_similar?: boolean;
}

export interface Duplicate {
	site: string;
	reason: string;
}

export interface SiteImportWarning {
	line_no: number;
	duplicates: Duplicate[];
}

export interface SiteImportFile extends UploadedFile {
	warnings: SiteImportWarning[];
}

export interface TenderInit {
	id?: number;
	sites?: (Site | Portfolio)[];
	portfolios?: Portfolio[];
	historical_data?: File[];
}
export interface SelectedSite {
	id: number;
	is_master_site?: boolean;
}

export function getTimeserieStatusColor(ts: Timeserie) {
	if (ts.status === "RAW") {
		return "black";
	}
	if (ts.status === "CLEANED") {
		return "green";
	}
	return "#007ACD";
}
