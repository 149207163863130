import { Box, Button } from "@mui/material";
import { Close, Refresh } from "@mui/icons-material";
import {
	backgroundBrandTertiary,
	colorPaletteBlue400,
	neutralPrimary,
	secondaryColor,
} from "../../../core/theme";
import { Operations } from "../FiltersDrawer";

export type FiltersWithLabelsType = Record<
	string,
	{
		value: any;
		label: string;
		operation: Operations;
	}
>;

const ActiveFilters = ({
	filters = {},
	removeFilter,
	resetFilters,
}: {
	filters: FiltersWithLabelsType;
	removeFilter: ({
		filter_key,
		operation,
	}: {
		filter_key: string;
		operation: string;
	}) => void;
	resetFilters: () => void;
}) => {
	const filtersByKeys = filters;

	return (
		<>
			{Object.keys(filtersByKeys).length > 0 && (
				<Box
					sx={{
						display: "flex",
						flexWrap: "wrap",
						gap: "8px",
						alignItems: "center",
						marginBottom: "16px",
					}}
				>
					Filters applied:{" "}
					{Object.keys(filtersByKeys).map((filterKey) => (
						<Box
							key={filterKey}
							sx={{
								backgroundColor: backgroundBrandTertiary,
								border: `1px solid ${colorPaletteBlue400}`,
								color: neutralPrimary,
								padding: "0 3px",
								display: "flex",
								alignItems: "center",
								height: "24px",
								boxSizing: "border-box",
							}}
						>
							<Box>
								{filtersByKeys[filterKey]?.label}{" "}
								<Box
									sx={{
										display: "inline",
										textTransform: "uppercase",
									}}
								>
									{filtersByKeys[filterKey].operation}
								</Box>{" "}
								{Array.isArray(filtersByKeys[filterKey].value)
									? (
											filtersByKeys[filterKey].value as []
									  ).join(", ")
									: filtersByKeys[filterKey].value}
							</Box>
							<Box
								onClick={() =>
									removeFilter({
										filter_key: filterKey,
										operation:
											filtersByKeys[filterKey].operation,
									})
								}
								role="button"
								sx={{
									display: "flex",
									alignItems: "center",
									marginLeft: "5px",
								}}
							>
								<Close fontSize="inherit" />
							</Box>
						</Box>
					))}
					<Button
						onClick={resetFilters}
						sx={{
							backgroundColor: secondaryColor,
							color: neutralPrimary,
							display: "flex",
							height: "24px",
							gap: "8px",
							textTransform: "none",
							marginLeft: "16px",
						}}
					>
						<Refresh />
						Reset
					</Button>
				</Box>
			)}
		</>
	);
};

export default ActiveFilters;
