import { ReactNode, useMemo } from "react";
import FluidSelect from "../../../../../common/components/FluidSelect";
import {
	BaseProductionSubtype,
	ComputationalMethod,
	DeltaGranularity,
	DeltaType,
	PartialKindOfSettlements,
} from "../../../../../requests_cm/gecoContractsService/types";
import {
	BaseEditableSettlementCard,
	EditableSettlementCardBase,
} from "./EditableSettlementCardBase";
import {
	getBaseLoadSettlementValidator,
	getBaseLoadDelta1Validator,
	getBaseLoadDelta2Validator,
	fixPriceSettlementValidator,
	indexSettlementValidator,
} from "../../../formik/editSettlementValidationValidator";
import { Box } from "@mui/material";

interface ChildrenProps {
	values: any;
	errors: any;
	setFieldValue: (name: string, value: any) => void;
	handleChange: (event: any) => void;
}

type ChildMethod = (props: ChildrenProps) => ReactNode;

const BaseProdPriceFields: ChildMethod = ({
	values,
	errors,
	setFieldValue,
}: ChildrenProps) => {
	return (
		<>
			<FluidSelect
				sx={{ width: "60%" }}
				isLabelStatic
				items={[
					{
						label: "Base Over Production",
						value: ComputationalMethod.BASE_OVER_PRODUCTION,
					},
					{
						label: ComputationalMethod.STANDARD,
						value: ComputationalMethod.STANDARD,
					},
					{
						label: "Year To Date",
						value: ComputationalMethod.YEAR_TO_DATE,
					},
				]}
				label={"Computation Method"}
				name={"computation_method"}
				// @ts-ignore does not provides an HTMLElement as it should but the value itself
				onChange={(index: number) =>
					setFieldValue("computation_method", index)
				}
				value={`${values.computation_method}`}
				errorMessage={
					//@ts-ignore
					errors.computation_method
				}
			/>
			<FluidSelect
				sx={{ width: "60%" }}
				isLabelStatic
				items={[
					{
						label: BaseProductionSubtype.BASELOAD,
						value: BaseProductionSubtype.BASELOAD,
					},
					{
						label: BaseProductionSubtype.THRESHOLD,
						value: BaseProductionSubtype.THRESHOLD,
					},
				]}
				label={"Base prod Sub Type"}
				name={"base_prod_subtype"}
				// @ts-ignore does not provides an HTMLElement as it should but the value itself
				onChange={(index: number) =>
					setFieldValue("base_prod_subtype", index)
				}
				value={`${values.base_prod_subtype}`}
				errorMessage={
					//@ts-ignore
					errors.base_prod_subtype
				}
			/>
		</>
	);
};

const BaseProdDeltaFields: ChildMethod = ({
	values,
	errors,
	setFieldValue,
}: ChildrenProps) => {
	return (
		<Box sx={{ display: "flex", gap: " 24px" }}>
			<FluidSelect
				sx={{ width: "40%" }}
				isLabelStatic
				items={[
					{ label: DeltaType.ALL, value: DeltaType.ALL },
					{
						label: "Under Production",
						value: DeltaType.UNDER_PRODUCTION,
					},
					{
						label: "Over Production",
						value: DeltaType.OVER_PRODUCTION,
					},
				]}
				label={"Delta"}
				name={"delta.type"}
				// @ts-ignore does not provides an HTMLElement as it should but the value itself
				onChange={(index: number) => setFieldValue("delta.type", index)}
				value={`${values?.delta?.type}`}
				errorMessage={
					//@ts-ignore
					errors?.delta?.type
				}
			/>
			<FluidSelect
				sx={{ width: "40%" }}
				isLabelStatic
				items={[
					{
						label: "Half Hourly",
						value: DeltaGranularity.HALF_HOURLY,
					},
					{
						label: DeltaGranularity.HOURLY,
						value: DeltaGranularity.HOURLY,
					},
					{
						label: DeltaGranularity.MONTHLY,
						value: DeltaGranularity.MONTHLY,
					},
					{
						label: DeltaGranularity.NONE,
						value: DeltaGranularity.NONE,
					},
				]}
				label={""}
				name={"delta.granularity"}
				// @ts-ignore does not provides an HTMLElement as it should but the value itself
				onChange={(index: number) =>
					setFieldValue("delta.granularity", index)
				}
				value={`${values?.delta?.granularity}`}
				errorMessage={
					//@ts-ignore
					errors?.delta?.granularity
				}
			/>
		</Box>
	);
};

export const EditableSettlementCard = (props: BaseEditableSettlementCard) => {
	const baseValidator = useMemo(() => {
		return props.settlement?.index
			? indexSettlementValidator
			: fixPriceSettlementValidator;
	}, [props.settlement]);

	const settingVariation: Partial<Record<PartialKindOfSettlements, any>> = {
		[PartialKindOfSettlements.BASE_PROD_PRICE]: {
			validation: getBaseLoadSettlementValidator(baseValidator),
			getAdditionalFields: BaseProdPriceFields,
		},
		[PartialKindOfSettlements.BP_DELTA_1]: {
			validation: getBaseLoadDelta1Validator(baseValidator),
			getAdditionalFields: BaseProdDeltaFields,
		},
		[PartialKindOfSettlements.BP_DELTA_2]: {
			validation: getBaseLoadDelta2Validator(baseValidator),
			getAdditionalFields: BaseProdDeltaFields,
		},
	};

	if (props?.settlement?.type in settingVariation) {
		return (
			<EditableSettlementCardBase
				{...props}
				getAdditionalFields={
					settingVariation[
						props.settlement.type as PartialKindOfSettlements
					].getAdditionalFields
				}
				validationSchema={
					settingVariation[
						props.settlement.type as PartialKindOfSettlements
					].validation
				}
			/>
		);
	}

	return <EditableSettlementCardBase {...props} />;
};
