import { Box, InputLabel } from "@mui/material";
import CustomInput from "../../../../../common/components/CustomInput";
import { PrimaryButton } from "../../../../../common/components/CustomButton";
import { Formik, FormikValues } from "formik";
import CustomSelect from "../../../../../common/components/CustomSelect";
import {
	AssetType,
	ServicePointType,
	TechnologyType,
} from "../../../../../requests_cm/gecoSitesService/types";
import { getAssetValidationSchema } from "./AddAssetsForm.schema";
import { GenericReferential } from "../../../../../requests_cm/gecoReferentialService/types";
import { ErrorText } from "../../../../../common/components/ErrorText";
import FluidDatePicker from "../../../../../common/components/FluidDatePicker";

const AssetFormWind = ({
	onClose,
	servicePoints,
	upsertSingleAsset,
	machines,
	technology,
	asset,
}: {
	onClose: () => void;
	servicePoints: ServicePointType[];
	upsertSingleAsset: (newAssets: FormikValues) => void;
	machines: GenericReferential[];
	technology: TechnologyType;
	asset?: AssetType;
}) => {
	return (
		<Formik
			initialValues={{
				id: asset?.id ?? undefined,
				commissioning_date: asset?.commissioning_date ?? "",
				hub_height: asset?.hub_height ?? 0,
				installed_capacity: asset?.installed_capacity ?? 0,
				key: asset?.key ?? "",
				latitude: asset?.latitude ?? 0,
				longitude: asset?.longitude ?? 0,
				name: asset?.name ?? "",
				rotor_diameter: asset?.rotor_diameter ?? 0,
				machine: String(asset?.machine?.id ?? ""),
				manufacturer_serial_number:
					asset?.manufacturer_serial_number ?? "",
				subsidy: asset?.subsidy ?? "",
				sr_code: asset?.sr_code ?? "",
				tr_code: asset?.tr_code ?? "",
				tr_mastr_no: asset?.tr_mastr_no ?? "",
				service_point:
					servicePoints.length === 1 ? servicePoints[0].id : "",
			}}
			validationSchema={getAssetValidationSchema(technology)}
			onSubmit={(values) => {
				upsertSingleAsset(values);
				onClose();
			}}
		>
			{({
				errors,
				handleChange,
				handleSubmit,
				submitForm,
				touched,
				values,
			}) => (
				<form onSubmit={handleSubmit}>
					<Box>
						<InputLabel shrink={false} htmlFor="service_point">
							Service Point *
						</InputLabel>
						<CustomSelect
							items={servicePoints.map(({ id, name }) => ({
								key: id,
								value: id,
								label: name,
							}))}
							label=""
							name="service_point"
							value={values.service_point}
							onChange={handleChange}
						/>
						{errors.service_point && touched.service_point && (
							<ErrorText>{errors.service_point}</ErrorText>
						)}
					</Box>
					<Box
						sx={{
							display: "flex",
							flexDirection: "column",
							gap: "16px",
							padding: "16px 0",
						}}
					>
						<Box>
							<InputLabel shrink={false} htmlFor="name">
								Asset Name *
							</InputLabel>
							<CustomInput
								id="name"
								name="name"
								aria-label="name"
								value={values.name}
								onChange={handleChange}
								fullWidth
							/>
							{errors.service_point && touched.service_point && (
								<ErrorText>{errors.service_point}</ErrorText>
							)}
						</Box>
						<Box>
							<InputLabel
								shrink={false}
								htmlFor="installed_capacity"
							>
								Installed Capacity *
							</InputLabel>
							<CustomInput
								type="number"
								id="installed_capacity"
								name="installed_capacity"
								aria-label="installed_capacity"
								value={values.installed_capacity}
								onChange={handleChange}
							/>
							{errors.installed_capacity &&
								touched.installed_capacity && (
									<ErrorText>
										{errors.installed_capacity}
									</ErrorText>
								)}
						</Box>
						<Box sx={{ display: "flex", gap: "16px" }}>
							<Box>
								<InputLabel shrink={false} htmlFor="latitude">
									Latitude
								</InputLabel>
								<CustomInput
									type="number"
									id="latitude"
									aria-label="latitude"
									value={values.latitude}
									onChange={handleChange}
								/>
							</Box>
							<Box>
								<InputLabel shrink={false} htmlFor="longitude">
									Longitude
								</InputLabel>
								<CustomInput
									type="number"
									id="longitude"
									aria-label="longitude"
									value={values.longitude}
									onChange={handleChange}
								/>
							</Box>
						</Box>
						<Box sx={{ display: "flex", gap: "16px" }}>
							<Box>
								<InputLabel shrink={false} htmlFor="hub_height">
									Hub Height
								</InputLabel>
								<CustomInput
									type="number"
									id="hub_height"
									aria-label="hub_height"
									value={values.hub_height}
									onChange={handleChange}
								/>
							</Box>
							<Box>
								<InputLabel
									shrink={false}
									htmlFor="rotor_diameter"
								>
									Rotor Diameter
								</InputLabel>
								<CustomInput
									type="number"
									id="rotor_diameter"
									aria-label="rotor_diameter"
									value={values.rotor_diameter}
									onChange={handleChange}
								/>
							</Box>
						</Box>
						<Box>
							<InputLabel shrink={false} htmlFor="machine">
								Machine Name *
							</InputLabel>
							<CustomSelect
								items={machines.map(({ id, name }) => ({
									key: id,
									value: id,
									label: name,
								}))}
								label=""
								name="machine"
								value={values.machine}
								id="machine"
								aria-label="machine"
								onChange={handleChange}
							/>
							{errors.machine && touched.machine && (
								<ErrorText>{errors.machine}</ErrorText>
							)}
						</Box>

						<Box>
							<InputLabel
								shrink={false}
								htmlFor="manufacturer_serial_number"
							>
								Serial Number *
							</InputLabel>
							<CustomInput
								id="manufacturer_serial_number"
								aria-label="manufacturer_serial_number"
								value={values.manufacturer_serial_number}
								onChange={handleChange}
								fullWidth
							/>
							{errors.manufacturer_serial_number &&
								touched.manufacturer_serial_number && (
									<ErrorText>
										{errors.manufacturer_serial_number}
									</ErrorText>
								)}
						</Box>

						<Box>
							<FluidDatePicker
								name="commissioning_date"
								onChange={handleChange}
								label={"Commissioning Date *"}
								value={values.commissioning_date}
								errorMessage={errors.commissioning_date}
							/>
						</Box>
						<Box>
							<InputLabel shrink={false} htmlFor="key">
								Asset Key *
							</InputLabel>
							<CustomInput
								id="key"
								aria-label="key"
								value={values.key}
								onChange={handleChange}
								fullWidth
							/>
							{errors.key && touched.key && (
								<ErrorText>{errors.key}</ErrorText>
							)}
						</Box>
						<Box>
							<InputLabel shrink={false} htmlFor="subsidy">
								Subsidy *
							</InputLabel>
							<CustomInput
								type="number"
								id="subsidy"
								aria-label="subsidy"
								value={values.subsidy}
								onChange={handleChange}
								fullWidth
							/>
							{errors.subsidy && touched.subsidy && (
								<ErrorText>{errors.subsidy}</ErrorText>
							)}
						</Box>
						<Box>
							<InputLabel shrink={false} htmlFor="sr_code">
								SR Code *
							</InputLabel>
							<CustomInput
								id="sr_code"
								aria-label="sr_code"
								value={values.sr_code}
								onChange={handleChange}
								fullWidth
							/>
							{errors.sr_code && touched.sr_code && (
								<ErrorText>{errors.sr_code}</ErrorText>
							)}
						</Box>
						<Box>
							<InputLabel shrink={false} htmlFor="tr_code">
								TR Code *
							</InputLabel>
							<CustomInput
								id="tr_code"
								aria-label="tr_code"
								value={values.tr_code}
								onChange={handleChange}
								fullWidth
							/>
							{errors.tr_code && touched.tr_code && (
								<ErrorText>{errors.tr_code}</ErrorText>
							)}
						</Box>
						<Box>
							<InputLabel shrink={false} htmlFor="tr_mastr_no">
								TR Master Number *
							</InputLabel>
							<CustomInput
								id="tr_mastr_no"
								aria-label="tr_mastr_no"
								value={values.tr_mastr_no}
								onChange={handleChange}
								fullWidth
							/>
							{errors.tr_mastr_no && touched.tr_mastr_no && (
								<ErrorText>{errors.tr_mastr_no}</ErrorText>
							)}
						</Box>
					</Box>

					<Box
						sx={{
							display: "flex",
							justifyContent: "flex-end",
							gap: "16px",
						}}
					>
						<PrimaryButton
							onClick={onClose}
							text="Cancel"
							type="button"
							color="secondary"
						/>
						<PrimaryButton
							onClick={submitForm}
							text={`${asset ? "Save" : "Add"}`}
							type="button"
							color="primary"
						/>
					</Box>
				</form>
			)}
		</Formik>
	);
};

export default AssetFormWind;
