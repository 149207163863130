import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Box, Button, Tooltip } from "@mui/material";
import FilePresentIcon from "@mui/icons-material/FilePresent";
import TrackChangesIcon from "@mui/icons-material/TrackChanges";
import CircularProgress from "@mui/material/CircularProgress";
import PageTitle from "../../common/components/PageTitle/PageTitle";
import PageContent from "../../common/components/PageContent";
import SimpleTable, {
	HeadersType,
} from "../../common/components/SimpleTable/SimpleTable";
import { FluidButton } from "../../common/components/FluidButton";
import ActiveFilters from "../../common/components/ActiveFilters";
import { PrimaryButton } from "../../common/components/CustomButton";
import { FieldValue, Operations } from "../../common/components/FiltersDrawer";
import { SearchField } from "../../common/components/SearchField";
import { useRtkQueryDynamicEndpoint } from "../../common/hooks/useRtkQueryDynamicEndpoint";
import { style } from "./ContractsPage.style";
import { RootState } from "../../core/rootReducers";
import ContractPageFilters from "./ContractPageFilters";
import { getPathWithParameters, PATH, setFilters } from "../../router-path";
import { updateFilters, updateSearch } from "./ContractsPageSlice";
import { isLastPage } from "../../requests_cm/utils/infiniteScroll.utils";
import { PageInfoType } from "../../requests_cm/gecoTypes";
import _ from "lodash";
import { primaryColor } from "../../core/theme";
import ContractFilesModal from "./ContractFilesModal";
import {
	useGetAgreementTypesQuery,
	useGetBooksQuery,
	useGetContractTypesQuery,
	useGetCountriesQuery,
} from "../../requests_cm/gecoReferentialService/service";
import {
	contractsSortableColumns,
	useLazyGetContractsQuery,
} from "../../requests_cm/gecoContractsService/service";
import { ContractType } from "../../requests_cm/gecoContractsService/types";
import { ChangeRequestStatus } from "../../requests_cm/gepoChangeRequestService/types";

const ContractsPage = () => {
	const navigate = useNavigate();

	const [isFilterOpen, setFilterOpen] = useState(false);
	const [selectedContractIdFiles, setSelectedContractIdFiles] = useState<
		number | null
	>(null);

	const dispatch = useDispatch();

	const filters = useSelector(
		(state: RootState) => state.contractsPage.filters
	);
	const filtersWithLabels = useSelector(
		(state: RootState) => state.contractsPage.filtersWithLabels
	);
	const search = useSelector(
		(state: RootState) => state.contractsPage.search
	);

	const [pageInfo, setPageInfo] = useState<PageInfoType>({
		page: 1,
		filters: filters,
		search: search,
	});

	const { data: countries, isLoading: isCountryLoading } =
		useRtkQueryDynamicEndpoint(useGetCountriesQuery)({});
	const { data: books, isLoading: isBookLoading } =
		useRtkQueryDynamicEndpoint(useGetBooksQuery)({});
	const { data: agreementTypes, isLoading: isAgreementType } =
		useRtkQueryDynamicEndpoint(useGetAgreementTypesQuery)({});
	const { data: contractTypes, isLoading: isLoadingContractTypes } =
		useRtkQueryDynamicEndpoint(useGetContractTypesQuery)({});

	const [
		baseGetContracts,
		{ data: contracts, isLoading: isContractLoading, isFetching },
	] = useLazyGetContractsQuery();

	const getContracts = useRtkQueryDynamicEndpoint(baseGetContracts);

	const isPageLoading = useMemo(
		() =>
			isContractLoading ||
			isCountryLoading ||
			isBookLoading ||
			isAgreementType ||
			isLoadingContractTypes,
		[
			isContractLoading,
			isCountryLoading,
			isBookLoading,
			isAgreementType,
			isLoadingContractTypes,
		]
	);

	useEffect(() => {
		getContracts(pageInfo);
	}, [pageInfo]);

	const handleSearch = (searchValue: string) => {
		dispatch(updateSearch(searchValue));
		setPageInfo((current) => ({
			...current,
			page: 1,
			search: searchValue,
		}));
	};

	const handleFilter = (
		newFilters: Record<string, FieldValue>,
		newFiltersWithLabels: Record<
			string,
			{
				value: FieldValue;
				label: string;
				operation: Operations;
			}
		>
	) => {
		dispatch(
			updateFilters({
				filters: newFilters,
				filtersWithLabels: newFiltersWithLabels,
			})
		);
		setPageInfo((current) => ({
			...current,
			page: 1,
			search: "",
			filters: newFilters,
		}));
	};

	const naviateOnChangeRequet = useCallback(() => {
		navigate(
			PATH.CHANGE_REQUESTS,
			setFilters({
				status__in: [
					ChangeRequestStatus.CORRECTION_NEEDED,
					ChangeRequestStatus.REJECTED,
					ChangeRequestStatus.PENDING,
					ChangeRequestStatus.VALID,
				],
			})
		);
	}, [navigate]);

	const handleRemoveFilter = ({
		filter_key,
		operation,
	}: {
		filter_key: string;
		operation: string;
	}) => {
		const newFilters = { ...filters };
		const newFiltersWithLabels = {
			...filtersWithLabels,
		};

		delete newFilters[`${filter_key}__${operation}`];
		delete newFiltersWithLabels[filter_key];

		handleFilter(newFilters, newFiltersWithLabels);
	};

	const handleRowClick = (contractId: number | string) => {
		navigate(getPathWithParameters(PATH.CONTRACT_DETAIL, { contractId }));
	};

	const headers: HeadersType<ContractType>[] = [
		{ label: "Status", accessor: "status" },
		{ label: "ID", accessor: "id" },
		{
			label: "Contract Name",
			accessor: "name",
			getAnchorTag: (item: ContractType) =>
				getPathWithParameters(PATH.CONTRACT_DETAIL, {
					contractId: item.id,
				}),
		},
		{ label: "Uid", accessor: "uid" },
		{ label: "CP", accessor: "nb_contractperiods" },
		{ label: "Country", accessor: "country" },
		{ label: "Book (prefix)", accessor: "book" },
		{ label: "Contract Type", accessor: "contract_type" },
		{
			label: "Start Date",
			accessor: "start_date",
		},
		{
			label: "End Date",
			accessor: "end_date",
		},
		{ label: "# Service point", accessor: "nb_service_point" },
		{ label: "# Site", accessor: "nb_site" },
		{
			label: "Signature Date",
			accessor: "signature_date",
		},
		{ label: "Agreement Type", accessor: "agreement_type" },
		{ label: "Payment Market Places", accessor: "payment_market_places" },
		{ label: "Language", accessor: "language" },
		{ label: "Files", accessor: "files" },
	].map((header) => ({
		...header,
		sortable: contractsSortableColumns.includes(header.accessor),
	})) as HeadersType<ContractType>[];

	const uniqueSortedItems = (
		items: { id: number; name: string; code?: string }[]
	) => {
		const uniqueItems = _.uniqBy(items, "name");
		return _.sortBy(uniqueItems, "name");
	};

	const items =
		contracts?.result.map((contract) => {
			const uniqueSortedServicePoints = uniqueSortedItems(
				contract.servicepoints
			);
			const uniqueSortedSites = uniqueSortedItems(contract.sites);

			return {
				...contract,
				nb_service_point: (
					<Tooltip
						title={uniqueSortedServicePoints.map((sp) => (
							<p key={sp.name}>
								{sp.name} - {sp?.code}
							</p>
						))}
					>
						<Button sx={{ minWidth: 0 }}>
							{uniqueSortedServicePoints.length}
						</Button>
					</Tooltip>
				),
				nb_site: (
					<Tooltip
						title={uniqueSortedSites.map((site) => (
							<p key={site.name}>{site.name}</p>
						))}
					>
						<Button
							sx={{ minWidth: 0 }}
							onClick={() =>
								navigate(
									PATH.SITES,
									setFilters({
										id__in: uniqueSortedSites.map(
											(site) => site.id
										),
									})
								)
							}
						>
							{uniqueSortedSites.length}
						</Button>
					</Tooltip>
				),
				files: (
					<Box
						role="button"
						onClick={() => setSelectedContractIdFiles(contract.id)}
						sx={{
							color: primaryColor,
							whiteSpace: "nowrap",
							display: "flex",
							alignItems: "center",
							gap: "2px",
						}}
					>
						<FilePresentIcon sx={{ width: "20px" }} />
						<Box sx={{ textDecoration: "underline" }}>files</Box>
					</Box>
				),
			};
		}) ?? [];

	return (
		<>
			<PageTitle
				fontWeight={"bold"}
				label="Contracts page"
				rightSide={
					<PrimaryButton
						text="Modification follow-up"
						type="button"
						color="secondary"
						onClick={naviateOnChangeRequet}
					>
						<TrackChangesIcon />
					</PrimaryButton>
				}
			/>
			{isPageLoading && (
				<Box sx={style.progressWrapper}>
					<CircularProgress data-testid="loader" />
				</Box>
			)}
			{!isPageLoading && contracts && (
				<>
					<PageContent>
						<Box sx={style.searchWrapper}>
							<SearchField
								defaultValue={search}
								onSearch={handleSearch}
							/>
							<FluidButton
								label={"Filters"}
								icon="filter_list"
								onClick={() => setFilterOpen(true)}
							/>
						</Box>

						<ActiveFilters
							filters={filtersWithLabels}
							removeFilter={handleRemoveFilter}
							resetFilters={() => handleFilter({}, {})}
						/>

						<SimpleTable
							handleRowClick={handleRowClick}
							headers={headers}
							infiniteScroll
							isFetching={isFetching}
							isLastPage={isLastPage(contracts)}
							items={items}
							pageInfo={pageInfo}
							setPageInfo={setPageInfo}
						/>
					</PageContent>
					{selectedContractIdFiles !== null && (
						<ContractFilesModal
							setContractIdFilesOpen={setSelectedContractIdFiles}
							contractIdFilesModalOpen={selectedContractIdFiles}
						/>
					)}
				</>
			)}
			<ContractPageFilters
				defaultFilters={pageInfo?.filters || {}}
				countries={countries || []}
				books={books || []}
				contractTypes={contractTypes || []}
				agreementTypes={agreementTypes || []}
				isOpen={isFilterOpen}
				onClose={() => setFilterOpen(false)}
				onApplyFilters={(newFilters, newFiltersWithLabels) => {
					setFilterOpen(false);
					handleFilter(newFilters, newFiltersWithLabels);
				}}
			/>
		</>
	);
};

export default ContractsPage;
