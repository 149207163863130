import { OrderEnum } from "../gecoTypes";

export const sortQueryString = ({
	order = OrderEnum.DESC,
	sortOn = "id",
}: {
	order: OrderEnum | undefined;
	sortOn: string | undefined;
}): string => {
	return `${order === OrderEnum.DESC ? "-" : ""}${sortOn}`;
};
