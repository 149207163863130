import diff from "microdiff";
import { unset, set, cloneDeep, get } from "lodash";
import {
	CreateChangeRequestType,
	JsonDiff,
	ChangeRequestResourceType,
} from "./types";
import { convertKeysToSnakeCase } from "../../common/utils/objectKeysFromCamelToSnake";
import { getPathWithParameters, PATH } from "../../router-path";
import { ContractDetailType } from "../gecoContractsService/types";

export function compareAndReturnJsonDiffs<T>(
	oldValues: T,
	newValues: T
): JsonDiff[] {
	return (diff(oldValues as any, newValues as any) || []).map((jsonDiff) =>
		convertKeysToSnakeCase<JsonDiff>(jsonDiff)
	);
}

export function getCleanParentValue<T>(
	values: T,
	parentPath: (string | number)[]
) {
	// it case of array, removing the child node will result in an array with an empty value
	let parentValue = get(values, parentPath);
	if (Array.isArray(parentValue)) {
		parentValue = Array.from(parentValue).filter((item) => !!item);
	}
	return parentValue;
}

export function updateOldValueWithDiffs<T extends Record<string, any>>(
	oldValues: T,
	diffs: JsonDiff[]
): T {
	const newValues = cloneDeep(oldValues);
	for (const jsonDiff of diffs) {
		if (jsonDiff.type == "REMOVE") {
			unset(newValues, jsonDiff.path);
			// removing last node to get parent path
			const parentPath = [...jsonDiff.path];
			parentPath.pop();
			const cleanparent = getCleanParentValue(newValues, parentPath);
			set(newValues, parentPath, cleanparent);
		} else {
			set(newValues, jsonDiff.path, jsonDiff.value);
		}
	}
	return newValues;
}

export const contractDetailChangeToChangeRequestMapper = (
	initialContract: ContractDetailType,
	updatedContract: ContractDetailType
): CreateChangeRequestType => {
	return {
		resource_type: ChangeRequestResourceType.CONTRACT,
		resource_id: initialContract.id,
		resource_edit_path: getPathWithParameters(PATH.CONTRACT_DETAIL, {
			contractId: initialContract.id,
		}),
		name: initialContract.name,
		diff_json: compareAndReturnJsonDiffs(initialContract, updatedContract),
		validator_gaia_id: initialContract.trader_id,
		requester_message: "",
	};
};

export const contractPeriodChangeToChangeRequestMapper = (
	initialContract: ContractDetailType,
	updatedContract: ContractDetailType,
	contractPeriodId: number
): CreateChangeRequestType => {
	return {
		resource_type: ChangeRequestResourceType.CONTRACT_PERIOD,
		resource_id: initialContract.id,
		resource_edit_path: getPathWithParameters(PATH.CONTRACTPERIOD, {
			contractId: initialContract.id,
			contractPeriodId,
		}),
		name: initialContract.name,
		diff_json: compareAndReturnJsonDiffs(initialContract, updatedContract),
		validator_gaia_id: initialContract.trader_id,
		requester_message: "",
	};
};
