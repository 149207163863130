import PersonAddIcon from "@mui/icons-material/PersonAdd";
import { PrimaryButton } from "../../../common/components/CustomButton";
import Modal from "../../../common/components/Modal";
import { Box } from "@mui/system";
import CustomAutocomplete from "../../../common/components/CustomAutocomplete";
import { useRtkQueryDynamicEndpoint } from "../../../common/hooks/useRtkQueryDynamicEndpoint";
import { useGetContactsQuery } from "../../../requests_cm/gecoReferentialService/service";
import { Formik } from "formik";
import { ContactType } from "../../../requests_cm/gecoReferentialService/types";

const AddContactModal = ({
	isOpen,
	onClose,
	addContact,
}: {
	isOpen: boolean;
	onClose: () => void;
	addContact: (contact: Pick<ContactType, "id" | "name">) => void;
}) => {
	const { data } = useRtkQueryDynamicEndpoint(useGetContactsQuery)({});

	const contacts = data?.result;

	return (
		<Modal
			onClose={onClose}
			open={isOpen}
			title={
				<Box sx={{ display: "flex", gap: "8px", alignItems: "center" }}>
					<PersonAddIcon /> Add contact
				</Box>
			}
		>
			<Formik
				initialValues={{
					contact: { id: "", name: "" },
				}}
				onSubmit={(values) => {
					addContact({
						id: Number(values.contact.id),
						name: values.contact.name,
					});
				}}
			>
				{({ setFieldValue, submitForm }) => (
					<form>
						<CustomAutocomplete
							data={contacts ?? []}
							fetchData={(search: string) =>
								contacts?.filter((contact) =>
									contact.email.includes(search)
								)
							}
							label="eMail Address"
							setFieldValue={(event, value) => {
								setFieldValue("contact", value);
							}}
						/>
						<Box sx={{ display: "flex", gap: "16px" }}>
							<PrimaryButton
								color="secondary"
								text="Cancel"
								onClick={onClose}
								type="button"
							/>
							<PrimaryButton
								text="Done"
								type="button"
								onClick={() => {
									submitForm();
									onClose();
								}}
							/>
						</Box>
					</form>
				)}
			</Formik>
		</Modal>
	);
};

export default AddContactModal;
