import {
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
} from "@mui/material";
import { PrimaryButton } from "../../../../common/components/CustomButton";
import { useEffect, useState } from "react";
import usePrevious from "../../../../common/hooks/usePrevious";
import CustomInput from "../../../../common/components/CustomInput";

export interface TimeserieModalProps {
	onClose: () => void;
	isOpen: boolean;
	onSubmit: (tag: string) => void;
}

export function TimeserieModal(props: TimeserieModalProps) {
	const { onClose, onSubmit, isOpen } = props;
	const [tag, setTag] = useState("");
	const prevIsOpen = usePrevious(isOpen);

	useEffect(() => {
		if (isOpen && !prevIsOpen) {
			setTag("");
		}
	}, [isOpen, prevIsOpen, setTag]);

	return (
		<Dialog onClose={onClose} open={isOpen}>
			<DialogTitle>Add a timeserie manually</DialogTitle>
			<DialogContent>
				<CustomInput
					onChange={(ev) => setTag(ev.target.value)}
					value={tag}
				/>
			</DialogContent>
			<DialogActions>
				<PrimaryButton
					onClick={() => onSubmit(tag)}
					text="Create"
					type="button"
					color="primary"
					disabled={!tag}
				></PrimaryButton>
				<PrimaryButton
					onClick={onClose}
					text="Cancel"
					type="button"
					color="secondary"
				></PrimaryButton>
			</DialogActions>
		</Dialog>
	);
}
