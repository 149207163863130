import * as yup from "yup";
import {
	AveragingType,
	DeltaType,
	FixingMethod,
} from "../../../requests_cm/gecoContractsService/types";

export const npqrsValidator = yup.object().shape({
	n: yup.number().required(),
	p: yup.number().required(),
	q: yup.number().required(),
	r: yup.number().required(),
	s: yup.number().required(),
});

export const feeValidator = yup.object().shape({
	name: yup.string().nonNullable(),
	value: yup.number().nonNullable(),
});

export const indexSettlementValidator = yup.object().shape({
	index: yup.object().shape({
		commodity_fixing_id: yup.number().required("Index is required field"),
		fixing_method: yup.string().required("Fixing Method is required field"),
		npqrs: yup
			.array()
			.of(yup.number())
			.when("fixing_method", {
				//@ts-ignore
				is: (fixing_method: string) =>
					FixingMethod.AVERAGING_NPQ === fixing_method,
				then: (schema) => schema.required(),
				otherwise: (schema) => schema.notRequired(),
			}),
		ref_contract: yup.string().when("fixing_method", {
			//@ts-ignore
			is: (fixing_method: string) =>
				FixingMethod.AVERAGING_NPQ === fixing_method,
			then: (schema) => schema.required().min(1).max(5),
			otherwise: (schema) => schema.notRequired(),
		}),
		averaging_type: yup.string().when("fixing_method", {
			//@ts-ignore
			is: (fixing_method: string) =>
				FixingMethod.AVERAGING_NPQ === fixing_method,
			then: (schema) =>
				schema
					.required()
					.oneOf(
						[AveragingType.INDEX],
						"For Averaging NPQ, averaging type should be Monthly average"
					),
			otherwise: (schema) => schema.notRequired(),
		}),
		beta: yup
			.object()
			.shape({
				commodity: yup.number(),
				fees: yup.array().of(feeValidator),
			})
			.notRequired(),
		alpha: yup.object().shape({
			commodity: yup.number().positive().required().min(0).max(1),
			fees: yup.array().of(feeValidator),
		}),
	}),
});

export const fixPriceSettlementValidator = yup.object().shape({
	price: yup.object().shape({
		commodity: yup.number().min(0),
		fees: yup.array().of(feeValidator),
	}),
});

export const getBaseLoadSettlementValidator = (
	initialValidator: yup.AnyObject
) =>
	initialValidator.shape({
		computation_method: yup.string().required(),
		base_prod_subtype: yup.string().required(),
	});

export const getBaseLoadDelta1Validator = (initialValidator: yup.AnyObject) =>
	initialValidator.shape({
		delta: yup
			.object()
			.shape({
				type: yup
					.string()
					.oneOf(
						[DeltaType.OVER_PRODUCTION, DeltaType.ALL],
						"Delta 1 can be of type Over Production or All"
					)
					.required(),
				granularity: yup.string().required(),
			})
			.required(),
	});

export const getBaseLoadDelta2Validator = (initialValidator: yup.AnyObject) =>
	initialValidator.shape({
		delta: yup
			.object()
			.shape({
				type: yup
					.string()
					.oneOf(
						[DeltaType.UNDER_PRODUCTION],
						"Delta 2 can only be of type under production"
					)
					.required(),
				granularity: yup.string().required(),
			})
			.required(),
	});
