export const CLICK_GRANULARITY = ["Year", "Quarter", "Month", "NoSplit"];
export const PRICING_GRANULARITY = [
	"monthly",
	"quarter",
	"CAL",
	"Whole Horizon",
];
export const CHAPTER51_TYPES = ["PayZero", "PayMarktwerte", "PayM0"];
export const CHAPTER51_NUMBER_OF_HOURS = [1, 2, 4, 6];
export const MAXIMUM_SITES_IN_ONE_GO = 100;
export const MAXIMUM_YEARS_IN_ONE_GO = 10;
export const MAXIMUM_TYPES_IN_ONE_GO = 10;

export const STRIKE_AVERAGE_TYPE = [
	{ key: "Index", value: "Index" },
	{
		key: "DailyWeighted",
		value: "DailyWeighted",
	},
	{ key: "Weighted", value: "Weighted" },
];
