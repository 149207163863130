import { useEffect } from "react";
import { Box } from "@mui/material";
import { Form, useFormikContext } from "formik";

import FluidSelect from "../../../../common/components/FluidSelect";
import { Spacer } from "../../../../common/components/Spacer";
import { DatePickers } from "./DatePickers";
import { CommodityFixing } from "./CommodityFixing";
import { StatusField } from "./StatusField";
import { If } from "../../../../common/components/If";
import { Toggle } from "../../../../common/components/Toggle";
import {
	AdjustmentsFrequency,
	TradeType,
} from "../../../../requests_cm/gecoContractsService/types";
import { getSyntheticEvent } from "../../../../common/utils/getSyntheticEvent";
import { FluidNumber } from "../../../../common/components/FluidNumberField";
import { FluidTextField } from "../../../../common/components/FluidTextField";
import { GeneralTabFormikData } from "../../formik/generalTabFormik";
import { isNull } from "../../../../common/utils/isFalsy";
import { useLazyGetBCVConfigQuery } from "../../../../requests_cm/gecoReferentialService/service";
import { useRtkQueryDynamicEndpoint } from "../../../../common/hooks/useRtkQueryDynamicEndpoint";
import { mappingBCVConfig } from "./dataMapping";
import CustomAutocomplete from "../../../../common/components/CustomAutocomplete";

const style = {
	container: {
		display: "flex",
		flexDirection: "column",
		flex: 1,
	},
	submitWrapper: {
		marginLeft: "auto",
	},
};
interface GeneralTabFormProps {
	setHorizonChangeOpen: (state: boolean) => void;
}
const GeneralTabForm = ({ setHorizonChangeOpen }: GeneralTabFormProps) => {
	const [
		lazySearchBCVConfig,
		{ data: BCVConfigData, isLoading: loadingBCVConfig },
	] = useLazyGetBCVConfigQuery({
		selectFromResult(result) {
			const formatedData = mappingBCVConfig(result.data);
			return { ...result, data: formatedData };
		},
	});
	const searchBCVConfig = useRtkQueryDynamicEndpoint(lazySearchBCVConfig);
	const [
		lazySearchBCVConfigGoo,
		{ data: BCVConfigDataGoo, isLoading: loadingBCVConfigGoo },
	] = useRtkQueryDynamicEndpoint(useLazyGetBCVConfigQuery)({
		selectFromResult(result) {
			const formatedData = mappingBCVConfig(result.data);
			return { ...result, data: formatedData };
		},
	});
	const searchBCVConfigGoo = useRtkQueryDynamicEndpoint(
		lazySearchBCVConfigGoo
	);

	const { values, handleChange, handleSubmit, dirty, setFieldValue } =
		useFormikContext<GeneralTabFormikData>();

	useEffect(() => {
		if (dirty) handleSubmit();
	}, [dirty, values]);

	return (
		<Form onSubmit={handleSubmit}>
			<Box sx={style.container}>
				<StatusField
					onChange={(status: string) => {
						handleChange(getSyntheticEvent("status", status));
					}}
					value={values.status}
				/>
				<Spacer gap={24} />
				<FluidTextField
					onChange={handleChange}
					value={values.name}
					name={"name"}
					label={"Name"}
					sx={{
						width: "100%",
					}}
					isDisabled
				/>
				<Spacer gap={24} />
				<DatePickers
					onChange={handleChange}
					tradeDate={values.trade_date}
					startDate={values.start_date}
					endDate={values.end_date}
					onOpenChangeHorizon={() => setHorizonChangeOpen(true)}
				/>
				<Spacer gap={24} />
				<Toggle
					onChange={handleChange}
					isTrue={values.test_phase || false}
					name={"test_phase"}
					label={"Test Phase"}
				/>
				<Spacer gap={24} />
				<FluidSelect
					isReadOnly
					isLabelStatic
					items={[
						{
							value: TradeType.PPA_BASE_PHYSICAL,
							label: "Ppa Base Physical",
						},
						{
							value: TradeType.PPA_PHYSICAL,
							label: "Ppa Physical",
						},
					]}
					label={"PPA Type"}
					name={"trade_type"}
					onChange={(value: unknown) => {
						// SelectFluid sends us only the value of the option and not the all input event
						handleChange(getSyntheticEvent("trade_type", value));
					}}
					value={values.trade_type}
				/>
				<Spacer gap={24} />
				<If
					condition={
						values.trade_type === TradeType.PPA_BASE_PHYSICAL
					}
				>
					<FluidNumber
						onChange={handleChange}
						value={values.base_prod_capacity || 0}
						name={"base_prod_capacity"}
						title={"Base Prod Capacity (MW)"}
					/>
				</If>
				<Spacer gap={24} />
				<CommodityFixing
					onChange={handleChange}
					commodity={values.commodity}
				/>
				<Spacer gap={24} />
				<Toggle
					name={"invoice_decomposition"}
					label={"Invoice Decomposition"}
					description={
						"True if the invoiced is decomposed by post (Click, Unclick, Negative Price etc) or not"
					}
					onChange={handleChange}
					isTrue={!!values.invoice_decomposition}
				/>
				<Spacer gap={24} />
				<Toggle
					name={"has_adjustments"}
					label={"Has Adjustments"}
					description={
						"For trades with final settlements only : says if monthly settlements are adjusted or not"
					}
					onChange={handleChange}
					isTrue={!!values.adjustment_frequency}
				/>
				<Spacer gap={24} />
				<FluidSelect
					isLabelStatic
					items={[
						{
							label: AdjustmentsFrequency.MONTHLY,
							value: AdjustmentsFrequency.MONTHLY,
						},
						{
							label: AdjustmentsFrequency.YEARLY,
							value: AdjustmentsFrequency.YEARLY,
						},
						{
							label: AdjustmentsFrequency.QUARTERLY,
							value: AdjustmentsFrequency.QUARTERLY,
						},
						{
							label: AdjustmentsFrequency.DAILY,
							value: AdjustmentsFrequency.DAILY,
						},
						{
							label: AdjustmentsFrequency.WEEKLY,
							value: AdjustmentsFrequency.WEEKLY,
						},
						{
							label: "Semi Annually",
							value: AdjustmentsFrequency.SEMI_ANNUALLY,
						},
						{
							label: "At Maturity",
							value: AdjustmentsFrequency.AT_MATURITY,
						},
					]}
					label={"Adjustment Frequency"}
					name={"adjustment_frequency"}
					description="Used only for BaseProd deals"
					onChange={(value: unknown) => {
						// SelectFluid sends us only the value of the option and not the all input event
						handleChange(
							getSyntheticEvent("adjustment_frequency", value)
						);
					}}
					value={values.adjustment_frequency}
				/>
				<Spacer gap={24} />
				<CustomAutocomplete
					data={BCVConfigData || []}
					fetchData={(long_name: string) => {
						const searchName = long_name.split("|")[0].trimEnd();
						searchBCVConfig({
							search__name: searchName,
						});
					}}
					label="BCV config"
					name="bcv_config"
					setFieldValue={setFieldValue}
					loading={loadingBCVConfig}
					value={values.bcv_config}
				/>
				<If condition={!isNull(values?.goo_price)}>
					<Spacer gap={8} />
					<FluidNumber
						onChange={handleChange}
						name={"goo_price"}
						title={"GOO Price"}
						value={values.goo_price || 0}
					/>
				</If>
				<If condition={values.goo_price && values.goo_price > 0}>
					<Spacer gap={24} />

					<CustomAutocomplete
						data={BCVConfigDataGoo || []}
						fetchData={(long_name: string) => {
							const searchName = long_name
								.split("|")[0]
								.trimEnd();
							searchBCVConfigGoo({
								search__name: searchName,
							});
						}}
						label="BCV config Goo"
						name="bcv_config_goo"
						setFieldValue={setFieldValue}
						loading={loadingBCVConfigGoo}
						value={values.bcv_config_goo}
					/>
				</If>
			</Box>
		</Form>
	);
};

export default GeneralTabForm;
