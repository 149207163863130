import React, { useCallback, useState } from "react";
import {
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	TextField,
	Typography,
} from "@mui/material";
import { useAppDispatch } from "../../common/hooks/default";
import { resetError } from "./ErrorModal.slice";
import { useSelector } from "react-redux";
import {
	selectErrorModalError,
	selectErrorModalLoading,
	selectErrorModalSuccess,
} from "./ErrorModal.selector";
import ErrorIcon from "@mui/icons-material/Error";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { submitError } from "./ErrorModal.thunk";
import { If } from "../../common/components/If";

const styles = {
	wrapper: {
		paddingTop: "36px",
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		margin: "8px",
	},
	icon: {
		color: "red",
		fontSize: "72px",
	},
	text: {
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
	},
	content: { display: "flex", flexDirection: "column", gap: "24px" },
};

export function ErrorModal() {
	const [description, setDescription] = useState<string | undefined>();
	const dispatch = useAppDispatch();

	const close = useCallback(() => {
		dispatch(resetError());
	}, [dispatch]);

	const error = useSelector(selectErrorModalError);
	const loading = useSelector(selectErrorModalLoading);
	const success = useSelector(selectErrorModalSuccess);

	const submit = useCallback(() => {
		dispatch(submitError(description || "", error || ""));
	}, [dispatch, description]);

	return (
		<Dialog open={!!error} onClose={close}>
			<Box sx={styles.wrapper} data-testid={!!error ? "error-modal" : ""}>
				<If condition={success}>
					<CheckCircleIcon
						sx={{ ...styles.icon, color: "#007ACD" }}
					/>
					<DialogTitle>Issue submitted!</DialogTitle>
					<DialogContent sx={styles.content}>
						<DialogContentText sx={styles.text}>
							<Typography component="span">
								Our team is working on it and will get back to
								you shortly.
							</Typography>
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button
							variant="outlined"
							disabled={loading}
							onClick={close}
						>
							Close
						</Button>
					</DialogActions>
				</If>
				<If condition={!success}>
					<ErrorIcon sx={styles.icon} />
					<DialogTitle>{error}</DialogTitle>
					<DialogContent sx={styles.content}>
						<DialogContentText sx={styles.text}>
							<Typography component="span">
								Our system has encountered an error, further
								actions may not be processed.{" "}
							</Typography>
							<Typography component="span">
								Please report the issue below or contact support
								for immediate assistance.
							</Typography>
						</DialogContentText>
						<TextField
							id="outlined-multiline-flexible"
							placeholder="Describe what you were trying to accomplish when the error occurred"
							inputProps={{ "data-testid": "error-textfield" }}
							multiline
							rows={4}
							value={description}
							onChange={(ev) => setDescription(ev.target.value)}
						/>
					</DialogContent>
					<DialogActions>
						<Button
							variant="outlined"
							disabled={loading}
							onClick={close}
						>
							Ignore
						</Button>
						<Button
							variant="contained"
							type="submit"
							disabled={loading}
							onClick={submit}
						>
							Report issue
						</Button>
					</DialogActions>
				</If>
			</Box>
		</Dialog>
	);
}
