import subDays from "date-fns/subDays";
import startOfWeek from "date-fns/startOfWeek";
import endOfWeek from "date-fns/endOfWeek";
import addDays from "date-fns/addDays";
import startOfMonth from "date-fns/startOfMonth";
import endOfMonth from "date-fns/endOfMonth";
import addMonths from "date-fns/addMonths";
import { endOfYear, startOfYear } from "date-fns";

export const predefinedRanges = [
	{
		label: "Today",
		value: [new Date(), new Date()],
		placement: "left",
	},
	{
		label: "Yesterday",
		value: [addDays(new Date(), -1), addDays(new Date(), -1)],
		placement: "left",
	},
	{
		label: "This week",
		value: [startOfWeek(new Date()), endOfWeek(new Date())],
		placement: "left",
	},
	{
		label: "Last 7 days",
		value: [subDays(new Date(), 6), new Date()],
		placement: "left",
	},
	{
		label: "Last 30 days",
		value: [subDays(new Date(), 29), new Date()],
		placement: "left",
	},
	{
		label: "This month",
		value: [startOfMonth(new Date()), endOfMonth(new Date())],
		placement: "left",
	},
	{
		label: "Last month",
		value: [
			startOfMonth(addMonths(new Date(), -1)),
			endOfMonth(addMonths(new Date(), -1)),
		],
		placement: "left",
	},
	{
		label: "This year",
		value: [startOfYear(new Date()), endOfYear(new Date())],
		placement: "left",
	},
	{
		label: "Last year",
		value: [
			new Date(new Date().getFullYear() - 1, 0, 1),
			new Date(new Date().getFullYear(), 0, 0),
		],
		placement: "left",
	},
	{
		label: "Next 2 days",
		closeOverlay: false,
		value: [new Date(), addDays(new Date(), 2)],
		appearance: "default",
	},
	{
		label: "Last week",
		closeOverlay: false,
		value: [
			addDays(startOfWeek(new Date(), { weekStartsOn: 1 }), -7),
			addDays(endOfWeek(new Date(), { weekStartsOn: 1 }), -7),
		],
		appearance: "default",
	},
	{
		label: "Next week",
		closeOverlay: false,
		value: [
			addDays(startOfWeek(new Date(), { weekStartsOn: 1 }), 7),
			addDays(endOfWeek(new Date(), { weekStartsOn: 1 }), 7),
		],
		appearance: "default",
	},
	{
		label: "Next month",
		value: [
			startOfMonth(addMonths(new Date(), 1)),
			endOfMonth(addMonths(new Date(), 1)),
		],
		closeOverlay: false,
		appearance: "default",
	},
	{
		label: "Next year",
		value: [
			new Date(new Date().getFullYear() + 1, 0, 1),
			new Date(new Date().getFullYear() + 2, 0, 0),
		],
		closeOverlay: false,
		appearance: "default",
	},
];
