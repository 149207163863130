import { createSlice } from "@reduxjs/toolkit";
import { WithPayload } from "../../common/utils/withPayload";

export interface ErrorModalState {
	error?: string | null;
	submit: {
		loading: boolean;
		success: boolean;
	};
}

export const initialState: ErrorModalState = {
	error: null,
	submit: { loading: false, success: false },
};

export const errorModalSlice = createSlice({
	name: "errorModal",
	initialState,
	reducers: {
		setError: (state, action: WithPayload<{ error: string }>) => {
			state.error = action.payload.error;
		},
		resetError: (state) => {
			state.error = null;
		},
		submit: (state) => {
			state.submit.loading = true;
			state.submit.success = false;
		},
		submitSuccess: (state) => {
			state.submit.loading = false;
			state.submit.success = true;
		},
		submitFailure: (state) => {
			state.submit.loading = false;
			state.submit.success = false;
		},
	},
});

export const { setError, resetError, submit, submitSuccess, submitFailure } =
	errorModalSlice.actions;

export default errorModalSlice.reducer;
