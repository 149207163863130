import { ValidationError } from "../../common/hooks/usePydanticErrorsFormat";
import { RootState } from "../../core/rootReducers";
import { Tender } from "../tender_page/tender.module";
import { Pricing, PricingRun, PricingStatus } from "./pricingListSlice";

export const selectPricingState = (state: RootState) => state.pricingList;

export const selectPricings =
	(tenderId?: number) =>
	(state: RootState): Pricing[] | undefined | null => {
		const pricingState = selectPricingState(state);
		return pricingState.pricings.filter(
			(pricing) => pricing.tender_id === tenderId
		);
	};

export const selectPricingsHasNextPage = (state: RootState) =>
	state.pricingList.hasNextPage;

export function selectPricingsLoader(state: RootState): boolean {
	const pricingState = selectPricingState(state);
	return pricingState.loader.pricings;
}

export function selectTransitionErrors(
	state: RootState
): string | ValidationError | undefined {
	const pricingState = selectPricingState(state);
	return pricingState.errors.transition;
}

export function selectExportLoader(state: RootState): boolean {
	const pricingState = selectPricingState(state);
	return pricingState.loader.export;
}

export function selectTransitionLoader(state: RootState): boolean {
	const pricingState = selectPricingState(state);
	return pricingState.loader.transition;
}

export function selectCanPrintUnderOffer(state: RootState): boolean {
	const pricingState = selectPricingState(state);
	return (
		(pricingState?.pricings || []).filter((pricing) =>
			[PricingStatus.UNDER_OFFER].includes(pricing.status)
		).length > 0
	);
}

// used as default in selector to keep selector output referentially stable
const EMPTY_PRICING_RUNS: PricingRun[] = [];

export function selectPricingRuns(state: RootState, pricingId: number) {
	const pricingState = selectPricingState(state);
	return pricingState.pricingRuns[pricingId] || EMPTY_PRICING_RUNS;
}

export function selectPricingRunsAreLoading(
	state: RootState,
	pricingId: number
) {
	const pricingState = selectPricingState(state);
	return pricingState.loader.pricingRuns[pricingId] || false;
}

export function selectLatestUpdatedAt(state: RootState) {
	const tenderState = selectPricingState(state);
	return tenderState.latestUpdatedAt;
}

export function selectAddedPricingCount(state: RootState) {
	const tenderState = selectPricingState(state);
	return tenderState.addedPricingCount;
}

export function selectParties(state: RootState) {
	const pricingListState = selectPricingState(state);
	let parties = pricingListState.parties;
	let newParties: any = [];
	if (Array.isArray(parties)) {
		newParties = parties.map(
			(party: {
				name?: string;
				partyId?: number;
				displayName?: string;
			}) => {
				return {
					id: Number(party.partyId),
					partyId: party.partyId,
					name: party.displayName,
					shortName: party.name,
				};
			}
		);
	}

	return newParties;
}

export function selectPartiesLoader(state: RootState): boolean {
	const pricingListState = selectPricingState(state);
	return pricingListState.loader.parties;
}

export function selectPricingsTender(state: RootState): Tender | undefined {
	const pricingState = selectPricingState(state);
	return pricingState.currentTender;
}

export function selectPricingstenderIsLoading(state: RootState): boolean {
	const pricingState = selectPricingState(state);
	return pricingState.loader.tender;
}

export function selectGraphTypes(
	state: RootState
): { id: number; ts_name: string }[] | undefined {
	const pricingListState = selectPricingState(state);
	return pricingListState.graphTypes;
}

export function selectTsoSelectableValues(
	state: RootState
): { label: string; value: string }[] | undefined {
	const pricingListState = selectPricingState(state);
	return pricingListState.pricingTso.options;
}

export function selectTsoDefaultValue(state: RootState): string | undefined {
	const pricingListState = selectPricingState(state);
	return pricingListState.pricingTso.defaultValue;
}

export function selectTsoLoader(state: RootState): boolean {
	const pricingListState = selectPricingState(state);
	return pricingListState.loader.pricingTso;
}

export function selectTsoWarning(state: RootState): string | undefined {
	const pricingListState = selectPricingState(state);
	return pricingListState.pricingTso.warning;
}
