import SimpleTable, {
	HeadersType,
} from "../../../../common/components/SimpleTable/SimpleTable";
import React, { useEffect } from "react";
import {
	useLazyGetDealsQuery,
	useSyncContractPeriodBCVMutation,
	useSyncContractPeriodBookDealsMutation,
	useSyncContractPeriodCompensationBCVMutation,
	useSyncContractPeriodCompensationBookDealsMutation,
} from "../../../../requests_cm/gecoContractsService/service";
import { useRtkQueryDynamicEndpoint } from "../../../../common/hooks/useRtkQueryDynamicEndpoint";
import CircularProgress from "@mui/material/CircularProgress";
import { Spacer } from "../../../../common/components/Spacer";
import DealTabCancelButton from "./DealTabCancelButton";
import DealTabStatusButton from "./DealTabStatusButton";
import { ContractPeriodDealsType } from "./DealsTab.type";
import { Box } from "@mui/material";
import { DealType } from "../../../../requests_cm/gecoContractsService/types";
import { useIsUserWithinGroups } from "../../../../common/hooks/useIsUserWithinGroups";
import { Groups } from "../../../authentication/authentication.constant";
import NoDataMessage from "../../../../common/components/NoDataMessage";
import Tag from "../../../../common/components/Tag/Tag";
import { FluidButton } from "../../../../common/components/FluidButton";
import { enqueueSnackbar } from "notistack";

const DealsTab = ({ contractPeriodId }: { contractPeriodId: number }) => {
	const { isUserAuthorized } = useIsUserWithinGroups();
	const [getDealsBase, { data: dataDeals, isLoading: isLoadingDeals }] =
		useLazyGetDealsQuery();

	const [
		syncContractPeriodBcvBase,
		{
			isSuccess: isSyncBcvSuccess,
			isError: isSyncBcvError,
			reset: resetSyncBcv,
		},
	] = useSyncContractPeriodBCVMutation();
	const [
		syncContractPeriodDealsBase,
		{
			isSuccess: isSyncDealsSuccess,
			isError: isSyncDealsError,
			reset: resetSyncDeals,
		},
	] = useSyncContractPeriodBookDealsMutation();
	const [
		syncContractPeriodCompensationBcvBase,
		{
			isSuccess: isSyncCompensationDealsSuccess,
			isError: isSyncCompensationDealsError,
			reset: resetSyncCompensationDeals,
		},
	] = useSyncContractPeriodCompensationBCVMutation();
	const [
		syncContractPeriodCompensationDealsBase,
		{
			isSuccess: isSyncCompensationBcvSuccess,
			isError: isSyncCompensationBcvError,
			reset: resetSyncCompensationBcv,
		},
	] = useSyncContractPeriodCompensationBookDealsMutation();

	const syncContractPeriodBcv = useRtkQueryDynamicEndpoint(
		syncContractPeriodBcvBase
	);
	const syncContractPeriodDeals = useRtkQueryDynamicEndpoint(
		syncContractPeriodDealsBase
	);
	const syncContractPeriodCompensationBcv = useRtkQueryDynamicEndpoint(
		syncContractPeriodCompensationBcvBase
	);
	const syncContractPeriodCompensationDeals = useRtkQueryDynamicEndpoint(
		syncContractPeriodCompensationDealsBase
	);

	const getDeals = useRtkQueryDynamicEndpoint(getDealsBase);

	const isUserAuthorizedBool = isUserAuthorized([
		Groups.geco_admin,
		Groups.geco_trader,
	]);

	useEffect(() => {
		getDeals({ contract_period_id: contractPeriodId }, false);

		const interval = setInterval(() => {
			getDeals({ contract_period_id: contractPeriodId }, false);
		}, 5000);

		return () => clearInterval(interval);
	}, []);

	useEffect(() => {
		if (
			isSyncBcvSuccess ||
			isSyncDealsSuccess ||
			isSyncCompensationDealsSuccess ||
			isSyncCompensationBcvSuccess
		) {
			enqueueSnackbar(`Sync dispatched successfully`, {
				variant: "success",
				autoHideDuration: 2000,
			});
			resetSyncBcv();
			resetSyncDeals();
			resetSyncCompensationDeals();
			resetSyncCompensationBcv();
		}
	}, [
		isSyncBcvSuccess,
		isSyncDealsSuccess,
		isSyncCompensationDealsSuccess,
		isSyncCompensationBcvSuccess,
	]);

	useEffect(() => {
		if (
			isSyncBcvError ||
			isSyncDealsError ||
			isSyncCompensationDealsError ||
			isSyncCompensationBcvError
		) {
			enqueueSnackbar(`Could not dispatch sync`, {
				variant: "error",
				autoHideDuration: 2000,
			});
			resetSyncBcv();
			resetSyncDeals();
			resetSyncCompensationDeals();
			resetSyncCompensationBcv();
		}
	}, [
		isSyncBcvError,
		isSyncDealsError,
		isSyncCompensationDealsError,
		isSyncCompensationBcvError,
	]);

	let headers: HeadersType<ContractPeriodDealsType>[] = [
		{ label: "Trade ID", accessor: "trade_id" },
		{ label: "Year", accessor: "year" },
		{
			label: "Start Date",
			accessor: "start_date",
		},
		{
			label: "End Date",
			accessor: "end_date",
		},
		{ label: "Deal Type", accessor: "deal_type" },
		{ label: "Deal Status", accessor: "deal_status" },
		{ label: "Curtailment Object", accessor: "curtailment_object" },
		{ label: "Actions", accessor: "actions" },
	];
	headers = headers.filter(
		(header) => header.accessor !== "actions" || isUserAuthorizedBool
	);

	const dealsObjects = dataDeals?.map((deal) => ({
		...deal,
		deal_type: <Tag label={deal.deal_type} size="small" />,
		deal_status: <DealTabStatusButton deal={deal} />,
		curtailment_object: deal.curtailment_object_name,
		actions:
			isUserAuthorizedBool && deal.trade_id ? (
				<DealTabCancelButton
					contractPeriodId={contractPeriodId}
					dealId={deal.id}
					isCompensation={deal.deal_type?.includes(
						DealType.COMPENSATION
					)}
				/>
			) : null,
	})) as ContractPeriodDealsType[];

	return (
		<>
			<Spacer gap={24} />
			{isLoadingDeals && (
				<Box sx={{ display: "flex", justifyContent: "center" }}>
					<CircularProgress />
				</Box>
			)}
			{!isLoadingDeals && (
				<>
					{dataDeals && !dealsObjects?.length && <NoDataMessage />}
					{!!dealsObjects?.length && (
						<>
							<Box
								sx={{
									display: "flex",
									gap: "16px",
									marginBottom: "24px",
								}}
							>
								<FluidButton
									icon="sync"
									label="Sync Power/Goo Deals"
									emphasis="subtle"
									onClick={() =>
										syncContractPeriodDeals({
											contract_period_id:
												contractPeriodId,
										})
									}
									isDisabled={!isUserAuthorizedBool}
								/>
								<FluidButton
									icon="sync"
									label="Sync Power/Goo BCV(s)"
									emphasis="subtle"
									onClick={() =>
										syncContractPeriodBcv({
											contract_period_id:
												contractPeriodId,
										})
									}
									isDisabled={!isUserAuthorizedBool}
								/>
								<FluidButton
									icon="sync"
									label="Sync Compensation Deals"
									emphasis="subtle"
									onClick={() =>
										syncContractPeriodCompensationDeals({
											contract_period_id:
												contractPeriodId,
										})
									}
									isDisabled={!isUserAuthorizedBool}
								/>
								<FluidButton
									icon="sync"
									label="Sync Compensation BCV(s)"
									emphasis="subtle"
									onClick={() =>
										syncContractPeriodCompensationBcv({
											contract_period_id:
												contractPeriodId,
										})
									}
									isDisabled={!isUserAuthorizedBool}
								/>
							</Box>

							<SimpleTable
								dataTestid="clickabilityTable"
								headers={headers}
								items={dealsObjects}
								infiniteScroll
							/>
						</>
					)}
				</>
			)}
		</>
	);
};

export default DealsTab;
