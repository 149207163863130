import * as React from "react";
import { useAppDispatch } from "../../../common/hooks/default";
import { requestGeneratingGraph, downloadGraph } from "../tenderForm.thunk";

import { useSelector } from "react-redux";
import { selectLoader } from "../tenderForm.selector";
import { If } from "../../../common/components/If";
import { TenderGraphStatus } from "../tenderForm.module";
import { PrimaryButton } from "../../../common/components/CustomButton";
import { Tender } from "../../tender_page/tender.module";
import { RootState } from "../../../core/rootReducers";
import EqualizerIcon from "@mui/icons-material/Equalizer";
import ErrorMessageModal from "../../../common/components/ErrorMessageDialog";
import { TSInUse } from "./timeserie_tab/TimeseriesTable";
import AlertDialogModal from "../../../common/components/AlertDialogModal";
import { Tooltip } from "@mui/material";

export const TenderGraphAction = (
	tender: Tender,
	ts_list: TSInUse[],
	tenderGraphStatus?: TenderGraphStatus
) => {
	const dispatch = useAppDispatch();
	const downloadGraphLoader = useSelector((state: RootState) =>
		selectLoader(state, "downloadGraph")
	);
	const generateGraphLoader = useSelector((state: RootState) =>
		selectLoader(state, "generateGraph")
	);
	const [openCancelModal, setOpenCancelModal] =
		React.useState<boolean>(false);
	const [fileError, setFileError] = React.useState<{
		title: string;
		content?: string;
	}>({ title: "", content: undefined });

	const displayError = (t: Tender) => {
		if (t?.graph_error) {
			setFileError({
				title: `Tender Graph ${t.id}`,
				content: t?.graph_error,
			});
		}
	};
	const afterClosingErrorModal = () => {
		setFileError({ title: "", content: undefined });
	};

	const cancelGraphGeneration = () => {
		setOpenCancelModal(false);
		dispatch(
			requestGeneratingGraph({
				tender_id: tender.id,
				ts_list,
				action: "CANCEL",
			})
		);
	};
	return (
		<>
			<If
				condition={
					tenderGraphStatus != undefined &&
					[
						TenderGraphStatus.NEW,
						TenderGraphStatus.READY,
						TenderGraphStatus.ERROR,
					].includes(tenderGraphStatus)
				}
			>
				<PrimaryButton
					color="secondary"
					sx={{
						marginRight: "16px",
					}}
					onClick={() => {
						dispatch(
							requestGeneratingGraph({
								tender_id: tender.id,
								ts_list,
							})
						);
					}}
					disabled={generateGraphLoader}
					loader={generateGraphLoader}
				>
					{!generateGraphLoader && <>{"Generate Graph"}</>}
				</PrimaryButton>
			</If>

			<If condition={tenderGraphStatus === TenderGraphStatus.PENDING}>
				<Tooltip title={"Cancel the process"} disableFocusListener>
					<PrimaryButton
						color="secondary"
						sx={{
							marginRight: "16px",
						}}
						onClick={() => {
							setOpenCancelModal(true);
						}}
					>
						<EqualizerIcon />
						{"Pending..."}
					</PrimaryButton>
				</Tooltip>
				<AlertDialogModal
					title={"are you sure you want to cancel the process?"}
					isOpen={
						openCancelModal &&
						tenderGraphStatus === TenderGraphStatus.PENDING
					}
					handleApprove={cancelGraphGeneration}
					handleClose={() => {
						setOpenCancelModal(false);
					}}
				/>
			</If>
			<If
				condition={
					tenderGraphStatus === TenderGraphStatus.READY &&
					tender?.graph_file_id
				}
			>
				<PrimaryButton
					color="secondary"
					sx={{
						marginRight: "16px",
					}}
					onClick={() => {
						dispatch(downloadGraph(tender?.graph_file_id));
					}}
					disabled={downloadGraphLoader}
					loader={downloadGraphLoader}
				>
					{!downloadGraphLoader && (
						<>
							<EqualizerIcon />
							{"Download Graph"}
						</>
					)}
				</PrimaryButton>
			</If>
			<If condition={tenderGraphStatus === TenderGraphStatus.ERROR}>
				<>
					<PrimaryButton
						color="secondary"
						sx={{
							marginRight: "16px",
						}}
						onClick={() => {
							displayError(tender);
						}}
					>
						<EqualizerIcon />
						{"ERROR..."}
					</PrimaryButton>
					<ErrorMessageModal
						title={fileError.title}
						content={fileError.content}
						actionAfterClose={afterClosingErrorModal}
					></ErrorMessageModal>
				</>
			</If>
		</>
	);
};
