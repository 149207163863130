import Autocomplete, {
	AutocompleteRenderOptionState,
} from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";
import TextField, { StandardTextFieldProps } from "@mui/material/TextField";
import * as React from "react";
import { debounce } from "@mui/material/utils";
import { PaperProps, SxProps, Theme } from "@mui/material";
import usePrevious from "../hooks/usePrevious";
import { SystemProps } from "@mui/system";
import { ErrorText } from "./ErrorText";

export interface AutocompleteAble {
	id: string | number;
	name: string;
}

export interface CustomAutocompleteProps<Of extends AutocompleteAble>
	extends StandardTextFieldProps {
	fetchData: (search: string) => void;
	setFieldValue: (fieldName: string, value: string | Of | null) => void;
	data: Of[];
	label: string;
	errorMessage?: string | string[];
	loading?: boolean;
	sx?: SxProps<Theme>;
	paperSx?: {
		paper?: PaperProps;
	};
	freeSolo?: boolean;
	renderOption?: (
		props: React.HTMLAttributes<HTMLLIElement>,
		option: string | Of,
		state: AutocompleteRenderOptionState
	) => React.ReactNode;
}

const style: { [key: string]: SxProps<Theme> } = {
	autocompleteContainer: {
		maxHeight: 40,
		"& label": {
			top: -8,
		},
		"& div": {
			borderRadius: 0,
			height: 40,
			"& .MuiOutlinedInput-root": {
				padding: "0 8px",
			},
		},
	},
	textfield: {
		backgroundColor: "white",
	},
};

export default function CustomAutocomplete<Of extends AutocompleteAble>(
	props: CustomAutocompleteProps<Of>
) {
	const [open, setOpen] = React.useState(false);
	const [options, setOptions] = React.useState<readonly (string | Of)[]>([]);
	const [inputValue, setInputValue] = React.useState("");
	const [value, setValue] = React.useState<Of | string | null>(
		props.value ? (props.value as Of) : null
	);

	const fetch = React.useMemo(() => {
		return debounce((newInputValue: string) => {
			props.fetchData(newInputValue);
		}, 400);
	}, []);

	const onInputChange = React.useCallback(
		(event: any, newInputValue: any) => {
			setInputValue(newInputValue);
			if (props.freeSolo)
				props.setFieldValue(props.name as string, newInputValue);
			fetch(newInputValue);
		},
		[props.name]
	);

	const containObject = (list: (string | Of)[], object: string | Of) => {
		return list.findIndex((element: string | Of) =>
			typeof element == "string"
				? element === object
				: element.id === (object as Of).id
		);
	};
	React.useEffect(() => {
		let newOptions: (string | Of)[] = [];
		if (value) {
			newOptions = [value];
		}
		if (value && containObject(props.data, value as string | Of) == -1) {
			newOptions = [...newOptions, ...props.data];
		} else {
			newOptions = props.data;
		}
		if (newOptions.length > 0) {
			setOptions(newOptions);
		}
	}, [props.data, value]);

	React.useEffect(() => {
		setValue(props.value ? (props.value as Of) : "");
	}, [props.value]);

	React.useEffect(() => {
		if (inputValue === "") {
			setOptions(value ? [value] : []);
			return undefined;
		}
	}, []);

	let preInputValue = usePrevious(inputValue);

	React.useEffect(() => {
		if (inputValue !== preInputValue) {
			setOptions([]);
		}
	}, [value, fetch]);

	React.useEffect(() => {
		let newOptions = [...options];
		if (
			containObject(newOptions, value ?? "") === -1 &&
			inputValue &&
			preInputValue !== "" &&
			newOptions.length == 0
		) {
			fetch(inputValue);
		}
	}, [options, inputValue]);

	const isOptionEqualToValue = (option: string | Of) => {
		if (value === "") return true;
		if (!value) return false;
		if (typeof option == "string") return option == inputValue;
		return (option as Of).id === (value as Of).id;
	};
	const onChangeAutocomplete = React.useCallback(
		(event: any, newValue: Of | string | null) => {
			props.setFieldValue(props.name as string, newValue);
			setOptions(newValue ? [newValue, ...options] : options);
			setValue(newValue);
		},
		[props.name, options, value]
	);

	return (
		<>
			<Autocomplete
				disabled={props.disabled}
				sx={{
					...(style.autocompleteContainer as SystemProps<Theme>),
					...props.sx,
				}}
				componentsProps={props.paperSx}
				open={open}
				onOpen={() => {
					setOpen(true);
				}}
				onClose={() => {
					setOpen(false);
				}}
				onFocus={() => setOpen(true)}
				isOptionEqualToValue={isOptionEqualToValue}
				onChange={onChangeAutocomplete}
				value={value}
				onInputChange={onInputChange}
				getOptionLabel={(option: any) => {
					if (option.inputValue) {
						return option.inputValue;
					} else {
						return typeof option == "string" ? option : option.name;
					}
				}}
				renderOption={props.renderOption}
				options={options}
				freeSolo={props.freeSolo ?? false}
				loading={props.loading}
				renderInput={(params) => (
					<TextField
						{...params}
						name={props.name}
						label={props.label}
						error={!!props.error}
						helperText={props.helperText}
						sx={style.textfield}
						required
						InputProps={{
							...params.InputProps,
							endAdornment: (
								<React.Fragment>
									{props.loading ? (
										<CircularProgress
											color="inherit"
											size={20}
										/>
									) : null}
								</React.Fragment>
							),
						}}
					/>
				)}
			/>
			<ErrorText>{props.errorMessage}</ErrorText>
		</>
	);
}
