import Modal from "../../../../../common/components/Modal";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { colorPaletteBlue600 } from "../../../../../core/theme";
import { Box } from "@mui/system";
import { Formik, FormikValues } from "formik";
import FluidDatePicker from "../../../../../common/components/FluidDatePicker";
import { InputLabel } from "@mui/material";
import { PrimaryButton } from "../../../../../common/components/CustomButton";
import { FluidRadioGroup } from "../../../../../common/components/FluidRadioGroup";
import CustomInput from "../../../../../common/components/CustomInput";
import * as yup from "yup";
import {
	AccountingModelValues,
	DuldungsfallStatusValues,
	RedispatchConstraint,
	SettlementModelValues,
} from "../../../../../requests_cm/gecoSitesService/types";

const EditRedispatchConstraintModal = ({
	isOpen,
	onClose,
	onSubmit,
	constraintModel,
	constraintEdit,
}: {
	isOpen: boolean;
	onClose: () => void;
	onSubmit: (values: FormikValues) => void;
	constraintModel?: RedispatchConstraint;
	constraintEdit?: RedispatchConstraint;
}) => {
	const constraintBase = constraintEdit ?? constraintModel;

	const validationSchema = yup.object().shape({
		name: yup
			.string()
			.required("Name is required")
			.test(
				"unique-name",
				"Name must be different from the default value",
				function (value) {
					return constraintEdit === undefined
						? value !== constraintBase?.name
						: true;
				}
			),
	});

	return (
		<Modal
			title={
				<Box sx={{ display: "flex", gap: "8px", alignItems: "center" }}>
					<AddCircleOutlineIcon sx={{ color: colorPaletteBlue600 }} />
					{constraintEdit?.id ? "Edit" : "Add"} Redispatch Constraint
				</Box>
			}
			open={isOpen}
			onClose={onClose}
		>
			<Formik
				initialValues={{
					name: constraintBase?.name ?? "",
					start_date: constraintBase?.start_date ?? "",
					end_date: constraintBase?.end_date ?? "",
					technical_resource_operator:
						constraintBase?.technical_resource_operator ?? "",
					eiv: constraintBase?.eiv ?? "",
					supplier: constraintBase?.supplier ?? "",
					tr_settlement_model:
						constraintBase?.tr_settlement_model ?? "",
					accounting_model: constraintBase?.accounting_model ?? "",
					redispatch_mode: constraintBase?.redispatch_mode ?? "",
				}}
				validationSchema={validationSchema}
				onSubmit={(values) => {
					const newValues = {
						...values,
						start_date: values.start_date
							? values.start_date
							: "2000-01-01",
						end_date: values.end_date
							? values.end_date
							: "2100-01-01",
					};

					onSubmit(newValues);
				}}
			>
				{({
					errors,
					handleChange,
					handleSubmit,
					setFieldValue,
					submitForm,
					touched,
					values,
				}) => (
					<form onSubmit={handleSubmit}>
						<Box
							sx={{
								display: "flex",
								flexDirection: "column",
								gap: "16px",
							}}
						>
							<Box>
								<InputLabel shrink={false} htmlFor="name">
									Name *
								</InputLabel>
								<CustomInput
									id="name"
									aria-label="name"
									value={values.name}
									onChange={handleChange}
									fullWidth
									errorText={errors.name}
									touched={touched.name}
								/>
							</Box>
							<Box sx={{ display: "flex", gap: "16px" }}>
								<FluidDatePicker
									name="start_date"
									onChange={handleChange}
									label={"Start Date"}
									value={values.start_date}
								/>
								<FluidDatePicker
									name="end_date"
									onChange={handleChange}
									label={"End Date"}
									value={values.end_date}
								/>
							</Box>
							<Box>
								<InputLabel
									shrink={false}
									htmlFor="technical_resource_operator"
								>
									BTR
								</InputLabel>
								<CustomInput
									id="technical_resource_operator"
									aria-label="technical_resource_operator"
									value={values.technical_resource_operator}
									onChange={handleChange}
									fullWidth
								/>
							</Box>
							<Box>
								<InputLabel shrink={false} htmlFor="eiv">
									Eiv
								</InputLabel>
								<CustomInput
									id="eiv"
									aria-label="eiv"
									value={values.eiv}
									onChange={handleChange}
									fullWidth
								/>
							</Box>
							<Box>
								<InputLabel shrink={false} htmlFor="supplier">
									Lieferant
								</InputLabel>
								<CustomInput
									id="supplier"
									aria-label="supplier"
									value={values.supplier}
									onChange={handleChange}
									fullWidth
								/>
							</Box>
							<FluidRadioGroup
								name="tr_settlement_model"
								onChange={(value: string) =>
									setFieldValue("tr_settlement_model", value)
								}
								orientation="row"
								options={Object.values(
									SettlementModelValues
								).map((value) => ({
									label: String(value),
									value: String(value),
								}))}
								title="Settlement Model"
								value={values.tr_settlement_model}
							/>
							<FluidRadioGroup
								name="accounting_model"
								onChange={(value: string) =>
									setFieldValue("accounting_model", value)
								}
								orientation="row"
								options={Object.values(
									AccountingModelValues
								).map((value) => ({
									label: String(value),
									value: String(value),
								}))}
								title="Account Model"
								value={values.accounting_model}
							/>
							<FluidRadioGroup
								name="redispatch_mode"
								onChange={(value: string) =>
									setFieldValue("redispatch_mode", value)
								}
								orientation="row"
								options={Object.values(
									DuldungsfallStatusValues
								).map((value) => ({
									label: String(value),
									value: String(value),
								}))}
								title="Status Duldungsfall"
								value={values.redispatch_mode}
							/>

							<Box
								sx={{
									display: "flex",
									justifyContent: "flex-end",
									gap: "16px",
								}}
							>
								<PrimaryButton
									onClick={onClose}
									text="Cancel"
									type="button"
									color="secondary"
								/>
								<PrimaryButton
									onClick={submitForm}
									text={constraintEdit?.id ? "Edit" : "Add"}
									type="button"
									color="primary"
								/>
							</Box>
						</Box>
					</form>
				)}
			</Formik>
		</Modal>
	);
};

export default EditRedispatchConstraintModal;
