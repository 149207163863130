import { useCallback, useState } from "react";
import { CircularProgress } from "@mui/material";
import { Formik } from "formik";

import {
	ContractDetailType,
	ContractPeriodType,
} from "../../../../requests_cm/gecoContractsService/types";
import GeneralTabForm from "./GeneralTabForm";
import {
	GeneralTabFormikData,
	generalTabFormikInitialValueMapper,
	formikDataToContractModelMapper,
} from "../../formik/generalTabFormik";
import { If } from "../../../../common/components/If";
import { HorizonChange } from "../../../horizon_change/HorizonChange";

export interface GeneralTabProps {
	contract?: ContractDetailType;
	contractPeriod: ContractPeriodType | undefined;
	onSaveDraftContract: (contract: ContractDetailType) => void;
}

const GeneralTab = ({
	contract,
	contractPeriod,
	onSaveDraftContract,
}: GeneralTabProps) => {
	const [isHorizonChangeOpen, setHorizonChangeOpen] = useState(false);

	const onUpdateGeneralTab = useCallback(
		(data: GeneralTabFormikData) => {
			return onSaveDraftContract(
				formikDataToContractModelMapper(
					data,
					contract as ContractDetailType,
					contractPeriod?.id!
				)
			);
		},
		[contract, contractPeriod?.id]
	);

	return (
		<>
			<If condition={!contract}>
				<CircularProgress
					sx={{ marginTop: "40px" }}
					data-testid="loader"
				/>
			</If>
			<If condition={!!contract}>
				<Formik
					enableReinitialize
					initialValues={generalTabFormikInitialValueMapper(
						contract as ContractDetailType,
						contractPeriod?.id!
					)}
					onSubmit={onUpdateGeneralTab}
				>
					{() => (
						<GeneralTabForm
							{...{
								setHorizonChangeOpen,
							}}
						/>
					)}
				</Formik>
			</If>
			<HorizonChange
				contractPeriod={contractPeriod}
				isOpen={isHorizonChangeOpen}
				onClose={() => setHorizonChangeOpen(false)}
			/>
		</>
	);
};

export default GeneralTab;
