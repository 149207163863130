import { Box, Chip } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import { PrimaryButton } from "../../common/components/CustomButton";
import RefreshIcon from "@mui/icons-material/Refresh";
import { useMemo } from "react";
import { buildPredicates, removeEmptyListsAndNullValues } from "./utils";
import { Filter, FilterType } from "../metadata/metadata.module";
import { PersistedFilters, setPersistedFilters } from "./filtersSlice";
import { useDispatch } from "react-redux";
import { If } from "../../common/components/If";
import { format, parseISO } from "date-fns";

const filtersPanelStyles = {
	display: "flex",
	flexWrap: "wrap",
	gap: "10px",
	margin: "0 20px",
	font: "Lato",
	fontSize: "24px",
	fontWeight: 700,
	width: "100%",
	marginBottom: "20px",
	"& p": {
		marginRight: "30px",
		marginTop: "-4px",
	},
	"& .MuiChip-root": {
		borderRadius: "0px",
		backgroundColor: "#E7EEFC",
		color: "#171D21",
		font: "Lato",
		fontSize: "14px",
		fontWeight: 400,
	},
	"& .MuiChip-deleteIcon": {
		color: "#171D21",
	},
	"& .MuiChip-deleteIcon:hover": {
		color: "#656e67",
	},
};

interface FilterPillsProps {
	persistedFilters?: {
		filters: PersistedFilters | undefined;
	};
	filters?: string;
	filtersData: Filter[];
	page: string;
}

export default function FilterPills(props: FilterPillsProps) {
	const { persistedFilters, filtersData, filters, page } = props;
	const dispatch = useDispatch();

	const selectedFilters = useMemo(() => {
		const parsedFilters = JSON.parse(filters || "{}");

		return Object.entries(parsedFilters);
	}, [filters]);

	const updateFilters = (parsedFilters: any) => {
		const { predicates } = buildPredicates(
			filtersData,
			parsedFilters,
			page
		);

		const filteredValues = removeEmptyListsAndNullValues(parsedFilters);
		const odataString = `"${predicates.join(" and ")}"`;
		const filtersToPersist = JSON.stringify(filteredValues);
		if (!persistedFilters) {
			return;
		}

		switch (page) {
			case "Tenders Workflow view":
				dispatch(
					setPersistedFilters({
						...persistedFilters.filters,
						tenders_workflow_view_filters: filtersToPersist,
						tenders_workflow_view_filters_count:
							predicates.length.toString(),
						tenders_workflow_view_query: odataString,
					})
				);
				break;
			case "Tenders":
				dispatch(
					setPersistedFilters({
						...persistedFilters.filters,
						tenders_filters: filtersToPersist,
						tenders_filters_count: predicates.length.toString(),
						tenders_query: odataString,
					})
				);
				break;
			default:
				dispatch(
					setPersistedFilters({
						...persistedFilters.filters,
						pricings_filters: filtersToPersist,
						pricings_filters_count: predicates.length.toString(),
						pricings_query: odataString,
					})
				);
				break;
		}
	};

	const handleDelete = (filterName: string, filterValue: any) => {
		if (!filters) {
			return;
		}
		let parsedFilters = JSON.parse(filters || "{}");

		if (Array.isArray(parsedFilters[filterName])) {
			parsedFilters[filterName] = parsedFilters[filterName].filter(
				(p: any) => p.id !== filterValue.id
			);
			if (parsedFilters[filterName].length === 0) {
				delete parsedFilters[filterName];
			}
		} else {
			delete parsedFilters[filterName];
		}

		updateFilters(parsedFilters);
	};

	const handleReset = () => {
		updateFilters({});
	};

	return (
		<Box sx={filtersPanelStyles}>
			<p>Filters applied:</p>
			<If condition={selectedFilters.length === 0}>
				<Chip label="None" variant="outlined" color="primary" />
			</If>
			<If condition={selectedFilters.length > 0}>
				{selectedFilters.map((filterItem) => {
					const filterDesc = filtersData.find(
						(fd) => fd.field_key === filterItem[0]
					);
					if (Array.isArray(filterItem[1])) {
						if (filterDesc?.filter_type === FilterType.date) {
							const label = `${filterItem[0]}: ${format(
								parseISO(filterItem[1][0]),
								"dd/MM/yyyy"
							)} <-> ${format(
								parseISO(filterItem[1][1]),
								"dd/MM/yyyy"
							)}`;
							return (
								<Chip
									label={label}
									variant="outlined"
									color="primary"
									key={label}
									deleteIcon={<ClearIcon />}
									onDelete={() => {
										handleDelete(filterItem[0], "");
									}}
								/>
							);
						} else {
							return filterItem[1].map((filterValue) => {
								const value = filterValue.name;
								return (
									<Chip
										label={`${filterItem[0]}: ${value}`}
										variant="outlined"
										color="primary"
										key={`${filterItem[0]}: ${value}`}
										deleteIcon={<ClearIcon />}
										onDelete={() => {
											handleDelete(
												filterItem[0],
												filterValue
											);
										}}
									/>
								);
							});
						}
					} else {
						return (
							<Chip
								label={filterItem[0]}
								variant="outlined"
								color="primary"
								deleteIcon={<ClearIcon />}
								key={filterItem[0]}
							/>
						);
					}
				})}
				<PrimaryButton
					text="Reset"
					type="button"
					color="secondary"
					data-testid="open-filters"
					sx={{
						width: "100px",
						minWidth: "100px",
						height: "32px",
						marginLeft: "20px",
					}}
					onClick={handleReset}
					startIcon={<RefreshIcon />}
				/>
			</If>
		</Box>
	);
}
