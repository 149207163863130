import { apiPost } from "../../common/utils/request.utils";
import { AppDispatch } from "../../store";
import * as actions from "./ErrorModal.slice";

export function submitError(description: string, error: string) {
	return async function (dispatch: AppDispatch) {
		dispatch(actions.submit());
		try {
			await apiPost("/external-services/signal-error", {
				description,
				error,
			});
			dispatch(actions.submitSuccess());
		} catch (err) {
			dispatch(actions.submitFailure());
		}
	};
}
