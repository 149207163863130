import { Spacer } from "../../../../common/components/Spacer";
import { useGetContractPeriodCurtailmentObjectsQuery } from "../../../../requests_cm/gecoContractsService/service";
import { useRtkQueryDynamicEndpoint } from "../../../../common/hooks/useRtkQueryDynamicEndpoint";
import CircularProgress from "@mui/material/CircularProgress";
import CurtailmentObjectBySite from "./CurtailmentObjectBySite";
import NoDataMessage from "../../../../common/components/NoDataMessage";
import { useCurtailementObjectsFilters } from "../../../../common/hooks/useCurtailementObjectsFilters";

const CurtailmentObjectTab = ({
	contractPeriodId,
}: {
	contractPeriodId: number;
}) => {
	const { data, isLoading } = useRtkQueryDynamicEndpoint(
		useGetContractPeriodCurtailmentObjectsQuery
	)({ contract_period_id: contractPeriodId });

	const { curtailementObjectsGroupsBySites } =
		useCurtailementObjectsFilters(data);

	return (
		<>
			<Spacer gap={24} />
			{isLoading && <CircularProgress />}
			{!isLoading && (
				<>
					{data && !data.length && <NoDataMessage />}
					{!!data?.length &&
						curtailementObjectsGroupsBySites.map(
							(curtailmentObjectGroup) => (
								<CurtailmentObjectBySite
									key={curtailmentObjectGroup.site}
									curtailmentObjectGroup={
										curtailmentObjectGroup
									}
								/>
							)
						)}
				</>
			)}
		</>
	);
};

export default CurtailmentObjectTab;
