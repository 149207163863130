import { useCallback, useEffect, useState } from "react";
import { Column } from "../../../../common/components/ColumnsSelector/ColumnsSelector";
import { pricingListColumns } from "./pricingListColumns";
import * as localStorage from "../../../../common/utils/localStorage.util";
import * as _ from "lodash";
import { ColumnConfigPreference, ColumnIds } from "./common";
import { migrate } from "./migrations";

const COLUMN_CONFIG_KEY = "pricing_list_columns";
const VERSION = 14;

export function usePricingListColumnSelection() {
	const [selectedColumns, setSelectedColumns] = useState<Column<ColumnIds>[]>(
		[]
	);

	useEffect(() => {
		const existingColumns = localStorage.get<ColumnConfigPreference>(
			COLUMN_CONFIG_KEY,
			{ version: VERSION, data: pricingListColumns }
		);
		const migrated = migrate(existingColumns);
		if (migrated.version !== existingColumns.version) {
			localStorage.set(COLUMN_CONFIG_KEY, migrated);
		}
		setSelectedColumns(migrated.data);
	}, []);

	const onColumnToggled = useCallback(
		(column: Column<ColumnIds>) => {
			setSelectedColumns((prev) => {
				const newColumns = _.xorBy(prev, [column], "id");
				// we can, but should we do this here ?
				localStorage.set(COLUMN_CONFIG_KEY, {
					version: VERSION,
					data: newColumns,
				});
				return newColumns;
			});
		},
		[setSelectedColumns]
	);
	return { selectedColumns, onColumnToggled };
}
