import ReactDiffViewer, { DiffMethod } from "react-diff-viewer-continued";
import { JsonDiff } from "../../../requests_cm/gepoChangeRequestService/types";

import { useRtkQueryDynamicEndpoint } from "../../../common/hooks/useRtkQueryDynamicEndpoint";
import {
	forwardRef,
	useCallback,
	useEffect,
	useImperativeHandle,
	useMemo,
} from "react";
import { updateOldValueWithDiffs } from "../../../requests_cm/gepoChangeRequestService/utils";
import ErrorMessageModal from "../../../common/components/ErrorMessageDialog";
import { jsonStringToObjectString } from "../helpers";
import {
	useLazyGetOneContractQuery,
	useUpdateOneContractMutation,
} from "../../../requests_cm/gecoContractsService/service";
import { ContractDetailType } from "../../../requests_cm/gecoContractsService/types";

export interface ContractValidationProps {
	contractId: number | undefined;
	jsonDiff: JsonDiff[];
	onConfirmSuccess: () => void;
}

// eslint-disable-next-line react/display-name
export const ContractValidation = forwardRef<ContractValidationProps>(
	(props, ref) => {
		// eslint-disable-next-line react/prop-types
		const { contractId, jsonDiff, onConfirmSuccess } =
			props as ContractValidationProps;
		const [
			getContractBase,
			{
				data: originalContract,

				error: getContractError,
			},
		] = useLazyGetOneContractQuery();

		const [
			updateContractBase,
			{ isSuccess: updateContractSuccess, error: updateContractError },
		] = useUpdateOneContractMutation();

		const updateContract = useRtkQueryDynamicEndpoint(updateContractBase);
		const getContract = useRtkQueryDynamicEndpoint(getContractBase);

		useEffect(() => {
			if (contractId) {
				getContract({
					contract_id: contractId,
					skip_validation: false,
				});
			}
		}, [contractId, getContract]);

		const contractWithChanges: ContractDetailType | undefined =
			useMemo(() => {
				if (originalContract && jsonDiff) {
					const result = updateOldValueWithDiffs<ContractDetailType>(
						originalContract,
						jsonDiff
					);
					return result;
				}
			}, [originalContract, jsonDiff]);

		const onConfirmUpdate = useCallback(() => {
			if (contractWithChanges) {
				updateContract(contractWithChanges as ContractDetailType);
			}
		}, [contractWithChanges]);

		useImperativeHandle(ref, () => ({
			// the onConfirmUpdate is the important bit
			onConfirmUpdate,
			contractId,
			jsonDiff,
			onConfirmSuccess,
		}));

		useEffect(() => {
			if (updateContractSuccess) {
				onConfirmSuccess();
			}
		}, [updateContractSuccess]);

		return (
			<>
				<ReactDiffViewer
					oldValue={
						originalContract
							? jsonStringToObjectString(
									JSON.stringify(originalContract)
							  )
							: ""
					}
					newValue={
						contractWithChanges
							? jsonStringToObjectString(
									JSON.stringify(contractWithChanges)
							  )
							: ""
					}
					compareMethod={DiffMethod.WORDS}
					splitView={true}
				/>
				<ErrorMessageModal
					title={`We have encoutered an issue with the contract ${contractId} with the following error.`}
					content={getContractError || updateContractError}
				/>
			</>
		);
	}
);
