import _ from "lodash";
import { CurtailmentObjectType } from "../../requests_cm/gecoSitesService/types";
import { CurtailmentObjectGroupType } from "../../features/contract_period/ContractPeriodTabs/CurtailmentObjectTab/CurtailmentObject.types";
import { useEffect, useMemo, useState } from "react";

export interface CurtailmentObjcetsFilters {
	curtailementObjectsGroupsBySites: CurtailmentObjectGroupType[];
	updateFilterByKinds: (kinds: { name: string; isActive: boolean }[]) => void;
	filterByKinds: { name: string; isActive: boolean }[];
}

export const useCurtailementObjectsFilters = (
	curtailmentObjects: CurtailmentObjectType[] | undefined
): CurtailmentObjcetsFilters => {
	const [activeCurtailementKinds, setActiveCurtailementKinds] = useState<
		{ name: string; isActive: boolean }[]
	>([]);

	useEffect(() => {
		if (curtailmentObjects?.length) {
			const data = _.uniqBy(
				curtailmentObjects.map((curtailement) => ({
					name: curtailement.curtailment_kind,
					isActive: true,
				})),
				"name"
			);
			setActiveCurtailementKinds(data);
		}
	}, [curtailmentObjects]);

	const curtailementObjectsGroupsBySites = useMemo(() => {
		if (curtailmentObjects) {
			return _.values(_.groupBy(curtailmentObjects, "site")).map(
				(items) => ({
					site: items[0].site,
					data: items.filter((entry: CurtailmentObjectType) =>
						_.find(activeCurtailementKinds, {
							name: entry.curtailment_kind,
							isActive: true,
						})
					),
					curtailmentKinds: _.filter(activeCurtailementKinds, {
						isActive: true,
					}).map((kind) => kind.name),
				})
			);
		}
		return [];
	}, [curtailmentObjects, activeCurtailementKinds]);

	return {
		curtailementObjectsGroupsBySites,
		updateFilterByKinds: setActiveCurtailementKinds,
		filterByKinds: activeCurtailementKinds,
	};
};
